export default {
    namespaced: true,
    state: {
        build: false,
        typeCommunication: ''
    },
    getters: {
        build(state) {
            return state.build
        },
        typeCommunication(state) {
            return state.typeCommunication
        }
    },
    mutations: {
        setBuilding(state, val) {
            state.build = val
        },
        setTypeCommunication(state, val) {
            state.typeCommunication = val
        }
    }
}