<template>
    <div class="oil-map-modal">
        <h2 class="oil-map-modal__title">{{ props.actualData.title.text }}</h2>
        <div class="oil-map-modal__object-container">
            <template v-for="item in props.actualData.subtitle" :key="item.id">
                <span class="oil-map-modal__object" v-if="item.id === 'Region'">{{ item.text }} x: {{ props.coordsRegion.x }} y: {{ props.coordsRegion.y }} / </span>
                <span class="oil-map-modal__object" v-if="item.id === 'Cell'">{{ item.text }} x: {{ props.coordsObject.x }} y: {{ props.coordsObject.y }}</span>
            </template>
        </div>
        <div class="oil-map-modal__table" 
            v-for="item in props.actualData.table" 
            :key="item" 
            :class="{_header: item.type === 'header', _content: item.type === 'content'}"
        >
            <template v-if="item.type === 'header'">
                <span v-for="elem in item.content" :key="elem">{{ elem.text }}</span>
            </template>
            <template v-else>
                <div v-for="elem in item.content" :key="elem" class="oil-map-modal__table-inner" @click="setType(elem.type)">
                    <div class="oil-map-modal__table-radio">
                        <svg v-if="type === elem.type" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5" cy="5" r="5" fill="#176DC1"/>
                        </svg>
                    </div>
                    <span>{{ elem.objectOfArrangement }}</span>
                    <!-- <span>{{ elem.capex }}</span> -->
                    <span>{{ elem.opex }}</span>
                    <span>{{ elem.tu }}</span>
                    <span>{{ elem.wfu }}</span>
                    <span>{{ elem.eu }}</span>
                </div>
            </template>
        </div>
        <div class="oil-map-modal__btns">
            <template
                v-for="item in props.actualData.buttons" 
                :key="item" 
            >
                <button-cmp     
                    v-if="item.name === 'Cancel'"
                    :text="item.text" 
                    :background="false"
                    @click="close"
                />
                <button-cmp     
                    v-else
                    :text="item.text" 
                    @click="setValue(item.name)"
                />
            </template>
        </div>
    </div>
</template>
<script>
import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'
import { mapActions, mapMutations } from 'vuex'
// import axios from 'axios'


export default {
    props: ['props'],
    data() {
        return {
            endpoint: 'api/v1/Purchased/ObjectOfArrangement',
            type: '',
            array: [
                {
                    name: 'Трубопровод',
                    capex: '500',
                    opex: '30',
                    time: '30',
                    brigades: '30',
                    energy: '30'
                },
                {
                    name: 'Трубопровод',
                    capex: '500',
                    opex: '30',
                    time: '30',
                    brigades: '30',
                    energy: '30'
                },
                {
                    name: 'Трубопровод',
                    capex: '500',
                    opex: '30',
                    time: '30',
                    brigades: '30',
                    energy: '30'
                },
                {
                    name: 'Трубопровод',
                    capex: '500',
                    opex: '30',
                    time: '30',
                    brigades: '30',
                    energy: '30'
                },
                {
                    name: 'Трубопровод',
                    capex: '500',
                    opex: '30',
                    time: '30',
                    brigades: '30',
                    energy: '30'
                },
                {
                    name: 'Трубопровод',
                    capex: '500',
                    opex: '30',
                    time: '30',
                    brigades: '30',
                    energy: '30'
                },
            ]
        }
    },
    components: {
        'button-cmp': buttonCmp
    },
    methods: {
        ...mapActions('request', ['setObject']),
        ...mapMutations('request', ['updateMap']),
        ...mapMutations('notification', ['openNotification']),
        ...mapMutations('modal', ['closeModal']),

        setType(val) {
            this.type = val
        },
        async setValue(name) {
            if(name === 'Add') {
                this.$root.setInfo({url: this.endpoint, body: {
                    "Key": this.type,
                    "Position": {
                        "X": this.$props.props.coordsRegion.y,
                        "Y": this.$props.props.coordsRegion.x,
                        "XInCell": this.$props.props.coordsObject.x,
                        "YInCell": this.$props.props.coordsObject.y
                    }
                }})
                .then((resp) => {
                    // console.log(typeof(resp), resp);
                    if(typeof(resp) === "number") {
                        this.updateMap()
                        this.$root.visibleNotification({
                            props: {
                                success: true,
                                title: "Готово",
                                desc: 'Объект успешно куплен!'
                            }
                        })
                    } else {
                        console.log(resp);
                        this.$root.visibleNotification({
                            props: {
                                error: true,
                                title: "Ошибка",
                                desc: resp.response.data[0]
                            }
                        })
                    }
                    this.closeModal()
                })
                .catch(err => {
                    console.log(err);
                })
            }
            },
            close() {
                this.closeModal()
            }
        },
        created() {
            console.log('qwerty');
        }
    }
</script>
