<template>
    <div class="oil-construct-table">
        <template v-if="view ==='header'">
            <div class="oil-construct-table__row _header" v-for="item in table" :key="item">
                <span class="oil-construct-table__row-cell">{{ item.interval }}</span>
                <span class="oil-construct-table__row-cell">{{ item.md }}</span>
                <span class="oil-construct-table__row-cell">{{ item.diameter }}</span>
            </div>
        </template>
        <transition-group name="fadeHeight">
            <template v-if="view === 'content'">
                <div class="oil-construct-table__row _table" v-for="(item, idx) in actualTable" :key="item" @click="openSubRow(idx, item.subrow)">
                    <div class="oil-construct-table__row__inner" :class="{_active: idx === subrow}">
                        <div class="oil-construct-table__row-cell">
                            <svg class="_chevron" v-if="item.subrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 9L12 15L18 9" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span >{{ item.interval }}</span>
                        </div>
                        <span class="oil-construct-table__row-cell">{{ item.md }}</span>
                        <span class="oil-construct-table__row-cell">{{ item.diameter }}</span>
                        <svg class="oil-construct-table__row-cell-basket" @click="deleteConstruct(item.id)" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 14V13.2C24 12.0799 24 11.5198 23.782 11.092C23.5903 10.7157 23.2843 10.4097 22.908 10.218C22.4802 10 21.9201 10 20.8 10H19.2C18.0799 10 17.5198 10 17.092 10.218C16.7157 10.4097 16.4097 10.7157 16.218 11.092C16 11.5198 16 12.0799 16 13.2V14M11 14H29M27 14V25.2C27 26.8802 27 27.7202 26.673 28.362C26.3854 28.9265 25.9265 29.3854 25.362 29.673C24.7202 30 23.8802 30 22.2 30H17.8C16.1198 30 15.2798 30 14.638 29.673C14.0735 29.3854 13.6146 28.9265 13.327 28.362C13 27.7202 13 26.8802 13 25.2V14" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <transition name="fadeHeight">
                        <template v-if="idx === subrow">
                            <div class="oil-construct-table__row-sub">
                                <div class="oil-construct-table__row-sub__inner" v-for="elem in item.subrow" :key="elem">
                                    <span class="oil-construct-table__row-sub-cell">{{ elem.name }}</span>
                                    <span class="oil-construct-table__row-sub-cell" v-if="elem.value === 'False'">Нет</span>
                                    <span class="oil-construct-table__row-sub-cell" v-else-if="elem.value === 'True'">Да</span>
                                    <span class="oil-construct-table__row-sub-cell" v-else>{{ elem.value }}</span>
                                </div>
                            </div>
                        </template>
                    </transition>
                </div>
            </template>
        </transition-group>
        <template v-if="table.length < 6">
            <button v-if="view === 'content'" class="oil-construct-table__add" @click="openModal({
                newState: 'intervalModal',
                props: {
                    boreholeId: boreholeId
                }
            })">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 5V19M5.5 12H19.5" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>Добавить интервал</span>
            </button>
        </template>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import axios from 'axios'

export default {
    props: {
        view: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        table: {
            type: Array,
            default: () => []
        },
        radio: {
            type: Boolean,
            default: false
        },
        boreholeId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            subrow: "",
            actualTable: this.$props.table
        }
    },
    computed: {
        ...mapState('request', ['host'])
    },
    methods: {
        ...mapMutations('modal', ['openModal', 'closeModal']),

        deleteConstruct(id) {
            const url = `${this.host}api/v1/Drilling/CasingColumn/${id}`
            const urlGetInfo = `Views/Browser/Drilling/Construction?id=${this.$props.boreholeId}`

            axios
                .delete(url)
                .then(() => {
                    this.$root.getInfo(urlGetInfo)
                        .then((resp) => {
                            this.actualTable = resp.content.content
                        })
                        .catch(() => {
                            this.$root.visibleNotification({
                                props: {
                                    error: true,
                                    title: "Ошибка",
                                    desc: 'Что-то пошло не так, попробуйте позже'
                                }
                            })
                        })
                })
                .catch(err => {
                    this.$root.visibleNotification({
                        props: {
                            error: true,
                            title: "Ошибка",
                            desc: err.response.data
                        }
                    })
                })
        },
        openSubRow(id, row) {
            if(row) {
                if(this.subrow !== id) {
                    this.subrow = id
                } else {
                    this.subrow = ""
                }
            }
        }
    }
}
</script>