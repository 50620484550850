<template>
    <div class="oil-params-table">
        <template v-if="view === 'header'">
            <div class="oil-params-table__row _header" v-for="item in table" :key="item">
                <span class="oil-params-table__row-cell">{{ item.parametr }}</span>
                <span class="oil-params-table__row-cell">{{ item.value }}</span>
            </div>
        </template>
        <template v-if="view ==='content'">
            <div class="oil-params-table__row _table"  v-for="item in dataTable" :key="item">
                <span class="oil-params-table__row-cell">{{ item.parametr }}</span>
                <div v-if="item.value.cell">
                    <span class="oil-params-table__row-cell _detailed">Участок x: {{ item.value.cell.x }} y: {{ item.value.cell.y }}</span>
                    <span class="oil-params-table__row-cell _detailed">Ячейка x: {{ item.value.region.x }} y: {{ item.value.region.y }}</span>
                </div>
                <div v-else-if="item.value.date">
                    <span class="oil-params-table__row-cell _detailed">{{  item.value.date  }}</span>
                    <span class="oil-params-table__row-cell _detailed">В эксплуатации {{  item.value.exploitation  }} сут</span>
                </div>
                <div v-else-if="item.value[0].status">
                    <!-- <template v-for="elem in item.value" :key="elem">
                        {{ elem.value }}
                    </template> -->
                    <div class="oil-params-table__row-radios">
                        <radio-cmp 
                            v-for="elem in item.value" 
                            :key="elem"
                            :text="elem.value"
                            :active="elem.status"
                            :status="elem.status"
                            :class="{_disable: elem.status === 'NotAvailable'}"
                            @setBoreholeId="setBoreholeType"
                            @setId="setId"
                            :boreholeId="elem.id"
                            class="oil-params-table__row-radios-btn"
                        />
                    </div>
                </div>
                <span v-else >
                    {{ item.value }}
                </span>
            </div>
        </template>
    </div>
</template>

<script> 
import Radio from '../oil-radio/radio-cmp.vue'
import { mapState } from 'vuex'
import axios from 'axios'

    export default {
        props: {
            view: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: ''
            },
            table: {
                type: Array,
                default: () => []
            },
            radio: {
                type: Boolean,
                default: false
            },
            id: {
                type: Number,
                default: 0
            },
        },
        data() {
            return {
                statusType: "",
                boreholeType: "",
                dataTable: this.$props.table,
                boreholeId: null
            }
        },
        components: {
            'radio-cmp': Radio
        },
        computed: {
            ...mapState('request', ['host'])
        },
        // watch: {
        //     dataTable() {
        //         this.boreholeId = this.dataTable.statuses.value.find(item => item.status === 'Current').status
        //     }
        // },
        methods: {
            setId(id) {
                this.boreholeId = id
            },
            setStatus(type) {
                this.statusType = type

                const url = `${this.host}api/v1/Purchased/Borehole`
                axios
                    .patch(url, {
                        id: this.$props.id,
                        status: type
                    })
                    .then(() => {
                    })
                    .catch(err => {
                        this.$root.visibleNotification({
                            props: {
                                error: true,
                                title: "Ошибка",
                                desc: err.response.data
                            }
                        })
                    })
                
            },
            updateTable() {
                const url = `${this.host}Views/Browser/Map/Borehole/generalInformation/${this.$route.params.id}`

                axios.get(url)
                    .then((resp) => {
                        this.dataTable = resp.data.content
                    })
                    .catch(() => this.$root.visibleNotification({
                            props: {
                                error: true,
                                title: "Ошибка",
                                desc: 'Что-то пошло не так, попробуйте позже'
                            }
                        }))
            },
            setBoreholeType(type) {
                this.boreholeType = type

                const url = `${this.host}api/v1/Purchased/Borehole`
                axios
                    .patch(url, {
                        id: this.$props.id,
                        status: type
                    })
                    .then(() => {
                        // console.log(type);
                        // this.boreholeType = type
                        this.$emit('update_info', true)
                    })
                    .catch(err => {
                        this.$root.visibleNotification({
                            props: {
                                error: true,
                                title: "Ошибка",
                                desc: err.response.data
                            }
                        })
                    })
            }
        }
    }
</script>