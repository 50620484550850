<template>
    <div class="oil-borehole-modal">
        <span class="oil-borehole-modal__title">Добавление скважины</span>
        <div class="oil-borehole-modal__cells">
            <span>Участок x{{ props.coords.regionX }} y{{ props.coords.regionY }} /</span>
            <span>Ячейка x{{ props.coords.cellX }} y{{ props.coords.cellY }}</span>
        </div>
        <div class="oil-borehole-modal__radios">
            <template v-for="item in typeBoreholes" :key="item">
                <div class="oil-borehole-modal__radios-container" @click="setType(item.type)">
                    <div class="oil-borehole-modal__radios-btn">
                        <svg v-if="type === item.type" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5" cy="5" r="5" fill="#176DC1"/>
                        </svg>
                    </div>
                    <span>{{ item.name }}</span>
                </div>
            </template>
        </div>
        <!-- <div class="oil-borehole-modal__inputs">
            <input-cmp 
                v-for="item in form"
                :key="item"
                :label="item.label"
                :type="item.type"
                :value="item.value"
                @set="setValue"
            />
        </div>
        <div class="oil-borehole-modal__table">
            <div 
                v-for="item in boreholes" 
                :key="item" 
                :class="{_header: item.type === 'header', _content: item.type === 'content'}"
            >
                <template v-if="item.type === 'header'">
                    <span v-for="elem in item.content" :key="elem">{{ elem.text }}</span>
                </template>
                <template v-else>
                    <div class="oil-borehole-modal__table-row" v-for="elem in item.content" :key="elem">
                        <span>{{ elem.name }}</span>
                        <span>{{ elem.value }}</span>
                    </div>
                </template>
            </div>
        </div> -->
        <div class="oil-borehole-modal__btns">
            <button-cmp
                :text="'Отменить'"
                :background="false"
                @click="close"
            ></button-cmp>
            <button-cmp
                :text="'Добавить'"
                @click="addBoreholes"
            ></button-cmp>
        </div>
    </div>
</template>
<script>
// import InputCmp from '../../../ui-components/oil-input/input-cmp.vue'
import { mapActions, mapMutations } from 'vuex'
import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'
// import axios from 'axios'
export default {
    props: ['props'],
    data() {
        return {
            typeBoreholes: [
                {
                    name: 'Разведочная',
                    type: 'Research'
                },
                {
                    name: 'Эксплуатационная',
                    type: 'Production'
                },
            ],
            boreholes: [
                {
                    type: 'header',
                    content: [
                        {
                            text: 'Тип затрат'
                        },
                        {
                            text: 'Значение'
                        }
                    ]
                },
                {
                    type: 'content',
                    content: [
                        {
                            name: 'Финансовые (ОС)',
                            value: 85700
                        },
                        {
                            name: 'Временные (сут)',
                            value: 30
                        },
                        {
                            name: 'Бригады (WFU)',
                            value: 2
                        },
                        {
                            name: 'Энергия (EU)',
                            value: 2000
                        },
                    ]
                },
            ],
            type: 'Research',
            form: [
                {
                    id: 1,
                    value: "",
                    type: "name",
                    required: false,
                    pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                    label: "Название",
                    error: "",
                },
                {
                    id: 2,
                    value: "",
                    type: "boreholes",
                    required: false,
                    pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                    label:"Глубина бурения, м",
                    error: "",
                },
            ],
            borehole: null,
            boreholeId: 0
        }
    },
    components: {
        // 'input-cmp': InputCmp,
        'button-cmp': buttonCmp
    },
    methods: {
        ...mapMutations('modal', ['closeModal']),
        ...mapMutations('notification', ['openNotification']),
        ...mapMutations('request', ['updateMap']),
        ...mapActions('request', ['setData']),

        setValue(val) {
            for (let i = 0; i < this.form.length; i++) {
                if (this.form[i].type === val.type) {
                    this.form[i].value = val.value;
                }
            }
        },
        setType(val) {
            this.type = val
        },
        addBoreholes() {
            const url = 'api/v1/Purchased/Borehole'
            this.$root.setInfo({
                url: url, body: {
                    WellPadId: this.props.data.line,
                    OrderId: this.props.data.icon,
                    BoreholeType: this.type
                }
            })
                // this.setData({url: url, body:{
                //     WellPadId: this.props.data.line,
                //     OrderId: this.props.data.icon,
                //     BoreholeType: this.type
                // }})
                .then((resp) => {
                    console.log(resp);
                    this.boreholeId = resp
                this.borehole = resp.data
                this.$root.visibleNotification({
                    props: {
                        success: true,
                        title: "Готово",
                        desc: 'Скважина успешно добавлена!'
                    }
                })
                this.closeModal()
                if(this.type === 'Research') {
                    this.$root.visibleNotification({
                        props: {
                            warning: true,
                            title: "Внимание",
                            desc: 'Не забудьте до завершения хода выбрать исследования, которые вы хотите провести. После начала бурения вы не сможете добавить исследования или изменить ранее выбранные. Чтобы перейти к выбору исследований, найдите нужную скважину в таблице “Скважины”, расположенной под картой, перейдите к подробной информации о ней и на открывшейся странице выберите вкладку “Исследования”'
                        }
                    })
                }
                this.$router.push(`/drilling/${this.boreholeId}`)
            })
            .catch(err => {
                this.$root.visibleNotification({
                    props: {
                        error: true,
                        title: "Ошибка",
                        desc: err.response.data
                    }
                })
            }) 
            // axios
            //     .post(url, {
            //         WellPadId: this.props.data.line,
            //         OrderId: this.props.data.icon,
            //         BoreholeType: this.type
            //     })
            //     .then(resp => {
            //         this.boreholeId = resp.data
            //         this.borehole = resp.data
            //         this.$root.visibleNotification({
            //             props: {
            //                 success: true,
            //                 title: "Готово",
            //                 desc: 'Скважина успешно куплена!'
            //             }
            //         })
            //         this.closeModal()
            //         if(this.type === 'Research') {
            //             this.$root.visibleNotification({
            //                 props: {
            //                     warning: true,
            //                     title: "Внимание",
            //                     desc: 'Не забудьте до завершения хода выбрать исследования, которые вы хотите провести. После начала бурения вы не сможете добавить исследования или изменить ранее выбранные. Чтобы перейти к выбору исследований, найдите нужную скважину в таблице “Скважины”, расположенной под картой, перейдите к подробной информации о ней и на открывшейся странице выберите вкладку “Исследования”'
            //                 }
            //             })
            //         }
            //         this.$router.push(`/drilling/${this.boreholeId}`)
            //     })
            //     .catch(err => {
            //         this.$root.visibleNotification({
            //             props: {
            //                 error: true,
            //                 title: "Ошибка",
            //                 desc: err.response.data
            //             }
            //         })
            //     }) 
        },
        close() {
            this.closeModal()
        }
    }
}
</script>
