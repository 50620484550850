<template>
    <div class='oil-mining-table'>
        <template v-if="view === 'header'">
            <div class="oil-mining-table__row _header" v-for="item in table" :key="item">
                <div class="oil-mining-table__row-text">
                    <span class="oil-mining-table__row-text-cell" @mouseenter="visibleTooltip = item.resource.text" @mouseleave="visibleTooltip = false">
                        {{ item.resource.text }}
                        <transition name="fade">
                            <span class="oil-mining-table__row-text-cell-tooltip" v-if="item.resource.tooltip && visibleTooltip === item.resource.text">{{ item.resource.tooltip }}</span>
                        </transition>
                    </span>
                    <span class="oil-mining-table__row-text-cell" @mouseenter="visibleTooltip = item.mining.text" @mouseleave="visibleTooltip = false">
                        {{ item.mining.text }}
                        <transition name="fade">
                            <span class="oil-mining-table__row-text-cell-tooltip" v-if="item.mining.tooltip && visibleTooltip === item.mining.text">{{ item.mining.tooltip }}</span>
                        </transition>
                    </span>
                    <span class="oil-mining-table__row-text-cell" @mouseenter="visibleTooltip = item.dayMining.text" @mouseleave="visibleTooltip = false">
                        {{ item.dayMining.text }}
                        <transition name="fade">
                            <span class="oil-mining-table__row-text-cell-tooltip" v-if="item.dayMining.tooltip && visibleTooltip === item.dayMining.text">{{ item.dayMining.tooltip }}</span>
                        </transition>
                    </span>
                    <span class="oil-mining-table__row-text-cell" @mouseenter="visibleTooltip = item.unitCost.text" @mouseleave="visibleTooltip = false">
                        {{ item.unitCost.text }}
                        <transition name="fade">
                            <span class="oil-mining-table__row-text-cell-tooltip" v-if="item.unitCost.tooltip && visibleTooltip === item.unitCost.text">{{ item.unitCost.tooltip }}</span>
                        </transition>
                    </span>
                    <span class="oil-mining-table__row-text-cell" @mouseenter="visibleTooltip = item.available.text" @mouseleave="visibleTooltip = false">
                        {{ item.available.text }}
                        <transition name="fade">
                            <span class="oil-mining-table__row-text-cell-tooltip" v-if="item.available.tooltip && visibleTooltip === item.available.text">{{ item.available.tooltip }}</span>
                        </transition>
                    </span>

                </div>
            </div>
        </template>
        <template v-if="view ==='content'">
            <div class="oil-mining-table__row _table" v-for="item in table" :key="item">
                <div class="oil-mining-table__row-text-cell">
                    <icon-cmp :img="item.icon"
                              :selector="'true'" />
                    <span>{{ item.resource }}</span>
                </div>
                <span class="oil-mining-table__row-text-cell">{{ item.mining }}</span>
                <span class="oil-mining-table__row-text-cell">{{ item.dayMining }}</span>
                <span class="oil-mining-table__row-text-cell">{{ item.unitCost }}</span>
                <span class="oil-mining-table__row-text-cell">{{ item.available }}</span>
            </div>
        </template>
    </div>
</template>

<script>
    import iconCmp from "../oil-icons/icons-cmp.vue"

    export default {
        props: {
            view: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: ''
            },
            table: {
                type: Array,
                default: () => []
            },
            radio: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                icons: [
                    {
                        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 21.5C13.8565 21.5 15.637 20.7625 16.9497 19.4497C18.2625 18.137 19 16.3565 19 14.5C19 12.5 18 10.6 16 9C14 7.4 12.5 5 12 2.5C11.5 5 10 7.4 8 9C6 10.6 5 12.5 5 14.5C5 16.3565 5.7375 18.137 7.05025 19.4497C8.36301 20.7625 10.1435 21.5 12 21.5Z" fill="#905CA9" stroke="#905CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                    },
                    {
                        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 18C21 18 19.8096 17.5305 19 17.3021C13.8797 15.8574 10.1203 20.1426 5 18.6979C4.19041 18.4695 3 18 3 18M21 12C21 12 19.8096 11.5305 19 11.3021C13.8797 9.85739 10.1203 14.1426 5 12.6979C4.19041 12.4695 3 12 3 12M21 6C21 6 19.8096 5.53048 19 5.30206C13.8797 3.85739 10.1203 8.14261 5 6.69794C4.19041 6.46952 3 6 3 6" stroke="#905CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                    },
                    {
                        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.56 6.08C13.2478 4.98112 13.7353 3.76904 14 2.5C14.5 5 16 7.4 18 9C20 10.6 21 12.5 21 14.5C21.0057 15.8823 20.6009 17.2352 19.8368 18.3871C19.0727 19.539 17.9838 20.4382 16.7081 20.9705C15.4324 21.5028 14.0274 21.6444 12.6712 21.3773C11.3149 21.1101 10.0685 20.4463 9.09 19.47M7 15.78C9.2 15.78 11 13.95 11 11.73C11 10.57 10.43 9.47 9.29 8.54C8.15 7.61 7.29 6.23 7 4.78C6.71 6.23 5.86 7.62 4.71 8.54C3.56 9.46 3 10.58 3 11.73C3 13.95 4.8 15.78 7 15.78Z" stroke="#905CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                    },
                    {
                        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 21.5C13.8565 21.5 15.637 20.7625 16.9497 19.4497C18.2625 18.137 19 16.3565 19 14.5C19 12.5 18 10.6 16 9C14 7.4 12.5 5 12 2.5C11.5 5 10 7.4 8 9C6 10.6 5 12.5 5 14.5C5 16.3565 5.7375 18.137 7.05025 19.4497C8.36301 20.7625 10.1435 21.5 12 21.5Z" fill="#905CA9" stroke="#905CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                    }
                ],
                visibleTooltip: false
            }
        },
        watch: {
            table() {
                if (this.view !== 'header') {
                    this.table.map((item, idx) => Object.assign(item, this.icons.find((img, index) => idx === index)))
                }
            }
        },
        components: {
            'icon-cmp': iconCmp
        },
        created() {
            if (this.view !== 'header') {
                this.table.map((item, idx) => Object.assign(item, this.icons.find((img, index) => idx === index)))
            }
        }
    }
</script>