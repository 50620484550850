<template>
    <div class="oil-level-modal">
        <span class="oil-level-modal__title">Добавление уровня</span>
        <span class="oil-level-modal__dots">Отметка {{ props.dots }}</span>
        <input-cmp 
            class="oil-level-modal__input"
            :label="'Название уровня'"
            :type="'text'"
            :value="name"
            @set="setName"
        />
        <div class="oil-level-modal__table">
            <table-cmp 
                v-for="item in table"
                :key="item"
                :view="item.type"
                :table="item.content"
            />
        </div>
       
        <div class="oil-level-modal__btns">
            <button-cmp 
                :text="'Отменить'"
                :background="false"
                @click="closeModal"
            />
            <button-cmp 
                :text="'Добавить'"
            />
        </div>
    </div>
</template>
<script> 
import inputCmp from "../../../ui-components/oil-input/input-cmp.vue"
import buttonCmp from "../../../ui-components/oil-button/button-cmp.vue"
import tableCmp from "./table-levels/table-levels.vue"
// import axios from "axios"
import {mapMutations} from 'vuex'

    export default {
        props: ['props'],
        data() {
            return {
                name: '',
                table: []
            }
        },
        components: {
            'input-cmp': inputCmp,
            'table-cmp': tableCmp,
            'button-cmp': buttonCmp
        },
        methods: {
            ...mapMutations('modal', ['openModal', 'closeModal']),

            setName(val) {
                this.name = val.value
            }
        },
        created() {
            const url = `Views/Browser/Result/WellTop/AddWellTop?boreholeId=${this.$route.params.id}&z=${this.props.dots}`
            this.$root.getInfo(url)
                .then((resp) => {this.table = [resp.header, resp.content]})
                .catch(() => this.$root.visibleNotification({
                    props: {
                        error: true,
                        title: "Ошибка",
                        desc: 'Что-то пошло не так, попробуйте позже'
                    }
                })
            )
            // axios
            //     .get('/api/levels.json')
            //     .then((resp) => {
            //         this.table = [resp.data.header, resp.data.content]
            //     })
        }
    }
</script>
    