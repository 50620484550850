<template>
    <div class="oil-results oil-container">
        <div class="oil-results__menubar">
            <menubar-cmp 
                v-for="item in menu"
                :key="item.id"
                :active="active"
                :id="item.id"
                :text="item.name"
                @click="selectTabs(item.id)"
                class="oil-results__menubar-item"
            />
        </div>
        <transition-group name="fadeRight">
            <div class="oil-results__wells" v-if="active === 1">
                <template v-if="visible">
                    <wells-cmp 
                        v-for="item in result"
                        :key="item"
                        :type="item.type"
                        :result="item.content"
                        @update="changeData"
                    />
                </template>
                <template v-else>
                    <span>Скважины еще не добавлены</span>
                </template>  
            </div>
            <div class="oil-results__profile" v-else>
                <template v-if="options.length">
                    <img v-for="item in options" :key="item" :src="`${host}${item.imageLink}`"/>                
                </template>
                <template v-else>
                    <span>2D профили пока не добавлены</span>
                </template>
            </div>
        </transition-group>
    </div>
</template>
<script>
import Menubar from '../../components/ui-components/oil-menubar/menubar-cmp.vue'
import Wells from '../../components/ui-components/oil-wells/wells-cmp.vue'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            active: 1,
            result: [],
            options: [],
            menu: [
                {
                    name: "Скважины",
                    id: 1
                },
                {
                    name: "2D Профили",
                    id: 2
                },
            ],
            update: false,
            visible: false
        }
    },
    components: {
        'menubar-cmp': Menubar,
        'wells-cmp': Wells,
    },
    computed: {
        ...mapState('request', ['host'])
    },
    watch: {
        result() {
            if(this.result.content.content.length) {
                this.visible = true

            }
        },
        update() {
            const url = 'Views/browser/result/borehole'

            this.$root.getInfo(url)
                .then((resp) => {
                    this.result = resp
                })
            // axios.all(urls.map((endpoint) => axios.get(endpoint)))
            //     .then(
            //         axios.spread((firstResp) => {
            //             this.result = firstResp.data 
            //         })
            // );  
        }
    },
    methods: {
        selectTabs(val) {
            this.active = val
        },
        changeData(val) {
            this.update = val
        }
    },
    created() {
        const urls = [
            `${this.host}Views/browser/result/borehole`,
            `${this.host}api/v1/Purchased/seismic`
        ]
        axios.all(urls.map((endpoint) => axios.get(endpoint)))
            .then(
                axios.spread((firstResp, secondResp) => {
                    this.result = firstResp.data 
                    this.options = secondResp.data 
                })

        )
    }
}
</script>
