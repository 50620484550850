<template>
    <div class="oil-table-levels">
        <template v-if="view === 'header'">
            <div class="oil-table-levels__container">
                <div class="oil-table-levels__row" v-for="item in table" :key="item">
                    <span class="oil-table-levels__row-cell _header">{{ item.text }}</span>
                </div>
            </div>
        </template>
        <template v-if="view === 'content'">
            <div class="oil-table-levels__row" v-for="item in table[0]" :key="item">
                <span class="oil-table-levels__row-cell _table">{{ item.Parameter }}</span>
                <span class="oil-table-levels__row-cell _table">{{ item.ParameterValue }}</span>
            </div>
        </template>
    </div>
</template>
<script> 
    export default {
        props: {
            table: {
                type: Array,
                default: () => []
            },
            view: {
                type: String,
                default: ''
            },
        },
        created() {
            console.log(this.$props.table[0]);
        }
    }
</script>