import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export const CanvasScene = {
    data() {
        return {
            dataDots: [],
        }
    },
    watch: {
        dataDots() {
            this.renderingModel()
        }
    },
    // computed: {
    //     dataSuccess() {
    //         this.renderingModel()
    //     }
    // },
    methods: {

        renderingModel() {
            const container = document.getElementById('scene')
            //общие переменные
            const scene = new THREE.Scene()
            const camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 1, 10000)
            const object = []

            // const range = 960
            const step = 40
            // console.log(step);
            const figures = []


            //рендер сцены
            const renderer = new THREE.WebGLRenderer()
            renderer.setSize(container.clientWidth, container.clientHeight) // размер окна рендера
            scene.background = new THREE.Color(0xf0f0f0); //добавление цвета бэкграунда

            //создание сетки
            const size = 1000
            const divisions = 25
            const gridHelper = new THREE.GridHelper(size, divisions, 0x0000ff, 0x808080)
            scene.add(gridHelper)

            // figures = [
            //     {
            //         position: {
            //             cellX: 1,
            //             cellY: 1
            //         }
            //     },
            //     {
            //         position: {
            //             cellX: 25,
            //             cellY: 25
            //         }
            //     },
            // ]
            // console.log(figures);
            for (let i = 0; i < this.dataDots.length; i++) {
                figures.push({
                    position: {
                        x: this.dataDots[i].x,
                        z: this.dataDots[i].y,
                        color: this.dataDots[i].color
                    }
                })
                // figures[i].position.x = figures[i].x
                // figures[i].position.y = figures[i].y

                // let x = figures[i].position.x
                // let z = figures[i].position.y
                // let positionX = (x - 1) * step - 480
                // let positionZ = (z - 1) * step - 480
                // figures[i].position.x = positionX
                // figures[i].position.z = positionZ


            }
            for (let i = 0; i < figures.length; i++) {
                let x = figures[i].position.x
                let z = figures[i].position.z
                let positionX = (x - 1) * step - 480
                let positionZ = (z - 1) * step - 480
                figures[i].position.x = positionX
                figures[i].position.z = positionZ
            }

            gridHelper.geometry.verticesNeedUpdate = true;

            //создание орбиты
            const controls = new OrbitControls(camera, renderer.domElement)
            camera.position.set(0, 200, 1000);


            //добавление плавности вращения
            controls.enableDamping = true

            //Добавление сцены в контейнер
            container.appendChild(renderer.domElement)


            //добавление куба
            const geometry = new THREE.BoxGeometry(40, 40, 40) //объявление фигуры
            // let cube = new THREE.Mesh(geometry, material); //создание куба
            const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });


            const figureCiylinder = new THREE.CylinderGeometry(5, 5, 20, 32);
            const cylinderColor = new THREE.MeshBasicMaterial({ color: 0xffff00 });
            const cylinder = new THREE.Mesh(figureCiylinder, cylinderColor);
            scene.add(cylinder);

            // for (let i = 0; i < figures.length; i++) {
            //     let color = new THREE.MeshBasicMaterial({ color: figures[i].position.color });
            //     let cube = new THREE.Mesh(geometry, color); //создание куба
            //     //цвет материала
            //     cube.position.x = figures[i].position.x
            //     cube.position.z = figures[i].position.z
            //     scene.add(cube)
            // }
            const mapping = (val) => {
                let colorArray = this.dataDots.find(item => item.x === val.x && item.y === val.y)             
                    if(colorArray) {
                        let color = colorArray.color.find(item => item.x === val.innerX && item.y === val.innerY)

                        if(color) {
                            return color ? color.color : undefined
                        }
                    }
            }
            
            for(let x = 1; x < 26; x++) {
                for(let y = 1; y < 26; y++) {
                    const geometryPlane = new THREE.BoxGeometry(38, 0, 38)
                    // const planeColor = new THREE.MeshBasicMaterial({ color: 0xffff00 });
                    let plane = new THREE.Mesh(geometryPlane)
                    
                    let positionX = [x - 1] * step - 480
                    let positionZ = [y - 1] * step - 480
    
                    plane.position.z = positionZ
                    plane.position.x = positionX
                    scene.add(plane)

                    for(let innerX = 0; innerX < 3; innerX++) {
                        for(let innerY = 0; innerY < 3; innerY++) {                           
                                const geometryPlane = new THREE.BoxGeometry(14, 14, 14)
                                const step = 16
                                
                                let innerXPosition = [innerX + 1] * step - 38
                                let innerZPosition = [innerY + 1] * step - 38
                               
                                // let colorCube = mapping({x, y, innerX, innerY})
                                const planeColor = new THREE.MeshBasicMaterial({color: mapping({x, y, innerX, innerY})});
                                let innerCube = new THREE.Mesh(geometryPlane, planeColor)


                                innerCube.position.z = innerZPosition
                                innerCube.position.x = innerXPosition

                                plane.add(innerCube)
                        }
                    }
                }
            }

            //создание тени куба
            const cubeShadow = new THREE.BoxGeometry(40, 40, 40)
            const cubeShadowMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, opacity: 0.2, transparent: true })
            const rollCubeShadow = new THREE.Mesh(cubeShadow, cubeShadowMaterial)
            scene.add(rollCubeShadow)


            //создание потомков для сцены и его пуш в массив
            // const facet = new THREE.PlaneGeometry(1000, 1000);
            // facet.rotateX(- Math.PI / 2);
            // let plane = new THREE.Mesh(facet, new THREE.MeshBasicMaterial({ visible: false }));
            // scene.add(plane);
            // object.push(plane);

            //добавление контроллера мыши
            let raycaster = new THREE.Raycaster()
            const pointer = new THREE.Vector2()


            function onPointerMove(event) {
                pointer.x = (event.clientX / container.clientWidth) * 2 - 1.4
                pointer.y = - (event.clientY / container.clientHeight) * 2 + 1.439

                raycaster.setFromCamera(pointer, camera);
                const intersects = raycaster.intersectObjects(object, false);
                if (intersects.length > 0) {

                    const intersect = intersects[0];

                    rollCubeShadow.position.copy(intersect.point).add(intersect.face.normal);
                    // rollCubeShadow.position.divideScalar(40).floor().multiplyScalar(40).addScalar(20);
                    rollCubeShadow.position.divideScalar(40).floor().multiplyScalar(40).addScalar(20);

                    for (let key in rollCubeShadow.position) {
                        if (key === 'x') {
                            rollCubeShadow.position[key] = rollCubeShadow.position[key] + 20
                        } else if (key === 'z') {
                            rollCubeShadow.position[key] = rollCubeShadow.position[key] - 20
                        }
                    }
                }
            }

            //добавление куба на поле
            function onPointerDown(event) {
                pointer.x = (event.clientX / container.clientWidth) * 2 - 1.4
                pointer.y = - (event.clientY / container.clientHeight) * 2 + 1.432
                raycaster.setFromCamera(pointer, camera);
                const intersects = raycaster.intersectObjects(object, false);

                if (intersects.length > 0) {

                    let intersect;
                    for (let i = 0; i < intersects.length; i++) {
                        intersect = intersects[i]
                    }

                    const voxel = new THREE.Mesh(geometry, material);
                    voxel.position.copy(intersect.point).add(intersect.face.normal);
                    voxel.position.divideScalar(40).floor().multiplyScalar(40).addScalar(20);

                    for (let key in voxel.position) {
                        if (key === 'x') {
                            voxel.position[key] = voxel.position[key] + 20
                        } else if (key === 'z') {
                            voxel.position[key] = voxel.position[key] - 20
                        }
                    }

                    scene.add(voxel);
                    figures.push(voxel)

                    for (let i = 0; i < figures.length; i++) {
                        let positionX = Math.floor((figures[i].position.x + 480) / step) + 1
                        let positionZ = Math.floor((figures[i].position.z + 480) / step) + 1
                        let z = Math.max(1, Math.min(25, positionZ))
                        let x = Math.max(1, Math.min(25, positionX))
                        figures[i].position.cellX = x
                        figures[i].position.cellY = z
                    }

                }
            }

            function render() {
                raycaster.setFromCamera(pointer, camera);
                const intersects = raycaster.intersectObjects(object, false);

                for (let i = 0; i < intersects.length; i++) {
                    intersects[i].object.material.color.set(0xff0000);
                }

                renderer.render(scene, camera);
            }

            container.addEventListener('pointermove', onPointerMove)
            container.addEventListener('pointerdown', onPointerDown)
            window.requestAnimationFrame(render)


            function animate() {
                renderer.render(scene, camera)
                requestAnimationFrame(animate);
                controls.update();
            }

            animate()

        }
    },
    created() {
        const url = 'Views/Browser/map/map/MapObject/object'

        this.$root.getInfo(url)
            .then((resp) => {this.dataDots = resp})
            .catch(() => this.$root.visibleNotification({
                props: {
                    error: true,
                    title: "Ошибка",
                    desc: 'Что-то пошло не так, попробуйте позже'
                }
            })
        )
    }
}