import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from './store/store'
// import HighchartsVue from 'highcharts-vue'
import HightchartMore from 'highcharts/highcharts-more'
import VueCookies from 'vue-cookies'
import Highcharts  from 'highcharts'
import App from './App.vue'
import './assets/style/index.sass'

import routes from "./router"

HightchartMore(Highcharts)
const router = createRouter({
    history: createWebHistory(),
    routes
})


createApp(App).use(router, Highcharts as any).use(store).use(VueCookies).mount('#app')
