<template>
  <div class="oil-checkbox" @click="setValue(text, status, id)">
    <div
      class="oil-checkbox__field"
      :class="{ _active: active }"
    >
      <svg
        class="oil-checkbox__field-check"
        v-if="active"
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.0001 7.7799L1.2201 4.9999L0.273438 5.9399L4.0001 9.66656L12.0001 1.66656L11.0601 0.726562L4.0001 7.7799Z"
          fill="white"
        />
      </svg>
    </div>
    <span class="oil-checkbox__field-text">{{ text }}</span>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: "text",
    },
    status: {
      type: String,
      default: 'Available'
    },
    id: {
      type: Number,
      default: 0
    },
    visible: {
      type: Object,
      default: () => {}
    },
    activeCheckbox: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      active: false,
      map: '',
      boreholeLog: ''
    };
  },
  watch: {
    activeCheckbox() {
      if(this.$props.activeCheckbox !== '') {
        this.active = true
      }
    },
    map() {
      this.$emit('set-maps', this.map);
    }
  },
  methods: {
    setValue(text, status, id) {
      if(status === 'InProgress' || status === 'Complete') {
        this.active = true;
      } else {
        if(this.activeCheckbox !== '' && this.activeCheckbox !== 'Эксплуатационная колонна') {
          this.active = true
        } else {
          this.active = !this.active
        }
        
        this.map = {text, id}
      }
      this.boreholeLog = id
      this.$emit('set-boreholeLog', this.boreholeLog)
      this.$emit('set-checkbox', this.active);
    },
  },
  created() {
    if(this.status === 'InProgress' || this.status === 'Complete' || this.status === 'Planned') {
      this.active = true
    }
    if(this.$props.visible) {
      for(let i = 0; i < this.$props.visible.length; i++) {
        if((this.$props.visible[i].val.id === this.$props.id)) {
          this.active = true
        }
      }
    }
    
  }
};
</script>
