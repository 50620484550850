import Legend from '../src/pages/legend/legend-page.vue'
import Map from '../src/pages/map/map-page.vue'
import Card from '../src/pages/card/card-page.vue'
import Scene from "../src/pages/scene/scene-page.vue"
import Graphics from "../src/pages/graphics/graphics-page.vue"
import Results from "../src/pages/results/results-page.vue"
import Turn from "../src/pages/turn/turn-page.vue"
import Drilling from "../src/pages/drilling/drilling-page.vue"


const routes = [
    {
        path: '/',
        name: 'legend',
        component: Legend
    },
    {
        path: '/map',
        name: 'map',
        component: Map
    },
    {
        path: '/card/:id',
        name: 'card',
        component: Card
    },
    {
        path: '/graphics/:id',
        name: 'graphics',
        component: Graphics
    },
    {
        path: '/scene',
        name: 'scene',
        component: Scene
    },
    {
        path: '/turn',
        name: 'turn',
        component: Turn
    },
    {
        path: '/results',
        name: 'result',
        component: Results
    },
    // {
    //     path: '/drilling',
    //     name: 'drilling',
    //     component: Drilling
    // },
    {
        path: '/drilling/:id',
        name: 'drilling',
        component: Drilling
    },
]

export default routes