<template>
    <div class="oil-selector-container">
        <div class="oil-selector-container__field" @click="openList">
            <label>{{ text }}</label>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9L12 15L18 9" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="oil-selector-container__list" v-if="open">
            <span v-for="type in types" :key="type" @click="selectType(type.text)">{{ type.text }}</span>
        </div>
    </div>
</template>
<script> 
    export default {
        data() {
            return {
                open: false,
                text: "Тип интервала",
                types: [
                    {
                        text: 'Направление',
                        id: 1
                    },
                    {
                        text: 'Кондуктор',
                        id: 2
                    },
                    {
                        text: 'Обсадная колонна',
                        id: 3
                    },
                    {
                        text: 'Эксплуатационная колонна',
                        id: 4
                    },
                ]
            }
        },
        methods: {
            selectType(val) {
                this.text = val
                this.open = false
                this.$emit("select-type", this.text)
            },
            openList() {
                this.open = !this.open
            }
        }
    }
</script>
