<template> 
    <div class="oil-switch">
        <div class="oil-switch__toggle" @click="toggle" :class="{_active: !active}">
            <div class="oil-switch__toggle-circle"></div>
        </div>
        <span v-if="title">{{ title }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: null
            },
            activeToggle: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                active: false
            }
        },
        watch: {
            activeToggle() {
                if(!this.activeToggle) {
                    this.active = false
                } else {
                    this.active = true
                }
            }
        },
        methods: {
            toggle() {
                this.active = !this.active
                this.$emit('toggle', this.active)
            }
        },
        created() {
            this.active = this.$props.activeToggle
        }
    }
</script>
