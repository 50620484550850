<template>
    <div class="oil-add-map-table">
        <template v-if="view === 'header'">
            <div class="oil-add-map-table__row _header" v-for="item in table" :key="item">
                <span class="oil-add-map-table__row-cell">{{ item.maps.text }}
                </span>
                <span class="oil-add-map-table__row-cell" @mouseenter="visibleTooltip = item.capex.tooltipId" @mouseleave="visibleTooltip = false">{{ item.capex.text }}
                    <transition name="fade">
                        <span class="oil-add-map-table__row-cell-tooltip" v-if="visibleTooltip === item.capex.tooltipId">{{ item.capex.tooltip }}</span>
                    </transition>
                </span>
                <span class="oil-add-map-table__row-cell" @mouseenter="visibleTooltip = item.tu.tooltipId" @mouseleave="visibleTooltip = false">{{ item.tu.text }}
                    <transition name="fade">
                        <span class="oil-add-map-table__row-cell-tooltip" v-if="visibleTooltip === item.tu.tooltipId">{{ item.tu.tooltip }}</span>
                    </transition>
                </span>
                <span class="oil-add-map-table__row-cell" @mouseenter="visibleTooltip = item.wfu.tooltipId" @mouseleave="visibleTooltip = false">{{ item.wfu.text }}
                    <transition name="fade">
                        <span class="oil-add-map-table__row-cell-tooltip" v-if="visibleTooltip === item.wfu.tooltipId">{{ item.wfu.tooltip }}</span>
                    </transition>
                </span>
            </div>
        </template>
        <template v-if="view === 'content'">
            <div class="oil-add-map-table__row _content" v-for="item in table" :key="item">
                <checkbox-cmp 
                    class="oil-add-map-table__row-cell"
                    :text="item.maps"
                    :id="item.id"
                    :status="item.status"
                    @set-maps="setMaps"
                />
                <span class="oil-add-map-table__row-cell">{{ item.capex }}</span>
                <span class="oil-add-map-table__row-cell">{{ item.tu }}</span>
                <span class="oil-add-map-table__row-cell">{{ item.wfu }}</span>
            </div>
        </template>
    </div>
</template>
<script> 
import checkboxCmp from "../../../../ui-components/oil-checkbox/checkbox-cmp.vue"
    export default {
        props: {
            table: {
                type: Array,
                default: () => []
            },
            view: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                maps: [],
                visibleTooltip: false
            }
        },
        // watch: {
        //     maps() {
        //         this.$emit('updateMaps', this.maps)
        //         console.log(this.maps);
        //     }
        // },
        components: {
            'checkbox-cmp': checkboxCmp
        },
        methods: {
            setMaps(val) {
                if(this.maps.find(item => item.val.id === val.id)) {
                    this.maps.splice(this.maps.findIndex(item => item.val.id === val.id), 1)
                } else {
                    this.maps.push({val})
                }
                this.$emit('updateMaps', this.maps)
            }
        }
    }
</script>