<template>
    <div class="oil-profile-borehole-table">
        <template v-if="view === 'header'">
            <div class="oil-profile-borehole-table__row _header" v-for="item in actualTable" :key="item">
                <span class="oil-profile-borehole-table__row-cell" @mouseenter="visibleTooltip = item.tVd.text" @mouseleave="visibleTooltip = false">{{ item.tVd.text }}
                    <transition name="fade">
                        <span class="oil-profile-borehole-table__row-cell-tooltip" v-if="visibleTooltip === item.tVd.text && item.tVd.tooltip">{{ item.tVd.tooltip }}</span>
                    </transition>
                </span>
                <span class="oil-profile-borehole-table__row-cell" @mouseenter="visibleTooltip = item.x.text" @mouseleave="visibleTooltip = false">{{ item.x.text }}
                    <transition name="fade">
                        <span class="oil-profile-borehole-table__row-cell-tooltip" v-if="visibleTooltip === item.x.text && item.x.tooltip">{{ item.x.tooltip }}</span>
                    </transition>
                </span>
                <span class="oil-profile-borehole-table__row-cell" @mouseenter="visibleTooltip = item.y.text" @mouseleave="visibleTooltip = false">{{ item.y.text }}
                    <transition name="fade">
                        <span class="oil-profile-borehole-table__row-cell-tooltip" v-if="visibleTooltip === item.y.text && item.y.tooltip">{{ item.y.tooltip }}</span>
                    </transition>
                </span>
                <span class="oil-profile-borehole-table__row-cell" @mouseenter="visibleTooltip = item.md.text" @mouseleave="visibleTooltip = false">{{ item.md.text }}
                    <transition name="fade">
                        <span class="oil-profile-borehole-table__row-cell-tooltip" v-if="visibleTooltip === item.md.text && item.md.tooltip">{{ item.md.tooltip }}</span>
                    </transition>
                </span>
                <span class="oil-profile-borehole-table__row-cell" @mouseenter="visibleTooltip = item.zenithAngle.text" @mouseleave="visibleTooltip = false">{{ item.zenithAngle.text }}
                    <transition name="fade">
                        <span class="oil-profile-borehole-table__row-cell-tooltip" v-if="visibleTooltip === item.zenithAngle.text && item.zenithAngle.tooltip">{{ item.zenithAngle.tooltip }}</span>
                    </transition>
                </span>
                <span class="oil-profile-borehole-table__row-cell" @mouseenter="visibleTooltip = item.azimuthAngle.text" @mouseleave="visibleTooltip = false">{{ item.azimuthAngle.text }}
                    <transition name="fade">
                        <span class="oil-profile-borehole-table__row-cell-tooltip" v-if="visibleTooltip === item.azimuthAngle.text && item.azimuthAngle.tooltip">{{ item.azimuthAngle.tooltip }}</span>
                    </transition>
                </span>
            </div>
        </template>
        <template v-if="view === 'content'">
            <div class="oil-profile-borehole-table__row _table" :class="{'_active-edit': edit=== (idx + 1)}" v-for="(item, idx) in actualTable" :key="item">
                <span class="oil-profile-borehole-table__row-cell" v-if="edit !== (idx + 1) || (edit === (idx + 1) && !item.tVd.isAvailable)">{{ item.tVd.text }}</span>
                <input-cmp 
                    v-else-if="edit === (idx + 1) && item.tVd.isAvailable"
                    class="oil-profile-borehole-table__row-cell"
                    @set="setValue"
                    :type="item.id"
                    :objKey="'tVd'"
                    :initValue="item.tVd.text"
                />
                <span class="oil-profile-borehole-table__row-cell" v-if="edit !== (idx + 1) || (edit === (idx + 1) && !item.x.isAvailable)">{{ item.x.text }}</span>
                <input-cmp 
                    v-else-if="edit === (idx + 1) && item.x.isAvailable"
                    class="oil-profile-borehole-table__row-cell"
                    @set="setValue"
                    :type="item.id"
                    :objKey="'x'"
                    :initValue="item.x.text"
                />
                <span class="oil-profile-borehole-table__row-cell" v-if="edit !== (idx + 1) || (edit === (idx + 1) && !item.y.isAvailable)">{{ item.y.text }}</span>
                <input-cmp 
                    v-else-if="edit === (idx + 1)"
                    class="oil-profile-borehole-table__row-cell"
                    @set="setValue"
                    :type="item.id"
                    :objKey="'y'"
                    :initValue="item.y.text"
                />
                <span class="oil-profile-borehole-table__row-cell">{{ Number(item.md.text).toFixed(2) }}</span>
                <span class="oil-profile-borehole-table__row-cell">{{ Number(item.zenithAngle.text).toFixed(2) }}</span>
                <span class="oil-profile-borehole-table__row-cell">{{ Number(item.azimuthAngle.text).toFixed(2) }}</span>
                
                <svg v-if="edit !== (idx + 1) && idx !== 0" class="oil-profile-borehole-table__row-cell-edit" @click="editCoords(idx, item.id, item.tVd, item.x, item.y)" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 12H14.8C13.1198 12 12.2798 12 11.638 12.327C11.0735 12.6146 10.6146 13.0736 10.327 13.638C10 14.2798 10 15.1199 10 16.8V25.2C10 26.8802 10 27.7203 10.327 28.362C10.6146 28.9265 11.0735 29.3854 11.638 29.673C12.2798 30 13.1198 30 14.8 30H23.2C24.8802 30 25.7202 30 26.362 29.673C26.9265 29.3854 27.3854 28.9265 27.673 28.362C28 27.7203 28 26.8802 28 25.2V21M16 24H17.6745C18.1637 24 18.4083 24 18.6385 23.9448C18.8425 23.8958 19.0376 23.815 19.2166 23.7053C19.4184 23.5816 19.5914 23.4087 19.9373 23.0628L29.5 13.5C30.3284 12.6716 30.3284 11.3284 29.5 10.5C28.6716 9.67159 27.3284 9.67159 26.5 10.5L16.9372 20.0628C16.5913 20.4087 16.4184 20.5816 16.2947 20.7834C16.185 20.9624 16.1042 21.1575 16.0552 21.3616C16 21.5917 16 21.8363 16 22.3255V24Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg @click="deleteProfile(item.id)" v-if="edit !== (idx + 1) && idx !== 0" class="oil-profile-borehole-table__row-cell-basket" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 14V13.2C24 12.0799 24 11.5198 23.782 11.092C23.5903 10.7157 23.2843 10.4097 22.908 10.218C22.4802 10 21.9201 10 20.8 10H19.2C18.0799 10 17.5198 10 17.092 10.218C16.7157 10.4097 16.4097 10.7157 16.218 11.092C16 11.5198 16 12.0799 16 13.2V14M11 14H29M27 14V25.2C27 26.8802 27 27.7202 26.673 28.362C26.3854 28.9265 25.9265 29.3854 25.362 29.673C24.7202 30 23.8802 30 22.2 30H17.8C16.1198 30 15.2798 30 14.638 29.673C14.0735 29.3854 13.6146 28.9265 13.327 28.362C13 27.7202 13 26.8802 13 25.2V14" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg v-else-if="edit === (idx + 1)" @click="editCoords(idx, item.id, item.tVd, item.x, item.y)" class="oil-profile-borehole-table__row-cell-check" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 6L9 17L4 12" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </template>
        <button v-if="view === 'content'" class="oil-profile-borehole-table__add" @click="addProfile()">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 5V19M5.5 12H19.5" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>Добавить координаты</span>
        </button>
    </div>
</template>
<script>
import InputCmp from "../../oil-input/input-cmp.vue"
import { mapState } from "vuex"
import axios from 'axios'
    export default {
        props: {
            view: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: ''
            },
            table: {
                type: Array,
                default: () => []
            },
            radio: {
                type: Boolean,
                default: false
            },
            boreholeId: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                edit: false,
                actualTable: this.$props.table,
                visibleTooltip: false
            }
        },
        computed: {
            ...mapState('request', ['host'])
        },
        methods: {
            setValue(val) {
                for (let i = 0; i < this.actualTable.length; i++) {
                    if (this.actualTable[i].id === val.type) {
                        this.actualTable[i][val.key].text = val.value;
                    }
                }
            },
            editCoords(idx, id, tvd, x, y) {
                if(this.edit === (idx + 1)) {
                    this.edit = false
                    const url = `${this.host}api/v1/Drilling/BoreholeTrajectory`

                    axios
                        .patch(url, 
                            {
                                id: id,
                                x: x.text,
                                y: y.text,
                                z: tvd.text
                            }
                        )
                        .then(() => {
                            const urlGetInfo = `${this.host}Views/Browser/Drilling/Profile?id=${this.$props.boreholeId}`
                            axios
                                .get(urlGetInfo)
                                .then((resp) => {
                                    this.actualTable = resp.data.content.content
                                })
                                .catch(() => this.$root.visibleNotification({
                                    props: {
                                        error: true,
                                        title: "Ошибка",
                                        desc: 'Что-то пошло не так, попробуйте позже'
                                    }
                                }))
                            })
                        .catch(err => {
                            this.$root.visibleNotification({
                                props: {
                                    error: true,
                                    title: "Ошибка",
                                    desc: err.response.data
                                }
                            })
                        })    
                } else {
                    this.edit = idx + 1
                }
            },
            deleteProfile(id) {
                const url = `${this.host}api/v1/Drilling/BoreholeTrajectory/${id}`

                axios
                    .delete(url)
                    .then(() => {
                    const urlGetInfo = `${this.host}Views/Browser/Drilling/Profile?id=${this.$props.boreholeId}`
                        axios
                            .get(urlGetInfo)
                            .then((resp) => {
                                this.actualTable = resp.data.content.content
                            })
                            .catch(() => this.$root.visibleNotification({
                                props: {
                                    error: true,
                                    title: "Ошибка",
                                    desc: 'Что-то пошло не так, попробуйте позже'
                                }
                            }))
                        })
            },
            addProfile() {
                const url = `api/v1/Drilling/BoreholeTrajectory?boreholeId=${this.$props.boreholeId}`
                const urlGetInfo = `Views/Browser/Drilling/Profile?id=${this.$props.boreholeId}`
                this.$root.setInfo({url: url})
                    .then(resp => {
                        if(typeof(resp) === "number") {
                            this.$root.getInfo(urlGetInfo)
                            .then((resp) => {
                                this.actualTable = resp.content.content
                            })
                            .catch(() => {
                                this.$root.visibleNotification({
                                    props: {
                                        error: true,
                                        title: "Ошибка",
                                        desc: 'Что-то пошло не так, попробуйте позже'
                                    }
                                })
                            })
                        } else {
                            this.$root.visibleNotification({
                                props: {
                                    error: true,
                                    title: "Ошибка",
                                    desc: resp.response.data
                                }
                            })
                        }
                    })
                // axios
                //     .post(url)
                //     .then(() => {
                //         this.$emit('add-profile', true)
                //         axios
                //             .get(urlGetInfo)
                //             .then((resp) => {
                //                 this.actualTable = resp.data.content.content
                //             })
                //             .catch(() => this.$root.visibleNotification({
                //             props: {
                //                 error: true,
                //                 title: "Ошибка",
                //                 desc: 'Что-то пошло не так, попробуйте позже'
                //             }
                //         }))
                //     })
                //     .catch(err => {
                //         this.$root.visibleNotification({
                //             props: {
                //                 error: true,
                //                 title: "Ошибка",
                //                 desc:err.response.data
                //             }
                //         })
                //     })
            }
        },
        components: {
            'input-cmp': InputCmp
        },
        created() {

        }
    }
</script>
