<template>
    <div class="oil-object-modal">
        <h2 class="oil-object-modal__title">Добавление объектов обустройства</h2>
        <span class="oil-object-modal__object">Участок I7 J10 / Ячейка i2 j1</span>
        <div class="oil-object-modal__table">
            <table-cmp />
            <div class="oil-object-modal__table-container">
                <table-cmp 
                    v-for="item in array"
                    :key="item.name"
                    :name="item.name"
                    :capex="item.capex"
                    :opex="item.opex"
                    :time="item.time"
                    :brigades="item.brigades"
                    :energy="item.energy"
                    :header="true"
                />
            </div>
        </div>
        <div class="oil-object-modal__btns">
            <button-cmp 
                :background="false"
                :text="'Отменить'"
            />
            <button-cmp 
                :text="'Добавить'"
            />
        </div>
    </div>
</template>
<script>
import tableCmp from './table-cmp/table-cmp.vue'
import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'


export default {
    data() {
        return {
            array: [
                {
                    name: 'Трубопровод',
                    capex: '500',
                    opex: '30',
                    time: '30',
                    brigades: '30',
                    energy: '30'
                },
                {
                    name: 'Трубопровод',
                    capex: '500',
                    opex: '30',
                    time: '30',
                    brigades: '30',
                    energy: '30'
                },
                {
                    name: 'Трубопровод',
                    capex: '500',
                    opex: '30',
                    time: '30',
                    brigades: '30',
                    energy: '30'
                },
                {
                    name: 'Трубопровод',
                    capex: '500',
                    opex: '30',
                    time: '30',
                    brigades: '30',
                    energy: '30'
                },
                {
                    name: 'Трубопровод',
                    capex: '500',
                    opex: '30',
                    time: '30',
                    brigades: '30',
                    energy: '30'
                },
                {
                    name: 'Трубопровод',
                    capex: '500',
                    opex: '30',
                    time: '30',
                    brigades: '30',
                    energy: '30'
                },
            ]
        }
    },
    components: {
        'table-cmp': tableCmp,
        'button-cmp': buttonCmp
    }
}
</script>
