<template>
    <div class="oil-view">
        <span class="oil-view__title">Вид</span>
        <switch-cmp 
            class="oil-view__switch"
            :title="'Объекты геологии'"
        />
        <switch-cmp 
            class="oil-view__switch"
            :title="'Объекты поверхности'"
        />
        <span class="oil-view__subtitle">Отображение объектов</span>
        <div class="oil-view__menubar">
            <menubar-cmp 
                class="oil-view__menubar-item"
                v-for="item in menu"
                :key="item.id"
                :active="active"
                :id="item.id"
                :text="item.name"
                @click="selectTabs(item.id)"
            />
        </div>
        <div class="oil-view__list">
            <template v-for="item in scene" :key="item.id">
                <div v-if="active === item.id">
                    <checkbox-cmp 
                        class="oil-view__list-item"
                        v-for="elem in item.value"
                        :key="elem.name"
                        :text="elem.name"
                    />
                </div>
            </template>
        </div>
    </div>
</template>
<script> 
import Switch from "../oil-switch/switch-cmp.vue"
import Menubar from "../oil-menubar/menubar-cmp.vue"
import Checkbox from "../oil-checkbox/checkbox-cmp.vue"

    export default {
        props: {
            scene: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                active: 1,
                menu: [
                    {
                        name: 'Скважины',
                        id: 1
                    },
                    {
                        name: 'Обустройство',
                        id: 2
                    },
                    {
                        name: 'Карты',
                        id: 3
                    },
                    {
                        name: '2D профили',
                        id: 4
                    },
                ]
            }
        },
        components: {
            'switch-cmp': Switch,
            'menubar-cmp': Menubar,
            'checkbox-cmp': Checkbox,
        },
        methods: {
            selectTabs(val) {
                this.active = val
            }
        },
    }
</script>