<template>
    <div class="oil-interval-modal">
        <span class="oil-interval-modal__title">Добавление интервала</span>
        <div class="oil-interval-modal__container">
            <selector-cmp 
                class="oil-interval-modal__selector"
                @select-type="setType"
            />
            <checkbox-cmp
                :text="'Цементирование'"
                class="oil-interval-modal__checkbox"
                @set-checkbox="setCheckbox"
                :activeCheckbox="type"
            ></checkbox-cmp>
            <template v-if="type === 'Эксплуатационная колонна'">
                <span class="oil-interval-modal__interval-label" v-if="checkbox === true">Интервал перфорации, м</span>
                <span class="oil-interval-modal__interval-label" v-else>Интервал открытого ствола, м</span>
                <div class="oil-interval-modal__interval">
                    <input-cmp
                        v-for="item in coords"
                        :key="item"
                        :label="item.label"
                        :type="item.id"
                        :value="item.value"
                        @set="setInterval"
                    ></input-cmp>
                </div>
            </template>
            <input-cmp 
                class="oil-interval-modal__inputs"
                v-for="item in form"
                :key="item"
                :label="item.label"
                :type="item.id"
                :value="item.value"
                @set="setValue"
            />
            <div class="oil-interval-modal__btns">
                <button-cmp
                    :text="'Отменить'"
                    :background="false"
                    @click="this.closeModal()"
                ></button-cmp>
                <button-cmp
                    @click="addInterbal"
                    :text="'Добавить'"
                ></button-cmp>
            </div>
        </div>
    </div>
</template>
<script> 
    import checkboxCmp from "../../../ui-components/oil-checkbox/checkbox-cmp.vue"
    import selectorCmp from '../../../ui-components/oil-selector-new/oil-selector.vue'
    import inputCmp from '../../../ui-components/oil-input/input-cmp'
    import buttonCmp from '../../../ui-components/oil-button/button-cmp'
    import InputCmp from '@/components/ui-components/oil-input/input-cmp.vue'
    // import axios from 'axios'
    import { mapMutations, mapActions } from 'vuex'


    export default {
        props: ['props'],
        data() {
            return {
                type: "",
                checkbox: true,
                coords: [
                    {
                        id: "before",
                        value: "1",
                        type: "text",
                        pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                        label: "От",
                        error: ""
                    },
                    {
                        id: "after",
                        value: "1",
                        type: "text",
                        pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                        label: "До",
                        error: ""
                    }
                ],
                form: [
                    {
                        id: "diametr",
                        value: "",
                        type: "text",
                        pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                        label: "Диаметр долота, mm",
                        error: ""
                    },
                    {
                        id: "md",
                        value: "",
                        type: "text",
                        pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                        label: "Глубина MD, mm",
                        error: ""
                    },
                    {
                        id: "inner_diametr",
                        value: "",
                        type: "text",
                        pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                        label: "Внутр. диаметр колонны, mm",
                        error: ""
                    },
                    {
                        id: "out_diametr",
                        value: "",
                        type: "text",
                        pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                        label: "Наруж. диаметр колонны, mm",
                        error: ""
                    },
                    {
                        id: "mark",
                        value: "",
                        type: "text",
                        pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                        label: "Марка стали",
                        error: ""
                    },
                    {
                        id: "weight",
                        value: "",
                        type: "text",
                        pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                        label: "Вес, kg/m",
                        error: ""
                    },
                    {
                        id: "MP",
                        value: "",
                        type: "text",
                        pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                        label: "Давление на разрыв, MPa ",
                        error: ""
                    },
                    {
                        id: "turmoil",
                        value: "",
                        type: "text",
                        pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                        label: "Давление на смятение, MPa ",
                        error: ""
                    },
                    {
                        id: "kn",
                        value: "",
                        type: "text",
                        pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                        label: "Страгивающая нагрузка, kN ",
                        error: ""
                    },
                ]
            }
        },
        components: {
            'checkbox-cmp': checkboxCmp,
            'selector-cmp': selectorCmp,
            'input-cmp': inputCmp,
            'button-cmp': buttonCmp,
                InputCmp,
        },
        methods: {
            ...mapMutations('modal', ['closeModal']),
            ...mapMutations('notification', ['openNotification']),
            ...mapMutations('request', ['updateIntervalDrilling']),
            ...mapActions('request', ['setData']),

            setValue(val) {
                for (let i = 0; i < this.form.length; i++) {
                    if (this.form[i].id === val.type) {
                        this.form[i].value = val.value;
                    }
                }
            },
            setInterval(val) {
                for (let i = 0; i < this.coords.length; i++) {
                    if (this.coords[i].id === val.type) {
                        this.coords[i].value = val.value;
                    }
                }
            },
            setType(val) {
                this.type = val
                if(this.type !== 'Эксплуатационная колонна') {
                    this.setCheckbox(true)
                }
            },
            setCheckbox(val) {
                
                if(this.type === 'Эксплуатационная колонна') {
                    this.checkbox = true
                    this.checkbox = val
                }
            },
            addInterbal() {
                const url = 'api/v1/Drilling/CasingColumn'
                
                // this.$root.setInfo({url: url, body: {
                //     "BoreholeId": this.$props.props.boreholeId,
                //     "IntervalName": this.type,

                //     "MeasuredDepthInMeters": this.form[1].value,

                //     "IsCementing": this.checkbox,
                //     "StartPerforationsMd": this.coords[0].value,
                //     "EndPerforationsMd": this.coords[1].value,
                //     "BitDiameterInMillimeters":this.form[0].value,

                //     "StartMd":this.coords[0].value,
                //     "EndMd":this.coords[1].value,

                //     "InnerColumnDiameterInMillimeters": this.form[2].value,
                //     "OuterColumnDiameterInMillimeters": this.form[3].value,

                //     "PerforationInterval": 40,
                //     "SteelGrade":  this.form[4].value,
                //     "WeightInKilo": this.form[5].value,
                //     "BurstPressureInMPa": this.form[6].value,
                //     "PressureOnConfusionInMPa": this.form[7].value,
                //     "StrainingLoadKN": this.form[8].value
                // }}).then(resp => {console.log(resp)})

                this.$root.setInfo({url: url, body: {
                    "BoreholeId": this.$props.props.boreholeId,
                    "IntervalName": this.type,

                    "MeasuredDepthInMeters": this.form[1].value,

                    "IsCementing": this.checkbox,
                    "StartPerforationsMd": this.coords[0].value,
                    "EndPerforationsMd": this.coords[1].value,
                    "BitDiameterInMillimeters":this.form[0].value,

                    "StartMd":this.coords[0].value,
                    "EndMd":this.coords[1].value,

                    "InnerColumnDiameterInMillimeters": this.form[2].value,
                    "OuterColumnDiameterInMillimeters": this.form[3].value,

                    "PerforationInterval": 40,
                    "SteelGrade":  this.form[4].value,
                    "WeightInKilo": this.form[5].value,
                    "BurstPressureInMPa": this.form[6].value,
                    "PressureOnConfusionInMPa": this.form[7].value,
                    "StrainingLoadKN": this.form[8].value
                }})
                .then((resp) => {
                    if(typeof(resp) === 'number') {
                        this.$root.visibleNotification({
                            props: {
                                success: true,
                                title: "Готово",
                                desc: 'Интервал успешно добавлен!'
                            }
                        })
                        this.updateIntervalDrilling()
                        
                        this.closeModal()
                    } else {
                        this.$root.visibleNotification({
                            props: {
                                error: true,
                                title: "Ошибка",
                                desc: resp.response.data
                            }
                        })
                    }            
                })
                // axios
                //     .post('http://193.124.112.131/api/v1/Drilling/CasingColumn', {
                //         "BoreholeId": this.$props.props.boreholeId,
                //         "IntervalName": this.type,

                //         "MeasuredDepthInMeters": this.form[1].value,

                //         "IsCementing": this.checkbox,
                //         "StartPerforationsMd": this.coords[0].value,
                //         "EndPerforationsMd": this.coords[1].value,
                //         "BitDiameterInMillimeters":this.form[0].value,

                //         "StartMd":this.coords[0].value,
                //         "EndMd":this.coords[1].value,

                //         "InnerColumnDiameterInMillimeters": this.form[2].value,
                //         "OuterColumnDiameterInMillimeters": this.form[3].value,

                //         "PerforationInterval": 40,
                //         "SteelGrade":  this.form[4].value,
                //         "WeightInKilo": this.form[5].value,
                //         "BurstPressureInMPa": this.form[6].value,
                //         "PressureOnConfusionInMPa": this.form[7].value,
                //         "StrainingLoadKN": this.form[8].value
                //     }
                // )
                // .then((resp) => {
                //     if(resp.status === 201) {
                //         this.$root.visibleNotification({
                //             props: {
                //                 success: true,
                //                 title: "Готово",
                //                 desc: 'Интервал успешно добавлен!'
                //             }
                //         })
                //         this.closeModal()
                //     }
                // })
                // .catch(err => {
                //     this.$root.visibleNotification({
                //         props: {
                //             error: true,
                //             title: "Ошибка",
                //             desc: err.response.data
                //         }
                //     })
                // })
            }
        }
    }
</script>