<template>
    <div class="oil-setting">
        <span class="oil-setting__title">Настройка карты</span>
        <template v-for="item in data" :key="item.id">
            <div class="oil-setting__switch" v-if="item.type === 'toggle'">
                <switch-cmp 
                    :title="item.name"
                />
            </div>
            <div class="oil-setting__radio" v-if="item.type === 'radio'" :class="{_disable: item.status === 'NotAvailable'}">
                <checkbox-cmp 
                    :text="item.name"
                    :id="item.id"
                    :visible="visible"
                    @set-maps="toggleMaps"
                />
            </div>
        </template>
    </div>
</template>
<script>
import Switch from '../oil-switch/switch-cmp.vue'
import checkboxCmp from '../../ui-components/oil-checkbox/checkbox-cmp.vue'

    export default {
        props: {
            data: {
                type: Array,
                default: () => []
            },
            visible: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                type: ''
            }
        },
        methods: {
            toggleMaps(val) {
                console.log(val);
                this.$emit('visible-map', val)
            }
        },
        components: {
            'switch-cmp': Switch,
            'checkbox-cmp': checkboxCmp
        }
    }
</script>

    