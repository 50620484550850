<template>
  <div class="oil-menubar" :class="{ _active: active === id }">
    <span>{{ text }}</span>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: 1,
    },
    active: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
};
</script>
