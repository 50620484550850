<template>
    <div class="oil-select-input">
        <div class="oil-select-input__choose" @click="openList">
            <label class="oil-select-input__choose-label">Скважины</label>
            <span>{{ choose }}</span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9L12 15L18 9" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="oil-select-input__list" v-if="open">
            <span v-for="item in list.items" :key="item" @click="selectBorehole({name: item.name, id:item.id})">{{ item.name }}</span>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            list: {
                type: Array,
                default: () => []
            }  
        },
        data() {
            return {
                choose: this.$props.list.items.length ? this.$props.list.items[0].name : null,
                open: false,
            }
        },
        // watch: {
        //     list() {
        //         this.choose = this.$props.list.items[0].name
        //     }
        // },
        methods: {
            openList() {
                this.open = !this.open
            },
            selectBorehole(val) {
                this.choose = val.name
                this.open = false
                this.$emit('boreholeId', val.id)
                // this.$router.push(`drilling/${id}`)
            }
        },
    }
</script>
