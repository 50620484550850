<template>
    <transition name="fadeTop">
        <div class="oil-header">
            <div class="oil-header__container">
                <div v-for="item in menu" :key="item" class="oil-header__container-inner">
                    <div class="oil-header__resources">
                        <div v-for="elem in item.items" :key="elem.unit" class="oil-header__resources-container">
                            <icon-cmp 
                                class="oil-header__resources-item"                            
                                :count="elem.count"
                                :units="elem.unit"
                                :img="elem.icon"
                                v-if="elem.unit"
                                @mouseenter="showTooltip = elem.tooltipId"
                                @mouseleave="showTooltip = false"
                            />
                            <transition name="fade">
                                <div class="oil-header__resources-tooltip" v-if="showTooltip === elem.tooltipId && elem.tooltip">
                                    <span>{{ elem.tooltip }}</span>
                                </div>
                            </transition>
                        </div>
                       
                    </div>
                    <div class="oil-header__game">
                        <div v-for="elem in item.subMenu" :key="elem" class="oil-header__game-sub-menu-container">
                            <icon-cmp 
                                class="oil-header__resources-item"
                                :count="elem.count"
                                :units="elem.unit"
                                :img="elem.icon"
                                @mouseenter="showTooltip = elem.tooltipId"
                                @mouseleave="showTooltip = false"
                            />
                            <transition name="fade">
                                <div class="oil-header__game-sub-menu-tooltip" v-if="showTooltip === elem.tooltipId && elem.tooltip">
                                    <span>{{ elem.tooltip }}</span>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
            <a class="oil-header__btns-tg" href="https://t.me/oilcase_support" target="_blank">Служба поддержки</a>
            <div class="oil-header__btns">
                <!-- <selector-cmp
                    :menu="menu.tools"
                /> -->
                <!-- <button style="background-color: blue; color: white; border-radius: 8px; padding: 8px; cursor: pointer;" @click="() =>
                    {
                    const url = 'Views/Browser/Navigation/Header'
                    this.$root.getInfo(url)
                    .then((resp) => {this.menu = resp.items})
                    .catch(() => this.$root.visibleNotification({
                    })
                    )
                    }">Обнови меня
                </button>
                <button style="background-color: blue; color: white; border-radius: 8px; padding: 8px; cursor: pointer;" @click="() => {
                    const url = 'api/v1/Reset'
                    this.$root.getInfo(url)}">Reset</button> -->
                <button-cmp 
                    :text="'Завершить ход'"
                    :done="true"
                    @click="$router.push('/turn')" 
                />
            </div>
        </div>
    </transition>    
</template>
<script>
import icon from "../../ui-components/oil-icons/icons-cmp.vue"
// import selector from "../../ui-components/oil-selector/selector-cmp.vue"
import button from "../../ui-components/oil-button/button-cmp.vue"

import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {
            showTooltip: false,
            menu: [],
            countResourse: [],
            iconsGeneral: [
                {
                    icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6663 4.16663C16.6663 5.54734 13.6816 6.66663 9.99967 6.66663C6.31778 6.66663 3.33301 5.54734 3.33301 4.16663M16.6663 4.16663C16.6663 2.78591 13.6816 1.66663 9.99967 1.66663C6.31778 1.66663 3.33301 2.78591 3.33301 4.16663M16.6663 4.16663V15.8333C16.6663 17.214 13.6816 18.3333 9.99967 18.3333C6.31778 18.3333 3.33301 17.214 3.33301 15.8333V4.16663M16.6663 8.05546C16.6663 9.43617 13.6816 10.5555 9.99967 10.5555C6.31778 10.5555 3.33301 9.43617 3.33301 8.05546M16.6663 11.9416C16.6663 13.3223 13.6816 14.4416 9.99967 14.4416C6.31778 14.4416 3.33301 13.3223 3.33301 11.9416" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                },
                {
                    icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.3337 17.5V15.8333C18.3337 14.2801 17.2713 12.9751 15.8337 12.605M12.917 2.7423C14.1386 3.23679 15.0003 4.43443 15.0003 5.83333C15.0003 7.23224 14.1386 8.42988 12.917 8.92437M14.167 17.5C14.167 15.9469 14.167 15.1703 13.9133 14.5577C13.5749 13.741 12.926 13.092 12.1093 12.7537C11.4967 12.5 10.7201 12.5 9.16699 12.5H6.66699C5.11385 12.5 4.33728 12.5 3.72471 12.7537C2.90795 13.092 2.25904 13.741 1.92073 14.5577C1.66699 15.1703 1.66699 15.9469 1.66699 17.5M11.2503 5.83333C11.2503 7.67428 9.75794 9.16667 7.91699 9.16667C6.07604 9.16667 4.58366 7.67428 4.58366 5.83333C4.58366 3.99238 6.07604 2.5 7.91699 2.5C9.75794 2.5 11.2503 3.99238 11.2503 5.83333Z" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                },
                {
                    icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8331 1.66663L3.41094 10.5732C3.12026 10.922 2.97492 11.0964 2.9727 11.2437C2.97077 11.3717 3.02783 11.4935 3.12744 11.574C3.24201 11.6666 3.46904 11.6666 3.92308 11.6666H9.99972L9.16639 18.3333L16.5885 9.42675C16.8792 9.07794 17.0245 8.90354 17.0267 8.75624C17.0287 8.62819 16.9716 8.50637 16.872 8.42588C16.7574 8.33329 16.5304 8.33329 16.0764 8.33329H9.99972L10.8331 1.66663Z" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                },
                {
                    icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 8.33329H2.5M13.3333 1.66663V4.99996M6.66667 1.66663V4.99996M6.5 18.3333H13.5C14.9001 18.3333 15.6002 18.3333 16.135 18.0608C16.6054 17.8211 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7334 17.5 14.3333V7.33329C17.5 5.93316 17.5 5.2331 17.2275 4.69832C16.9878 4.22791 16.6054 3.84546 16.135 3.60578C15.6002 3.33329 14.9001 3.33329 13.5 3.33329H6.5C5.09987 3.33329 4.3998 3.33329 3.86502 3.60578C3.39462 3.84546 3.01217 4.22791 2.77248 4.69832C2.5 5.2331 2.5 5.93316 2.5 7.33329V14.3333C2.5 15.7334 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8211 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333Z" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                },
            ],
            iconsResources: [
                {
                    icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0003 17.9167C11.5474 17.9167 13.0312 17.3021 14.1251 16.2082C15.2191 15.1142 15.8337 13.6305 15.8337 12.0834C15.8337 10.4167 15.0003 8.83337 13.3337 7.50004C11.667 6.16671 10.417 4.16671 10.0003 2.08337C9.58366 4.16671 8.33366 6.16671 6.66699 7.50004C5.00033 8.83337 4.16699 10.4167 4.16699 12.0834C4.16699 13.6305 4.78157 15.1142 5.87554 16.2082C6.9695 17.3021 8.45323 17.9167 10.0003 17.9167Z" fill="#905CA9" stroke="#905CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                },
                {
                    icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 15C17.5 15 16.508 14.6087 15.8333 14.4183C11.5664 13.2145 8.43357 16.7855 4.16667 15.5816C3.49201 15.3912 2.5 15 2.5 15M17.5 9.99996C17.5 9.99996 16.508 9.60869 15.8333 9.41834C11.5664 8.21445 8.43357 11.7855 4.16667 10.5816C3.49201 10.3912 2.5 9.99996 2.5 9.99996M17.5 4.99996C17.5 4.99996 16.508 4.60869 15.8333 4.41834C11.5664 3.21445 8.43357 6.78547 4.16667 5.58158C3.49201 5.39122 2.5 4.99996 2.5 4.99996" stroke="#905CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                },
                {
                    icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.4667 5.06671C11.0398 4.15098 11.4461 3.14091 11.6667 2.08337C12.0833 4.16671 13.3333 6.16671 15 7.50004C16.6667 8.83337 17.5 10.4167 17.5 12.0834C17.5048 13.2353 17.1674 14.3627 16.5307 15.3226C15.8939 16.2826 14.9865 17.0318 13.9234 17.4754C12.8604 17.9191 11.6895 18.037 10.5593 17.8144C9.42911 17.5918 8.39044 17.0387 7.575 16.225M5.83333 13.15C7.66667 13.15 9.16667 11.625 9.16667 9.77504C9.16667 8.80837 8.69167 7.89171 7.74167 7.11671C6.79167 6.34171 6.075 5.19171 5.83333 3.98337C5.59167 5.19171 4.88333 6.35004 3.925 7.11671C2.96667 7.88337 2.5 8.81671 2.5 9.77504C2.5 11.625 4 13.15 5.83333 13.15Z" stroke="#905CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                },
                {
                    icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0003 17.9167C11.5474 17.9167 13.0312 17.3021 14.1251 16.2082C15.2191 15.1142 15.8337 13.6305 15.8337 12.0834C15.8337 10.4167 15.0003 8.83337 13.3337 7.50004C11.667 6.16671 10.417 4.16671 10.0003 2.08337C9.58366 4.16671 8.33366 6.16671 6.66699 7.50004C5.00033 8.83337 4.16699 10.4167 4.16699 12.0834C4.16699 13.6305 4.78157 15.1142 5.87554 16.2082C6.9695 17.3021 8.45323 17.9167 10.0003 17.9167Z" stroke="#905CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                }
            ],
            iconsTools: [
                {
                    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 9.5V6.1C17 5.53995 17 5.25992 16.891 5.04601C16.7951 4.85785 16.6422 4.70487 16.454 4.60899C16.2401 4.5 15.9601 4.5 15.4 4.5H3M13 14.5V17.9C13 18.4601 13 18.7401 12.891 18.954C12.7951 19.1422 12.6422 19.2951 12.454 19.391C12.2401 19.5 11.9601 19.5 11.4 19.5H3M3 2L3 22M3 14.5L19.4 14.5C19.9601 14.5 20.2401 14.5 20.454 14.391C20.6422 14.2951 20.7951 14.1422 20.891 13.954C21 13.7401 21 13.4601 21 12.9V11.1C21 10.5399 21 10.2599 20.891 10.046C20.7951 9.85785 20.6422 9.70487 20.454 9.60899C20.2401 9.5 19.9601 9.5 19.4 9.5L3 9.5L3 14.5Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                },
                {
                    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 8.37722C2 8.0269 2 7.85174 2.01462 7.70421C2.1556 6.28127 3.28127 5.1556 4.70421 5.01462C4.85174 5 5.03636 5 5.40558 5C5.54785 5 5.61899 5 5.67939 4.99634C6.45061 4.94963 7.12595 4.46288 7.41414 3.746C7.43671 3.68986 7.45781 3.62657 7.5 3.5C7.54219 3.37343 7.56329 3.31014 7.58586 3.254C7.87405 2.53712 8.54939 2.05037 9.32061 2.00366C9.38101 2 9.44772 2 9.58114 2H14.4189C14.5523 2 14.619 2 14.6794 2.00366C15.4506 2.05037 16.126 2.53712 16.4141 3.254C16.4367 3.31014 16.4578 3.37343 16.5 3.5C16.5422 3.62657 16.5633 3.68986 16.5859 3.746C16.874 4.46288 17.5494 4.94963 18.3206 4.99634C18.381 5 18.4521 5 18.5944 5C18.9636 5 19.1483 5 19.2958 5.01462C20.7187 5.1556 21.8444 6.28127 21.9854 7.70421C22 7.85174 22 8.0269 22 8.37722V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V8.37722Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 16.5C14.2091 16.5 16 14.7091 16 12.5C16 10.2909 14.2091 8.5 12 8.5C9.79086 8.5 8 10.2909 8 12.5C8 14.7091 9.79086 16.5 12 16.5Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                },
                {
                    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.5056 10.7754C21.1225 10.5355 21.431 10.4155 21.5176 10.2459C21.5926 10.099 21.5903 9.92446 21.5115 9.77954C21.4205 9.61226 21.109 9.50044 20.486 9.2768L4.59629 3.5728C4.0866 3.38983 3.83175 3.29835 3.66514 3.35605C3.52029 3.40621 3.40645 3.52004 3.35629 3.6649C3.29859 3.8315 3.39008 4.08635 3.57304 4.59605L9.277 20.4858C9.50064 21.1088 9.61246 21.4203 9.77973 21.5113C9.92465 21.5901 10.0991 21.5924 10.2461 21.5174C10.4157 21.4308 10.5356 21.1223 10.7756 20.5054L13.3724 13.8278C13.4194 13.707 13.4429 13.6466 13.4792 13.5957C13.5114 13.5506 13.5508 13.5112 13.5959 13.479C13.6468 13.4427 13.7072 13.4192 13.828 13.3722L20.5056 10.7754Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                },
                {
                    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 15L6.92474 18.1137C6.49579 18.548 6.28131 18.7652 6.09695 18.7805C5.93701 18.7938 5.78042 18.7295 5.67596 18.6076C5.55556 18.4672 5.55556 18.162 5.55556 17.5515V15.9916C5.55556 15.444 5.10707 15.0477 4.5652 14.9683V14.9683C3.25374 14.7762 2.22378 13.7463 2.03168 12.4348C2 12.2186 2 11.9605 2 11.4444V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H14.2C15.8802 2 16.7202 2 17.362 2.32698C17.9265 2.6146 18.3854 3.07354 18.673 3.63803C19 4.27976 19 5.11984 19 6.8V11M19 22L16.8236 20.4869C16.5177 20.2742 16.3647 20.1678 16.1982 20.0924C16.0504 20.0255 15.8951 19.9768 15.7356 19.9474C15.5558 19.9143 15.3695 19.9143 14.9969 19.9143H13.2C12.0799 19.9143 11.5198 19.9143 11.092 19.6963C10.7157 19.5046 10.4097 19.1986 10.218 18.8223C10 18.3944 10 17.8344 10 16.7143V14.2C10 13.0799 10 12.5198 10.218 12.092C10.4097 11.7157 10.7157 11.4097 11.092 11.218C11.5198 11 12.0799 11 13.2 11H18.8C19.9201 11 20.4802 11 20.908 11.218C21.2843 11.4097 21.5903 11.7157 21.782 12.092C22 12.5198 22 13.0799 22 14.2V16.9143C22 17.8462 22 18.3121 21.8478 18.6797C21.6448 19.1697 21.2554 19.5591 20.7654 19.762C20.3978 19.9143 19.9319 19.9143 19 19.9143V22Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                }
            ]
        }
    },
    components: {
        "icon-cmp": icon,
        // "selector-cmp": selector,
        "button-cmp": button,
    },
    computed: {
        ...mapGetters('request', ['update']),
        ...mapState('request', ['update', 'host']),
    },
    watch: {
        update() {
            const url = 'Views/Browser/Navigation/Header'
            this.$root.getInfo(url)
                .then((resp) => {this.menu = resp.items})
                .catch(() => this.$root.visibleNotification({
                    props: {
                        error: true,
                        title: "Ошибка",
                        desc: 'Что-то пошло не так, попробуйте позже'
                    }
                })
            )
        },
        menu() {
            for (let i = 0; i < this.menu.length; i ++) {                
                this.menu[i].items.map((item, idx) => Object.assign(item, this.iconsGeneral[idx]))
                this.menu[i].subMenu.map((item, idx) => Object.assign(item, this.iconsResources[idx]))
            }
            this.disableCurtain()
        }
    },
    methods: {
        ...mapMutations('curtain', ['enableCurtain', 'disableCurtain']),
    },
    created() { 
        const url = 'Views/Browser/Navigation/Header'
        this.$root.getInfo(url)
            .then((resp) => {this.menu = resp.items})
            .catch(() => this.$root.visibleNotification({
                props: {
                    error: true,
                    title: "Ошибка",
                    desc: 'Что-то пошло не так, попробуйте позже'
                }
            })
        )
    }   
}
</script>