<template>
    <div class="oil-add-map-modal" v-if="visible">
        <span class="oil-add-map-modal__title">Добавление карт и лицензионных участков</span>
        <div class="oil-add-map-modal__table">
            <table-cmp 
                v-for="item in table"
                :key="item"
                :view="item.type"
                :table="item.content"
                @updateMaps="updateMapList"
            />
        </div>
        <div class="oil-add-map-modal__btns">
            <button-cmp 
                :text="'Отменить'"
                :background="false"
                @click="closeModal"
            />
            <button-cmp 
                :text="'Добавить'"
                @click="addMaps"
            />
        </div>
    </div>
</template>
<script>  
import tableCmp from "./table-map/table-map.vue"
import buttonCmp from "../../../ui-components/oil-button/button-cmp.vue"
// import axios from 'axios'
import {mapMutations } from 'vuex'

    export default {
        data() {
            return {
                table: [],
                maps: [],
                visible: false
            }
        },
        components: {
            'table-cmp': tableCmp,
            'button-cmp': buttonCmp
        },
        watch: {
            table() {
                if(this.table.length) {
                    this.visible = true
                }
            }
        },
        methods: {
            ...mapMutations('modal', ['openModal', 'closeModal']),
            ...mapMutations('notification', ['openNotification']),
            ...mapMutations('request', ['updateMap']),

            addMaps() {
                const url = 'api/v1/Purchased/SurfaceImage'

                this.$root.setInfo({url: url, body: this.maps.map(item => {
                    return {Id: item.val.id}
                })})
                .then((resp) => {
                    if(typeof(resp) === "object") {
                        this.closeModal()
                        this.updateMap()
                    } else {
                        this.$root.visibleNotification({
                            props: {
                                error: true,
                                title: "Ошибка",
                                desc: resp.response.data
                            }
                        })
                    }
                })
                // axios.post(url, this.maps.map(item => {
                //     return {Id: item.val.id
                // }}))
                // .then(() => {
                //     this.closeModal()
                // })
                // .catch((err) => {
                //     this.$root.visibleNotification({
                //         props: {
                //             error: true,
                //             title: "Ошибка",
                //             desc: err.response.data
                //         }
                //     })
                // })
            },
            updateMapList(maps) {
                this.maps = maps
            }
        },
        created() {
            const url = 'Views/Browser/Map/ObjectsAndResearches/SurfaceImageAddModal'
            this.$root.getInfo(url)
                .then((resp) => {this.table = [resp.header, resp.content]})
                .catch(() => {
                    this.$root.visibleNotification({
                        props: {
                            error: true,
                            title: "Ошибка",
                            desc: "Что-то пошло не так, попробуйте позже"
                        }
                    })
                }
            )
        }
    }
</script>