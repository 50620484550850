<template>
    <div class="oil-communication-modal">
        <span class="oil-communication-modal__title">Добавление коммуникаций</span>
        <p class="oil-communication-modal__text">Выберите тип коммуникации, который вы хотите построить. Нажмите кнопку “Начать” и выберите клетку, до которой хотите провести коммуникацию. Чтобы завершить строительство, нажмите кнопку “Выйти из режима строительства”.</p>
        <table-cmp 
            v-for="item in props.actualData"
            :key="item"
            :view="item.type"
            :content="item.content"
        />
        <div class="oil-communication-modal__btns">
            <btn-cmp 
                :text="'Отменить'"
                :background="false"
                @click="closeModal()"
            />
            <btn-cmp 
                :text="'Начать'"
                :background="true"
                @click="startBuilding()"
            />
        </div>
    </div>
</template>
<script>
    import tableCmp from './table-cmp/table-cmp.vue'
    import btnCmp from '../../../ui-components/oil-button/button-cmp.vue'
    import { mapMutations } from 'vuex'

    export default {
        data() {
            return {
            }
        },
        props: ['props'],
        components: {
            'table-cmp': tableCmp,
            'btn-cmp': btnCmp
        },
        methods: {
            ...mapMutations('modal', ['closeModal']),
            ...mapMutations('building', ['setBuilding']),

            startBuilding() {
                this.setBuilding(true)
                this.closeModal()
            },
        }
    }
</script>