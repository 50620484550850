<template>
    <div class="oil-object-modal-table" :class="{'_non-header': header}">
        <radio-cmp 
            v-if="header"
            :text="name"
        />
        <div class="oil-object-modal-table__first" v-else>
            <div class="oil-object-modal-table__first-frame"></div>
            <span class="_large">{{ name }}</span>
        </div>
        <span class="_medium">{{ capex }}</span>
        <span class="_medium">{{ opex }}</span>
        <span class="_medium">{{ time }}</span>
        <span class="_medium">{{ brigades }}</span>
        <span class="_medium">{{ energy }}</span>
    </div>
</template>
<script> 
    import radioCmp from '../../../../ui-components/oil-radio/radio-cmp.vue'

    export default {
        props: {
            name: {
                type: String,
                default: 'Объект обустройства'
            },
            capex: {
                type: String,
                default: 'CAPEX'
            },
            opex: {
                type: String,
                default: 'OPEX'
            },
            time: {
                type: String,
                default: 'Время'
            },
            brigades: {
                type: String,
                default: 'Бригады'
            },
            energy: {
                type: String,
                default: 'Энергия'
            },
            header: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {

            }
        },
        components: {
            'radio-cmp': radioCmp
        }

    }
</script>