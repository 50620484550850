<template>
    <div class="oil-profile-modal">
        <h2 class="oil-profile-modal__title">{{ props.actualData.title.text }}</h2>
        <template v-if="props.horizontal">
            <span class="oil-profile-modal__coords"> 
                    x: {{ props.coords.x }}
                    Восток-Запад
            </span>
        </template>
        <template v-if="props.vertical">
            <!-- <span class="oil-profile-modal__coords" v-for="item in props.actualData.subtitle" :key="item.id"> 
                y: {{ props.coords.y }}
                {{ item.text.vertical }}
            </span> -->
            <span class="oil-profile-modal__coords"> 
                y: {{ props.coords.y }}
                Север-Юг
            </span>
        </template>
        <table-cmp 
            v-for="item in props.actualData.table"
            :key="item"
            :view="item.type"
            :table="item.content"
        />
        <!-- <template v-for="item in actualData.table" :key="item.id">
            <table-cmp 
                :type="item.type"
                :data="item"
            />
        </template> -->
        <div class="oil-profile-modal__btns">
            <button-cmp 
                :background="false"
                :text="'Отменить'"
                @click="this.closeModal()"
            />
            <button-cmp 
            @click="setLine"
                :text="'Добавить'"
            />
        </div>
    </div>
</template>
<script> 
// import tableCmp from '../../../ui-components/oil-tables/arrangement-table/arrangement-table.vue'

import tableCmp from './table-cmp/table-cmp.vue'
import buttonCmp from '../../../ui-components/oil-button/button-cmp.vue'
import { mapMutations } from "vuex"

    export default {
        props: ['props'],
        data() {
            return {
                array: [
                    {
                        name: 'REVEX (ОС)',
                        value: '85 700'
                    },
                    {
                        name: 'Время (сут)',
                        value: '30'
                    },
                    {
                        name: 'Бригады (WFU)',
                        value: '2'
                    },
                ]
            }
        },
        components: {
            'table-cmp': tableCmp,
            'button-cmp': buttonCmp
        },
        methods: {
            ...mapMutations('request', ['updateMap']),
            ...mapMutations('modal', ['closeModal']),
            ...mapMutations('notification', ['openNotification']),

            
            setLine() {
                const url = 'api/v1/Purchased/Seismic'
                if(this.$props.props.type === 'horizontalSeismic') {
                    this.$root.setInfo({url: url, body: {   
                        "StartX": 1,
                        "StartY": this.$props.props.coords.y,
                        "EndX": 25,
                        "EndY": this.$props.props.coords.y
                    }})
                    .then((resp) => {
                        if(typeof(resp) === "number") {
                            this.updateMap()
                            this.$root.visibleNotification({
                                props: {
                                    success: true,
                                    title: "Готово",
                                    desc: 'Сеймика успешно добавлена'
                                }
                            })
                            this.closeModal()
                        } else {
                            this.$root.visibleNotification({
                                props: {
                                    error: true,
                                    title: "Ошибка",
                                    desc: resp.response.data
                                }
                            })
                        }
                    })
                } else {
                    this.$root.setInfo({url: url, body: {   
                        "StartX": this.$props.props.coords.x,
                        "StartY": 1,
                        "EndX": this.$props.props.coords.x,
                        "EndY": 25
                    }})
                    .then((resp) => {
                        if(typeof(resp) === "number") {
                            this.updateMap()
                            this.$root.visibleNotification({
                                props: {
                                    success: true,
                                    title: "Готово",
                                    desc: 'Сеймика успешно добавлена'
                                }
                            })
                            this.closeModal()
                        } else {
                            this.$root.visibleNotification({
                                props: {
                                    error: true,
                                    title: "Ошибка",
                                    desc: resp.response.data
                                }
                            })
                        }
                    })
                }
            }
            // closeModal() {
            //     this.closeModal()
            // }
        }
    }
</script>