<template>
    <transition name="fade">
        <div class="oil-modal" v-if="modalComponent" :key="modalComponent">
            <div v-if="modalComponent !== 'authModal' && modalComponent !== 'restoreModal'" @click="$root.closeModal()" class="oil-modal__shadow"></div>
            <component v-bind:is="modalComponent" :props="modalProps" @blind="setCurtainState"></component>
        </div>
    </transition>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import authModal from './auth-modal/auth-modal.vue'
    import mapModal from './map-modal-new/map-modal.vue'
    import objectModal from './object-modal/object-modal.vue'
    import profileModal from './profile-modal/profile-modal.vue'
    import restoreModal from './restore-modal/restore-modal.vue'
    import boreholeModal from './borehole-modal/boreholes-modal.vue'
    import intervalModal from './interval-modal/interval-modal.vue'
    import levelsModal from './levels-modal/levels-modal.vue'
    import addMapModal from './add-map-modal/add-map-modal.vue'
    import communicationModal from './communication-modal/communication-modal.vue'
    import statusModal from './status-modal/status-modal.vue'

    export default {
        data() {
            return {
                modalShadow: true,
                curtain: true
            }
        },
        components: {
            authModal,
            mapModal,
            objectModal,
            profileModal,
            restoreModal,
            boreholeModal,
            intervalModal,
            levelsModal,
            addMapModal,
            communicationModal,
            statusModal
        },
        computed: {
            ...mapGetters('modal', ['modalComponent', 'modalProps', 'modalShadow']),
        },
        watch: {
            modalShadow() {
                this.$emit('shadow', this.modalShadow)
            },
            curtain() {
                this.$emit('curtain', this.curtain)
            },
            modalComponent() {
                console.log(this.modalComponent);
            }
        },
        methods: {
            ...mapMutations('modal', ['closeModal']),

            setModalShadow(val) {
                this.modalShadow = val
            },
            setCurtainState(val) {
                this.curtain = val
            }
        },
    }
</script>

<style lang="sass">

</style>