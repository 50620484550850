
import { defineComponent } from 'vue'
import InputCmp from "../../../ui-components/oil-input/input-cmp.vue";
import CheckboxCmp from "../../../ui-components/oil-checkbox/checkbox-cmp.vue";
import ButtonCmp from "../../../ui-components/oil-button/button-cmp.vue";
import { mapActions, mapMutations, mapState } from 'vuex';


export default defineComponent({
    setup() {
    },
    data() {
        return {
            form: [
                {
                    id: "userName",
                    value: "",
                    type: "email",
                    pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                    label: "Email",
                    error: ""
                },
                {
                    id: "password",
                    value: "",
                    type: "password",
                    pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                    label: "Пароль",
                    error: ""
                },
            ] as Array<{
                id: string,
                value: string,
                type: string,
                pattern: string,
                label: string,
                error: string
            }>,
            data: {} as {
                title: string,
                remember: string,
                login: string,
                switch: string,
                forgotPass: string
            },
            remember: false as Boolean,
            password: false as Boolean,
            login: {},
            blind: false
        }
    },
    components: {
        'input-cmp': InputCmp,
        'button-cmp': ButtonCmp,
        'checkbox-cmp': CheckboxCmp,
    },  
    watch: {
        data() {                        
            this.disableCurtain()
        }
    },
    computed: {
        ...mapState('request', ['isAuth', 'token']),

    },
    methods: {
        ...mapActions('request', ['getToken']),
        ...mapMutations('modal', ['openModal', 'closeModal']),
        ...mapMutations('curtain', ['enableCurtain', 'disableCurtain']),
        ...mapMutations('request', ['rememberMe']),

        setValue(val) {
            for (let i = 0; i < this.form.length; i++) {
                if (this.form[i].type === val.type) {
                    this.form[i].value = val.value;
                }
            }
        },
        visiblePass(type) {
            if(type === 'password') {
                this.form[1].type = 'text'
            } else if(type === 'text') {
                this.form[1].type = 'password'
            }
        },
        rememberLogin() {
            this.rememberMe()
        },
        submit() {
            for (let key in this.form) {
                this.login[this.form[key].id] = this.form[key].value
            }            
            this.getToken(this.login)
            .then(() => {
                this.enableCurtain()
            })
            .catch(err => {   
                    this.disableCurtain()
                    this.$root.visibleNotification({
                        props: {
                            error: true,
                            title: "Ошибка",
                            desc: err.response.data
                        }
                    })
                })
            
        },
        
    },
    created() {
        const url = 'api/v1/Static/Text?key=mainModal&languageId=ru'
        this.$root.getInfo(url)
            .then((resp) => {this.data = resp})
            .catch(() => this.$root.visibleNotification({
                props: {
                    error: true,
                    title: "Ошибка",
                    desc: 'Что-то пошло не так, попробуйте позже'
                }
            })
        )
    }
})
