<template>
    <div class="oil-status-modal" v-if="visible">
        <span class="oil-status-modal__title">Изменение статуса {{ props.text }}</span>
        <div class="oil-status-modal__table">
            <radio-cmp 
                class="oil-status-modal__table-radio"
                v-for="item in statuses"
                :key="item"
                :text="item.text"
                :active="boreholeId"
                :id="item.id"
                :status="item.status"
                @setRadio="setValue"
                @setId="setId"
                :class="{_disable: item.status === 'NotAvailable'}"
            />
        </div>
        <div class="oil-status-modal__btns">
            <btn-cmp 
                :text="'Отменить'"
                :background="false"
                @click="closeModal()"
            />
            <btn-cmp 
                :text="'Изменить'"
                @click="changeStatus"
            />
        </div>
    </div>
</template>
<script>
import radioCmp from '../../../ui-components/oil-radio/radio-cmp.vue'
import btnCmp from "../../../ui-components/oil-button/button-cmp.vue"
import { mapMutations, mapState } from 'vuex'
import axios from 'axios'

    export default {
        props: ['props'],
        data() {
            return {
                choose: '',
                statuses: [],
                visible: false,
                boreholeId: null
            }
        },
        components: {
            'radio-cmp': radioCmp,
            'btn-cmp': btnCmp
        },
        computed: {
            ...mapState('request', ['host'])
        },
        methods: {
            ...mapMutations('modal', ['closeModal']),
            ...mapMutations('request', ['updateMap']),
            ...mapMutations('notification', ['openNotification']),

            setValue(val) {
                this.choose = val
            },
            setId(id) {
                this.boreholeId = id
            },
            changeStatus() {
                const url = `${this.host}api/v1/Purchased/${this.$props.props.type}`

                axios
                    .patch(url, {
                        id: this.$props.props.id,
                        status: this.boreholeId
                    })
                    .then(() => {
                        this.closeModal()
                        this.updateMap()
                    })
                    .catch(err => {
                        this.$root.visibleNotification({
                            props: {
                                error: true,
                                title: "Ошибка",
                                desc: err.response.data
                            }
                        })
                    })
            }
        },
        watch: {
            statuses() {
                if(this.statuses.length) {
                    this.visible = true
                    this.boreholeId = this.statuses.find(item => item.status === 'Current').id
                    this.choose = this.statuses.find(item => item.status === 'Current').status
                }
            }
        },
        created() {
            const url = this.$props.props.url
            axios
                .get(url)
                .then((resp) => {
                    this.statuses = resp.data
                })
                .catch(() => this.$root.visibleNotification({
                    props: {
                        error: true,
                        title: "Ошибка",
                        desc: 'Что-то пошло не так, попробуйте позже'
                    }
                }))
        },
    }
</script>