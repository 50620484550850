<template>
    <div class="oil-drilling-page oil-container">
        <div v-if="visible">
            <div class="oil-drilling-page__header">
                <select-input 
                    class="oil-drilling-page__header-select"
                    v-if="selectInputVisible"
                    :list="list"
                    @boreholeId="selectBorehole"
                />
                <div class="oil-drilling-page__header-menubar">
                    <menubar-cmp
                        v-for="item in menubar"
                        :key="item.id"
                        :active="active"
                        :id="item.id"
                        class="oil-drilling-page__header-menubar-item"
                        :text="item.text"
                        @click="selectMenu(item.id)"
                    />
                </div>
            </div>
            <div class="oil-drilling-page__content">
                <h1>Ввод конструкции скважины</h1>
                <div class="oil-drilling-page__content-container">
                    <div class="oil-drilling-page__content-container-table">
                        <transition-group name="fadeRight">
                            <div v-if="active === 'Construction'">
                                <construct-table 
                                    v-for="item in construct"
                                    :key="item"
                                    :view="item.type"
                                    :table="item.content"
                                    :boreholeId="boreholeId"
                                />
                            </div>
                            <div v-if="active === 'Profile'">
                                <profile-table 
                                    v-for="item in profile"
                                    :key="item"
                                    :view="item.type"
                                    :table="item.content"
                                    @add-profile="updateMap"
                                    :boreholeId="boreholeId"
                                />
                            </div>
                        </transition-group>
                    </div>
                    <transition name="fade">
                        <div class="oil-drilling-page__content-container-scheme"
                            v-if="active !== 'Profile'"
                        >
                        <svg class="oil-drilling-page__content-container-scheme-borehole" width="31" height="53" viewBox="0 0 31 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.1457 7.29268V3.5122L16.7023 2H14.2965L12.853 3.5122V6.91463" stroke="#374351" stroke-width="2.26829"/>
                            <path d="M18.9011 12.6406L9.4499 25.1162L23.8157 36.4911L4.15723 46.3982" stroke="#374351" stroke-width="2.21297"/>
                            <path d="M11.719 12.6406L21.1702 25.1162L7.18245 36.4911L26.4629 46.3982" stroke="#374351" stroke-width="2.21297"/>
                            <path d="M21.9269 7.13672H9.07324V11.5399H21.9269V7.13672Z" fill="#374351" stroke="#374351" stroke-width="2.21297"/>
                            <path d="M9.07324 25.4834H21.9269" stroke="#374351" stroke-width="2.21297"/>
                            <path d="M6.80664 36.4912L24.1968 36.4912" stroke="#374351" stroke-width="2.21297"/>
                            <path d="M0 46.7656L30.9999 46.7656" stroke="#374351" stroke-width="2.95062"/>
                            <path d="M0 51.1689L30.9999 51.1689" stroke="#374351" stroke-width="2.95062"/>
                            <path d="M12.0988 11.9072L3.78174 47.1325" stroke="#374351" stroke-width="2.21297"/>
                            <path d="M18.5246 11.9072L27.2197 47.1325" stroke="#374351" stroke-width="2.21297"/>
                            <rect x="0.368828" y="46.4001" width="2.28673" height="5.13322" fill="#374351" stroke="#374351" stroke-width="0.737656"/>
                            <rect x="28.3449" y="46.4001" width="2.28673" height="5.13322" fill="#374351" stroke="#374351" stroke-width="0.737656"/>
                        </svg>
                        <svg width="373" height="689" viewBox="0 0 373 689" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="path-1-inside-1_4809_11223" fill="white">
                            <path d="M0 12C0 5.37258 5.37258 0 12 0H361C367.627 0 373 5.37258 373 12V677C373 683.627 367.627 689 361 689H12C5.37259 689 0 683.627 0 677V12Z"/>
                            </mask>
                            <path d="M0 12C0 5.37258 5.37258 0 12 0H361C367.627 0 373 5.37258 373 12V677C373 683.627 367.627 689 361 689H12C5.37259 689 0 683.627 0 677V12Z" fill="#DDE3EC"/>
                            <path d="M0 12C0 2.61116 7.61116 -5 17 -5H356C365.389 -5 373 2.61116 373 12C373 8.13401 367.627 5 361 5H12C5.37258 5 0 8.13401 0 12ZM373 689H0H373ZM0 689V0V689ZM373 0V689V0Z" fill="#808E9D" mask="url(#path-1-inside-1_4809_11223)"/>
                        </svg>
                        <svg class="oil-drilling-page__content-container-scheme-line" width="2" height="684" viewBox="0 0 2 684" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 0L1 684" stroke="#BEC9D6" stroke-width="2" stroke-dasharray="4 4"/>
                        </svg>
                        <div class="oil-drilling-page__content-container-scheme-img">
                            <template v-if="size === 1">
                                <svg width="129" height="104" viewBox="0 0 129 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4826_17835" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="103" fill="black">
                                    <rect fill="white" x="2" width="12" height="103"/>
                                    <path d="M5 0H13V100H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V100H5V0Z" fill="white"/>
                                    <path d="M13 100V103H14V100H13ZM5 100H2V103H5V100ZM12 0V100H14V0H12ZM13 97H5V103H13V97ZM8 100V0H2V100H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4826_17835)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 52.9707C1.88571 53.66 1.19373 54.8948 0.41304 56.9857C-0.152521 58.5004 0.24287 58.8422 0.655006 59.1985C1.11871 59.5993 1.60361 60.0184 0.764893 62.1471C-0.329534 63.9555 -0.030444 64.3253 0.325393 64.7653C0.668488 65.1896 1.06434 65.679 0.314701 67.5861C0.121937 68.6576 0.459179 69.0584 0.762211 69.4186C1.17144 69.9051 1.51829 70.3173 0.413117 72.2069C0.413067 73.0756 0.766251 73.6882 1.05537 74.1897C1.50041 74.9616 1.79366 75.4703 0.413183 76.244C-0.261059 77.8433 0.324575 78.5341 0.880538 79.1898C1.5746 80.0085 2.22242 80.7726 0.315041 83.1816C-0.0646114 84.3302 0.259459 84.9271 0.6077 85.5685C1.0994 86.4741 1.63929 87.4684 0.314519 90.23C0.314519 91.8575 0.790691 92.1507 1.20687 92.407C1.78422 92.7625 2.24611 93.047 1.16116 96.7237C0.850626 98.9629 1.51406 101.387 2.52344 103.002V52.9707Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 -3.59946e-05C1.88571 0.729787 1.19373 2.03716 0.41304 4.25101C-0.152521 5.85481 0.24287 6.21667 0.655007 6.59386C1.11871 7.01825 1.60361 7.46204 0.764893 9.71591C-0.329534 11.6306 -0.030444 12.0222 0.325393 12.488C0.668488 12.9372 1.06434 13.4554 0.314701 15.4746C0.121937 16.6091 0.459179 17.0335 0.762211 17.4149C1.17144 17.9299 1.51829 18.3664 0.413117 20.3672C0.413067 21.2868 0.766251 21.9355 1.05537 22.4665C1.50041 23.2838 1.79366 23.8224 0.413183 24.6416C-0.261059 26.3349 0.324575 27.0663 0.880538 27.7606C1.5746 28.6273 2.22242 29.4364 0.315041 31.987C-0.0646114 33.2032 0.259459 33.8352 0.6077 34.5143C1.0994 35.4731 1.63929 36.5259 0.314519 39.4499C0.314519 41.1731 0.790691 41.4835 1.20687 41.7549C1.78422 42.1313 2.24611 42.4324 1.16116 46.3254C0.850626 48.6962 1.51406 51.2625 2.52344 52.9732V-3.59946e-05Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4826_17835" maskUnits="userSpaceOnUse" x="115" y="0" width="12" height="103" fill="black">
                                    <rect fill="white" x="115" width="12" height="103"/>
                                    <path d="M124 0H116V100H124V0Z"/>
                                    </mask>
                                    <path d="M124 0H116V100H124V0Z" fill="white"/>
                                    <path d="M116 100V103H115V100H116ZM124 100H127V103H124V100ZM117 0V100H115V0H117ZM116 97H124V103H116V97ZM121 100V0H127V100H121Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4826_17835)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M126.477 52.9707C127.114 53.66 127.806 54.8948 128.587 56.9857C129.153 58.5004 128.757 58.8422 128.345 59.1985C127.881 59.5993 127.396 60.0184 128.235 62.1471C129.33 63.9555 129.03 64.3253 128.675 64.7653C128.332 65.1896 127.936 65.679 128.685 67.5861C128.878 68.6576 128.541 69.0584 128.238 69.4186C127.829 69.9051 127.482 70.3173 128.587 72.2069C128.587 73.0756 128.234 73.6882 127.945 74.1897C127.5 74.9616 127.206 75.4703 128.587 76.244C129.261 77.8433 128.675 78.5341 128.119 79.1898C127.425 80.0085 126.778 80.7726 128.685 83.1816C129.065 84.3302 128.741 84.9271 128.392 85.5685C127.901 86.4741 127.361 87.4684 128.685 90.23C128.685 91.8575 128.209 92.1507 127.793 92.407C127.216 92.7625 126.754 93.047 127.839 96.7237C128.149 98.9629 127.486 101.387 126.477 103.002V52.9707Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M126.477 -3.59946e-05C127.114 0.729787 127.806 2.03716 128.587 4.25101C129.153 5.85481 128.757 6.21667 128.345 6.59386C127.881 7.01825 127.396 7.46204 128.235 9.71591C129.33 11.6306 129.03 12.0222 128.675 12.488C128.332 12.9372 127.936 13.4554 128.685 15.4746C128.878 16.6091 128.541 17.0335 128.238 17.4149C127.829 17.9299 127.482 18.3664 128.587 20.3672C128.587 21.2868 128.234 21.9355 127.945 22.4665C127.5 23.2838 127.206 23.8224 128.587 24.6416C129.261 26.3349 128.675 27.0663 128.119 27.7606C127.425 28.6273 126.778 29.4364 128.685 31.987C129.065 33.2032 128.741 33.8352 128.392 34.5143C127.901 35.4731 127.361 36.5259 128.685 39.4499C128.685 41.1731 128.209 41.4835 127.793 41.7549C127.216 42.1313 126.754 42.4324 127.839 46.3254C128.149 48.6962 127.486 51.2625 126.477 52.9732V-3.59946e-05Z" fill="#B6C2D0"/>
                                    <rect x="14" width="102" height="100" fill="#EDF2F8"/>
                                </svg>
                            </template>
                            <template v-if="size === 2">
                                <svg width="128" height="105" viewBox="0 0 128 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4822_17759" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="103" fill="black">
                                    <rect fill="white" x="2" width="12" height="103"/>
                                    <path d="M5 0H13V100H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V100H5V0Z" fill="white"/>
                                    <path d="M13 100V103H14V100H13ZM5 100H2V103H5V100ZM12 0V100H14V0H12ZM13 97H5V103H13V97ZM8 100V0H2V100H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4822_17759)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 52.9697C1.88571 53.659 1.19373 54.8938 0.41304 56.9846C-0.152521 58.4993 0.24287 58.8411 0.655007 59.1973C1.11871 59.5982 1.60361 60.0173 0.764893 62.146C-0.329534 63.9543 -0.030444 64.3241 0.325393 64.7641C0.668488 65.1883 1.06434 65.6778 0.314701 67.5848C0.121937 68.6563 0.459179 69.0571 0.762211 69.4173C1.17144 69.9037 1.51829 70.316 0.413117 72.2056C0.413067 73.0742 0.766251 73.6868 1.05537 74.1882C1.50041 74.9602 1.79366 75.4688 0.413183 76.2426C-0.261059 77.8418 0.324575 78.5325 0.880538 79.1883C1.5746 80.0069 2.22242 80.771 0.315041 83.18C-0.0646113 84.3286 0.259458 84.9255 0.6077 85.5668C1.0994 86.4724 1.63929 87.4667 0.314519 90.2283C0.314519 91.8557 0.790691 92.1489 1.20687 92.4052C1.78422 92.7607 2.24611 93.0451 1.16116 96.7218C0.850626 98.961 1.51406 101.385 2.52344 103V52.9697Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.729807 1.19373 2.03715 0.41304 4.25096C-0.152521 5.85472 0.24287 6.21658 0.655007 6.59376C1.11871 7.01814 1.60361 7.46191 0.764893 9.71574C-0.329534 11.6304 -0.030444 12.022 0.325393 12.4878C0.668488 12.937 1.06434 13.4552 0.314701 15.4743C0.121937 16.6088 0.459179 17.0332 0.762211 17.4146C1.17144 17.9296 1.51829 18.3661 0.413117 20.3668C0.413067 21.2864 0.766251 21.935 1.05537 22.466C1.50041 23.2833 1.79366 23.8219 0.413183 24.6411C-0.261059 26.3344 0.324575 27.0657 0.880538 27.76C1.5746 28.6268 2.22242 29.4358 0.315041 31.9864C-0.0646115 33.2026 0.259459 33.8345 0.6077 34.5136C1.0994 35.4724 1.63929 36.5252 0.314519 39.4491C0.314519 41.1722 0.790691 41.4827 1.20687 41.754C1.78422 42.1304 2.24611 42.4316 1.16116 46.3244C0.850626 48.6952 1.51406 51.2614 2.52344 52.9721V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4822_17759" maskUnits="userSpaceOnUse" x="114" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="114" width="12" height="104"/>
                                    <path d="M123 0H115V101H123V0Z"/>
                                    </mask>
                                    <path d="M123 0H115V101H123V0Z" fill="white"/>
                                    <path d="M115 101V104H114V101H115ZM123 101H126V104H123V101ZM116 0V101H114V0H116ZM115 98H123V104H115V98ZM120 101V0H126V101H120Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4822_17759)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 53.4844C126.114 54.1804 126.806 55.4271 127.587 57.5383C128.153 59.0678 127.757 59.4129 127.345 59.7726C126.881 60.1773 126.396 60.6005 127.235 62.7499C128.33 64.5758 128.03 64.9493 127.675 65.3935C127.332 65.8219 126.936 66.3161 127.685 68.2417C127.878 69.3235 127.541 69.7283 127.238 70.092C126.829 70.5831 126.482 70.9994 127.587 72.9074C127.587 73.7844 127.234 74.403 126.945 74.9094C126.5 75.6888 126.206 76.2024 127.587 76.9837C128.261 78.5985 127.675 79.2959 127.119 79.958C126.425 80.7846 125.778 81.5561 127.685 83.9886C128.065 85.1484 127.741 85.751 127.392 86.3986C126.901 87.313 126.361 88.317 127.685 91.1055C127.685 92.7487 127.209 93.0448 126.793 93.3036C126.216 93.6625 125.754 93.9497 126.839 97.6622C127.149 99.9231 126.486 102.37 125.477 104.002V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 0C126.114 0.736908 126.806 2.05697 127.587 4.29232C128.153 5.91169 127.757 6.27707 127.345 6.65792C126.881 7.08642 126.396 7.53452 127.235 9.81027C128.33 11.7436 128.03 12.1389 127.675 12.6093C127.332 13.0628 126.936 13.5861 127.685 15.6249C127.878 16.7704 127.541 17.1989 127.238 17.584C126.829 18.104 126.482 18.5448 127.587 20.5649C127.587 21.4935 127.234 22.1485 126.945 22.6846C126.5 23.5099 126.206 24.0537 127.587 24.8809C128.261 26.5906 127.675 27.3291 127.119 28.0301C126.425 28.9053 125.778 29.7222 127.685 32.2976C128.065 33.5256 127.741 34.1637 127.392 34.8494C126.901 35.8175 126.361 36.8806 127.685 39.8329C127.685 41.5728 127.209 41.8863 126.793 42.1603C126.216 42.5403 125.754 42.8444 126.839 46.7752C127.149 49.169 126.486 51.7602 125.477 53.4876V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4822_17759" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="103" fill="black">
                                    <rect fill="white" x="13" width="10" height="103"/>
                                    <path d="M14 0H22V103H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V103H14V0Z" fill="white"/>
                                    <path d="M21 0V103H23V0H21ZM15 103V0H13V103H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4822_17759)"/>
                                    <mask id="path-11-outside-4_4822_17759" maskUnits="userSpaceOnUse" x="106" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="106" width="10" height="104"/>
                                    <path d="M107 0H115V104H107V0Z"/>
                                    </mask>
                                    <path d="M107 0H115V104H107V0Z" fill="white"/>
                                    <path d="M114 0V104H116V0H114ZM108 104V0H106V104H108Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4822_17759)"/>
                                    <rect x="22" width="85" height="103" fill="#EDF2F8"/>
                                </svg>
                                <svg width="112" height="105" viewBox="0 0 112 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4822_17738" maskUnits="userSpaceOnUse" x="97" y="0" width="12" height="105" fill="black">
                                    <rect fill="white" x="97" width="12" height="105"/>
                                    <path d="M106 0H98V101.029H106V0Z"/>
                                    </mask>
                                    <path d="M106 0H98V101.029H106V0Z" fill="white"/>
                                    <path d="M98 101.029V104.029H97V101.029H98ZM106 101.029H109V104.029H106V101.029ZM99 0V101.029H97V0H99ZM98 98.029H106V104.029H98V98.029ZM103 101.029V0H109V101.029H103Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4822_17738)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.001 53.4873C109.639 54.1836 110.33 55.4301 111.11 57.5397C111.676 59.0691 111.281 59.4142 110.868 59.7739C110.405 60.1786 109.92 60.6018 110.759 62.7512C111.853 64.5771 111.554 64.9505 111.198 65.3948C110.855 65.8231 110.459 66.3173 111.209 68.2429C111.402 69.3248 111.064 69.7295 110.761 70.0932C110.352 70.5844 110.005 71.0006 111.11 72.9086C111.11 73.7856 110.757 74.4042 110.468 74.9106C110.023 75.69 109.73 76.2036 111.11 76.9849C111.784 78.5996 111.199 79.2971 110.643 79.9592C109.949 80.7858 109.301 81.5573 111.208 83.9897C111.588 85.1495 111.264 85.7522 110.916 86.3998C110.424 87.3142 109.884 88.3182 111.209 91.1066C111.209 92.7498 110.733 93.0459 110.317 93.3047C109.739 93.6636 109.277 93.9508 110.362 97.6633C110.673 99.9231 110.01 102.369 109.001 104.001V53.4873Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M108.477 2.97363C109.114 3.71054 109.806 5.0306 110.587 7.26593C111.153 8.88529 110.757 9.25066 110.345 9.63151C109.881 10.06 109.396 10.5081 110.235 12.7838C111.33 14.7171 111.03 15.1125 110.675 15.5829C110.332 16.0364 109.936 16.5597 110.685 18.5985C110.878 19.7439 110.541 20.1725 110.238 20.5576C109.829 21.0776 109.482 21.5183 110.587 23.5384C110.587 24.4671 110.234 25.122 109.945 25.6581C109.5 26.4834 109.206 27.0272 110.587 27.8544C111.261 29.5641 110.675 30.3026 110.119 31.0036C109.425 31.8788 108.778 32.6957 110.685 35.2711C111.065 36.4991 110.741 37.1372 110.392 37.8228C109.901 38.791 109.361 39.854 110.685 42.8064C110.685 44.5462 110.209 44.8597 109.793 45.1337C109.216 45.5137 108.754 45.8178 109.839 49.7485C110.149 52.1424 109.486 54.7335 108.477 56.4609V2.97363Z" fill="#B6C2D0"/> -->
                                    <mask id="path-5-outside-2_4822_17738" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4822_17738)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0667 0.24287 59.4118 0.655007 59.7715C1.11871 60.1762 1.60361 60.5994 0.764893 62.7488C-0.329534 64.5747 -0.030444 64.9481 0.325393 65.3924C0.668488 65.8207 1.06434 66.3149 0.314701 68.2405C0.121937 69.3223 0.459179 69.7271 0.762211 70.0908C1.17144 70.5819 1.51829 70.9982 0.413117 72.9061C0.413067 73.7832 0.766251 74.4017 1.05537 74.9081C1.50041 75.6875 1.79366 76.2011 0.413183 76.9824C-0.261059 78.5971 0.324575 79.2946 0.880538 79.9567C1.5746 80.7833 2.22242 81.5548 0.315041 83.9872C-0.0646113 85.147 0.259458 85.7496 0.6077 86.3972C1.0994 87.3116 1.63929 88.3156 0.314519 91.104C0.314519 92.7472 0.790691 93.0433 1.20687 93.302C1.78422 93.661 2.24611 93.9482 1.16116 97.6606C0.850626 99.9215 1.51406 102.369 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736898 1.19373 2.05695 0.41304 4.29226C-0.152521 5.9116 0.24287 6.27698 0.655007 6.65783C1.11871 7.08632 1.60361 7.53441 0.764893 9.81013C-0.329534 11.7434 -0.030444 12.1388 0.325393 12.6091C0.668488 13.0627 1.06434 13.5859 0.314701 15.6247C0.121937 16.7702 0.459179 17.1987 0.762211 17.5838C1.17144 18.1038 1.51829 18.5445 0.413117 20.5646C0.413067 21.4932 0.766251 22.1482 1.05537 22.6843C1.50041 23.5095 1.79366 24.0533 0.413183 24.8805C-0.261059 26.5902 0.324575 27.3287 0.880538 28.0297C1.5746 28.9049 2.22242 29.7218 0.315041 32.2972C-0.0646114 33.5252 0.259459 34.1632 0.6077 34.8489C1.0994 35.817 1.63929 36.8801 0.314519 39.8324C0.314519 41.5722 0.790691 41.8857 1.20687 42.1597C1.78422 42.5398 2.24611 42.8438 1.16116 46.7745C0.850626 49.1684 1.51406 51.7595 2.52344 53.4868V0Z" fill="#B6C2D0"/>
                                    <rect x="13" width="85" height="101" fill="#EDF2F8"/>
                                </svg>       
                            </template>
                            <template v-if="size === 3">
                                <svg width="128" height="105" viewBox="0 0 128 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26134" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                        <rect fill="white" x="2" width="12" height="104"/>
                                        <path d="M5 0H13V100.971H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V100.971H5V0Z" fill="white"/>
                                    <path d="M13 100.971V103.971H14V100.971H13ZM5 100.971H2V103.971H5V100.971ZM12 0V100.971H14V0H12ZM13 97.9709H5V103.971H13V97.9709ZM8 100.971V0H2V100.971H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26134)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0667 0.24287 59.4118 0.655007 59.7715C1.11871 60.1762 1.60361 60.5994 0.764893 62.7487C-0.329534 64.5746 -0.030444 64.948 0.325393 65.3923C0.668488 65.8206 1.06434 66.3148 0.314701 68.2404C0.121937 69.3222 0.459179 69.727 0.762211 70.0907C1.17144 70.5818 1.51829 70.9981 0.413117 72.906C0.413067 73.783 0.766251 74.4016 1.05537 74.9079C1.50041 75.6873 1.79366 76.2009 0.413183 76.9822C-0.261059 78.5969 0.324575 79.2944 0.880538 79.9565C1.5746 80.7831 2.22242 81.5546 0.315041 83.987C-0.0646113 85.1467 0.259458 85.7494 0.6077 86.397C1.0994 87.3113 1.63929 88.3153 0.314519 91.1037C0.314519 92.7469 0.790691 93.043 1.20687 93.3017C1.78422 93.6607 2.24611 93.9479 1.16116 97.6603C0.850626 99.9212 1.51406 102.368 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.0304441 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.2969C-0.0646112 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26134" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                        <rect fill="white" x="13" width="10" height="104"/>
                                        <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26134)"/>
                                    <mask id="path-7-outside-3_4813_26134" maskUnits="userSpaceOnUse" x="114" y="0" width="12" height="104" fill="black">
                                        <rect fill="white" x="114" width="12" height="104"/>
                                        <path d="M123 0H115V101H123V0Z"/>
                                    </mask>
                                    <path d="M123 0H115V101H123V0Z" fill="white"/>
                                    <path d="M115 101V104H114V101H115ZM123 101H126V104H123V101ZM116 0V101H114V0H116ZM115 98H123V104H115V98ZM120 101V0H126V101H120Z" fill="#B6C2D0" mask="url(#path-7-outside-3_4813_26134)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 53.4844C126.114 54.1804 126.806 55.4271 127.587 57.5383C128.153 59.0678 127.757 59.4129 127.345 59.7726C126.881 60.1773 126.396 60.6005 127.235 62.7499C128.33 64.5758 128.03 64.9493 127.675 65.3935C127.332 65.8219 126.936 66.3161 127.685 68.2417C127.878 69.3235 127.541 69.7283 127.238 70.092C126.829 70.5831 126.482 70.9994 127.587 72.9074C127.587 73.7844 127.234 74.403 126.945 74.9094C126.5 75.6888 126.206 76.2024 127.587 76.9837C128.261 78.5985 127.675 79.2959 127.119 79.958C126.425 80.7846 125.778 81.5561 127.685 83.9886C128.065 85.1484 127.741 85.751 127.392 86.3986C126.901 87.313 126.361 88.317 127.685 91.1055C127.685 92.7487 127.209 93.0448 126.793 93.3036C126.216 93.6625 125.754 93.9497 126.839 97.6622C127.149 99.9231 126.486 102.37 125.477 104.002V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 0C126.114 0.736908 126.806 2.05697 127.587 4.29232C128.153 5.91169 127.757 6.27707 127.345 6.65792C126.881 7.08642 126.396 7.53452 127.235 9.81027C128.33 11.7436 128.03 12.1389 127.675 12.6093C127.332 13.0628 126.936 13.5861 127.685 15.6249C127.878 16.7704 127.541 17.1989 127.238 17.584C126.829 18.104 126.482 18.5448 127.587 20.5649C127.587 21.4935 127.234 22.1485 126.945 22.6846C126.5 23.5099 126.206 24.0537 127.587 24.8809C128.261 26.5906 127.675 27.3291 127.119 28.0301C126.425 28.9053 125.778 29.7222 127.685 32.2976C128.065 33.5256 127.741 34.1637 127.392 34.8494C126.901 35.8175 126.361 36.8806 127.685 39.8329C127.685 41.5728 127.209 41.8863 126.793 42.1603C126.216 42.5403 125.754 42.8444 126.839 46.7752C127.149 49.169 126.486 51.7602 125.477 53.4876V0Z" fill="#B6C2D0"/>
                                    <mask id="path-11-outside-4_4813_26134" maskUnits="userSpaceOnUse" x="106" y="0" width="10" height="104" fill="black">
                                        <rect fill="white" x="106" width="10" height="104"/>
                                        <path d="M107 0H115V104H107V0Z"/>
                                    </mask>
                                    <path d="M107 0H115V104H107V0Z" fill="white"/>
                                    <path d="M114 0V104H116V0H114ZM108 104V0H106V104H108Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26134)"/>
                                    <mask id="path-13-outside-5_4813_26134" maskUnits="userSpaceOnUse" x="97" y="0" width="10" height="104" fill="black">
                                        <rect fill="white" x="97" width="10" height="104"/>
                                        <path d="M98 0H106V104H98V0Z"/>
                                    </mask>
                                    <path d="M98 0H106V104H98V0Z" fill="white"/>
                                    <path d="M105 0V104H107V0H105ZM99 104V0H97V104H99Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4813_26134)"/>
                                    <mask id="path-15-outside-6_4813_26134" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                        <rect fill="white" x="22" width="10" height="104"/>
                                        <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4813_26134)"/>
                                    <rect x="31" width="67" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="112" height="104" viewBox="0 0 110 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26110" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V103.999H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V103.999H14V0Z" fill="white"/>
                                    <path d="M21 0V103.999H23V0H21ZM15 103.999V0H13V103.999H15Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26110)"/>
                                    <mask id="path-3-outside-2_4813_26110" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-3-outside-2_4813_26110)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0668 0.24287 59.4118 0.655007 59.7715C1.11871 60.1762 1.60361 60.5995 0.764893 62.7488C-0.329534 64.5747 -0.030444 64.9481 0.325393 65.3924C0.668488 65.8207 1.06434 66.3149 0.314701 68.2405C0.121937 69.3224 0.459179 69.7271 0.762211 70.0908C1.17144 70.582 1.51829 70.9982 0.413117 72.9062C0.413067 73.7832 0.766251 74.4018 1.05537 74.9081C1.50041 75.6876 1.79366 76.2011 0.413183 76.9824C-0.261059 78.5972 0.324575 79.2946 0.880538 79.9568C1.5746 80.7833 2.22242 81.5548 0.315041 83.9872C-0.0646114 85.147 0.259459 85.7497 0.6077 86.3973C1.0994 87.3117 1.63929 88.3156 0.314519 91.104C0.314519 92.7473 0.790691 93.0434 1.20687 93.3021C1.78422 93.6611 2.24611 93.9483 1.16116 97.6607C0.850626 99.9216 1.51406 102.369 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.7369 1.19373 2.05695 0.41304 4.29227C-0.152521 5.91162 0.24287 6.27699 0.655007 6.65784C1.11871 7.08634 1.60361 7.53443 0.764893 9.81016C-0.329534 11.7434 -0.030444 12.1388 0.325393 12.6092C0.668488 13.0627 1.06434 13.586 0.314701 15.6247C0.121937 16.7702 0.459179 17.1987 0.762211 17.5838C1.17144 18.1038 1.51829 18.5446 0.413117 20.5647C0.413067 21.4933 0.766251 22.1482 1.05537 22.6843C1.50041 23.5096 1.79366 24.0534 0.413183 24.8806C-0.261059 26.5903 0.324575 27.3288 0.880538 28.0298C1.5746 28.905 2.22242 29.7218 0.315041 32.2973C-0.0646114 33.5252 0.259459 34.1633 0.6077 34.849C1.0994 35.8171 1.63929 36.8801 0.314519 39.8325C0.314519 41.5723 0.790691 41.8858 1.20687 42.1598C1.78422 42.5399 2.24611 42.8439 1.16116 46.7746C0.850626 49.1685 1.51406 51.7596 2.52344 53.4869V0Z" fill="#B6C2D0"/>
                                    <mask id="path-7-outside-3_4813_26110" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-7-outside-3_4813_26110)"/>
                                    <mask id="path-9-outside-4_4813_26110" maskUnits="userSpaceOnUse" x="97" y="-0.000976562" width="12" height="104" fill="black">
                                    <rect fill="white" x="97" y="-0.000976562" width="12" height="104"/>
                                    <path d="M106 -0.000976562H98V100.999H106V-0.000976562Z"/>
                                    </mask>
                                    <path d="M106 -0.000976562H98V100.999H106V-0.000976562Z" fill="white"/>
                                    <path d="M98 100.999V103.999H97V100.999H98ZM106 100.999H109V103.999H106V100.999ZM99 -0.000976562V100.999H97V-0.000976562H99ZM98 97.999H106V103.999H98V97.999ZM103 100.999V-0.000976562H109V100.999H103Z" fill="#B6C2D0" mask="url(#path-9-outside-4_4813_26110)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.001 52C109.639 52.7168 110.33 53.9999 111.11 56.1715C111.676 57.746 111.281 58.1012 110.868 58.4715C110.405 58.8881 109.92 59.3238 110.759 61.5364C111.853 63.416 111.554 63.8004 111.198 64.2577C110.855 64.6987 110.459 65.2074 111.209 67.1896C111.402 68.3033 111.064 68.72 110.761 69.0944C110.352 69.6 110.005 70.0285 111.11 71.9925C111.11 72.8954 110.757 73.5321 110.468 74.0534C110.023 74.8558 109.73 75.3845 111.11 76.1887C111.784 77.851 111.199 78.569 110.643 79.2506C109.949 80.1015 109.301 80.8957 111.208 83.3996C111.588 84.5935 111.264 85.2139 110.916 85.8806C110.424 86.8218 109.884 87.8554 111.209 90.7258C111.209 92.4174 110.733 92.7222 110.317 92.9885C109.739 93.3581 109.277 93.6537 110.362 97.4753C110.673 99.8017 110.01 102.32 109.001 103.999V52Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M108.477 -0.000976562C109.114 0.757603 109.806 2.11649 110.587 4.41757C111.153 6.08456 110.757 6.46068 110.345 6.85274C109.881 7.29384 109.396 7.75512 110.235 10.0978C111.33 12.0879 111.03 12.4949 110.675 12.9791C110.332 13.446 109.936 13.9847 110.685 16.0834C110.878 17.2626 110.541 17.7038 110.238 18.1002C109.829 18.6355 109.482 19.0892 110.587 21.1687C110.587 22.1246 110.234 22.7988 109.945 23.3507C109.5 24.2003 109.206 24.7601 110.587 25.6116C111.261 27.3716 110.675 28.1318 110.119 28.8535C109.425 29.7544 108.778 30.5953 110.685 33.2465C111.065 34.5106 110.741 35.1674 110.392 35.8733C109.901 36.8699 109.361 37.9642 110.685 41.0034C110.685 42.7944 110.209 43.1171 109.793 43.3991C109.216 43.7904 108.754 44.1034 109.839 48.1497C110.149 50.614 109.486 53.2814 108.477 55.0595V-0.000976562Z" fill="#B6C2D0"/>
                                    <rect x="22" width="67" height="104" fill="#EDF2F8"/>
                                </svg>
                                <template v-if="mappingBorehole() === 4">
                                    <svg width="115" height="106" viewBox="0 0 113 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-outside-1_4813_26682" maskUnits="userSpaceOnUse" x="13" y="0" width="12" height="106" fill="black">
                                        <rect fill="white" x="13" width="12" height="106"/>
                                        <path d="M16 0H24V103H16V0Z"/>
                                        </mask>
                                        <path d="M16 0H24V103H16V0Z" fill="white"/>
                                        <path d="M24 103V106H25V103H24ZM16 103H13V106H16V103ZM23 0V103H25V0H23ZM24 100H16V106H24V100ZM19 103V0H13V103H19Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26682)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5234 51.4258C12.8857 52.095 12.1937 53.2938 11.413 55.3238C10.8475 56.7944 11.2429 57.1262 11.655 57.4721C12.1187 57.8613 12.6036 58.2682 11.7649 60.3349C10.6705 62.0906 10.9696 62.4496 11.3254 62.8768C11.6685 63.2887 12.0643 63.7639 11.3147 65.6154C11.1219 66.6557 11.4592 67.0448 11.7622 67.3946C12.1714 67.8668 12.5183 68.2671 11.4131 70.1016C11.4131 70.945 11.7663 71.5397 12.0554 72.0266C12.5004 72.7761 12.7937 73.2699 11.4132 74.0211C10.7389 75.5738 11.3246 76.2444 11.8805 76.8811C12.5746 77.6759 13.2224 78.4177 11.315 80.7566C10.9354 81.8718 11.2595 82.4513 11.6077 83.074C12.0994 83.9532 12.6393 84.9185 11.3145 87.5997C11.3145 89.1798 11.7907 89.4645 12.2069 89.7133C12.7842 90.0584 13.2461 90.3346 12.1612 93.9042C11.8506 96.0782 12.5141 98.4313 13.5234 100V51.4258Z" fill="#B6C2D0"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5234 0C12.8857 0.708562 12.1937 1.97785 11.413 4.12721C10.8475 5.68429 11.2429 6.03561 11.655 6.40181C12.1187 6.81383 12.6036 7.24469 11.7649 9.43291C10.6705 11.2918 10.9696 11.672 11.3254 12.1243C11.6685 12.5604 12.0643 13.0635 11.3147 15.0239C11.1219 16.1253 11.4592 16.5374 11.7622 16.9076C12.1714 17.4076 12.5183 17.8314 11.4131 19.7739C11.4131 20.6668 11.7663 21.2965 12.0554 21.812C12.5004 22.6055 12.7937 23.1284 11.4132 23.9238C10.7389 25.5678 11.3246 26.2778 11.8805 26.9519C12.5746 27.7934 13.2224 28.5789 11.315 31.0553C10.9354 32.236 11.2595 32.8496 11.6077 33.5089C12.0994 34.4398 12.6393 35.4619 11.3145 38.3007C11.3145 39.9737 11.7907 40.2751 12.2069 40.5385C12.7842 40.904 13.2461 41.1964 12.1612 44.9759C11.8506 47.2777 12.5141 49.7692 13.5234 51.4301V0Z" fill="#B6C2D0"/>
                                        <mask id="path-5-outside-2_4813_26682" maskUnits="userSpaceOnUse" x="90" y="0" width="12" height="106" fill="black">
                                        <rect fill="white" x="90" width="12" height="106"/>
                                        <path d="M99 0H91V103H99V0Z"/>
                                        </mask>
                                        <path d="M99 0H91V103H99V0Z" fill="white"/>
                                        <path d="M91 103V106H90V103H91ZM99 103H102V106H99V103ZM92 0V103H90V0H92ZM91 100H99V106H91V100ZM96 103V0H102V103H96Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26682)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M101.477 53.4834C102.114 54.1794 102.806 55.4261 103.587 57.5373C104.153 59.0668 103.757 59.4119 103.345 59.7716C102.881 60.1763 102.396 60.5995 103.235 62.7489C104.33 64.5748 104.03 64.9482 103.675 65.3925C103.332 65.8208 102.936 66.315 103.685 68.2406C103.878 69.3225 103.541 69.7272 103.238 70.0909C102.829 70.5821 102.482 70.9983 103.587 72.9063C103.587 73.7833 103.234 74.4019 102.945 74.9082C102.5 75.6877 102.206 76.2013 103.587 76.9825C104.261 78.5973 103.675 79.2948 103.119 79.9569C102.425 80.7835 101.778 81.555 103.685 83.9874C104.065 85.1472 103.741 85.7498 103.392 86.3975C102.901 87.3118 102.361 88.3158 103.685 91.1042C103.685 92.7475 103.209 93.0436 102.793 93.3023C102.216 93.6613 101.754 93.9485 102.839 97.6609C103.149 99.9219 102.486 102.369 101.477 104.001V53.4834Z" fill="#B6C2D0"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M101.477 0C102.114 0.736903 102.806 2.05696 103.587 4.29229C104.153 5.91165 103.757 6.27702 103.345 6.65788C102.881 7.08638 102.396 7.53447 103.235 9.81021C104.33 11.7435 104.03 12.1389 103.675 12.6092C103.332 13.0628 102.936 13.586 103.685 15.6248C103.878 16.7703 103.541 17.1988 103.238 17.5839C102.829 18.1039 102.482 18.5447 103.587 20.5648C103.587 21.4934 103.234 22.1483 102.945 22.6845C102.5 23.5097 102.206 24.0535 103.587 24.8807C104.261 26.5904 103.675 27.3289 103.119 28.0299C102.425 28.9051 101.778 29.722 103.685 32.2974C104.065 33.5254 103.741 34.1635 103.392 34.8492C102.901 35.8173 102.361 36.8803 103.685 39.8327C103.685 41.5726 103.209 41.886 102.793 42.16C102.216 42.5401 101.754 42.8441 102.839 46.7749C103.149 49.1687 102.486 51.7599 101.477 53.4872V0Z" fill="#B6C2D0"/>
                                        <rect x="25" y="103" width="65" height="3" fill="#B6C2D0"/>
                                        <rect x="24" width="67" height="103" fill="#EDF2F8"/>
                                        <path d="M-2.18557e-07 21L24 16.6699L24 25.3301L-2.18557e-07 21Z" fill="#EDF2F8"/>
                                        <path d="M-2.18557e-07 35L24 30.6699L24 39.3301L-2.18557e-07 35Z" fill="#EDF2F8"/>
                                        <path d="M-2.18557e-07 49L24 44.6699L24 53.3301L-2.18557e-07 49Z" fill="#EDF2F8"/>
                                        <path d="M-2.18557e-07 63L24 58.6699L24 67.3301L-2.18557e-07 63Z" fill="#EDF2F8"/>
                                        <path d="M-2.18557e-07 77L24 72.6699L24 81.3301L-2.18557e-07 77Z" fill="#EDF2F8"/>
                                        <path d="M115 21L91 16.6699L91 25.3301L115 21Z" fill="#EDF2F8"/>
                                        <path d="M115 35L91 30.6699L91 39.3301L115 35Z" fill="#EDF2F8"/>
                                        <path d="M115 49L91 44.6699L91 53.3301L115 49Z" fill="#EDF2F8"/>
                                        <path d="M115 63L91 58.6699L91 67.3301L115 63Z" fill="#EDF2F8"/>
                                        <path d="M115 77L91 72.6699L91 81.3301L115 77Z" fill="#EDF2F8"/>
                                    </svg>
                                </template>
                                <template v-if="mappingBorehole() === 11">
                                    <svg width="93" height="104" viewBox="0 0 91 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-outside-1_4828_18921" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                        <rect fill="white" x="2" width="12" height="104"/>
                                        <path d="M5 0H13V101H5V0Z"/>
                                        </mask>
                                        <path d="M5 0H13V101H5V0Z" fill="white"/>
                                        <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4828_18921)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4922C1.88571 54.188 1.19373 55.4346 0.41304 57.5454C-0.152521 59.0746 0.24287 59.4196 0.655007 59.7792C1.11871 60.1839 1.60361 60.607 0.764893 62.756C-0.329534 64.5816 -0.030444 64.9549 0.325393 65.3991C0.668488 65.8273 1.06434 66.3215 0.314701 68.2467C0.121937 69.3284 0.459179 69.733 0.762211 70.0967C1.17144 70.5877 1.51829 71.0039 0.413117 72.9115C0.413067 73.7884 0.766251 74.4068 1.05537 74.9131C1.50041 75.6924 1.79366 76.2059 0.413183 76.9871C-0.261059 78.6015 0.324575 79.2989 0.880538 79.9609C1.5746 80.7873 2.22242 81.5587 0.315041 83.9906C-0.0646114 85.1502 0.259459 85.7528 0.6077 86.4002C1.0994 87.3145 1.63929 88.3183 0.314519 91.1062C0.314519 92.7491 0.790691 93.0451 1.20687 93.3039C1.78422 93.6628 2.24611 93.9499 1.16116 97.6617C0.850626 99.9222 1.51406 102.369 2.52344 104V53.4922Z" fill="#B6C2D0"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.0195312C1.88571 0.7563 1.19373 2.07612 0.41304 4.31104C-0.152521 5.9301 0.24287 6.29541 0.655007 6.67619C1.11871 7.10462 1.60361 7.55263 0.764893 9.82795C-0.329534 11.7609 -0.030444 12.1562 0.325393 12.6265C0.668488 13.0799 1.06434 13.6031 0.314701 15.6415C0.121937 16.7868 0.459179 17.2152 0.762211 17.6002C1.17144 18.1202 1.51829 18.5608 0.413117 20.5806C0.413067 21.509 0.766251 22.1638 1.05537 22.6999C1.50041 23.525 1.79366 24.0687 0.413183 24.8957C-0.261059 26.6051 0.324575 27.3435 0.880538 28.0444C1.5746 28.9194 2.22242 29.7361 0.315041 32.3111C-0.0646113 33.5388 0.259458 34.1768 0.6077 34.8624C1.0994 35.8303 1.63929 36.8931 0.314519 39.845C0.314519 41.5845 0.790691 41.8979 1.20687 42.1718C1.78422 42.5519 2.24611 42.8559 1.16116 46.7859C0.850626 49.1793 1.51406 51.77 2.52344 53.497V0.0195312Z" fill="#B6C2D0"/>
                                        <mask id="path-5-outside-2_4828_18921" maskUnits="userSpaceOnUse" x="79" y="0" width="12" height="104" fill="black">
                                        <rect fill="white" x="79" width="12" height="104"/>
                                        <path d="M88 0H80V101H88V0Z"/>
                                        </mask>
                                        <path d="M88 0H80V101H88V0Z" fill="white"/>
                                        <path d="M80 101V104H79V101H80ZM88 101H91V104H88V101ZM81 0V101H79V0H81ZM80 98H88V104H80V98ZM85 101V0H91V101H85Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4828_18921)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 53.4824C91.1143 54.1784 91.8063 55.4252 92.587 57.5364C93.1525 59.0658 92.7571 59.4109 92.345 59.7706C91.8813 60.1753 91.3964 60.5985 92.2351 62.7479C93.3295 64.5738 93.0304 64.9472 92.6746 65.3915C92.3315 65.8198 91.9357 66.314 92.6853 68.2396C92.8781 69.3215 92.5408 69.7262 92.2378 70.0899C91.8286 70.581 91.4817 70.9973 92.5869 72.9053C92.5869 73.7823 92.2337 74.4009 91.9446 74.9072C91.4996 75.6867 91.2063 76.2002 92.5868 76.9815C93.2611 78.5963 92.6754 79.2937 92.1195 79.9559C91.4254 80.7824 90.7776 81.554 92.685 83.9864C93.0646 85.1462 92.7405 85.7488 92.3923 86.3964C91.9006 87.3108 91.3607 88.3148 92.6855 91.1032C92.6855 92.7464 92.2093 93.0425 91.7931 93.3013C91.2158 93.6602 90.7539 93.9474 91.8388 97.6599C92.1494 99.9208 91.4859 102.368 90.4766 103.999V53.4824Z" fill="#B6C2D0"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 0C91.1143 0.736902 91.8063 2.05696 92.587 4.29228C93.1525 5.91164 92.7571 6.27701 92.345 6.65786C91.8813 7.08636 91.3964 7.53445 92.2351 9.81019C93.3295 11.7435 93.0304 12.1388 92.6746 12.6092C92.3315 13.0627 91.9357 13.586 92.6853 15.6248C92.8781 16.7703 92.5408 17.1988 92.2378 17.5839C91.8286 18.1039 91.4817 18.5446 92.5869 20.5648C92.5869 21.4934 92.2337 22.1483 91.9446 22.6844C91.4996 23.5097 91.2063 24.0535 92.5868 24.8807C93.2611 26.5904 92.6754 27.3288 92.1195 28.0299C91.4254 28.9051 90.7776 29.7219 92.685 32.2974C93.0646 33.5253 92.7405 34.1634 92.3923 34.8491C91.9006 35.8172 91.3607 36.8803 92.6855 39.8326C92.6855 41.5725 92.2093 41.8859 91.7931 42.1599C91.2158 42.54 90.7539 42.8441 91.8388 46.7748C92.1494 49.1686 91.4859 51.7598 90.4766 53.4871V0Z" fill="#B6C2D0"/>
                                        <rect x="13" width="67" height="104" fill="#EDF2F8"/>
                                    </svg>
                                    <svg width="73" height="108" viewBox="0 0 73 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="9" width="54" height="104" fill="#EDF2F8"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M73 103H0V106.405C1.73193 106.38 3.58924 106.611 5.52513 106.853C8.36508 107.207 11.3741 107.583 14.4045 107.2C16.9709 106.703 17.4957 106.839 18.1201 107C18.7221 107.156 19.4167 107.336 22.1232 106.996C23.6437 106.908 24.2126 107.061 24.7237 107.199C25.4141 107.385 25.9991 107.543 28.6807 107.04C29.9134 107.04 30.7828 107.201 31.4945 107.332C32.59 107.535 33.3119 107.668 34.4099 107.04C36.6795 106.734 37.6598 107 38.5904 107.253C39.7522 107.568 40.8365 107.863 44.2553 106.996C45.8854 106.823 46.7324 106.97 47.6426 107.129C48.9278 107.352 50.3389 107.598 54.258 106.996C56.5676 106.996 56.9837 107.212 57.3474 107.401C57.8519 107.664 58.2556 107.873 63.4734 107.38C66.0196 107.267 68.734 107.438 70.8919 107.747C71.4736 107.649 72.1703 107.549 73 107.445V103ZM73 107.998H72.3778L69.607 107.998L0 107.998V108L73 108V107.998Z" fill="#EDF2F8"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M62 0L62 31.7783L71 31.7783L71 30.7525C70.4783 30.027 69.9122 28.7275 69.2735 26.5272C68.8108 24.9331 69.1343 24.5734 69.4715 24.1985C69.8509 23.7766 70.2476 23.3355 69.5614 21.0952C68.6659 19.192 68.9106 18.8028 69.2018 18.3397C69.4825 17.8933 69.8064 17.3781 69.193 15.3711C69.0353 14.2434 69.3112 13.8216 69.5592 13.4425C69.894 12.9306 70.1778 12.4967 69.2736 10.508C69.2735 9.59383 69.5625 8.9491 69.799 8.42131C70.1632 7.60889 70.4031 7.07356 69.2736 6.25922C68.722 4.57613 69.2011 3.84916 69.656 3.15902C70.1376 2.42837 70.5919 1.739 69.7671 -3.26935e-07L62 0Z" fill="#EDF2F8"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M63 31.7764L63 63.5547L72 63.5547L72 62.5294C71.4784 61.8038 70.9123 60.5044 70.2738 58.3046C69.8111 56.7104 70.1346 56.3507 70.4718 55.9758C70.8512 55.554 71.2479 55.1129 70.5617 52.8725C69.6662 50.9693 69.9109 50.5801 70.2021 50.1171C70.4828 49.6706 70.8067 49.1555 70.1933 47.1484C70.0356 46.0208 70.3115 45.5989 70.5595 45.2198C70.8943 44.7079 71.1781 44.274 70.2739 42.2853C70.2738 41.3712 70.5628 40.7264 70.7993 40.1987C71.1635 39.3862 71.4034 38.8509 70.2739 38.0366C69.7223 36.3535 70.2014 35.6265 70.6563 34.9364C71.138 34.2056 71.5924 33.5161 70.7669 31.7764L63 31.7764Z" fill="#EDF2F8"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M63 63.5557L63 104L73 104L73 102.696C72.4203 101.772 71.7912 100.118 71.0815 97.3179C70.5674 95.2889 70.9268 94.8312 71.3015 94.354C71.7231 93.8171 72.1639 93.2557 71.4014 90.4044C70.4065 87.9821 70.6784 87.4868 71.0019 86.8974C71.3138 86.3292 71.6736 85.6736 70.9921 83.1191C70.8169 81.6839 71.1235 81.147 71.399 80.6646C71.771 80.013 72.0863 79.4608 71.0816 76.9297C71.0816 75.7663 71.4026 74.9457 71.6655 74.274C72.0701 73.24 72.3367 72.5586 71.0817 71.5222C70.4687 69.3801 71.0011 68.4549 71.5065 67.5765C72.0417 66.6465 72.5465 65.7691 71.6298 63.5557L63 63.5557Z" fill="#EDF2F8"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0L9 31.7783L1.63637 31.7783L1.63637 30.7523C2.06321 30.0268 2.52636 28.7274 3.04888 26.5272C3.42748 24.9331 3.16279 24.5734 2.8869 24.1985C2.57649 23.7766 2.25189 23.3355 2.81334 21.0952C3.54597 19.192 3.34576 18.8028 3.10755 18.3397C2.87788 17.8933 2.61289 17.3781 3.11471 15.3711C3.24375 14.2434 3.01799 13.8216 2.81514 13.4425C2.54119 12.9306 2.309 12.4967 3.04883 10.508C3.04886 9.59383 2.81243 8.9491 2.61889 8.42131C2.32097 7.60889 2.12466 7.07356 3.04878 6.25922C3.50014 4.57613 3.1081 3.84916 2.73593 3.15902C2.3419 2.42837 1.97014 1.739 2.64505 -2.94245e-07L9 0Z" fill="#EDF2F8"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00049 31.7764L9.00049 63.5547L0.818496 63.5547L0.818498 31.7764L0.819017 31.7764L0.819016 62.5296C1.29328 61.8041 1.80789 60.5046 2.38846 58.3046C2.80913 56.7104 2.51503 56.3507 2.20848 55.9758C1.86357 55.554 1.50289 55.1129 2.12674 52.8725C2.9408 50.9693 2.71833 50.5801 2.45365 50.1171C2.19845 49.6706 1.90401 49.1555 2.4616 47.1484C2.60499 46.0208 2.35414 45.5989 2.12874 45.2198C1.82434 44.7079 1.56636 44.274 2.3884 42.2853C2.38844 41.3712 2.12573 40.7264 1.91068 40.1986C1.57965 39.3862 1.36153 38.8509 2.38835 38.0366C2.88987 36.3535 2.45426 35.6265 2.04073 34.9364C1.60283 34.2056 1.18968 33.5161 1.94017 31.7764L9.00049 31.7764Z" fill="#EDF2F8"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 63.5557L9 104L0.000312805 104L0.000316215 63.5557L0.000474525 63.5557L0.000472117 102.696C0.522235 101.773 1.08839 100.119 1.72712 97.3179C2.18984 95.2889 1.86634 94.8312 1.52915 94.354C1.14977 93.8171 0.753051 93.2557 1.43925 90.4044C2.33466 87.9821 2.08996 87.4868 1.79883 86.8974C1.51813 86.3292 1.19426 85.6736 1.80758 83.1191C1.96529 81.6839 1.68937 81.147 1.44145 80.6646C1.10663 80.013 0.822859 79.4608 1.72706 76.9297C1.7271 75.7663 1.43814 74.9457 1.20159 74.274C0.837484 73.24 0.59756 72.5586 1.727 71.5222C2.27864 69.3801 1.7995 68.4549 1.34464 67.5765C0.863048 66.6465 0.408673 65.7691 1.23366 63.5557L9 63.5557Z" fill="#EDF2F8"/>
                                    </svg>
                                </template>
                                <template v-if="mappingBorehole() === 21">
                                    <svg width="93" height="106" viewBox="0 0 93 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-outside-1_4813_26088" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="106" fill="black">
                                        <rect fill="white" x="2" width="12" height="106"/>
                                        <path d="M5 0H13V103H5V0Z"/>
                                        </mask>
                                        <path d="M5 0H13V103H5V0Z" fill="white"/>
                                        <path d="M13 103V106H14V103H13ZM5 103H2V106H5V103ZM12 0V103H14V0H12ZM13 100H5V106H13V100ZM8 103V0H2V103H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26088)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 51.4257C1.88571 52.0949 1.19373 53.2937 0.41304 55.3237C-0.152521 56.7943 0.24287 57.1261 0.655007 57.472C1.11871 57.8611 1.60361 58.2681 0.764893 60.3348C-0.329534 62.0905 -0.030444 62.4495 0.325393 62.8767C0.668488 63.2886 1.06434 63.7638 0.314701 65.6153C0.121937 66.6555 0.459179 67.0447 0.762211 67.3944C1.17144 67.8667 1.51829 68.267 0.413117 70.1015C0.413067 70.9448 0.766251 71.5396 1.05537 72.0265C1.50041 72.776 1.79366 73.2698 0.413183 74.021C-0.261059 75.5737 0.324575 76.2443 0.880538 76.881C1.5746 77.6758 2.22242 78.4176 0.315041 80.7565C-0.0646113 81.8717 0.259458 82.4511 0.6077 83.0738C1.0994 83.953 1.63929 84.9184 0.314519 87.5996C0.314519 89.1797 0.790691 89.4643 1.20687 89.7131C1.78422 90.0583 2.24611 90.3344 1.16116 93.9041C0.850626 96.0781 1.51406 98.4312 2.52344 99.9999V51.4257Z" fill="#B6C2D0"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 5.5803e-08C1.88571 0.708562 1.19373 1.97785 0.41304 4.12721C-0.152521 5.68429 0.24287 6.03561 0.655007 6.40181C1.11871 6.81383 1.60361 7.24469 0.764893 9.4329C-0.329534 11.2918 -0.030444 11.672 0.325393 12.1243C0.668488 12.5604 1.06434 13.0635 0.314701 15.0239C0.121937 16.1253 0.459179 16.5374 0.762211 16.9076C1.17144 17.4076 1.51829 17.8314 0.413117 19.7739C0.413067 20.6668 0.766251 21.2965 1.05537 21.812C1.50041 22.6055 1.79366 23.1284 0.413183 23.9238C-0.261059 25.5678 0.324575 26.2778 0.880538 26.9519C1.5746 27.7934 2.22242 28.5789 0.315041 31.0553C-0.0646114 32.236 0.259459 32.8496 0.6077 33.5089C1.0994 34.4398 1.63929 35.4619 0.314519 38.3007C0.314519 39.9737 0.790691 40.2751 1.20687 40.5385C1.78422 40.904 2.24611 41.1963 1.16116 44.9759C0.850626 47.2777 1.51406 49.7692 2.52344 51.4301V5.5803e-08Z" fill="#B6C2D0"/>
                                        <mask id="path-5-outside-2_4813_26088" maskUnits="userSpaceOnUse" x="79" y="0" width="12" height="106" fill="black">
                                        <rect fill="white" x="79" width="12" height="106"/>
                                        <path d="M88 0H80V103H88V0Z"/>
                                        </mask>
                                        <path d="M88 0H80V103H88V0Z" fill="white"/>
                                        <path d="M80 103V106H79V103H80ZM88 103H91V106H88V103ZM81 0V103H79V0H81ZM80 100H88V106H80V100ZM85 103V0H91V103H85Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26088)"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 53.4834C91.1143 54.1794 91.8063 55.4261 92.587 57.5373C93.1525 59.0668 92.7571 59.4119 92.345 59.7716C91.8813 60.1763 91.3964 60.5995 92.2351 62.7489C93.3295 64.5748 93.0304 64.9482 92.6746 65.3925C92.3315 65.8208 91.9357 66.315 92.6853 68.2406C92.8781 69.3225 92.5408 69.7272 92.2378 70.0909C91.8286 70.582 91.4817 70.9983 92.5869 72.9063C92.5869 73.7833 92.2337 74.4019 91.9446 74.9082C91.4996 75.6877 91.2063 76.2013 92.5868 76.9825C93.2611 78.5973 92.6754 79.2948 92.1195 79.9569C91.4254 80.7835 90.7776 81.555 92.685 83.9874C93.0646 85.1472 92.7405 85.7498 92.3923 86.3975C91.9006 87.3118 91.3607 88.3158 92.6855 91.1042C92.6855 92.7475 92.2093 93.0436 91.7931 93.3023C91.2158 93.6613 90.7539 93.9485 91.8388 97.6609C92.1494 99.9219 91.4859 102.369 90.4766 104.001V53.4834Z" fill="#B6C2D0"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 0.000127974C91.1143 0.737031 91.8063 2.05709 92.587 4.29242C93.1525 5.91178 92.7571 6.27715 92.345 6.658C91.8813 7.08651 91.3964 7.5346 92.2351 9.81034C93.3295 11.7436 93.0304 12.139 92.6746 12.6094C92.3315 13.0629 91.9357 13.5862 92.6853 15.6249C92.8781 16.7704 92.5408 17.199 92.2378 17.584C91.8286 18.1041 91.4817 18.5448 92.5869 20.5649C92.5869 21.4935 92.2337 22.1485 91.9446 22.6846C91.4996 23.5099 91.2063 24.0536 92.5868 24.8809C93.2611 26.5906 92.6754 27.329 92.1195 28.0301C91.4254 28.9053 90.7776 29.7221 92.685 32.2976C93.0646 33.5255 92.7405 34.1636 92.3923 34.8493C91.9006 35.8174 91.3607 36.8805 92.6855 39.8328C92.6855 41.5727 92.2093 41.8862 91.7931 42.1601C91.2158 42.5402 90.7539 42.8443 91.8388 46.775C92.1494 49.1688 91.4859 51.76 90.4766 53.4873V0.000127974Z" fill="#B6C2D0"/>
                                        <rect x="14" y="103" width="65" height="3" fill="#B6C2D0"/>
                                        <rect x="13" width="67" height="103" fill="#EDF2F8"/>
                                    </svg>
                                </template>
                            </template>
                            <template v-if="size === 4 && mappingBorehole() === 1">
                                <svg width="128" height="105" viewBox="0 0 128 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26245" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V100.971H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V100.971H5V0Z" fill="white"/>
                                    <path d="M13 100.971V103.971H14V100.971H13ZM5 100.971H2V103.971H5V100.971ZM12 0V100.971H14V0H12ZM13 97.9709H5V103.971H13V97.9709ZM8 100.971V0H2V100.971H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26245)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0667 0.24287 59.4118 0.655007 59.7715C1.11871 60.1762 1.60361 60.5994 0.764893 62.7487C-0.329534 64.5746 -0.030444 64.948 0.325393 65.3923C0.668488 65.8206 1.06434 66.3148 0.314701 68.2404C0.121937 69.3222 0.459179 69.727 0.762211 70.0907C1.17144 70.5818 1.51829 70.9981 0.413117 72.906C0.413067 73.783 0.766251 74.4016 1.05537 74.9079C1.50041 75.6873 1.79366 76.2009 0.413183 76.9822C-0.261059 78.5969 0.324575 79.2944 0.880538 79.9565C1.5746 80.7831 2.22242 81.5546 0.315041 83.987C-0.0646113 85.1467 0.259458 85.7494 0.6077 86.397C1.0994 87.3113 1.63929 88.3153 0.314519 91.1037C0.314519 92.7469 0.790691 93.043 1.20687 93.3017C1.78422 93.6607 2.24611 93.9479 1.16116 97.6603C0.850626 99.9212 1.51406 102.368 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.0304441 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.2969C-0.0646112 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26245" maskUnits="userSpaceOnUse" x="114" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="114" width="12" height="104"/>
                                    <path d="M123 0H115V101H123V0Z"/>
                                    </mask>
                                    <path d="M123 0H115V101H123V0Z" fill="white"/>
                                    <path d="M115 101V104H114V101H115ZM123 101H126V104H123V101ZM116 0V101H114V0H116ZM115 98H123V104H115V98ZM120 101V0H126V101H120Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26245)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 53.4844C126.114 54.1804 126.806 55.4271 127.587 57.5383C128.153 59.0678 127.757 59.4129 127.345 59.7726C126.881 60.1773 126.396 60.6005 127.235 62.7499C128.33 64.5758 128.03 64.9493 127.675 65.3935C127.332 65.8219 126.936 66.3161 127.685 68.2417C127.878 69.3235 127.541 69.7283 127.238 70.092C126.829 70.5831 126.482 70.9994 127.587 72.9074C127.587 73.7844 127.234 74.403 126.945 74.9094C126.5 75.6888 126.206 76.2024 127.587 76.9837C128.261 78.5985 127.675 79.2959 127.119 79.958C126.425 80.7846 125.778 81.5561 127.685 83.9886C128.065 85.1484 127.741 85.751 127.392 86.3986C126.901 87.313 126.361 88.317 127.685 91.1055C127.685 92.7487 127.209 93.0448 126.793 93.3036C126.216 93.6625 125.754 93.9497 126.839 97.6622C127.149 99.9231 126.486 102.37 125.477 104.002V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 0C126.114 0.736908 126.806 2.05697 127.587 4.29232C128.153 5.91169 127.757 6.27707 127.345 6.65792C126.881 7.08642 126.396 7.53452 127.235 9.81027C128.33 11.7436 128.03 12.1389 127.675 12.6093C127.332 13.0628 126.936 13.5861 127.685 15.6249C127.878 16.7704 127.541 17.1989 127.238 17.584C126.829 18.104 126.482 18.5448 127.587 20.5649C127.587 21.4935 127.234 22.1485 126.945 22.6846C126.5 23.5099 126.206 24.0537 127.587 24.8809C128.261 26.5906 127.675 27.3291 127.119 28.0301C126.425 28.9053 125.778 29.7222 127.685 32.2976C128.065 33.5256 127.741 34.1637 127.392 34.8494C126.901 35.8175 126.361 36.8806 127.685 39.8329C127.685 41.5728 127.209 41.8863 126.793 42.1603C126.216 42.5403 125.754 42.8444 126.839 46.7752C127.149 49.169 126.486 51.7602 125.477 53.4876V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4813_26245" maskUnits="userSpaceOnUse" x="106" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="106" width="10" height="104"/>
                                    <path d="M107 0H115V104H107V0Z"/>
                                    </mask>
                                    <path d="M107 0H115V104H107V0Z" fill="white"/>
                                    <path d="M114 0V104H116V0H114ZM108 104V0H106V104H108Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4813_26245)"/>
                                    <mask id="path-11-outside-4_4813_26245" maskUnits="userSpaceOnUse" x="97" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="97" width="10" height="104"/>
                                    <path d="M98 0H106V104H98V0Z"/>
                                    </mask>
                                    <path d="M98 0H106V104H98V0Z" fill="white"/>
                                    <path d="M105 0V104H107V0H105ZM99 104V0H97V104H99Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26245)"/>
                                    <mask id="path-13-outside-5_4813_26245" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4813_26245)"/>
                                    <mask id="path-15-outside-6_4813_26245" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4813_26245)"/>
                                    <mask id="path-17-outside-7_4813_26245" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-17-outside-7_4813_26245)"/>
                                    <mask id="path-19-outside-8_4813_26245" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-19-outside-8_4813_26245)"/>
                                    <rect x="40" width="49" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="112" height="104" viewBox="0 0 110 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26221" maskUnits="userSpaceOnUse" x="97" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="97" width="12" height="104"/>
                                    <path d="M106 0H98V101H106V0Z"/>
                                    </mask>
                                    <path d="M106 0H98V101H106V0Z" fill="white"/>
                                    <path d="M98 101V104H97V101H98ZM106 101H109V104H106V101ZM99 0V101H97V0H99ZM98 98H106V104H98V98ZM103 101V0H109V101H103Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26221)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.001 52C109.639 52.7168 110.33 53.9999 111.11 56.1716C111.676 57.746 111.281 58.1012 110.868 58.4715C110.405 58.8881 109.92 59.3238 110.759 61.5364C111.853 63.416 111.554 63.8004 111.198 64.2578C110.855 64.6987 110.459 65.2075 111.209 67.1897C111.402 68.3034 111.064 68.72 110.761 69.0944C110.352 69.6 110.005 70.0285 111.11 71.9926C111.11 72.8955 110.757 73.5322 110.468 74.0535C110.023 74.8558 109.73 75.3846 111.11 76.1888C111.784 77.8511 111.199 78.5691 110.643 79.2507C109.949 80.1016 109.301 80.8958 111.208 83.3997C111.588 84.5937 111.264 85.214 110.916 85.8807C110.424 86.822 109.884 87.8555 111.209 90.726C111.209 92.4176 110.733 92.7223 110.317 92.9887C109.739 93.3582 109.277 93.6539 110.362 97.4755C110.673 99.8018 110.01 102.32 109.001 103.999V52Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M108.477 0C109.114 0.758582 109.806 2.11747 110.587 4.41857C111.153 6.08556 110.757 6.46169 110.345 6.85374C109.881 7.29485 109.396 7.75612 110.235 10.0988C111.33 12.089 111.03 12.496 110.675 12.9802C110.332 13.447 109.936 13.9857 110.685 16.0845C110.878 17.2636 110.541 17.7048 110.238 18.1012C109.829 18.6365 109.482 19.0902 110.587 21.1698C110.587 22.1257 110.234 22.7999 109.945 23.3518C109.5 24.2014 109.206 24.7611 110.587 25.6127C111.261 27.3727 110.675 28.1329 110.119 28.8546C109.425 29.7555 108.778 30.5964 110.685 33.2476C111.065 34.5117 110.741 35.1685 110.392 35.8744C109.901 36.871 109.361 37.9653 110.685 41.0045C110.685 42.7956 110.209 43.1183 109.793 43.4003C109.216 43.7916 108.754 44.1046 109.839 48.1509C110.149 50.6152 109.486 53.2826 108.477 55.0607V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26221" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26221)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0668 0.24287 59.4118 0.655007 59.7716C1.11871 60.1763 1.60361 60.5995 0.764893 62.7488C-0.329534 64.5748 -0.030444 64.9482 0.325393 65.3924C0.668488 65.8208 1.06434 66.315 0.314701 68.2405C0.121937 69.3224 0.459179 69.7271 0.762211 70.0908C1.17144 70.582 1.51829 70.9983 0.413117 72.9062C0.413067 73.7832 0.766251 74.4018 1.05537 74.9082C1.50041 75.6876 1.79366 76.2012 0.413183 76.9825C-0.261059 78.5972 0.324575 79.2947 0.880538 79.9568C1.5746 80.7834 2.22242 81.5549 0.315041 83.9873C-0.0646114 85.1471 0.259459 85.7497 0.6077 86.3973C1.0994 87.3117 1.63929 88.3157 0.314519 91.1041C0.314519 92.7474 0.790691 93.0434 1.20687 93.3022C1.78422 93.6611 2.24611 93.9483 1.16116 97.6608C0.850626 99.9217 1.51406 102.369 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736901 1.19373 2.05695 0.41304 4.29228C-0.152521 5.91163 0.24287 6.277 0.655007 6.65785C1.11871 7.08635 1.60361 7.53444 0.764893 9.81017C-0.329534 11.7434 -0.030444 12.1388 0.325393 12.6092C0.668488 13.0627 1.06434 13.586 0.314701 15.6248C0.121937 16.7702 0.459179 17.1988 0.762211 17.5838C1.17144 18.1039 1.51829 18.5446 0.413117 20.5647C0.413067 21.4933 0.766251 22.1483 1.05537 22.6844C1.50041 23.5096 1.79366 24.0534 0.413183 24.8806C-0.261059 26.5903 0.324575 27.3288 0.880538 28.0298C1.5746 28.905 2.22242 29.7219 0.315041 32.2973C-0.0646115 33.5253 0.259459 34.1634 0.6077 34.849C1.0994 35.8172 1.63929 36.8802 0.314519 39.8325C0.314519 41.5724 0.790691 41.8859 1.20687 42.1598C1.78422 42.5399 2.24611 42.844 1.16116 46.7747C0.850626 49.1685 1.51406 51.7597 2.52344 53.487V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4813_26221" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4813_26221)"/>
                                    <mask id="path-11-outside-4_4813_26221" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26221)"/>
                                    <mask id="path-13-outside-5_4813_26221" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4813_26221)"/>
                                    <mask id="path-15-outside-6_4813_26221" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4813_26221)"/>
                                    <rect x="31" width="49" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="93" height="104" viewBox="0 0 91 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26199" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26199)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4922C1.88571 54.188 1.19373 55.4346 0.41304 57.5454C-0.152521 59.0746 0.24287 59.4196 0.655007 59.7792C1.11871 60.1839 1.60361 60.607 0.764893 62.756C-0.329534 64.5816 -0.030444 64.9549 0.325393 65.3991C0.668488 65.8273 1.06434 66.3215 0.314701 68.2467C0.121937 69.3284 0.459179 69.733 0.762211 70.0967C1.17144 70.5877 1.51829 71.0039 0.413117 72.9115C0.413067 73.7884 0.766251 74.4068 1.05537 74.9131C1.50041 75.6924 1.79366 76.2059 0.413183 76.9871C-0.261059 78.6015 0.324575 79.2989 0.880538 79.9609C1.5746 80.7873 2.22242 81.5587 0.315041 83.9906C-0.0646114 85.1502 0.259459 85.7528 0.6077 86.4002C1.0994 87.3145 1.63929 88.3183 0.314519 91.1062C0.314519 92.7491 0.790691 93.0451 1.20687 93.3039C1.78422 93.6628 2.24611 93.9499 1.16116 97.6617C0.850626 99.9222 1.51406 102.369 2.52344 104V53.4922Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.0195312C1.88571 0.7563 1.19373 2.07612 0.41304 4.31104C-0.152521 5.9301 0.24287 6.29541 0.655007 6.67619C1.11871 7.10462 1.60361 7.55263 0.764893 9.82795C-0.329534 11.7609 -0.030444 12.1562 0.325393 12.6265C0.668488 13.0799 1.06434 13.6031 0.314701 15.6415C0.121937 16.7868 0.459179 17.2152 0.762211 17.6002C1.17144 18.1202 1.51829 18.5608 0.413117 20.5806C0.413067 21.509 0.766251 22.1638 1.05537 22.6999C1.50041 23.525 1.79366 24.0687 0.413183 24.8957C-0.261059 26.6051 0.324575 27.3435 0.880538 28.0444C1.5746 28.9194 2.22242 29.7361 0.315041 32.3111C-0.0646113 33.5388 0.259458 34.1768 0.6077 34.8624C1.0994 35.8303 1.63929 36.8931 0.314519 39.845C0.314519 41.5845 0.790691 41.8979 1.20687 42.1718C1.78422 42.5519 2.24611 42.8559 1.16116 46.7859C0.850626 49.1793 1.51406 51.77 2.52344 53.497V0.0195312Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26199" maskUnits="userSpaceOnUse" x="79" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="79" width="12" height="104"/>
                                    <path d="M88 0H80V101H88V0Z"/>
                                    </mask>
                                    <path d="M88 0H80V101H88V0Z" fill="white"/>
                                    <path d="M80 101V104H79V101H80ZM88 101H91V104H88V101ZM81 0V101H79V0H81ZM80 98H88V104H80V98ZM85 101V0H91V101H85Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26199)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 53.4824C91.1143 54.1784 91.8063 55.4252 92.587 57.5364C93.1525 59.0658 92.7571 59.4109 92.345 59.7706C91.8813 60.1753 91.3964 60.5985 92.2351 62.7479C93.3295 64.5738 93.0304 64.9472 92.6746 65.3915C92.3315 65.8198 91.9357 66.314 92.6853 68.2396C92.8781 69.3215 92.5408 69.7262 92.2378 70.0899C91.8286 70.581 91.4817 70.9973 92.5869 72.9053C92.5869 73.7823 92.2337 74.4009 91.9446 74.9072C91.4996 75.6867 91.2063 76.2002 92.5868 76.9815C93.2611 78.5963 92.6754 79.2937 92.1195 79.9559C91.4254 80.7824 90.7776 81.554 92.685 83.9864C93.0646 85.1462 92.7405 85.7488 92.3923 86.3964C91.9006 87.3108 91.3607 88.3148 92.6855 91.1032C92.6855 92.7464 92.2093 93.0425 91.7931 93.3013C91.2158 93.6602 90.7539 93.9474 91.8388 97.6599C92.1494 99.9208 91.4859 102.368 90.4766 103.999V53.4824Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 0C91.1143 0.736902 91.8063 2.05696 92.587 4.29228C93.1525 5.91164 92.7571 6.27701 92.345 6.65786C91.8813 7.08636 91.3964 7.53445 92.2351 9.81019C93.3295 11.7435 93.0304 12.1388 92.6746 12.6092C92.3315 13.0627 91.9357 13.586 92.6853 15.6248C92.8781 16.7703 92.5408 17.1988 92.2378 17.5839C91.8286 18.1039 91.4817 18.5446 92.5869 20.5648C92.5869 21.4934 92.2337 22.1483 91.9446 22.6844C91.4996 23.5097 91.2063 24.0535 92.5868 24.8807C93.2611 26.5904 92.6754 27.3288 92.1195 28.0299C91.4254 28.9051 90.7776 29.7219 92.685 32.2974C93.0646 33.5253 92.7405 34.1634 92.3923 34.8491C91.9006 35.8172 91.3607 36.8803 92.6855 39.8326C92.6855 41.5725 92.2093 41.8859 91.7931 42.1599C91.2158 42.54 90.7539 42.8441 91.8388 46.7748C92.1494 49.1686 91.4859 51.7598 90.4766 53.4871V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4813_26199" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4813_26199)"/>
                                    <mask id="path-11-outside-4_4813_26199" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26199)"/>
                                    <rect x="22" width="49" height="104" fill="#EDF2F8"/>
                                </svg>

                                <svg width="97" height="104" viewBox="0 0 97 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="25" y="101" width="47" height="3" fill="#B6C2D0"/>
                                    <mask id="path-2-outside-1_4813_26770" maskUnits="userSpaceOnUse" x="13" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="13" width="12" height="104"/>
                                    <path d="M16 0H24V101H16V0Z"/>
                                    </mask>
                                    <path d="M16 0H24V101H16V0Z" fill="white"/>
                                    <path d="M24 101V104H25V101H24ZM16 101H13V104H16V101ZM23 0V101H25V0H23ZM24 98H16V104H24V98ZM19 101V0H13V101H19Z" fill="#B6C2D0" mask="url(#path-2-outside-1_4813_26770)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5234 53.4844C12.8857 54.1803 12.1937 55.4271 11.413 57.5383C10.8475 59.0677 11.2429 59.4128 11.655 59.7725C12.1187 60.1772 12.6036 60.6004 11.7649 62.7497C10.6705 64.5756 10.9696 64.949 11.3254 65.3933C11.6685 65.8216 12.0643 66.3158 11.3147 68.2414C11.1219 69.3232 11.4592 69.7279 11.7622 70.0916C12.1714 70.5828 12.5183 70.999 11.4131 72.907C11.4131 73.784 11.7663 74.4025 12.0554 74.9089C12.5004 75.6883 12.7937 76.2019 11.4132 76.9832C10.7389 78.5979 11.3246 79.2954 11.8805 79.9575C12.5746 80.784 13.2224 81.5555 11.315 83.9879C10.9354 85.1477 11.2595 85.7503 11.6077 86.3979C12.0994 87.3123 12.6393 88.3163 11.3145 91.1047C11.3145 92.7479 11.7907 93.044 12.2069 93.3027C12.7842 93.6617 13.2461 93.9489 12.1612 97.6613C11.8506 99.9221 12.5141 102.369 13.5234 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5234 0C12.8857 0.736893 12.1937 2.05693 11.413 4.29223C10.8475 5.91156 11.2429 6.27693 11.655 6.65778C12.1187 7.08627 12.6036 7.53436 11.7649 9.81006C10.6705 11.7433 10.9696 12.1387 11.3254 12.609C11.6685 13.0626 12.0643 13.5858 11.3147 15.6246C11.1219 16.77 11.4592 17.1986 11.7622 17.5836C12.1714 18.1037 12.5183 18.5444 11.4131 20.5645C11.4131 21.4931 11.7663 22.148 12.0554 22.6841C12.5004 23.5094 12.7937 24.0532 11.4132 24.8804C10.7389 26.59 11.3246 27.3285 11.8805 28.0295C12.5746 28.9047 13.2224 29.7216 11.315 32.297C10.9354 33.5249 11.2595 34.163 11.6077 34.8487C12.0994 35.8168 12.6393 36.8798 11.3145 39.8321C11.3145 41.5719 11.7907 41.8854 12.2069 42.1594C12.7842 42.5394 13.2461 42.8435 12.1612 46.7742C11.8506 49.168 12.5141 51.7591 13.5234 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-6-outside-2_4813_26770" maskUnits="userSpaceOnUse" x="72" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="72" width="12" height="104"/>
                                    <path d="M81 0H73V101H81V0Z"/>
                                    </mask>
                                    <path d="M81 0H73V101H81V0Z" fill="white"/>
                                    <path d="M73 101V104H72V101H73ZM81 101H84V104H81V101ZM74 0V101H72V0H74ZM73 98H81V104H73V98ZM78 101V0H84V101H78Z" fill="#B6C2D0" mask="url(#path-6-outside-2_4813_26770)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M83.4766 53.4844C84.1143 54.1803 84.8063 55.4271 85.587 57.5383C86.1525 59.0677 85.7571 59.4128 85.345 59.7725C84.8813 60.1772 84.3964 60.6004 85.2351 62.7497C86.3295 64.5756 86.0304 64.949 85.6746 65.3933C85.3315 65.8216 84.9357 66.3158 85.6853 68.2414C85.8781 69.3232 85.5408 69.7279 85.2378 70.0916C84.8286 70.5828 84.4817 70.999 85.5869 72.907C85.5869 73.784 85.2337 74.4025 84.9446 74.9089C84.4996 75.6883 84.2063 76.2019 85.5868 76.9832C86.2611 78.5979 85.6754 79.2954 85.1195 79.9575C84.4254 80.784 83.7776 81.5555 85.685 83.9879C86.0646 85.1477 85.7405 85.7503 85.3923 86.3979C84.9006 87.3123 84.3607 88.3163 85.6855 91.1047C85.6855 92.7479 85.2093 93.044 84.7931 93.3027C84.2158 93.6617 83.7539 93.9489 84.8388 97.6613C85.1494 99.9221 84.4859 102.369 83.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M83.4766 0C84.1143 0.736893 84.8063 2.05693 85.587 4.29223C86.1525 5.91156 85.7571 6.27693 85.345 6.65778C84.8813 7.08627 84.3964 7.53436 85.2351 9.81006C86.3295 11.7433 86.0304 12.1387 85.6746 12.609C85.3315 13.0626 84.9357 13.5858 85.6853 15.6246C85.8781 16.77 85.5408 17.1986 85.2378 17.5836C84.8286 18.1037 84.4817 18.5444 85.5869 20.5645C85.5869 21.4931 85.2337 22.148 84.9446 22.6841C84.4996 23.5094 84.2063 24.0532 85.5868 24.8804C86.2611 26.59 85.6754 27.3285 85.1195 28.0295C84.4254 28.9047 83.7776 29.7216 85.685 32.297C86.0646 33.5249 85.7405 34.163 85.3923 34.8487C84.9006 35.8168 84.3607 36.8798 85.6855 39.8321C85.6855 41.5719 85.2093 41.8854 84.7931 42.1594C84.2158 42.5394 83.7539 42.8435 84.8388 46.7742C85.1494 49.168 84.4859 51.7591 83.4766 53.4864V0Z" fill="#B6C2D0"/>
                                    <rect x="24" width="49" height="101" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 21L24 16.6699L24 25.3301L-2.18557e-07 21Z" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 35L24 30.6699L24 39.3301L-2.18557e-07 35Z" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 49L24 44.6699L24 53.3301L-2.18557e-07 49Z" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 63L24 58.6699L24 67.3301L-2.18557e-07 63Z" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 77L24 72.6699L24 81.3301L-2.18557e-07 77Z" fill="#EDF2F8"/>
                                    <path d="M97 21L73 16.6699L73 25.3301L97 21Z" fill="#EDF2F8"/>
                                    <path d="M97 35L73 30.6699L73 39.3301L97 35Z" fill="#EDF2F8"/>
                                    <path d="M97 49L73 44.6699L73 53.3301L97 49Z" fill="#EDF2F8"/>
                                    <path d="M97 63L73 58.6699L73 67.3301L97 63Z" fill="#EDF2F8"/>
                                    <path d="M97 77L73 72.6699L73 81.3301L97 77Z" fill="#EDF2F8"/>
                                </svg>
                            </template>
                            <template v-if="size === 4 && mappingBorehole() === 12">
                                <svg width="128" height="105" viewBox="0 0 128 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4826_18536" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V100.971H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V100.971H5V0Z" fill="white"/>
                                    <path d="M13 100.971V103.971H14V100.971H13ZM5 100.971H2V103.971H5V100.971ZM12 0V100.971H14V0H12ZM13 97.9709H5V103.971H13V97.9709ZM8 100.971V0H2V100.971H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4826_18536)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0667 0.24287 59.4118 0.655007 59.7715C1.11871 60.1762 1.60361 60.5994 0.764893 62.7487C-0.329534 64.5746 -0.030444 64.948 0.325393 65.3923C0.668488 65.8206 1.06434 66.3148 0.314701 68.2404C0.121937 69.3222 0.459179 69.727 0.762211 70.0907C1.17144 70.5818 1.51829 70.9981 0.413117 72.906C0.413067 73.783 0.766251 74.4016 1.05537 74.9079C1.50041 75.6873 1.79366 76.2009 0.413183 76.9822C-0.261059 78.5969 0.324575 79.2944 0.880538 79.9565C1.5746 80.7831 2.22242 81.5546 0.315041 83.987C-0.0646113 85.1467 0.259458 85.7494 0.6077 86.397C1.0994 87.3113 1.63929 88.3153 0.314519 91.1037C0.314519 92.7469 0.790691 93.043 1.20687 93.3017C1.78422 93.6607 2.24611 93.9479 1.16116 97.6603C0.850626 99.9212 1.51406 102.368 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.0304441 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.2969C-0.0646112 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4826_18536" maskUnits="userSpaceOnUse" x="114" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="114" width="12" height="104"/>
                                    <path d="M123 0H115V101H123V0Z"/>
                                    </mask>
                                    <path d="M123 0H115V101H123V0Z" fill="white"/>
                                    <path d="M115 101V104H114V101H115ZM123 101H126V104H123V101ZM116 0V101H114V0H116ZM115 98H123V104H115V98ZM120 101V0H126V101H120Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4826_18536)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 53.4844C126.114 54.1804 126.806 55.4271 127.587 57.5383C128.153 59.0678 127.757 59.4129 127.345 59.7726C126.881 60.1773 126.396 60.6005 127.235 62.7499C128.33 64.5758 128.03 64.9493 127.675 65.3935C127.332 65.8219 126.936 66.3161 127.685 68.2417C127.878 69.3235 127.541 69.7283 127.238 70.092C126.829 70.5831 126.482 70.9994 127.587 72.9074C127.587 73.7844 127.234 74.403 126.945 74.9094C126.5 75.6888 126.206 76.2024 127.587 76.9837C128.261 78.5985 127.675 79.2959 127.119 79.958C126.425 80.7846 125.778 81.5561 127.685 83.9886C128.065 85.1484 127.741 85.751 127.392 86.3986C126.901 87.313 126.361 88.317 127.685 91.1055C127.685 92.7487 127.209 93.0448 126.793 93.3036C126.216 93.6625 125.754 93.9497 126.839 97.6622C127.149 99.9231 126.486 102.37 125.477 104.002V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 0C126.114 0.736908 126.806 2.05697 127.587 4.29232C128.153 5.91169 127.757 6.27707 127.345 6.65792C126.881 7.08642 126.396 7.53452 127.235 9.81027C128.33 11.7436 128.03 12.1389 127.675 12.6093C127.332 13.0628 126.936 13.5861 127.685 15.6249C127.878 16.7704 127.541 17.1989 127.238 17.584C126.829 18.104 126.482 18.5448 127.587 20.5649C127.587 21.4935 127.234 22.1485 126.945 22.6846C126.5 23.5099 126.206 24.0537 127.587 24.8809C128.261 26.5906 127.675 27.3291 127.119 28.0301C126.425 28.9053 125.778 29.7222 127.685 32.2976C128.065 33.5256 127.741 34.1637 127.392 34.8494C126.901 35.8175 126.361 36.8806 127.685 39.8329C127.685 41.5728 127.209 41.8863 126.793 42.1603C126.216 42.5403 125.754 42.8444 126.839 46.7752C127.149 49.169 126.486 51.7602 125.477 53.4876V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4826_18536" maskUnits="userSpaceOnUse" x="106" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="106" width="10" height="104"/>
                                    <path d="M107 0H115V104H107V0Z"/>
                                    </mask>
                                    <path d="M107 0H115V104H107V0Z" fill="white"/>
                                    <path d="M114 0V104H116V0H114ZM108 104V0H106V104H108Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4826_18536)"/>
                                    <mask id="path-11-outside-4_4826_18536" maskUnits="userSpaceOnUse" x="97" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="97" width="10" height="104"/>
                                    <path d="M98 0H106V104H98V0Z"/>
                                    </mask>
                                    <path d="M98 0H106V104H98V0Z" fill="white"/>
                                    <path d="M105 0V104H107V0H105ZM99 104V0H97V104H99Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4826_18536)"/>
                                    <mask id="path-13-outside-5_4826_18536" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4826_18536)"/>
                                    <mask id="path-15-outside-6_4826_18536" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4826_18536)"/>
                                    <mask id="path-17-outside-7_4826_18536" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-17-outside-7_4826_18536)"/>
                                    <mask id="path-19-outside-8_4826_18536" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-19-outside-8_4826_18536)"/>
                                    <rect x="40" width="49" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="112" height="104" viewBox="0 0 110 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4826_18510" maskUnits="userSpaceOnUse" x="97" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="97" width="12" height="104"/>
                                    <path d="M106 0H98V101H106V0Z"/>
                                    </mask>
                                    <path d="M106 0H98V101H106V0Z" fill="white"/>
                                    <path d="M98 101V104H97V101H98ZM106 101H109V104H106V101ZM99 0V101H97V0H99ZM98 98H106V104H98V98ZM103 101V0H109V101H103Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4826_18510)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.001 52C109.639 52.7168 110.33 53.9999 111.11 56.1716C111.676 57.746 111.281 58.1012 110.868 58.4715C110.405 58.8881 109.92 59.3238 110.759 61.5364C111.853 63.416 111.554 63.8004 111.198 64.2578C110.855 64.6987 110.459 65.2075 111.209 67.1897C111.402 68.3034 111.064 68.72 110.761 69.0944C110.352 69.6 110.005 70.0285 111.11 71.9926C111.11 72.8955 110.757 73.5322 110.468 74.0535C110.023 74.8558 109.73 75.3846 111.11 76.1888C111.784 77.8511 111.199 78.5691 110.643 79.2507C109.949 80.1016 109.301 80.8958 111.208 83.3997C111.588 84.5937 111.264 85.214 110.916 85.8807C110.424 86.822 109.884 87.8555 111.209 90.726C111.209 92.4176 110.733 92.7223 110.317 92.9887C109.739 93.3582 109.277 93.6539 110.362 97.4755C110.673 99.8018 110.01 102.32 109.001 103.999V52Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M108.477 0C109.114 0.758582 109.806 2.11747 110.587 4.41857C111.153 6.08556 110.757 6.46169 110.345 6.85374C109.881 7.29485 109.396 7.75612 110.235 10.0988C111.33 12.089 111.03 12.496 110.675 12.9802C110.332 13.447 109.936 13.9857 110.685 16.0845C110.878 17.2636 110.541 17.7048 110.238 18.1012C109.829 18.6365 109.482 19.0902 110.587 21.1698C110.587 22.1257 110.234 22.7999 109.945 23.3518C109.5 24.2014 109.206 24.7611 110.587 25.6127C111.261 27.3727 110.675 28.1329 110.119 28.8546C109.425 29.7555 108.778 30.5964 110.685 33.2476C111.065 34.5117 110.741 35.1685 110.392 35.8744C109.901 36.871 109.361 37.9653 110.685 41.0045C110.685 42.7956 110.209 43.1183 109.793 43.4003C109.216 43.7916 108.754 44.1046 109.839 48.1509C110.149 50.6152 109.486 53.2826 108.477 55.0607V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4826_18510" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4826_18510)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0668 0.24287 59.4118 0.655007 59.7716C1.11871 60.1763 1.60361 60.5995 0.764893 62.7488C-0.329534 64.5748 -0.030444 64.9482 0.325393 65.3924C0.668488 65.8208 1.06434 66.315 0.314701 68.2405C0.121937 69.3224 0.459179 69.7271 0.762211 70.0908C1.17144 70.582 1.51829 70.9983 0.413117 72.9062C0.413067 73.7832 0.766251 74.4018 1.05537 74.9082C1.50041 75.6876 1.79366 76.2012 0.413183 76.9825C-0.261059 78.5972 0.324575 79.2947 0.880538 79.9568C1.5746 80.7834 2.22242 81.5549 0.315041 83.9873C-0.0646114 85.1471 0.259459 85.7497 0.6077 86.3973C1.0994 87.3117 1.63929 88.3157 0.314519 91.1041C0.314519 92.7474 0.790691 93.0434 1.20687 93.3022C1.78422 93.6611 2.24611 93.9483 1.16116 97.6608C0.850626 99.9217 1.51406 102.369 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736901 1.19373 2.05695 0.41304 4.29228C-0.152521 5.91163 0.24287 6.277 0.655007 6.65785C1.11871 7.08635 1.60361 7.53444 0.764893 9.81017C-0.329534 11.7434 -0.030444 12.1388 0.325393 12.6092C0.668488 13.0627 1.06434 13.586 0.314701 15.6248C0.121937 16.7702 0.459179 17.1988 0.762211 17.5838C1.17144 18.1039 1.51829 18.5446 0.413117 20.5647C0.413067 21.4933 0.766251 22.1483 1.05537 22.6844C1.50041 23.5096 1.79366 24.0534 0.413183 24.8806C-0.261059 26.5903 0.324575 27.3288 0.880538 28.0298C1.5746 28.905 2.22242 29.7219 0.315041 32.2973C-0.0646115 33.5253 0.259459 34.1634 0.6077 34.849C1.0994 35.8172 1.63929 36.8802 0.314519 39.8325C0.314519 41.5724 0.790691 41.8859 1.20687 42.1598C1.78422 42.5399 2.24611 42.844 1.16116 46.7747C0.850626 49.1685 1.51406 51.7597 2.52344 53.487V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4826_18510" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4826_18510)"/>
                                    <mask id="path-11-outside-4_4826_18510" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4826_18510)"/>
                                    <mask id="path-13-outside-5_4826_18510" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4826_18510)"/>
                                    <mask id="path-15-outside-6_4826_18510" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4826_18510)"/>
                                    <rect x="31" width="49" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="93" height="104" viewBox="0 0 91 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4826_18486" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4826_18486)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4922C1.88571 54.188 1.19373 55.4346 0.41304 57.5454C-0.152521 59.0746 0.24287 59.4196 0.655007 59.7792C1.11871 60.1839 1.60361 60.607 0.764893 62.756C-0.329534 64.5816 -0.030444 64.9549 0.325393 65.3991C0.668488 65.8273 1.06434 66.3215 0.314701 68.2467C0.121937 69.3284 0.459179 69.733 0.762211 70.0967C1.17144 70.5877 1.51829 71.0039 0.413117 72.9115C0.413067 73.7884 0.766251 74.4068 1.05537 74.9131C1.50041 75.6924 1.79366 76.2059 0.413183 76.9871C-0.261059 78.6015 0.324575 79.2989 0.880538 79.9609C1.5746 80.7873 2.22242 81.5587 0.315041 83.9906C-0.0646114 85.1502 0.259459 85.7528 0.6077 86.4002C1.0994 87.3145 1.63929 88.3183 0.314519 91.1062C0.314519 92.7491 0.790691 93.0451 1.20687 93.3039C1.78422 93.6628 2.24611 93.9499 1.16116 97.6617C0.850626 99.9222 1.51406 102.369 2.52344 104V53.4922Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.0195312C1.88571 0.7563 1.19373 2.07612 0.41304 4.31104C-0.152521 5.9301 0.24287 6.29541 0.655007 6.67619C1.11871 7.10462 1.60361 7.55263 0.764893 9.82795C-0.329534 11.7609 -0.030444 12.1562 0.325393 12.6265C0.668488 13.0799 1.06434 13.6031 0.314701 15.6415C0.121937 16.7868 0.459179 17.2152 0.762211 17.6002C1.17144 18.1202 1.51829 18.5608 0.413117 20.5806C0.413067 21.509 0.766251 22.1638 1.05537 22.6999C1.50041 23.525 1.79366 24.0687 0.413183 24.8957C-0.261059 26.6051 0.324575 27.3435 0.880538 28.0444C1.5746 28.9194 2.22242 29.7361 0.315041 32.3111C-0.0646113 33.5388 0.259458 34.1768 0.6077 34.8624C1.0994 35.8303 1.63929 36.8931 0.314519 39.845C0.314519 41.5845 0.790691 41.8979 1.20687 42.1718C1.78422 42.5519 2.24611 42.8559 1.16116 46.7859C0.850626 49.1793 1.51406 51.77 2.52344 53.497V0.0195312Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4826_18486" maskUnits="userSpaceOnUse" x="79" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="79" width="12" height="104"/>
                                    <path d="M88 0H80V101H88V0Z"/>
                                    </mask>
                                    <path d="M88 0H80V101H88V0Z" fill="white"/>
                                    <path d="M80 101V104H79V101H80ZM88 101H91V104H88V101ZM81 0V101H79V0H81ZM80 98H88V104H80V98ZM85 101V0H91V101H85Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4826_18486)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 53.4824C91.1143 54.1784 91.8063 55.4252 92.587 57.5364C93.1525 59.0658 92.7571 59.4109 92.345 59.7706C91.8813 60.1753 91.3964 60.5985 92.2351 62.7479C93.3295 64.5738 93.0304 64.9472 92.6746 65.3915C92.3315 65.8198 91.9357 66.314 92.6853 68.2396C92.8781 69.3215 92.5408 69.7262 92.2378 70.0899C91.8286 70.581 91.4817 70.9973 92.5869 72.9053C92.5869 73.7823 92.2337 74.4009 91.9446 74.9072C91.4996 75.6867 91.2063 76.2002 92.5868 76.9815C93.2611 78.5963 92.6754 79.2937 92.1195 79.9559C91.4254 80.7824 90.7776 81.554 92.685 83.9864C93.0646 85.1462 92.7405 85.7488 92.3923 86.3964C91.9006 87.3108 91.3607 88.3148 92.6855 91.1032C92.6855 92.7464 92.2093 93.0425 91.7931 93.3013C91.2158 93.6602 90.7539 93.9474 91.8388 97.6599C92.1494 99.9208 91.4859 102.368 90.4766 103.999V53.4824Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 0C91.1143 0.736902 91.8063 2.05696 92.587 4.29228C93.1525 5.91164 92.7571 6.27701 92.345 6.65786C91.8813 7.08636 91.3964 7.53445 92.2351 9.81019C93.3295 11.7435 93.0304 12.1388 92.6746 12.6092C92.3315 13.0627 91.9357 13.586 92.6853 15.6248C92.8781 16.7703 92.5408 17.1988 92.2378 17.5839C91.8286 18.1039 91.4817 18.5446 92.5869 20.5648C92.5869 21.4934 92.2337 22.1483 91.9446 22.6844C91.4996 23.5097 91.2063 24.0535 92.5868 24.8807C93.2611 26.5904 92.6754 27.3288 92.1195 28.0299C91.4254 28.9051 90.7776 29.7219 92.685 32.2974C93.0646 33.5253 92.7405 34.1634 92.3923 34.8491C91.9006 35.8172 91.3607 36.8803 92.6855 39.8326C92.6855 41.5725 92.2093 41.8859 91.7931 42.1599C91.2158 42.54 90.7539 42.8441 91.8388 46.7748C92.1494 49.1686 91.4859 51.7598 90.4766 53.4871V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4826_18486" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4826_18486)"/>
                                    <mask id="path-11-outside-4_4826_18486" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4826_18486)"/>
                                    <rect x="22" width="49" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="75" height="104" viewBox="0 0 73 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4826_18461" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4826_18461)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4844C1.88571 54.1803 1.19373 55.4271 0.41304 57.5383C-0.152521 59.0677 0.24287 59.4128 0.655007 59.7725C1.11871 60.1772 1.60361 60.6004 0.764893 62.7497C-0.329534 64.5756 -0.030444 64.949 0.325393 65.3933C0.668488 65.8216 1.06434 66.3158 0.314701 68.2414C0.121937 69.3232 0.459179 69.7279 0.762211 70.0916C1.17144 70.5828 1.51829 70.999 0.413117 72.907C0.413067 73.784 0.766251 74.4025 1.05537 74.9089C1.50041 75.6883 1.79366 76.2019 0.413183 76.9832C-0.261059 78.5979 0.324575 79.2954 0.880538 79.9575C1.5746 80.784 2.22242 81.5555 0.315041 83.9879C-0.0646114 85.1477 0.259459 85.7503 0.6077 86.3979C1.0994 87.3123 1.63929 88.3163 0.314519 91.1047C0.314519 92.7479 0.790691 93.044 1.20687 93.3027C1.78422 93.6617 2.24611 93.9489 1.16116 97.6612C0.850626 99.9221 1.51406 102.369 2.52344 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.000203383C1.88571 0.737096 1.19373 2.05713 0.41304 4.29243C-0.152521 5.91176 0.24287 6.27713 0.655007 6.65798C1.11871 7.08648 1.60361 7.53456 0.764893 9.81027C-0.329534 11.7435 -0.030444 12.1389 0.325393 12.6092C0.668488 13.0628 1.06434 13.586 0.314701 15.6248C0.121937 16.7702 0.459179 17.1988 0.762211 17.5838C1.17144 18.1039 1.51829 18.5446 0.413117 20.5647C0.413067 21.4933 0.766251 22.1482 1.05537 22.6843C1.50041 23.5096 1.79366 24.0534 0.413183 24.8806C-0.261059 26.5902 0.324575 27.3287 0.880538 28.0297C1.5746 28.9049 2.22242 29.7218 0.315041 32.2971C-0.0646114 33.5251 0.259459 34.1632 0.6077 34.8489C1.0994 35.817 1.63929 36.88 0.314519 39.8323C0.314519 41.5721 0.790691 41.8856 1.20687 42.1596C1.78422 42.5396 2.24611 42.8437 1.16116 46.7744C0.850626 49.1682 1.51406 51.7593 2.52344 53.4866V0.000203383Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4826_18461" maskUnits="userSpaceOnUse" x="61" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="61" width="12" height="104"/>
                                    <path d="M70 0H62V101H70V0Z"/>
                                    </mask>
                                    <path d="M70 0H62V101H70V0Z" fill="white"/>
                                    <path d="M62 101V104H61V101H62ZM70 101H73V104H70V101ZM63 0V101H61V0H63ZM62 98H70V104H62V98ZM67 101V0H73V101H67Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4826_18461)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 53.4844C73.1143 54.1803 73.8063 55.4271 74.587 57.5383C75.1525 59.0677 74.7571 59.4128 74.345 59.7725C73.8813 60.1772 73.3964 60.6004 74.2351 62.7497C75.3295 64.5756 75.0304 64.949 74.6746 65.3933C74.3315 65.8216 73.9357 66.3158 74.6853 68.2414C74.8781 69.3232 74.5408 69.7279 74.2378 70.0916C73.8286 70.5828 73.4817 70.999 74.5869 72.907C74.5869 73.784 74.2337 74.4025 73.9446 74.9089C73.4996 75.6883 73.2063 76.2019 74.5868 76.9832C75.2611 78.5979 74.6754 79.2954 74.1195 79.9575C73.4254 80.784 72.7776 81.5555 74.685 83.9879C75.0646 85.1477 74.7405 85.7503 74.3923 86.3979C73.9006 87.3123 73.3607 88.3163 74.6855 91.1047C74.6855 92.7479 74.2093 93.044 73.7931 93.3027C73.2158 93.6617 72.7539 93.9489 73.8388 97.6612C74.1494 99.9221 73.4859 102.369 72.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 0.000203383C73.1143 0.737096 73.8063 2.05713 74.587 4.29243C75.1525 5.91176 74.7571 6.27713 74.345 6.65798C73.8813 7.08648 73.3964 7.53456 74.2351 9.81027C75.3295 11.7435 75.0304 12.1389 74.6746 12.6092C74.3315 13.0628 73.9357 13.586 74.6853 15.6248C74.8781 16.7702 74.5408 17.1988 74.2378 17.5838C73.8286 18.1039 73.4817 18.5446 74.5869 20.5647C74.5869 21.4933 74.2337 22.1482 73.9446 22.6843C73.4996 23.5096 73.2063 24.0534 74.5868 24.8806C75.2611 26.5902 74.6754 27.3287 74.1195 28.0297C73.4254 28.9049 72.7776 29.7218 74.685 32.2971C75.0646 33.5251 74.7405 34.1632 74.3923 34.8489C73.9006 35.817 73.3607 36.88 74.6855 39.8323C74.6855 41.5721 74.2093 41.8856 73.7931 42.1596C73.2158 42.5396 72.7539 42.8437 73.8388 46.7744C74.1494 49.1682 73.4859 51.7593 72.4766 53.4866V0.000203383Z" fill="#B6C2D0"/>
                                    <rect x="13" width="49" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="54" height="107" viewBox="0 0 54 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M43 0L43 31.7783L52 31.7783L52 30.7525C51.4783 30.027 50.9122 28.7275 50.2735 26.5272C49.8108 24.9331 50.1343 24.5734 50.4715 24.1985C50.8509 23.7766 51.2476 23.3355 50.5614 21.0952C49.6659 19.192 49.9106 18.8028 50.2018 18.3397C50.4825 17.8933 50.8064 17.3781 50.193 15.3711C50.0353 14.2434 50.3112 13.8216 50.5592 13.4425C50.894 12.9306 51.1778 12.4967 50.2736 10.508C50.2735 9.59383 50.5625 8.9491 50.799 8.42131C51.1632 7.60889 51.4031 7.07356 50.2736 6.25922C49.722 4.57613 50.2011 3.84916 50.656 3.15902C51.1376 2.42837 51.5919 1.739 50.7671 -3.26935e-07L43 0Z" fill="#EDF2F8"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M43 31.7764L43 63.5547L53 63.5547L53 62.5294C52.4204 61.8039 51.7915 60.5045 51.082 58.3046C50.5678 56.7104 50.9273 56.3507 51.3019 55.9758C51.7235 55.554 52.1643 55.1129 51.4018 52.8725C50.4069 50.9693 50.6788 50.5801 51.0023 50.1171C51.3142 49.6706 51.674 49.1555 50.9926 47.1484C50.8173 46.0208 51.1239 45.5989 51.3994 45.2198C51.7714 44.7079 52.0867 44.274 51.082 42.2853C51.082 41.3712 51.4031 40.7264 51.6659 40.1986C52.0705 39.3862 52.3371 38.8509 51.0821 38.0366C50.4691 36.3535 51.0015 35.6265 51.507 34.9364C52.0421 34.2056 52.5471 33.5161 51.6299 31.7764L43 31.7764Z" fill="#EDF2F8"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M43 63.5557L43 104L54 104L54 102.695C53.3623 101.772 52.6704 100.118 51.8898 97.3179C51.3242 95.2889 51.7196 94.8312 52.1318 94.354C52.5955 93.8171 53.0804 93.2557 52.2416 90.4044C51.1472 87.9821 51.4463 87.4868 51.8021 86.8974C52.1452 86.3292 52.5411 85.6736 51.7915 83.1191C51.5987 81.6839 51.9359 81.147 52.239 80.6646C52.6482 80.013 52.995 79.4608 51.8899 76.9297C51.8898 75.7663 52.243 74.9457 52.5321 74.274C52.9772 73.24 53.2704 72.5586 51.8899 71.5222C51.2157 69.3801 51.8013 68.4549 52.3573 67.5765C52.9459 66.6465 53.5013 65.7691 52.4929 63.5557L43 63.5557Z" fill="#EDF2F8"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0L9 31.7783L1.63637 31.7783L1.63637 30.7523C2.06321 30.0268 2.52636 28.7274 3.04888 26.5272C3.42748 24.9331 3.16279 24.5734 2.8869 24.1985C2.57649 23.7766 2.25189 23.3355 2.81334 21.0952C3.54597 19.192 3.34576 18.8028 3.10755 18.3397C2.87788 17.8933 2.61289 17.3781 3.11471 15.3711C3.24375 14.2434 3.01799 13.8216 2.81514 13.4425C2.54119 12.9306 2.309 12.4967 3.04883 10.508C3.04886 9.59383 2.81243 8.9491 2.61889 8.42131C2.32097 7.60889 2.12466 7.07356 3.04878 6.25922C3.50014 4.57613 3.1081 3.84916 2.73593 3.15902C2.3419 2.42837 1.97014 1.739 2.64505 -2.94245e-07L9 0Z" fill="#EDF2F8"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.00049 31.7764L9.00049 63.5547L0.818496 63.5547L0.818498 31.7764L0.819017 31.7764L0.819016 62.5296C1.29328 61.8041 1.80789 60.5046 2.38846 58.3046C2.80913 56.7104 2.51503 56.3507 2.20848 55.9758C1.86357 55.554 1.50289 55.1129 2.12674 52.8725C2.9408 50.9693 2.71833 50.5801 2.45365 50.1171C2.19845 49.6706 1.90401 49.1555 2.4616 47.1484C2.60499 46.0208 2.35414 45.5989 2.12874 45.2198C1.82434 44.7079 1.56636 44.274 2.3884 42.2853C2.38844 41.3712 2.12573 40.7264 1.91068 40.1986C1.57965 39.3862 1.36153 38.8509 2.38835 38.0366C2.88987 36.3535 2.45426 35.6265 2.04073 34.9364C1.60283 34.2056 1.18968 33.5161 1.94017 31.7764L9.00049 31.7764Z" fill="#EDF2F8"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 63.5557L9 104L0.000313759 104L0.000317168 63.5557L0.000475478 63.5557L0.000473071 102.696C0.522235 101.773 1.08839 100.119 1.72712 97.3179C2.18984 95.2889 1.86635 94.8312 1.52916 94.354C1.14978 93.8171 0.753052 93.2557 1.43925 90.4044C2.33466 87.9821 2.08996 87.4868 1.79883 86.8974C1.51813 86.3292 1.19426 85.6736 1.80758 83.1191C1.96529 81.6839 1.68937 81.147 1.44145 80.6646C1.10663 80.013 0.82286 79.4608 1.72706 76.9297C1.7271 75.7663 1.43814 74.9457 1.2016 74.274C0.837485 73.24 0.597561 72.5586 1.72701 71.5222C2.27864 69.3801 1.7995 68.4549 1.34464 67.5765C0.863049 66.6465 0.408673 65.7691 1.23366 63.5557L9 63.5557Z" fill="#EDF2F8"/>
                                    <rect x="9" width="34" height="104" fill="#EDF2F8"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 103H54V105.82C53.5225 105.831 53.0207 105.888 52.5 106C48.0644 107.372 46.3161 106.744 44.532 106.104C43.5073 105.736 42.4707 105.364 40.9064 105.364C37.8585 106.896 36.7611 106.272 35.7616 105.703C35.0537 105.3 34.3949 104.925 33.1272 105.364C30.4684 107.571 29.6251 106.821 28.7216 106.019C27.9978 105.375 27.2355 104.698 25.4704 105.478C24.6164 107.075 24.055 106.736 23.203 106.221C22.6495 105.886 21.9734 105.478 21.0147 105.478C18.9292 106.756 18.4742 106.355 17.9373 105.882C17.5398 105.531 17.0974 105.141 15.9148 105.364C13.81 106.231 13.2698 105.773 12.8016 105.376C12.316 104.965 11.9078 104.619 9.91195 105.885C5.9209 107.533 3.49299 106.78 0.341224 105.802C0.228444 105.767 0.114738 105.732 0 105.696V103Z" fill="#EDF2F8"/>
                                </svg>
                            </template>
                            <template v-if="size === 4 && mappingBorehole() === 22">
                                <svg width="128" height="105" viewBox="0 0 128 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26245" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V100.971H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V100.971H5V0Z" fill="white"/>
                                    <path d="M13 100.971V103.971H14V100.971H13ZM5 100.971H2V103.971H5V100.971ZM12 0V100.971H14V0H12ZM13 97.9709H5V103.971H13V97.9709ZM8 100.971V0H2V100.971H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26245)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0667 0.24287 59.4118 0.655007 59.7715C1.11871 60.1762 1.60361 60.5994 0.764893 62.7487C-0.329534 64.5746 -0.030444 64.948 0.325393 65.3923C0.668488 65.8206 1.06434 66.3148 0.314701 68.2404C0.121937 69.3222 0.459179 69.727 0.762211 70.0907C1.17144 70.5818 1.51829 70.9981 0.413117 72.906C0.413067 73.783 0.766251 74.4016 1.05537 74.9079C1.50041 75.6873 1.79366 76.2009 0.413183 76.9822C-0.261059 78.5969 0.324575 79.2944 0.880538 79.9565C1.5746 80.7831 2.22242 81.5546 0.315041 83.987C-0.0646113 85.1467 0.259458 85.7494 0.6077 86.397C1.0994 87.3113 1.63929 88.3153 0.314519 91.1037C0.314519 92.7469 0.790691 93.043 1.20687 93.3017C1.78422 93.6607 2.24611 93.9479 1.16116 97.6603C0.850626 99.9212 1.51406 102.368 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.0304441 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.2969C-0.0646112 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26245" maskUnits="userSpaceOnUse" x="114" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="114" width="12" height="104"/>
                                    <path d="M123 0H115V101H123V0Z"/>
                                    </mask>
                                    <path d="M123 0H115V101H123V0Z" fill="white"/>
                                    <path d="M115 101V104H114V101H115ZM123 101H126V104H123V101ZM116 0V101H114V0H116ZM115 98H123V104H115V98ZM120 101V0H126V101H120Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26245)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 53.4844C126.114 54.1804 126.806 55.4271 127.587 57.5383C128.153 59.0678 127.757 59.4129 127.345 59.7726C126.881 60.1773 126.396 60.6005 127.235 62.7499C128.33 64.5758 128.03 64.9493 127.675 65.3935C127.332 65.8219 126.936 66.3161 127.685 68.2417C127.878 69.3235 127.541 69.7283 127.238 70.092C126.829 70.5831 126.482 70.9994 127.587 72.9074C127.587 73.7844 127.234 74.403 126.945 74.9094C126.5 75.6888 126.206 76.2024 127.587 76.9837C128.261 78.5985 127.675 79.2959 127.119 79.958C126.425 80.7846 125.778 81.5561 127.685 83.9886C128.065 85.1484 127.741 85.751 127.392 86.3986C126.901 87.313 126.361 88.317 127.685 91.1055C127.685 92.7487 127.209 93.0448 126.793 93.3036C126.216 93.6625 125.754 93.9497 126.839 97.6622C127.149 99.9231 126.486 102.37 125.477 104.002V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 0C126.114 0.736908 126.806 2.05697 127.587 4.29232C128.153 5.91169 127.757 6.27707 127.345 6.65792C126.881 7.08642 126.396 7.53452 127.235 9.81027C128.33 11.7436 128.03 12.1389 127.675 12.6093C127.332 13.0628 126.936 13.5861 127.685 15.6249C127.878 16.7704 127.541 17.1989 127.238 17.584C126.829 18.104 126.482 18.5448 127.587 20.5649C127.587 21.4935 127.234 22.1485 126.945 22.6846C126.5 23.5099 126.206 24.0537 127.587 24.8809C128.261 26.5906 127.675 27.3291 127.119 28.0301C126.425 28.9053 125.778 29.7222 127.685 32.2976C128.065 33.5256 127.741 34.1637 127.392 34.8494C126.901 35.8175 126.361 36.8806 127.685 39.8329C127.685 41.5728 127.209 41.8863 126.793 42.1603C126.216 42.5403 125.754 42.8444 126.839 46.7752C127.149 49.169 126.486 51.7602 125.477 53.4876V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4813_26245" maskUnits="userSpaceOnUse" x="106" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="106" width="10" height="104"/>
                                    <path d="M107 0H115V104H107V0Z"/>
                                    </mask>
                                    <path d="M107 0H115V104H107V0Z" fill="white"/>
                                    <path d="M114 0V104H116V0H114ZM108 104V0H106V104H108Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4813_26245)"/>
                                    <mask id="path-11-outside-4_4813_26245" maskUnits="userSpaceOnUse" x="97" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="97" width="10" height="104"/>
                                    <path d="M98 0H106V104H98V0Z"/>
                                    </mask>
                                    <path d="M98 0H106V104H98V0Z" fill="white"/>
                                    <path d="M105 0V104H107V0H105ZM99 104V0H97V104H99Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26245)"/>
                                    <mask id="path-13-outside-5_4813_26245" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4813_26245)"/>
                                    <mask id="path-15-outside-6_4813_26245" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4813_26245)"/>
                                    <mask id="path-17-outside-7_4813_26245" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-17-outside-7_4813_26245)"/>
                                    <mask id="path-19-outside-8_4813_26245" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-19-outside-8_4813_26245)"/>
                                    <rect x="40" width="49" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="112" height="104" viewBox="0 0 110 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26221" maskUnits="userSpaceOnUse" x="97" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="97" width="12" height="104"/>
                                    <path d="M106 0H98V101H106V0Z"/>
                                    </mask>
                                    <path d="M106 0H98V101H106V0Z" fill="white"/>
                                    <path d="M98 101V104H97V101H98ZM106 101H109V104H106V101ZM99 0V101H97V0H99ZM98 98H106V104H98V98ZM103 101V0H109V101H103Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26221)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.001 52C109.639 52.7168 110.33 53.9999 111.11 56.1716C111.676 57.746 111.281 58.1012 110.868 58.4715C110.405 58.8881 109.92 59.3238 110.759 61.5364C111.853 63.416 111.554 63.8004 111.198 64.2578C110.855 64.6987 110.459 65.2075 111.209 67.1897C111.402 68.3034 111.064 68.72 110.761 69.0944C110.352 69.6 110.005 70.0285 111.11 71.9926C111.11 72.8955 110.757 73.5322 110.468 74.0535C110.023 74.8558 109.73 75.3846 111.11 76.1888C111.784 77.8511 111.199 78.5691 110.643 79.2507C109.949 80.1016 109.301 80.8958 111.208 83.3997C111.588 84.5937 111.264 85.214 110.916 85.8807C110.424 86.822 109.884 87.8555 111.209 90.726C111.209 92.4176 110.733 92.7223 110.317 92.9887C109.739 93.3582 109.277 93.6539 110.362 97.4755C110.673 99.8018 110.01 102.32 109.001 103.999V52Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M108.477 0C109.114 0.758582 109.806 2.11747 110.587 4.41857C111.153 6.08556 110.757 6.46169 110.345 6.85374C109.881 7.29485 109.396 7.75612 110.235 10.0988C111.33 12.089 111.03 12.496 110.675 12.9802C110.332 13.447 109.936 13.9857 110.685 16.0845C110.878 17.2636 110.541 17.7048 110.238 18.1012C109.829 18.6365 109.482 19.0902 110.587 21.1698C110.587 22.1257 110.234 22.7999 109.945 23.3518C109.5 24.2014 109.206 24.7611 110.587 25.6127C111.261 27.3727 110.675 28.1329 110.119 28.8546C109.425 29.7555 108.778 30.5964 110.685 33.2476C111.065 34.5117 110.741 35.1685 110.392 35.8744C109.901 36.871 109.361 37.9653 110.685 41.0045C110.685 42.7956 110.209 43.1183 109.793 43.4003C109.216 43.7916 108.754 44.1046 109.839 48.1509C110.149 50.6152 109.486 53.2826 108.477 55.0607V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26221" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26221)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0668 0.24287 59.4118 0.655007 59.7716C1.11871 60.1763 1.60361 60.5995 0.764893 62.7488C-0.329534 64.5748 -0.030444 64.9482 0.325393 65.3924C0.668488 65.8208 1.06434 66.315 0.314701 68.2405C0.121937 69.3224 0.459179 69.7271 0.762211 70.0908C1.17144 70.582 1.51829 70.9983 0.413117 72.9062C0.413067 73.7832 0.766251 74.4018 1.05537 74.9082C1.50041 75.6876 1.79366 76.2012 0.413183 76.9825C-0.261059 78.5972 0.324575 79.2947 0.880538 79.9568C1.5746 80.7834 2.22242 81.5549 0.315041 83.9873C-0.0646114 85.1471 0.259459 85.7497 0.6077 86.3973C1.0994 87.3117 1.63929 88.3157 0.314519 91.1041C0.314519 92.7474 0.790691 93.0434 1.20687 93.3022C1.78422 93.6611 2.24611 93.9483 1.16116 97.6608C0.850626 99.9217 1.51406 102.369 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736901 1.19373 2.05695 0.41304 4.29228C-0.152521 5.91163 0.24287 6.277 0.655007 6.65785C1.11871 7.08635 1.60361 7.53444 0.764893 9.81017C-0.329534 11.7434 -0.030444 12.1388 0.325393 12.6092C0.668488 13.0627 1.06434 13.586 0.314701 15.6248C0.121937 16.7702 0.459179 17.1988 0.762211 17.5838C1.17144 18.1039 1.51829 18.5446 0.413117 20.5647C0.413067 21.4933 0.766251 22.1483 1.05537 22.6844C1.50041 23.5096 1.79366 24.0534 0.413183 24.8806C-0.261059 26.5903 0.324575 27.3288 0.880538 28.0298C1.5746 28.905 2.22242 29.7219 0.315041 32.2973C-0.0646115 33.5253 0.259459 34.1634 0.6077 34.849C1.0994 35.8172 1.63929 36.8802 0.314519 39.8325C0.314519 41.5724 0.790691 41.8859 1.20687 42.1598C1.78422 42.5399 2.24611 42.844 1.16116 46.7747C0.850626 49.1685 1.51406 51.7597 2.52344 53.487V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4813_26221" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4813_26221)"/>
                                    <mask id="path-11-outside-4_4813_26221" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26221)"/>
                                    <mask id="path-13-outside-5_4813_26221" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4813_26221)"/>
                                    <mask id="path-15-outside-6_4813_26221" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4813_26221)"/>
                                    <rect x="31" width="49" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="93" height="104" viewBox="0 0 91 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26199" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26199)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4922C1.88571 54.188 1.19373 55.4346 0.41304 57.5454C-0.152521 59.0746 0.24287 59.4196 0.655007 59.7792C1.11871 60.1839 1.60361 60.607 0.764893 62.756C-0.329534 64.5816 -0.030444 64.9549 0.325393 65.3991C0.668488 65.8273 1.06434 66.3215 0.314701 68.2467C0.121937 69.3284 0.459179 69.733 0.762211 70.0967C1.17144 70.5877 1.51829 71.0039 0.413117 72.9115C0.413067 73.7884 0.766251 74.4068 1.05537 74.9131C1.50041 75.6924 1.79366 76.2059 0.413183 76.9871C-0.261059 78.6015 0.324575 79.2989 0.880538 79.9609C1.5746 80.7873 2.22242 81.5587 0.315041 83.9906C-0.0646114 85.1502 0.259459 85.7528 0.6077 86.4002C1.0994 87.3145 1.63929 88.3183 0.314519 91.1062C0.314519 92.7491 0.790691 93.0451 1.20687 93.3039C1.78422 93.6628 2.24611 93.9499 1.16116 97.6617C0.850626 99.9222 1.51406 102.369 2.52344 104V53.4922Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.0195312C1.88571 0.7563 1.19373 2.07612 0.41304 4.31104C-0.152521 5.9301 0.24287 6.29541 0.655007 6.67619C1.11871 7.10462 1.60361 7.55263 0.764893 9.82795C-0.329534 11.7609 -0.030444 12.1562 0.325393 12.6265C0.668488 13.0799 1.06434 13.6031 0.314701 15.6415C0.121937 16.7868 0.459179 17.2152 0.762211 17.6002C1.17144 18.1202 1.51829 18.5608 0.413117 20.5806C0.413067 21.509 0.766251 22.1638 1.05537 22.6999C1.50041 23.525 1.79366 24.0687 0.413183 24.8957C-0.261059 26.6051 0.324575 27.3435 0.880538 28.0444C1.5746 28.9194 2.22242 29.7361 0.315041 32.3111C-0.0646113 33.5388 0.259458 34.1768 0.6077 34.8624C1.0994 35.8303 1.63929 36.8931 0.314519 39.845C0.314519 41.5845 0.790691 41.8979 1.20687 42.1718C1.78422 42.5519 2.24611 42.8559 1.16116 46.7859C0.850626 49.1793 1.51406 51.77 2.52344 53.497V0.0195312Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26199" maskUnits="userSpaceOnUse" x="79" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="79" width="12" height="104"/>
                                    <path d="M88 0H80V101H88V0Z"/>
                                    </mask>
                                    <path d="M88 0H80V101H88V0Z" fill="white"/>
                                    <path d="M80 101V104H79V101H80ZM88 101H91V104H88V101ZM81 0V101H79V0H81ZM80 98H88V104H80V98ZM85 101V0H91V101H85Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26199)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 53.4824C91.1143 54.1784 91.8063 55.4252 92.587 57.5364C93.1525 59.0658 92.7571 59.4109 92.345 59.7706C91.8813 60.1753 91.3964 60.5985 92.2351 62.7479C93.3295 64.5738 93.0304 64.9472 92.6746 65.3915C92.3315 65.8198 91.9357 66.314 92.6853 68.2396C92.8781 69.3215 92.5408 69.7262 92.2378 70.0899C91.8286 70.581 91.4817 70.9973 92.5869 72.9053C92.5869 73.7823 92.2337 74.4009 91.9446 74.9072C91.4996 75.6867 91.2063 76.2002 92.5868 76.9815C93.2611 78.5963 92.6754 79.2937 92.1195 79.9559C91.4254 80.7824 90.7776 81.554 92.685 83.9864C93.0646 85.1462 92.7405 85.7488 92.3923 86.3964C91.9006 87.3108 91.3607 88.3148 92.6855 91.1032C92.6855 92.7464 92.2093 93.0425 91.7931 93.3013C91.2158 93.6602 90.7539 93.9474 91.8388 97.6599C92.1494 99.9208 91.4859 102.368 90.4766 103.999V53.4824Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 0C91.1143 0.736902 91.8063 2.05696 92.587 4.29228C93.1525 5.91164 92.7571 6.27701 92.345 6.65786C91.8813 7.08636 91.3964 7.53445 92.2351 9.81019C93.3295 11.7435 93.0304 12.1388 92.6746 12.6092C92.3315 13.0627 91.9357 13.586 92.6853 15.6248C92.8781 16.7703 92.5408 17.1988 92.2378 17.5839C91.8286 18.1039 91.4817 18.5446 92.5869 20.5648C92.5869 21.4934 92.2337 22.1483 91.9446 22.6844C91.4996 23.5097 91.2063 24.0535 92.5868 24.8807C93.2611 26.5904 92.6754 27.3288 92.1195 28.0299C91.4254 28.9051 90.7776 29.7219 92.685 32.2974C93.0646 33.5253 92.7405 34.1634 92.3923 34.8491C91.9006 35.8172 91.3607 36.8803 92.6855 39.8326C92.6855 41.5725 92.2093 41.8859 91.7931 42.1599C91.2158 42.54 90.7539 42.8441 91.8388 46.7748C92.1494 49.1686 91.4859 51.7598 90.4766 53.4871V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4813_26199" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4813_26199)"/>
                                    <mask id="path-11-outside-4_4813_26199" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26199)"/>
                                    <rect x="22" width="49" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="75" height="104" viewBox="0 0 73 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="14" y="101" width="47" height="3" fill="#B6C2D0"/>
                                    <mask id="path-2-outside-1_4813_26176" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-2-outside-1_4813_26176)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4844C1.88571 54.1803 1.19373 55.4271 0.41304 57.5383C-0.152521 59.0677 0.24287 59.4128 0.655007 59.7725C1.11871 60.1772 1.60361 60.6004 0.764893 62.7497C-0.329534 64.5756 -0.030444 64.949 0.325393 65.3933C0.668488 65.8216 1.06434 66.3158 0.314701 68.2414C0.121937 69.3232 0.459179 69.7279 0.762211 70.0916C1.17144 70.5828 1.51829 70.999 0.413117 72.907C0.413067 73.784 0.766251 74.4025 1.05537 74.9089C1.50041 75.6883 1.79366 76.2019 0.413183 76.9832C-0.261059 78.5979 0.324575 79.2954 0.880538 79.9575C1.5746 80.784 2.22242 81.5555 0.315041 83.9879C-0.0646114 85.1477 0.259459 85.7503 0.6077 86.3979C1.0994 87.3123 1.63929 88.3163 0.314519 91.1047C0.314519 92.7479 0.790691 93.044 1.20687 93.3027C1.78422 93.6617 2.24611 93.9489 1.16116 97.6613C0.850626 99.9221 1.51406 102.369 2.52344 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.030444 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.297C-0.0646113 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-6-outside-2_4813_26176" maskUnits="userSpaceOnUse" x="61" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="61" width="12" height="104"/>
                                    <path d="M70 0H62V101H70V0Z"/>
                                    </mask>
                                    <path d="M70 0H62V101H70V0Z" fill="white"/>
                                    <path d="M62 101V104H61V101H62ZM70 101H73V104H70V101ZM63 0V101H61V0H63ZM62 98H70V104H62V98ZM67 101V0H73V101H67Z" fill="#B6C2D0" mask="url(#path-6-outside-2_4813_26176)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 53.4844C73.1143 54.1803 73.8063 55.4271 74.587 57.5383C75.1525 59.0677 74.7571 59.4128 74.345 59.7725C73.8813 60.1772 73.3964 60.6004 74.2351 62.7497C75.3295 64.5756 75.0304 64.949 74.6746 65.3933C74.3315 65.8216 73.9357 66.3158 74.6853 68.2414C74.8781 69.3232 74.5408 69.7279 74.2378 70.0916C73.8286 70.5828 73.4817 70.999 74.5869 72.907C74.5869 73.784 74.2337 74.4025 73.9446 74.9089C73.4996 75.6883 73.2063 76.2019 74.5868 76.9832C75.2611 78.5979 74.6754 79.2954 74.1195 79.9575C73.4254 80.784 72.7776 81.5555 74.685 83.9879C75.0646 85.1477 74.7405 85.7503 74.3923 86.3979C73.9006 87.3123 73.3607 88.3163 74.6855 91.1047C74.6855 92.7479 74.2093 93.044 73.7931 93.3027C73.2158 93.6617 72.7539 93.9489 73.8388 97.6613C74.1494 99.9221 73.4859 102.369 72.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 0C73.1143 0.736893 73.8063 2.05693 74.587 4.29223C75.1525 5.91156 74.7571 6.27693 74.345 6.65778C73.8813 7.08627 73.3964 7.53436 74.2351 9.81006C75.3295 11.7433 75.0304 12.1387 74.6746 12.609C74.3315 13.0626 73.9357 13.5858 74.6853 15.6246C74.8781 16.77 74.5408 17.1986 74.2378 17.5836C73.8286 18.1037 73.4817 18.5444 74.5869 20.5645C74.5869 21.4931 74.2337 22.148 73.9446 22.6841C73.4996 23.5094 73.2063 24.0532 74.5868 24.8804C75.2611 26.59 74.6754 27.3285 74.1195 28.0295C73.4254 28.9047 72.7776 29.7216 74.685 32.297C75.0646 33.5249 74.7405 34.163 74.3923 34.8487C73.9006 35.8168 73.3607 36.8798 74.6855 39.8321C74.6855 41.5719 74.2093 41.8854 73.7931 42.1594C73.2158 42.5394 72.7539 42.8435 73.8388 46.7742C74.1494 49.168 73.4859 51.7591 72.4766 53.4864V0Z" fill="#B6C2D0"/>
                                    <rect x="13" width="49" height="101" fill="#EDF2F8"/>
                                </svg>
                            </template>
                            <template v-if="size === 5 && mappingBorehole() === 2">
                                <svg width="128" height="105" viewBox="0 0 128 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26385" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V100.971H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V100.971H5V0Z" fill="white"/>
                                    <path d="M13 100.971V103.971H14V100.971H13ZM5 100.971H2V103.971H5V100.971ZM12 0V100.971H14V0H12ZM13 97.9709H5V103.971H13V97.9709ZM8 100.971V0H2V100.971H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26385)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0667 0.24287 59.4118 0.655007 59.7715C1.11871 60.1762 1.60361 60.5994 0.764893 62.7487C-0.329534 64.5746 -0.030444 64.948 0.325393 65.3923C0.668488 65.8206 1.06434 66.3148 0.314701 68.2404C0.121937 69.3222 0.459179 69.727 0.762211 70.0907C1.17144 70.5818 1.51829 70.9981 0.413117 72.906C0.413067 73.783 0.766251 74.4016 1.05537 74.9079C1.50041 75.6873 1.79366 76.2009 0.413183 76.9822C-0.261059 78.5969 0.324575 79.2944 0.880538 79.9565C1.5746 80.7831 2.22242 81.5546 0.315041 83.987C-0.0646113 85.1467 0.259458 85.7494 0.6077 86.397C1.0994 87.3113 1.63929 88.3153 0.314519 91.1037C0.314519 92.7469 0.790691 93.043 1.20687 93.3017C1.78422 93.6607 2.24611 93.9479 1.16116 97.6603C0.850626 99.9212 1.51406 102.368 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.0304441 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.2969C-0.0646112 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26385" maskUnits="userSpaceOnUse" x="114" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="114" width="12" height="104"/>
                                    <path d="M123 0H115V101H123V0Z"/>
                                    </mask>
                                    <path d="M123 0H115V101H123V0Z" fill="white"/>
                                    <path d="M115 101V104H114V101H115ZM123 101H126V104H123V101ZM116 0V101H114V0H116ZM115 98H123V104H115V98ZM120 101V0H126V101H120Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26385)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 53.4844C126.114 54.1804 126.806 55.4271 127.587 57.5383C128.153 59.0678 127.757 59.4129 127.345 59.7726C126.881 60.1773 126.396 60.6005 127.235 62.7499C128.33 64.5758 128.03 64.9493 127.675 65.3935C127.332 65.8219 126.936 66.3161 127.685 68.2417C127.878 69.3235 127.541 69.7283 127.238 70.092C126.829 70.5831 126.482 70.9994 127.587 72.9074C127.587 73.7844 127.234 74.403 126.945 74.9094C126.5 75.6888 126.206 76.2024 127.587 76.9837C128.261 78.5985 127.675 79.2959 127.119 79.958C126.425 80.7846 125.778 81.5561 127.685 83.9886C128.065 85.1484 127.741 85.751 127.392 86.3986C126.901 87.313 126.361 88.317 127.685 91.1055C127.685 92.7487 127.209 93.0448 126.793 93.3036C126.216 93.6625 125.754 93.9497 126.839 97.6622C127.149 99.9231 126.486 102.37 125.477 104.002V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 0C126.114 0.736908 126.806 2.05697 127.587 4.29232C128.153 5.91169 127.757 6.27707 127.345 6.65792C126.881 7.08642 126.396 7.53452 127.235 9.81027C128.33 11.7436 128.03 12.1389 127.675 12.6093C127.332 13.0628 126.936 13.5861 127.685 15.6249C127.878 16.7704 127.541 17.1989 127.238 17.584C126.829 18.104 126.482 18.5448 127.587 20.5649C127.587 21.4935 127.234 22.1485 126.945 22.6846C126.5 23.5099 126.206 24.0537 127.587 24.8809C128.261 26.5906 127.675 27.3291 127.119 28.0301C126.425 28.9053 125.778 29.7222 127.685 32.2976C128.065 33.5256 127.741 34.1637 127.392 34.8494C126.901 35.8175 126.361 36.8806 127.685 39.8329C127.685 41.5728 127.209 41.8863 126.793 42.1603C126.216 42.5403 125.754 42.8444 126.839 46.7752C127.149 49.169 126.486 51.7602 125.477 53.4876V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4813_26385" maskUnits="userSpaceOnUse" x="106" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="106" width="10" height="104"/>
                                    <path d="M107 0H115V104H107V0Z"/>
                                    </mask>
                                    <path d="M107 0H115V104H107V0Z" fill="white"/>
                                    <path d="M114 0V104H116V0H114ZM108 104V0H106V104H108Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4813_26385)"/>
                                    <mask id="path-11-outside-4_4813_26385" maskUnits="userSpaceOnUse" x="97" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="97" width="10" height="104"/>
                                    <path d="M98 0H106V104H98V0Z"/>
                                    </mask>
                                    <path d="M98 0H106V104H98V0Z" fill="white"/>
                                    <path d="M105 0V104H107V0H105ZM99 104V0H97V104H99Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26385)"/>
                                    <mask id="path-13-outside-5_4813_26385" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4813_26385)"/>
                                    <mask id="path-15-outside-6_4813_26385" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4813_26385)"/>
                                    <mask id="path-17-outside-7_4813_26385" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-17-outside-7_4813_26385)"/>
                                    <mask id="path-19-outside-8_4813_26385" maskUnits="userSpaceOnUse" x="40" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="40" width="10" height="104"/>
                                    <path d="M41 0H49V104H41V0Z"/>
                                    </mask>
                                    <path d="M41 0H49V104H41V0Z" fill="white"/>
                                    <path d="M48 0V104H50V0H48ZM42 104V0H40V104H42Z" fill="#B6C2D0" mask="url(#path-19-outside-8_4813_26385)"/>
                                    <mask id="path-21-outside-9_4813_26385" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-21-outside-9_4813_26385)"/>
                                    <mask id="path-23-outside-10_4813_26385" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-23-outside-10_4813_26385)"/>
                                    <rect x="49" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="112" height="104" viewBox="0 0 110 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26359" maskUnits="userSpaceOnUse" x="97" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="97" width="12" height="104"/>
                                    <path d="M106 0H98V101H106V0Z"/>
                                    </mask>
                                    <path d="M106 0H98V101H106V0Z" fill="white"/>
                                    <path d="M98 101V104H97V101H98ZM106 101H109V104H106V101ZM99 0V101H97V0H99ZM98 98H106V104H98V98ZM103 101V0H109V101H103Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26359)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.001 52C109.639 52.7168 110.33 53.9999 111.11 56.1716C111.676 57.746 111.281 58.1012 110.868 58.4715C110.405 58.8881 109.92 59.3238 110.759 61.5364C111.853 63.416 111.554 63.8004 111.198 64.2578C110.855 64.6987 110.459 65.2075 111.209 67.1897C111.402 68.3034 111.064 68.72 110.761 69.0944C110.352 69.6 110.005 70.0285 111.11 71.9926C111.11 72.8955 110.757 73.5322 110.468 74.0535C110.023 74.8558 109.73 75.3846 111.11 76.1888C111.784 77.8511 111.199 78.5691 110.643 79.2507C109.949 80.1016 109.301 80.8958 111.208 83.3997C111.588 84.5937 111.264 85.214 110.916 85.8807C110.424 86.822 109.884 87.8555 111.209 90.726C111.209 92.4176 110.733 92.7223 110.317 92.9887C109.739 93.3582 109.277 93.6539 110.362 97.4755C110.673 99.8018 110.01 102.32 109.001 103.999V52Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M108.477 0C109.114 0.758582 109.806 2.11747 110.587 4.41857C111.153 6.08556 110.757 6.46169 110.345 6.85374C109.881 7.29485 109.396 7.75612 110.235 10.0988C111.33 12.089 111.03 12.496 110.675 12.9802C110.332 13.447 109.936 13.9857 110.685 16.0845C110.878 17.2636 110.541 17.7048 110.238 18.1012C109.829 18.6365 109.482 19.0902 110.587 21.1698C110.587 22.1257 110.234 22.7999 109.945 23.3518C109.5 24.2014 109.206 24.7611 110.587 25.6127C111.261 27.3727 110.675 28.1329 110.119 28.8546C109.425 29.7555 108.778 30.5964 110.685 33.2476C111.065 34.5117 110.741 35.1685 110.392 35.8744C109.901 36.871 109.361 37.9653 110.685 41.0045C110.685 42.7956 110.209 43.1183 109.793 43.4003C109.216 43.7916 108.754 44.1046 109.839 48.1509C110.149 50.6152 109.486 53.2826 108.477 55.0607V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26359" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26359)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0668 0.24287 59.4118 0.655007 59.7716C1.11871 60.1763 1.60361 60.5995 0.764893 62.7488C-0.329534 64.5748 -0.030444 64.9482 0.325393 65.3924C0.668488 65.8208 1.06434 66.315 0.314701 68.2405C0.121937 69.3224 0.459179 69.7271 0.762211 70.0908C1.17144 70.582 1.51829 70.9983 0.413117 72.9062C0.413067 73.7832 0.766251 74.4018 1.05537 74.9082C1.50041 75.6876 1.79366 76.2012 0.413183 76.9825C-0.261059 78.5972 0.324575 79.2947 0.880538 79.9568C1.5746 80.7834 2.22242 81.5549 0.315041 83.9873C-0.0646114 85.1471 0.259459 85.7497 0.6077 86.3973C1.0994 87.3117 1.63929 88.3157 0.314519 91.1041C0.314519 92.7474 0.790691 93.0434 1.20687 93.3022C1.78422 93.6611 2.24611 93.9483 1.16116 97.6608C0.850626 99.9217 1.51406 102.369 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736901 1.19373 2.05695 0.41304 4.29228C-0.152521 5.91163 0.24287 6.277 0.655007 6.65785C1.11871 7.08635 1.60361 7.53444 0.764893 9.81017C-0.329534 11.7434 -0.030444 12.1388 0.325393 12.6092C0.668488 13.0627 1.06434 13.586 0.314701 15.6248C0.121937 16.7702 0.459179 17.1988 0.762211 17.5838C1.17144 18.1039 1.51829 18.5446 0.413117 20.5647C0.413067 21.4933 0.766251 22.1483 1.05537 22.6844C1.50041 23.5096 1.79366 24.0534 0.413183 24.8806C-0.261059 26.5903 0.324575 27.3288 0.880538 28.0298C1.5746 28.905 2.22242 29.7219 0.315041 32.2973C-0.0646115 33.5253 0.259459 34.1634 0.6077 34.849C1.0994 35.8172 1.63929 36.8802 0.314519 39.8325C0.314519 41.5724 0.790691 41.8859 1.20687 42.1598C1.78422 42.5399 2.24611 42.844 1.16116 46.7747C0.850626 49.1685 1.51406 51.7597 2.52344 53.487V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4813_26359" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4813_26359)"/>
                                    <mask id="path-11-outside-4_4813_26359" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26359)"/>
                                    <mask id="path-13-outside-5_4813_26359" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4813_26359)"/>
                                    <mask id="path-15-outside-6_4813_26359" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4813_26359)"/>
                                    <mask id="path-17-outside-7_4813_26359" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-17-outside-7_4813_26359)"/>
                                    <mask id="path-19-outside-8_4813_26359" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-19-outside-8_4813_26359)"/>
                                    <rect x="40" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="93" height="104" viewBox="0 0 91 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26335" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26335)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4922C1.88571 54.188 1.19373 55.4346 0.41304 57.5454C-0.152521 59.0746 0.24287 59.4196 0.655007 59.7792C1.11871 60.1839 1.60361 60.607 0.764893 62.756C-0.329534 64.5816 -0.030444 64.9549 0.325393 65.3991C0.668488 65.8273 1.06434 66.3215 0.314701 68.2467C0.121937 69.3284 0.459179 69.733 0.762211 70.0967C1.17144 70.5877 1.51829 71.0039 0.413117 72.9115C0.413067 73.7884 0.766251 74.4068 1.05537 74.9131C1.50041 75.6924 1.79366 76.2059 0.413183 76.9871C-0.261059 78.6015 0.324575 79.2989 0.880538 79.9609C1.5746 80.7873 2.22242 81.5587 0.315041 83.9906C-0.0646114 85.1502 0.259459 85.7528 0.6077 86.4002C1.0994 87.3145 1.63929 88.3183 0.314519 91.1062C0.314519 92.7491 0.790691 93.0451 1.20687 93.3039C1.78422 93.6628 2.24611 93.9499 1.16116 97.6617C0.850626 99.9222 1.51406 102.369 2.52344 104V53.4922Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.0195312C1.88571 0.7563 1.19373 2.07612 0.41304 4.31104C-0.152521 5.9301 0.24287 6.29541 0.655007 6.67619C1.11871 7.10462 1.60361 7.55263 0.764893 9.82795C-0.329534 11.7609 -0.030444 12.1562 0.325393 12.6265C0.668488 13.0799 1.06434 13.6031 0.314701 15.6415C0.121937 16.7868 0.459179 17.2152 0.762211 17.6002C1.17144 18.1202 1.51829 18.5608 0.413117 20.5806C0.413067 21.509 0.766251 22.1638 1.05537 22.6999C1.50041 23.525 1.79366 24.0687 0.413183 24.8957C-0.261059 26.6051 0.324575 27.3435 0.880538 28.0444C1.5746 28.9194 2.22242 29.7361 0.315041 32.3111C-0.0646113 33.5388 0.259458 34.1768 0.6077 34.8624C1.0994 35.8303 1.63929 36.8931 0.314519 39.845C0.314519 41.5845 0.790691 41.8979 1.20687 42.1718C1.78422 42.5519 2.24611 42.8559 1.16116 46.7859C0.850626 49.1793 1.51406 51.77 2.52344 53.497V0.0195312Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26335" maskUnits="userSpaceOnUse" x="79" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="79" width="12" height="104"/>
                                    <path d="M88 0H80V101H88V0Z"/>
                                    </mask>
                                    <path d="M88 0H80V101H88V0Z" fill="white"/>
                                    <path d="M80 101V104H79V101H80ZM88 101H91V104H88V101ZM81 0V101H79V0H81ZM80 98H88V104H80V98ZM85 101V0H91V101H85Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26335)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 53.4824C91.1143 54.1784 91.8063 55.4252 92.587 57.5364C93.1525 59.0658 92.7571 59.4109 92.345 59.7706C91.8813 60.1753 91.3964 60.5985 92.2351 62.7479C93.3295 64.5738 93.0304 64.9472 92.6746 65.3915C92.3315 65.8198 91.9357 66.314 92.6853 68.2396C92.8781 69.3215 92.5408 69.7262 92.2378 70.0899C91.8286 70.581 91.4817 70.9973 92.5869 72.9053C92.5869 73.7823 92.2337 74.4009 91.9446 74.9072C91.4996 75.6867 91.2063 76.2002 92.5868 76.9815C93.2611 78.5963 92.6754 79.2937 92.1195 79.9559C91.4254 80.7824 90.7776 81.554 92.685 83.9864C93.0646 85.1462 92.7405 85.7488 92.3923 86.3964C91.9006 87.3108 91.3607 88.3148 92.6855 91.1032C92.6855 92.7464 92.2093 93.0425 91.7931 93.3013C91.2158 93.6602 90.7539 93.9474 91.8388 97.6599C92.1494 99.9208 91.4859 102.368 90.4766 103.999V53.4824Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 0C91.1143 0.736902 91.8063 2.05696 92.587 4.29228C93.1525 5.91164 92.7571 6.27701 92.345 6.65786C91.8813 7.08636 91.3964 7.53445 92.2351 9.81019C93.3295 11.7435 93.0304 12.1388 92.6746 12.6092C92.3315 13.0627 91.9357 13.586 92.6853 15.6248C92.8781 16.7703 92.5408 17.1988 92.2378 17.5839C91.8286 18.1039 91.4817 18.5446 92.5869 20.5648C92.5869 21.4934 92.2337 22.1483 91.9446 22.6844C91.4996 23.5097 91.2063 24.0535 92.5868 24.8807C93.2611 26.5904 92.6754 27.3288 92.1195 28.0299C91.4254 28.9051 90.7776 29.7219 92.685 32.2974C93.0646 33.5253 92.7405 34.1634 92.3923 34.8491C91.9006 35.8172 91.3607 36.8803 92.6855 39.8326C92.6855 41.5725 92.2093 41.8859 91.7931 42.1599C91.2158 42.54 90.7539 42.8441 91.8388 46.7748C92.1494 49.1686 91.4859 51.7598 90.4766 53.4871V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4813_26335" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4813_26335)"/>
                                    <mask id="path-11-outside-4_4813_26335" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26335)"/>
                                    <mask id="path-13-outside-5_4813_26335" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4813_26335)"/>
                                    <mask id="path-15-outside-6_4813_26335" maskUnits="userSpaceOnUse" x="61" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="61" width="10" height="104"/>
                                    <path d="M62 0H70V104H62V0Z"/>
                                    </mask>
                                    <path d="M62 0H70V104H62V0Z" fill="white"/>
                                    <path d="M69 0V104H71V0H69ZM63 104V0H61V104H63Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4813_26335)"/>
                                    <rect x="31" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="75" height="104" viewBox="0 0 73 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="14" y="101" width="47" height="3" fill="#B6C2D0"/>
                                    <mask id="path-2-outside-1_4813_26310" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-2-outside-1_4813_26310)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4844C1.88571 54.1803 1.19373 55.4271 0.41304 57.5383C-0.152521 59.0677 0.24287 59.4128 0.655007 59.7725C1.11871 60.1772 1.60361 60.6004 0.764893 62.7497C-0.329534 64.5756 -0.030444 64.949 0.325393 65.3933C0.668488 65.8216 1.06434 66.3158 0.314701 68.2414C0.121937 69.3232 0.459179 69.7279 0.762211 70.0916C1.17144 70.5828 1.51829 70.999 0.413117 72.907C0.413067 73.784 0.766251 74.4025 1.05537 74.9089C1.50041 75.6883 1.79366 76.2019 0.413183 76.9832C-0.261059 78.5979 0.324575 79.2954 0.880538 79.9575C1.5746 80.784 2.22242 81.5555 0.315041 83.9879C-0.0646114 85.1477 0.259459 85.7503 0.6077 86.3979C1.0994 87.3123 1.63929 88.3163 0.314519 91.1047C0.314519 92.7479 0.790691 93.044 1.20687 93.3027C1.78422 93.6617 2.24611 93.9489 1.16116 97.6613C0.850626 99.9221 1.51406 102.369 2.52344 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.030444 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.297C-0.0646113 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-6-outside-2_4813_26310" maskUnits="userSpaceOnUse" x="61" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="61" width="12" height="104"/>
                                    <path d="M70 0H62V101H70V0Z"/>
                                    </mask>
                                    <path d="M70 0H62V101H70V0Z" fill="white"/>
                                    <path d="M62 101V104H61V101H62ZM70 101H73V104H70V101ZM63 0V101H61V0H63ZM62 98H70V104H62V98ZM67 101V0H73V101H67Z" fill="#B6C2D0" mask="url(#path-6-outside-2_4813_26310)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 53.4844C73.1143 54.1803 73.8063 55.4271 74.587 57.5383C75.1525 59.0677 74.7571 59.4128 74.345 59.7725C73.8813 60.1772 73.3964 60.6004 74.2351 62.7497C75.3295 64.5756 75.0304 64.949 74.6746 65.3933C74.3315 65.8216 73.9357 66.3158 74.6853 68.2414C74.8781 69.3232 74.5408 69.7279 74.2378 70.0916C73.8286 70.5828 73.4817 70.999 74.5869 72.907C74.5869 73.784 74.2337 74.4025 73.9446 74.9089C73.4996 75.6883 73.2063 76.2019 74.5868 76.9832C75.2611 78.5979 74.6754 79.2954 74.1195 79.9575C73.4254 80.784 72.7776 81.5555 74.685 83.9879C75.0646 85.1477 74.7405 85.7503 74.3923 86.3979C73.9006 87.3123 73.3607 88.3163 74.6855 91.1047C74.6855 92.7479 74.2093 93.044 73.7931 93.3027C73.2158 93.6617 72.7539 93.9489 73.8388 97.6613C74.1494 99.9221 73.4859 102.369 72.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 0C73.1143 0.736893 73.8063 2.05693 74.587 4.29223C75.1525 5.91156 74.7571 6.27693 74.345 6.65778C73.8813 7.08627 73.3964 7.53436 74.2351 9.81006C75.3295 11.7433 75.0304 12.1387 74.6746 12.609C74.3315 13.0626 73.9357 13.5858 74.6853 15.6246C74.8781 16.77 74.5408 17.1986 74.2378 17.5836C73.8286 18.1037 73.4817 18.5444 74.5869 20.5645C74.5869 21.4931 74.2337 22.148 73.9446 22.6841C73.4996 23.5094 73.2063 24.0532 74.5868 24.8804C75.2611 26.59 74.6754 27.3285 74.1195 28.0295C73.4254 28.9047 72.7776 29.7216 74.685 32.297C75.0646 33.5249 74.7405 34.163 74.3923 34.8487C73.9006 35.8168 73.3607 36.8798 74.6855 39.8321C74.6855 41.5719 74.2093 41.8854 73.7931 42.1594C73.2158 42.5394 72.7539 42.8435 73.8388 46.7742C74.1494 49.168 73.4859 51.7591 72.4766 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-10-outside-3_4813_26310" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-10-outside-3_4813_26310)"/>
                                    <mask id="path-12-outside-4_4813_26310" maskUnits="userSpaceOnUse" x="52" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="52" width="10" height="104"/>
                                    <path d="M53 0H61V104H53V0Z"/>
                                    </mask>
                                    <path d="M53 0H61V104H53V0Z" fill="white"/>
                                    <path d="M60 0V104H62V0H60ZM54 104V0H52V104H54Z" fill="#B6C2D0" mask="url(#path-12-outside-4_4813_26310)"/>
                                    <rect x="22" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="79" height="104" viewBox="0 0 79 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="16" y="101" width="47" height="3" fill="#B6C2D0"/>
                                    <mask id="path-2-outside-1_4813_26881" maskUnits="userSpaceOnUse" x="13" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="13" width="12" height="104"/>
                                    <path d="M16 0H24V101H16V0Z"/>
                                    </mask>
                                    <path d="M16 0H24V101H16V0Z" fill="white"/>
                                    <path d="M24 101V104H25V101H24ZM16 101H13V104H16V101ZM23 0V101H25V0H23ZM24 98H16V104H24V98ZM19 101V0H13V101H19Z" fill="#B6C2D0" mask="url(#path-2-outside-1_4813_26881)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5234 53.4844C12.8857 54.1803 12.1937 55.4271 11.413 57.5383C10.8475 59.0677 11.2429 59.4128 11.655 59.7725C12.1187 60.1772 12.6036 60.6004 11.7649 62.7497C10.6705 64.5756 10.9696 64.949 11.3254 65.3933C11.6685 65.8216 12.0643 66.3158 11.3147 68.2414C11.1219 69.3232 11.4592 69.7279 11.7622 70.0916C12.1714 70.5828 12.5183 70.999 11.4131 72.907C11.4131 73.784 11.7663 74.4025 12.0554 74.9089C12.5004 75.6883 12.7937 76.2019 11.4132 76.9832C10.7389 78.5979 11.3246 79.2954 11.8805 79.9575C12.5746 80.784 13.2224 81.5555 11.315 83.9879C10.9354 85.1477 11.2595 85.7503 11.6077 86.3979C12.0994 87.3123 12.6393 88.3163 11.3145 91.1047C11.3145 92.7479 11.7907 93.044 12.2069 93.3027C12.7842 93.6617 13.2461 93.9489 12.1612 97.6613C11.8506 99.9221 12.5141 102.369 13.5234 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5234 0C12.8857 0.736893 12.1937 2.05693 11.413 4.29223C10.8475 5.91156 11.2429 6.27693 11.655 6.65778C12.1187 7.08627 12.6036 7.53436 11.7649 9.81006C10.6705 11.7433 10.9696 12.1387 11.3254 12.609C11.6685 13.0626 12.0643 13.5858 11.3147 15.6246C11.1219 16.77 11.4592 17.1986 11.7622 17.5836C12.1714 18.1037 12.5183 18.5444 11.4131 20.5645C11.4131 21.4931 11.7663 22.148 12.0554 22.6841C12.5004 23.5094 12.7937 24.0532 11.4132 24.8804C10.7389 26.59 11.3246 27.3285 11.8805 28.0295C12.5746 28.9047 13.2224 29.7216 11.315 32.297C10.9354 33.5249 11.2595 34.163 11.6077 34.8487C12.0994 35.8168 12.6393 36.8798 11.3145 39.8321C11.3145 41.5719 11.7907 41.8854 12.2069 42.1594C12.7842 42.5394 13.2461 42.8435 12.1612 46.7742C11.8506 49.168 12.5141 51.7591 13.5234 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-6-outside-2_4813_26881" maskUnits="userSpaceOnUse" x="54" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="54" width="12" height="104"/>
                                    <path d="M63 0H55V101H63V0Z"/>
                                    </mask>
                                    <path d="M63 0H55V101H63V0Z" fill="white"/>
                                    <path d="M55 101V104H54V101H55ZM63 101H66V104H63V101ZM56 0V101H54V0H56ZM55 98H63V104H55V98ZM60 101V0H66V101H60Z" fill="#B6C2D0" mask="url(#path-6-outside-2_4813_26881)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M65.4766 53.4844C66.1143 54.1803 66.8063 55.4271 67.587 57.5383C68.1525 59.0677 67.7571 59.4128 67.345 59.7725C66.8813 60.1772 66.3964 60.6004 67.2351 62.7497C68.3295 64.5756 68.0304 64.949 67.6746 65.3933C67.3315 65.8216 66.9357 66.3158 67.6853 68.2414C67.8781 69.3232 67.5408 69.7279 67.2378 70.0916C66.8286 70.5828 66.4817 70.999 67.5869 72.907C67.5869 73.784 67.2337 74.4025 66.9446 74.9089C66.4996 75.6883 66.2063 76.2019 67.5868 76.9832C68.2611 78.5979 67.6754 79.2954 67.1195 79.9575C66.4254 80.784 65.7776 81.5555 67.685 83.9879C68.0646 85.1477 67.7405 85.7503 67.3923 86.3979C66.9006 87.3123 66.3607 88.3163 67.6855 91.1047C67.6855 92.7479 67.2093 93.044 66.7931 93.3027C66.2158 93.6617 65.7539 93.9489 66.8388 97.6613C67.1494 99.9221 66.4859 102.369 65.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M65.4766 0C66.1143 0.736893 66.8063 2.05693 67.587 4.29223C68.1525 5.91156 67.7571 6.27693 67.345 6.65778C66.8813 7.08627 66.3964 7.53436 67.2351 9.81006C68.3295 11.7433 68.0304 12.1387 67.6746 12.609C67.3315 13.0626 66.9357 13.5858 67.6853 15.6246C67.8781 16.77 67.5408 17.1986 67.2378 17.5836C66.8286 18.1037 66.4817 18.5444 67.5869 20.5645C67.5869 21.4931 67.2337 22.148 66.9446 22.6841C66.4996 23.5094 66.2063 24.0532 67.5868 24.8804C68.2611 26.59 67.6754 27.3285 67.1195 28.0295C66.4254 28.9047 65.7776 29.7216 67.685 32.297C68.0646 33.5249 67.7405 34.163 67.3923 34.8487C66.9006 35.8168 66.3607 36.8798 67.6855 39.8321C67.6855 41.5719 67.2093 41.8854 66.7931 42.1594C66.2158 42.5394 65.7539 42.8435 66.8388 46.7742C67.1494 49.168 66.4859 51.7591 65.4766 53.4864V0Z" fill="#B6C2D0"/>
                                    <rect x="24" width="31" height="101" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 21L24 16.6699L24 25.3301L-2.18557e-07 21Z" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 35L24 30.6699L24 39.3301L-2.18557e-07 35Z" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 49L24 44.6699L24 53.3301L-2.18557e-07 49Z" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 63L24 58.6699L24 67.3301L-2.18557e-07 63Z" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 77L24 72.6699L24 81.3301L-2.18557e-07 77Z" fill="#EDF2F8"/>
                                    <path d="M79 21L55 16.6699L55 25.3301L79 21Z" fill="#EDF2F8"/>
                                    <path d="M79 35L55 30.6699L55 39.3301L79 35Z" fill="#EDF2F8"/>
                                    <path d="M79 49L55 44.6699L55 53.3301L79 49Z" fill="#EDF2F8"/>
                                    <path d="M79 63L55 58.6699L55 67.3301L79 63Z" fill="#EDF2F8"/>
                                    <path d="M79 77L55 72.6699L55 81.3301L79 77Z" fill="#EDF2F8"/>
                                </svg>
                            </template>
                            <template v-if="size === 5 && mappingBorehole() === 13">
                                <svg width="128" height="105" viewBox="0 0 128 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4828_19759" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V100.971H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V100.971H5V0Z" fill="white"/>
                                    <path d="M13 100.971V103.971H14V100.971H13ZM5 100.971H2V103.971H5V100.971ZM12 0V100.971H14V0H12ZM13 97.9709H5V103.971H13V97.9709ZM8 100.971V0H2V100.971H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4828_19759)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0667 0.24287 59.4118 0.655006 59.7715C1.11871 60.1762 1.60361 60.5994 0.764893 62.7487C-0.329534 64.5746 -0.0304441 64.948 0.325393 65.3923C0.668488 65.8206 1.06434 66.3148 0.314701 68.2404C0.121937 69.3222 0.459179 69.727 0.762211 70.0907C1.17144 70.5818 1.51829 70.9981 0.413117 72.906C0.413067 73.783 0.766251 74.4016 1.05537 74.9079C1.50041 75.6873 1.79366 76.2009 0.413183 76.9822C-0.261059 78.5969 0.324575 79.2944 0.880538 79.9565C1.5746 80.7831 2.22242 81.5546 0.315041 83.987C-0.0646113 85.1467 0.259458 85.7494 0.6077 86.397C1.0994 87.3113 1.63929 88.3153 0.314519 91.1037C0.314519 92.7469 0.790691 93.043 1.20687 93.3017C1.78422 93.6607 2.24611 93.9479 1.16116 97.6603C0.850626 99.9212 1.51406 102.368 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.000185743C1.88571 0.737078 1.19373 2.05712 0.41304 4.29241C-0.152521 5.91175 0.24287 6.27712 0.655007 6.65796C1.11871 7.08646 1.60361 7.53454 0.764893 9.81025C-0.329534 11.7435 -0.030444 12.1389 0.325393 12.6092C0.668488 13.0627 1.06434 13.586 0.314701 15.6248C0.121937 16.7702 0.459179 17.1988 0.762211 17.5838C1.17144 18.1038 1.51829 18.5446 0.413117 20.5647C0.413067 21.4933 0.766251 22.1482 1.05537 22.6843C1.50041 23.5096 1.79366 24.0533 0.413183 24.8806C-0.261059 26.5902 0.324575 27.3287 0.880538 28.0297C1.5746 28.9049 2.22242 29.7217 0.315041 32.2971C-0.0646114 33.5251 0.259459 34.1632 0.6077 34.8488C1.0994 35.817 1.63929 36.88 0.314519 39.8323C0.314519 41.5721 0.790691 41.8856 1.20687 42.1596C1.78422 42.5396 2.24611 42.8437 1.16116 46.7744C0.850626 49.1682 1.51406 51.7593 2.52344 53.4866V0.000185743Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4828_19759" maskUnits="userSpaceOnUse" x="114" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="114" width="12" height="104"/>
                                    <path d="M123 0H115V101H123V0Z"/>
                                    </mask>
                                    <path d="M123 0H115V101H123V0Z" fill="white"/>
                                    <path d="M115 101V104H114V101H115ZM123 101H126V104H123V101ZM116 0V101H114V0H116ZM115 98H123V104H115V98ZM120 101V0H126V101H120Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4828_19759)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 53.4844C126.114 54.1804 126.806 55.4271 127.587 57.5383C128.153 59.0678 127.757 59.4129 127.345 59.7726C126.881 60.1773 126.396 60.6005 127.235 62.7499C128.33 64.5758 128.03 64.9493 127.675 65.3935C127.332 65.8219 126.936 66.3161 127.685 68.2417C127.878 69.3235 127.541 69.7283 127.238 70.092C126.829 70.5831 126.482 70.9994 127.587 72.9074C127.587 73.7844 127.234 74.403 126.945 74.9094C126.5 75.6888 126.206 76.2024 127.587 76.9837C128.261 78.5985 127.675 79.2959 127.119 79.958C126.425 80.7846 125.778 81.5561 127.685 83.9886C128.065 85.1484 127.741 85.751 127.392 86.3986C126.901 87.313 126.361 88.317 127.685 91.1055C127.685 92.7487 127.209 93.0448 126.793 93.3036C126.216 93.6625 125.754 93.9497 126.839 97.6622C127.149 99.9231 126.486 102.37 125.477 104.002V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 -3.61433e-05C126.114 0.736872 126.806 2.05694 127.587 4.29228C128.153 5.91165 127.757 6.27703 127.345 6.65788C126.881 7.08639 126.396 7.53448 127.235 9.81024C128.33 11.7435 128.03 12.1389 127.675 12.6093C127.332 13.0628 126.936 13.5861 127.685 15.6249C127.878 16.7704 127.541 17.1989 127.238 17.584C126.829 18.104 126.482 18.5448 127.587 20.5649C127.587 21.4935 127.234 22.1484 126.945 22.6846C126.5 23.5098 126.206 24.0536 127.587 24.8809C128.261 26.5906 127.675 27.329 127.119 28.0301C126.425 28.9053 125.778 29.7222 127.685 32.2976C128.065 33.5256 127.741 34.1637 127.392 34.8494C126.901 35.8175 126.361 36.8805 127.685 39.8329C127.685 41.5728 127.209 41.8863 126.793 42.1602C126.216 42.5403 125.754 42.8444 126.839 46.7751C127.149 49.169 126.486 51.7602 125.477 53.4875V-3.61433e-05Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4828_19759" maskUnits="userSpaceOnUse" x="106" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="106" width="10" height="104"/>
                                    <path d="M107 0H115V104H107V0Z"/>
                                    </mask>
                                    <path d="M107 0H115V104H107V0Z" fill="white"/>
                                    <path d="M114 0V104H116V0H114ZM108 104V0H106V104H108Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4828_19759)"/>
                                    <mask id="path-11-outside-4_4828_19759" maskUnits="userSpaceOnUse" x="97" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="97" width="10" height="104"/>
                                    <path d="M98 0H106V104H98V0Z"/>
                                    </mask>
                                    <path d="M98 0H106V104H98V0Z" fill="white"/>
                                    <path d="M105 0V104H107V0H105ZM99 104V0H97V104H99Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4828_19759)"/>
                                    <mask id="path-13-outside-5_4828_19759" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4828_19759)"/>
                                    <mask id="path-15-outside-6_4828_19759" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4828_19759)"/>
                                    <mask id="path-17-outside-7_4828_19759" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-17-outside-7_4828_19759)"/>
                                    <mask id="path-19-outside-8_4828_19759" maskUnits="userSpaceOnUse" x="40" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="40" width="10" height="104"/>
                                    <path d="M41 0H49V104H41V0Z"/>
                                    </mask>
                                    <path d="M41 0H49V104H41V0Z" fill="white"/>
                                    <path d="M48 0V104H50V0H48ZM42 104V0H40V104H42Z" fill="#B6C2D0" mask="url(#path-19-outside-8_4828_19759)"/>
                                    <mask id="path-21-outside-9_4828_19759" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-21-outside-9_4828_19759)"/>
                                    <mask id="path-23-outside-10_4828_19759" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-23-outside-10_4828_19759)"/>
                                    <rect x="49" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="112" height="104" viewBox="0 0 110 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4828_19733" maskUnits="userSpaceOnUse" x="97" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="97" width="12" height="104"/>
                                    <path d="M106 0H98V101H106V0Z"/>
                                    </mask>
                                    <path d="M106 0H98V101H106V0Z" fill="white"/>
                                    <path d="M98 101V104H97V101H98ZM106 101H109V104H106V101ZM99 0V101H97V0H99ZM98 98H106V104H98V98ZM103 101V0H109V101H103Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4828_19733)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.001 52C109.639 52.7168 110.33 53.9999 111.11 56.1716C111.676 57.746 111.281 58.1012 110.868 58.4715C110.405 58.8881 109.92 59.3238 110.759 61.5364C111.853 63.416 111.554 63.8004 111.198 64.2578C110.855 64.6987 110.459 65.2075 111.209 67.1897C111.402 68.3034 111.064 68.72 110.761 69.0944C110.352 69.6 110.005 70.0285 111.11 71.9926C111.11 72.8955 110.757 73.5322 110.468 74.0535C110.023 74.8558 109.73 75.3846 111.11 76.1888C111.784 77.8511 111.199 78.5691 110.643 79.2507C109.949 80.1016 109.301 80.8958 111.208 83.3997C111.588 84.5937 111.264 85.214 110.916 85.8807C110.424 86.822 109.884 87.8555 111.209 90.726C111.209 92.4176 110.733 92.7223 110.317 92.9887C109.739 93.3582 109.277 93.6539 110.362 97.4755C110.673 99.8018 110.01 102.32 109.001 103.999V52Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M108.477 -4.91509e-08C109.114 0.758582 109.806 2.11747 110.587 4.41857C111.153 6.08556 110.757 6.46169 110.345 6.85374C109.881 7.29485 109.396 7.75612 110.235 10.0988C111.33 12.089 111.03 12.496 110.675 12.9802C110.332 13.447 109.936 13.9857 110.685 16.0845C110.878 17.2636 110.541 17.7048 110.238 18.1012C109.829 18.6365 109.482 19.0902 110.587 21.1698C110.587 22.1257 110.234 22.7999 109.945 23.3518C109.5 24.2014 109.206 24.7611 110.587 25.6127C111.261 27.3727 110.675 28.1329 110.119 28.8546C109.425 29.7555 108.778 30.5964 110.685 33.2476C111.065 34.5117 110.741 35.1685 110.392 35.8744C109.901 36.871 109.361 37.9653 110.685 41.0045C110.685 42.7956 110.209 43.1183 109.793 43.4003C109.216 43.7916 108.754 44.1046 109.839 48.1509C110.149 50.6152 109.486 53.2826 108.477 55.0607V-4.91509e-08Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4828_19733" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4828_19733)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4835C1.88571 54.1795 1.19373 55.4262 0.41304 57.5374C-0.152521 59.0668 0.24287 59.4119 0.655006 59.7716C1.11871 60.1763 1.60361 60.5995 0.764893 62.7489C-0.329534 64.5748 -0.030444 64.9482 0.325393 65.3925C0.668488 65.8208 1.06434 66.315 0.314701 68.2406C0.121937 69.3225 0.459179 69.7272 0.762211 70.0909C1.17144 70.5821 1.51829 70.9983 0.413117 72.9063C0.413067 73.7833 0.766251 74.4019 1.05537 74.9082C1.50041 75.6877 1.79366 76.2013 0.413183 76.9825C-0.261059 78.5973 0.324575 79.2947 0.880538 79.9569C1.5746 80.7834 2.22242 81.555 0.315041 83.9874C-0.0646113 85.1472 0.259458 85.7498 0.6077 86.3974C1.0994 87.3118 1.63929 88.3158 0.314519 91.1042C0.314519 92.7474 0.790691 93.0435 1.20687 93.3022C1.78422 93.6612 2.24611 93.9484 1.16116 97.6608C0.850626 99.9218 1.51406 102.369 2.52344 104V53.4835Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.000135563C1.88571 0.737036 1.19373 2.05709 0.41304 4.29241C-0.152521 5.91176 0.24287 6.27714 0.655007 6.65799C1.11871 7.08649 1.60361 7.53458 0.764893 9.81031C-0.329534 11.7436 -0.030444 12.1389 0.325393 12.6093C0.668488 13.0628 1.06434 13.5861 0.314701 15.6249C0.121937 16.7704 0.459179 17.1989 0.762211 17.584C1.17144 18.104 1.51829 18.5447 0.413117 20.5649C0.413067 21.4935 0.766251 22.1484 1.05537 22.6845C1.50041 23.5098 1.79366 24.0536 0.413183 24.8808C-0.261059 26.5905 0.324575 27.3289 0.880538 28.03C1.5746 28.9052 2.22242 29.722 0.315041 32.2974C-0.0646114 33.5254 0.259459 34.1635 0.6077 34.8492C1.0994 35.8173 1.63929 36.8803 0.314519 39.8327C0.314519 41.5725 0.790691 41.886 1.20687 42.16C1.78422 42.5401 2.24611 42.8441 1.16116 46.7748C0.850626 49.1687 1.51406 51.7598 2.52344 53.4871V0.000135563Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4828_19733" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4828_19733)"/>
                                    <mask id="path-11-outside-4_4828_19733" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4828_19733)"/>
                                    <mask id="path-13-outside-5_4828_19733" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4828_19733)"/>
                                    <mask id="path-15-outside-6_4828_19733" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4828_19733)"/>
                                    <mask id="path-17-outside-7_4828_19733" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-17-outside-7_4828_19733)"/>
                                    <mask id="path-19-outside-8_4828_19733" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-19-outside-8_4828_19733)"/>
                                    <rect x="40" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="93" height="104" viewBox="0 0 91 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4828_19709" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4828_19709)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4923C1.88571 54.1881 1.19373 55.4346 0.41304 57.5455C-0.152521 59.0746 0.24287 59.4196 0.655006 59.7793C1.11871 60.1839 1.60361 60.607 0.764893 62.756C-0.329534 64.5816 -0.030444 64.955 0.325393 65.3991C0.668488 65.8274 1.06434 66.3215 0.314701 68.2468C0.121937 69.3284 0.459179 69.7331 0.762211 70.0967C1.17144 70.5878 1.51829 71.004 0.413117 72.9116C0.413067 73.7885 0.766251 74.4069 1.05537 74.9132C1.50041 75.6925 1.79366 76.206 0.413183 76.9871C-0.261059 78.6016 0.324575 79.2989 0.880538 79.9609C1.5746 80.7873 2.22242 81.5587 0.315041 83.9907C-0.0646114 85.1503 0.259459 85.7528 0.6077 86.4003C1.0994 87.3145 1.63929 88.3183 0.314519 91.1062C0.314519 92.7492 0.790691 93.0452 1.20687 93.3039C1.78422 93.6628 2.24611 93.95 1.16116 97.6617C0.850626 99.9222 1.51406 102.369 2.52344 104V53.4923Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.0195693C1.88571 0.756338 1.19373 2.07616 0.41304 4.31108C-0.152521 5.93014 0.24287 6.29545 0.655007 6.67623C1.11871 7.10466 1.60361 7.55267 0.764893 9.82799C-0.329534 11.7609 -0.030444 12.1562 0.325393 12.6265C0.668488 13.0799 1.06434 13.6031 0.314701 15.6415C0.121937 16.7868 0.459179 17.2153 0.762211 17.6003C1.17144 18.1202 1.51829 18.5609 0.413117 20.5806C0.413067 21.5091 0.766251 22.1639 1.05537 22.6999C1.50041 23.525 1.79366 24.0687 0.413183 24.8958C-0.261059 26.6052 0.324575 27.3435 0.880538 28.0444C1.5746 28.9194 2.22242 29.7362 0.315041 32.3111C-0.0646113 33.5389 0.259458 34.1768 0.6077 34.8624C1.0994 35.8304 1.63929 36.8932 0.314519 39.845C0.314519 41.5846 0.790691 41.898 1.20687 42.1719C1.78422 42.5519 2.24611 42.8559 1.16116 46.7859C0.850626 49.1793 1.51406 51.77 2.52344 53.497V0.0195693Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4828_19709" maskUnits="userSpaceOnUse" x="79" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="79" width="12" height="104"/>
                                    <path d="M88 0H80V101H88V0Z"/>
                                    </mask>
                                    <path d="M88 0H80V101H88V0Z" fill="white"/>
                                    <path d="M80 101V104H79V101H80ZM88 101H91V104H88V101ZM81 0V101H79V0H81ZM80 98H88V104H80V98ZM85 101V0H91V101H85Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4828_19709)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 53.4826C91.1143 54.1786 91.8063 55.4253 92.587 57.5365C93.1525 59.066 92.7571 59.4111 92.345 59.7708C91.8813 60.1755 91.3964 60.5987 92.2351 62.748C93.3295 64.574 93.0304 64.9474 92.6746 65.3916C92.3315 65.82 91.9357 66.3142 92.6853 68.2398C92.8781 69.3216 92.5408 69.7264 92.2378 70.0901C91.8286 70.5812 91.4817 70.9975 92.5869 72.9054C92.5869 73.7825 92.2337 74.401 91.9446 74.9074C91.4996 75.6868 91.2063 76.2004 92.5868 76.9817C93.2611 78.5965 92.6754 79.2939 92.1195 79.956C91.4254 80.7826 90.7776 81.5541 92.685 83.9865C93.0646 85.1463 92.7405 85.749 92.3923 86.3966C91.9006 87.311 91.3607 88.315 92.6855 91.1034C92.6855 92.7466 92.2093 93.0427 91.7931 93.3014C91.2158 93.6604 90.7539 93.9476 91.8388 97.66C92.1494 99.921 91.4859 102.368 90.4766 104V53.4826Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 0.000203302C91.1143 0.737105 91.8063 2.05716 92.587 4.29249C93.1525 5.91184 92.7571 6.27722 92.345 6.65807C91.8813 7.08657 91.3964 7.53466 92.2351 9.81039C93.3295 11.7437 93.0304 12.139 92.6746 12.6094C92.3315 13.0629 91.9357 13.5862 92.6853 15.625C92.8781 16.7705 92.5408 17.199 92.2378 17.5841C91.8286 18.1041 91.4817 18.5448 92.5869 20.565C92.5869 21.4936 92.2337 22.1485 91.9446 22.6846C91.4996 23.5099 91.2063 24.0537 92.5868 24.8809C93.2611 26.5906 92.6754 27.3291 92.1195 28.0301C91.4254 28.9053 90.7776 29.7221 92.685 32.2976C93.0646 33.5255 92.7405 34.1636 92.3923 34.8493C91.9006 35.8174 91.3607 36.8805 92.6855 39.8328C92.6855 41.5727 92.2093 41.8861 91.7931 42.1601C91.2158 42.5402 90.7539 42.8443 91.8388 46.775C92.1494 49.1688 91.4859 51.76 90.4766 53.4873V0.000203302Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4828_19709" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4828_19709)"/>
                                    <mask id="path-11-outside-4_4828_19709" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4828_19709)"/>
                                    <mask id="path-13-outside-5_4828_19709" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4828_19709)"/>
                                    <mask id="path-15-outside-6_4828_19709" maskUnits="userSpaceOnUse" x="61" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="61" width="10" height="104"/>
                                    <path d="M62 0H70V104H62V0Z"/>
                                    </mask>
                                    <path d="M62 0H70V104H62V0Z" fill="white"/>
                                    <path d="M69 0V104H71V0H69ZM63 104V0H61V104H63Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4828_19709)"/>
                                    <rect x="31" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="75" height="104" viewBox="0 0 73 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="14" y="101" width="47" height="3" fill="#B6C2D0"/>
                                    <mask id="path-2-outside-1_4828_19684" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-2-outside-1_4828_19684)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4844C1.88571 54.1803 1.19373 55.4271 0.41304 57.5383C-0.152521 59.0677 0.24287 59.4128 0.655007 59.7725C1.11871 60.1772 1.60361 60.6004 0.764893 62.7497C-0.329534 64.5756 -0.030444 64.949 0.325393 65.3933C0.668488 65.8216 1.06434 66.3158 0.314701 68.2414C0.121937 69.3232 0.459179 69.7279 0.762211 70.0916C1.17144 70.5828 1.51829 70.999 0.413117 72.907C0.413067 73.784 0.766251 74.4025 1.05537 74.9089C1.50041 75.6883 1.79366 76.2019 0.413183 76.9832C-0.261059 78.5979 0.324575 79.2954 0.880538 79.9575C1.5746 80.784 2.22242 81.5555 0.315041 83.9879C-0.0646114 85.1477 0.259459 85.7503 0.6077 86.3979C1.0994 87.3123 1.63929 88.3163 0.314519 91.1047C0.314519 92.7479 0.790691 93.044 1.20687 93.3027C1.78422 93.6617 2.24611 93.9489 1.16116 97.6612C0.850626 99.9221 1.51406 102.369 2.52344 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.000203383C1.88571 0.737096 1.19373 2.05713 0.41304 4.29243C-0.152521 5.91176 0.24287 6.27713 0.655007 6.65798C1.11871 7.08648 1.60361 7.53456 0.764893 9.81027C-0.329534 11.7435 -0.030444 12.1389 0.325393 12.6092C0.668488 13.0628 1.06434 13.586 0.314701 15.6248C0.121937 16.7702 0.459179 17.1988 0.762211 17.5838C1.17144 18.1039 1.51829 18.5446 0.413117 20.5647C0.413067 21.4933 0.766251 22.1482 1.05537 22.6843C1.50041 23.5096 1.79366 24.0534 0.413183 24.8806C-0.261059 26.5902 0.324575 27.3287 0.880538 28.0297C1.5746 28.9049 2.22242 29.7218 0.315041 32.2971C-0.0646114 33.5251 0.259459 34.1632 0.6077 34.8489C1.0994 35.817 1.63929 36.88 0.314519 39.8323C0.314519 41.5721 0.790691 41.8856 1.20687 42.1596C1.78422 42.5396 2.24611 42.8437 1.16116 46.7744C0.850626 49.1682 1.51406 51.7593 2.52344 53.4866V0.000203383Z" fill="#B6C2D0"/>
                                    <mask id="path-6-outside-2_4828_19684" maskUnits="userSpaceOnUse" x="61" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="61" width="12" height="104"/>
                                    <path d="M70 0H62V101H70V0Z"/>
                                    </mask>
                                    <path d="M70 0H62V101H70V0Z" fill="white"/>
                                    <path d="M62 101V104H61V101H62ZM70 101H73V104H70V101ZM63 0V101H61V0H63ZM62 98H70V104H62V98ZM67 101V0H73V101H67Z" fill="#B6C2D0" mask="url(#path-6-outside-2_4828_19684)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 53.4844C73.1143 54.1803 73.8063 55.4271 74.587 57.5383C75.1525 59.0677 74.7571 59.4128 74.345 59.7725C73.8813 60.1772 73.3964 60.6004 74.2351 62.7497C75.3295 64.5756 75.0304 64.949 74.6746 65.3933C74.3315 65.8216 73.9357 66.3158 74.6853 68.2414C74.8781 69.3232 74.5408 69.7279 74.2378 70.0916C73.8286 70.5828 73.4817 70.999 74.5869 72.907C74.5869 73.784 74.2337 74.4025 73.9446 74.9089C73.4996 75.6883 73.2063 76.2019 74.5868 76.9832C75.2611 78.5979 74.6754 79.2954 74.1195 79.9575C73.4254 80.784 72.7776 81.5555 74.685 83.9879C75.0646 85.1477 74.7405 85.7503 74.3923 86.3979C73.9006 87.3123 73.3607 88.3163 74.6855 91.1047C74.6855 92.7479 74.2093 93.044 73.7931 93.3027C73.2158 93.6617 72.7539 93.9489 73.8388 97.6612C74.1494 99.9221 73.4859 102.369 72.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 0.000203383C73.1143 0.737096 73.8063 2.05713 74.587 4.29243C75.1525 5.91176 74.7571 6.27713 74.345 6.65798C73.8813 7.08648 73.3964 7.53456 74.2351 9.81027C75.3295 11.7435 75.0304 12.1389 74.6746 12.6092C74.3315 13.0628 73.9357 13.586 74.6853 15.6248C74.8781 16.7702 74.5408 17.1988 74.2378 17.5838C73.8286 18.1039 73.4817 18.5446 74.5869 20.5647C74.5869 21.4933 74.2337 22.1482 73.9446 22.6843C73.4996 23.5096 73.2063 24.0534 74.5868 24.8806C75.2611 26.5902 74.6754 27.3287 74.1195 28.0297C73.4254 28.9049 72.7776 29.7218 74.685 32.2971C75.0646 33.5251 74.7405 34.1632 74.3923 34.8489C73.9006 35.817 73.3607 36.88 74.6855 39.8323C74.6855 41.5721 74.2093 41.8856 73.7931 42.1596C73.2158 42.5396 72.7539 42.8437 73.8388 46.7744C74.1494 49.1682 73.4859 51.7593 72.4766 53.4866V0.000203383Z" fill="#B6C2D0"/>
                                    <mask id="path-10-outside-3_4828_19684" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-10-outside-3_4828_19684)"/>
                                    <mask id="path-12-outside-4_4828_19684" maskUnits="userSpaceOnUse" x="52" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="52" width="10" height="104"/>
                                    <path d="M53 0H61V104H53V0Z"/>
                                    </mask>
                                    <path d="M53 0H61V104H53V0Z" fill="white"/>
                                    <path d="M60 0V104H62V0H60ZM54 104V0H52V104H54Z" fill="#B6C2D0" mask="url(#path-12-outside-4_4828_19684)"/>
                                    <rect x="22" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="57" height="104" viewBox="0 0 55 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4828_19661" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4828_19661)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4844C1.88571 54.1803 1.19373 55.4271 0.41304 57.5383C-0.152521 59.0677 0.24287 59.4128 0.655007 59.7725C1.11871 60.1772 1.60361 60.6004 0.764893 62.7497C-0.329534 64.5756 -0.030444 64.949 0.325393 65.3933C0.668488 65.8216 1.06434 66.3158 0.314701 68.2414C0.121937 69.3232 0.459179 69.7279 0.762211 70.0916C1.17144 70.5828 1.51829 70.999 0.413117 72.907C0.413067 73.784 0.766251 74.4025 1.05537 74.9089C1.50041 75.6883 1.79366 76.2019 0.413183 76.9832C-0.261059 78.5979 0.324575 79.2954 0.880538 79.9575C1.5746 80.784 2.22242 81.5555 0.315041 83.9879C-0.0646114 85.1477 0.259459 85.7503 0.6077 86.3979C1.0994 87.3123 1.63929 88.3163 0.314519 91.1047C0.314519 92.7479 0.790691 93.044 1.20687 93.3027C1.78422 93.6617 2.24611 93.9489 1.16116 97.6612C0.850626 99.9221 1.51406 102.369 2.52344 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.000203383C1.88571 0.737096 1.19373 2.05713 0.41304 4.29243C-0.152521 5.91176 0.24287 6.27713 0.655007 6.65798C1.11871 7.08648 1.60361 7.53456 0.764893 9.81027C-0.329534 11.7435 -0.030444 12.1389 0.325393 12.6092C0.668488 13.0628 1.06434 13.586 0.314701 15.6248C0.121937 16.7702 0.459179 17.1988 0.762211 17.5838C1.17144 18.1039 1.51829 18.5446 0.413117 20.5647C0.413067 21.4933 0.766251 22.1482 1.05537 22.6843C1.50041 23.5096 1.79366 24.0534 0.413183 24.8806C-0.261059 26.5902 0.324575 27.3287 0.880538 28.0297C1.5746 28.9049 2.22242 29.7218 0.315041 32.2971C-0.0646114 33.5251 0.259459 34.1632 0.6077 34.8489C1.0994 35.817 1.63929 36.88 0.314519 39.8323C0.314519 41.5721 0.790691 41.8856 1.20687 42.1596C1.78422 42.5396 2.24611 42.8437 1.16116 46.7744C0.850626 49.1682 1.51406 51.7593 2.52344 53.4866V0.000203383Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4828_19661" maskUnits="userSpaceOnUse" x="43" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="43" width="12" height="104"/>
                                    <path d="M52 0H44V101H52V0Z"/>
                                    </mask>
                                    <path d="M52 0H44V101H52V0Z" fill="white"/>
                                    <path d="M44 101V104H43V101H44ZM52 101H55V104H52V101ZM45 0V101H43V0H45ZM44 98H52V104H44V98ZM49 101V0H55V101H49Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4828_19661)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.4766 53.4844C55.1143 54.1803 55.8063 55.4271 56.587 57.5383C57.1525 59.0677 56.7571 59.4128 56.345 59.7725C55.8813 60.1772 55.3964 60.6004 56.2351 62.7497C57.3295 64.5756 57.0304 64.949 56.6746 65.3933C56.3315 65.8216 55.9357 66.3158 56.6853 68.2414C56.8781 69.3232 56.5408 69.7279 56.2378 70.0916C55.8286 70.5828 55.4817 70.999 56.5869 72.907C56.5869 73.784 56.2337 74.4025 55.9446 74.9089C55.4996 75.6883 55.2063 76.2019 56.5868 76.9832C57.2611 78.5979 56.6754 79.2954 56.1195 79.9575C55.4254 80.784 54.7776 81.5555 56.685 83.9879C57.0646 85.1477 56.7405 85.7503 56.3923 86.3979C55.9006 87.3123 55.3607 88.3163 56.6855 91.1047C56.6855 92.7479 56.2093 93.044 55.7931 93.3027C55.2158 93.6617 54.7539 93.9489 55.8388 97.6612C56.1494 99.9221 55.4859 102.369 54.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.4766 0.000203383C55.1143 0.737096 55.8063 2.05713 56.587 4.29243C57.1525 5.91176 56.7571 6.27713 56.345 6.65798C55.8813 7.08648 55.3964 7.53456 56.2351 9.81027C57.3295 11.7435 57.0304 12.1389 56.6746 12.6092C56.3315 13.0628 55.9357 13.586 56.6853 15.6248C56.8781 16.7702 56.5408 17.1988 56.2378 17.5838C55.8286 18.1039 55.4817 18.5446 56.5869 20.5647C56.5869 21.4933 56.2337 22.1482 55.9446 22.6843C55.4996 23.5096 55.2063 24.0534 56.5868 24.8806C57.2611 26.5902 56.6754 27.3287 56.1195 28.0297C55.4254 28.9049 54.7776 29.7218 56.685 32.2971C57.0646 33.5251 56.7405 34.1632 56.3923 34.8489C55.9006 35.817 55.3607 36.88 56.6855 39.8323C56.6855 41.5721 56.2093 41.8856 55.7931 42.1596C55.2158 42.5396 54.7539 42.8437 55.8388 46.7744C56.1494 49.1682 55.4859 51.7593 54.4766 53.4866V0.000203383Z" fill="#B6C2D0"/>
                                    <rect x="13" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="36" height="107" viewBox="0 0 36 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M25 0L25 31.7783L34 31.7783L34 30.7525C33.4783 30.027 32.9122 28.7275 32.2735 26.5272C31.8108 24.9331 32.1343 24.5734 32.4715 24.1985C32.8509 23.7766 33.2476 23.3355 32.5614 21.0952C31.6659 19.192 31.9106 18.8028 32.2018 18.3397C32.4825 17.8933 32.8064 17.3781 32.193 15.3711C32.0353 14.2434 32.3112 13.8216 32.5592 13.4425C32.894 12.9306 33.1778 12.4967 32.2736 10.508C32.2735 9.59383 32.5625 8.9491 32.799 8.42131C33.1632 7.60889 33.4031 7.07356 32.2736 6.25922C31.722 4.57613 32.2011 3.84916 32.656 3.15902C33.1376 2.42837 33.5919 1.739 32.7671 -3.26935e-07L25 0Z" fill="#EDF2F8"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M25 31.7764L25 63.5547L35 63.5547L35 62.5294C34.4204 61.8039 33.7915 60.5045 33.082 58.3046C32.5678 56.7104 32.9273 56.3507 33.3019 55.9758C33.7235 55.554 34.1643 55.1129 33.4018 52.8725C32.4069 50.9693 32.6788 50.5801 33.0023 50.1171C33.3142 49.6706 33.674 49.1555 32.9926 47.1484C32.8173 46.0208 33.1239 45.5989 33.3994 45.2198C33.7714 44.7079 34.0867 44.274 33.082 42.2853C33.082 41.3712 33.4031 40.7264 33.6659 40.1986C34.0705 39.3862 34.3371 38.8509 33.0821 38.0366C32.4691 36.3535 33.0015 35.6265 33.507 34.9364C34.0421 34.2056 34.5471 33.5161 33.6299 31.7764L25 31.7764Z" fill="#EDF2F8"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M25 63.5557L25 104L36 104L36 102.695C35.3623 101.772 34.6704 100.118 33.8898 97.3179C33.3242 95.2889 33.7196 94.8312 34.1318 94.354C34.5955 93.8171 35.0804 93.2557 34.2416 90.4044C33.1472 87.9821 33.4463 87.4868 33.8021 86.8974C34.1452 86.3292 34.5411 85.6736 33.7915 83.1191C33.5987 81.684 33.9359 81.147 34.239 80.6646C34.6482 80.013 34.995 79.4608 33.8899 76.9297C33.8898 75.7663 34.243 74.9457 34.5321 74.274C34.9772 73.24 35.2704 72.5586 33.8899 71.5222C33.2157 69.3801 33.8013 68.4549 34.3573 67.5765C34.9459 66.6465 35.5013 65.7691 34.4929 63.5557L25 63.5557Z" fill="#EDF2F8"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99951 0L8.99951 31.7783L1.63588 31.7783L1.63588 30.7523C2.06272 30.0268 2.52588 28.7274 3.04839 26.5272C3.42699 24.9331 3.16231 24.5734 2.88641 24.1985C2.576 23.7766 2.2514 23.3355 2.81285 21.0952C3.54549 19.192 3.34527 18.8028 3.10706 18.3397C2.87739 17.8933 2.6124 17.3781 3.11422 15.3711C3.24326 14.2434 3.0175 13.8216 2.81465 13.4425C2.5407 12.9306 2.30852 12.4967 3.04834 10.508C3.04837 9.59383 2.81194 8.9491 2.6184 8.42131C2.32048 7.60889 2.12417 7.07356 3.0483 6.25922C3.49965 4.57613 3.10761 3.84916 2.73544 3.15902C2.34141 2.42837 1.96965 1.739 2.64456 -2.94245e-07L8.99951 0Z" fill="#EDF2F8"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 31.7764L9 63.5547L0.818007 63.5547L0.818009 31.7764L0.818527 31.7764L0.818527 62.5296C1.29279 61.8041 1.8074 60.5046 2.38797 58.3046C2.80864 56.7104 2.51454 56.3507 2.20799 55.9758C1.86308 55.554 1.5024 55.1129 2.12625 52.8725C2.94031 50.9693 2.71784 50.5801 2.45316 50.1171C2.19796 49.6706 1.90352 49.1555 2.46112 47.1484C2.6045 46.0208 2.35365 45.5989 2.12825 45.2198C1.82386 44.7079 1.56587 44.274 2.38791 42.2853C2.38795 41.3712 2.12525 40.7264 1.91019 40.1987C1.57916 39.3862 1.36104 38.8509 2.38786 38.0366C2.88938 36.3535 2.45377 35.6265 2.04024 34.9364C1.60234 34.2056 1.18919 33.5161 1.93968 31.7764L9 31.7764Z" fill="#EDF2F8"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99951 63.5557L8.99951 104L-0.000175476 104L-0.000172067 63.5557L-1.37567e-05 63.5557L-1.6164e-05 102.696C0.521747 101.773 1.0879 100.119 1.72663 97.3179C2.18935 95.2889 1.86586 94.8312 1.52867 94.354C1.14929 93.8171 0.752563 93.2557 1.43876 90.4044C2.33417 87.9821 2.08947 87.4868 1.79834 86.8974C1.51764 86.3292 1.19377 85.6736 1.80709 83.1191C1.9648 81.6839 1.68888 81.147 1.44096 80.6646C1.10614 80.013 0.82237 79.4608 1.72657 76.9297C1.72661 75.7663 1.43765 74.9457 1.20111 74.274C0.836995 73.24 0.597072 72.5586 1.72652 71.5222C2.27815 69.3801 1.79901 68.4549 1.34415 67.5765C0.86256 66.6465 0.408184 65.7691 1.23317 63.5557L8.99951 63.5557Z" fill="#EDF2F8"/>
                                    <rect x="9" width="16" height="104" fill="#EDF2F8"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 103H36V105.82C35.6816 105.831 35.3472 105.888 35 106C32.0429 107.372 30.8774 106.744 29.688 106.104C29.0048 105.736 28.3138 105.364 27.2709 105.364C25.239 106.896 24.5074 106.272 23.841 105.703C23.3691 105.3 22.93 104.925 22.0848 105.364C20.3123 107.571 19.75 106.821 19.1477 106.019C18.6652 105.375 18.157 104.698 16.9803 105.478C16.4109 107.075 16.0367 106.736 15.4687 106.221C15.0997 105.886 14.6489 105.478 14.0098 105.478C12.6195 106.756 12.3161 106.355 11.9582 105.882C11.6932 105.531 11.3982 105.141 10.6099 105.364C9.21958 106.223 8.59111 105.658 8.01161 105.137C7.39961 104.587 6.84222 104.086 5.49982 105.363C3.38031 106.676 2.74643 106.236 1.64626 105.471C1.21086 105.169 0.70244 104.815 0 104.5V103Z" fill="#EDF2F8"/>
                                </svg>
                            </template>
                            <template v-if="size === 5 && mappingBorehole() === 23">
                                <svg width="128" height="105" viewBox="0 0 128 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26385" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V100.971H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V100.971H5V0Z" fill="white"/>
                                    <path d="M13 100.971V103.971H14V100.971H13ZM5 100.971H2V103.971H5V100.971ZM12 0V100.971H14V0H12ZM13 97.9709H5V103.971H13V97.9709ZM8 100.971V0H2V100.971H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26385)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0667 0.24287 59.4118 0.655007 59.7715C1.11871 60.1762 1.60361 60.5994 0.764893 62.7487C-0.329534 64.5746 -0.030444 64.948 0.325393 65.3923C0.668488 65.8206 1.06434 66.3148 0.314701 68.2404C0.121937 69.3222 0.459179 69.727 0.762211 70.0907C1.17144 70.5818 1.51829 70.9981 0.413117 72.906C0.413067 73.783 0.766251 74.4016 1.05537 74.9079C1.50041 75.6873 1.79366 76.2009 0.413183 76.9822C-0.261059 78.5969 0.324575 79.2944 0.880538 79.9565C1.5746 80.7831 2.22242 81.5546 0.315041 83.987C-0.0646113 85.1467 0.259458 85.7494 0.6077 86.397C1.0994 87.3113 1.63929 88.3153 0.314519 91.1037C0.314519 92.7469 0.790691 93.043 1.20687 93.3017C1.78422 93.6607 2.24611 93.9479 1.16116 97.6603C0.850626 99.9212 1.51406 102.368 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.0304441 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.2969C-0.0646112 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26385" maskUnits="userSpaceOnUse" x="114" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="114" width="12" height="104"/>
                                    <path d="M123 0H115V101H123V0Z"/>
                                    </mask>
                                    <path d="M123 0H115V101H123V0Z" fill="white"/>
                                    <path d="M115 101V104H114V101H115ZM123 101H126V104H123V101ZM116 0V101H114V0H116ZM115 98H123V104H115V98ZM120 101V0H126V101H120Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26385)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 53.4844C126.114 54.1804 126.806 55.4271 127.587 57.5383C128.153 59.0678 127.757 59.4129 127.345 59.7726C126.881 60.1773 126.396 60.6005 127.235 62.7499C128.33 64.5758 128.03 64.9493 127.675 65.3935C127.332 65.8219 126.936 66.3161 127.685 68.2417C127.878 69.3235 127.541 69.7283 127.238 70.092C126.829 70.5831 126.482 70.9994 127.587 72.9074C127.587 73.7844 127.234 74.403 126.945 74.9094C126.5 75.6888 126.206 76.2024 127.587 76.9837C128.261 78.5985 127.675 79.2959 127.119 79.958C126.425 80.7846 125.778 81.5561 127.685 83.9886C128.065 85.1484 127.741 85.751 127.392 86.3986C126.901 87.313 126.361 88.317 127.685 91.1055C127.685 92.7487 127.209 93.0448 126.793 93.3036C126.216 93.6625 125.754 93.9497 126.839 97.6622C127.149 99.9231 126.486 102.37 125.477 104.002V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 0C126.114 0.736908 126.806 2.05697 127.587 4.29232C128.153 5.91169 127.757 6.27707 127.345 6.65792C126.881 7.08642 126.396 7.53452 127.235 9.81027C128.33 11.7436 128.03 12.1389 127.675 12.6093C127.332 13.0628 126.936 13.5861 127.685 15.6249C127.878 16.7704 127.541 17.1989 127.238 17.584C126.829 18.104 126.482 18.5448 127.587 20.5649C127.587 21.4935 127.234 22.1485 126.945 22.6846C126.5 23.5099 126.206 24.0537 127.587 24.8809C128.261 26.5906 127.675 27.3291 127.119 28.0301C126.425 28.9053 125.778 29.7222 127.685 32.2976C128.065 33.5256 127.741 34.1637 127.392 34.8494C126.901 35.8175 126.361 36.8806 127.685 39.8329C127.685 41.5728 127.209 41.8863 126.793 42.1603C126.216 42.5403 125.754 42.8444 126.839 46.7752C127.149 49.169 126.486 51.7602 125.477 53.4876V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4813_26385" maskUnits="userSpaceOnUse" x="106" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="106" width="10" height="104"/>
                                    <path d="M107 0H115V104H107V0Z"/>
                                    </mask>
                                    <path d="M107 0H115V104H107V0Z" fill="white"/>
                                    <path d="M114 0V104H116V0H114ZM108 104V0H106V104H108Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4813_26385)"/>
                                    <mask id="path-11-outside-4_4813_26385" maskUnits="userSpaceOnUse" x="97" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="97" width="10" height="104"/>
                                    <path d="M98 0H106V104H98V0Z"/>
                                    </mask>
                                    <path d="M98 0H106V104H98V0Z" fill="white"/>
                                    <path d="M105 0V104H107V0H105ZM99 104V0H97V104H99Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26385)"/>
                                    <mask id="path-13-outside-5_4813_26385" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4813_26385)"/>
                                    <mask id="path-15-outside-6_4813_26385" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4813_26385)"/>
                                    <mask id="path-17-outside-7_4813_26385" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-17-outside-7_4813_26385)"/>
                                    <mask id="path-19-outside-8_4813_26385" maskUnits="userSpaceOnUse" x="40" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="40" width="10" height="104"/>
                                    <path d="M41 0H49V104H41V0Z"/>
                                    </mask>
                                    <path d="M41 0H49V104H41V0Z" fill="white"/>
                                    <path d="M48 0V104H50V0H48ZM42 104V0H40V104H42Z" fill="#B6C2D0" mask="url(#path-19-outside-8_4813_26385)"/>
                                    <mask id="path-21-outside-9_4813_26385" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-21-outside-9_4813_26385)"/>
                                    <mask id="path-23-outside-10_4813_26385" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-23-outside-10_4813_26385)"/>
                                    <rect x="49" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="112" height="104" viewBox="0 0 110 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26359" maskUnits="userSpaceOnUse" x="97" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="97" width="12" height="104"/>
                                    <path d="M106 0H98V101H106V0Z"/>
                                    </mask>
                                    <path d="M106 0H98V101H106V0Z" fill="white"/>
                                    <path d="M98 101V104H97V101H98ZM106 101H109V104H106V101ZM99 0V101H97V0H99ZM98 98H106V104H98V98ZM103 101V0H109V101H103Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26359)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.001 52C109.639 52.7168 110.33 53.9999 111.11 56.1716C111.676 57.746 111.281 58.1012 110.868 58.4715C110.405 58.8881 109.92 59.3238 110.759 61.5364C111.853 63.416 111.554 63.8004 111.198 64.2578C110.855 64.6987 110.459 65.2075 111.209 67.1897C111.402 68.3034 111.064 68.72 110.761 69.0944C110.352 69.6 110.005 70.0285 111.11 71.9926C111.11 72.8955 110.757 73.5322 110.468 74.0535C110.023 74.8558 109.73 75.3846 111.11 76.1888C111.784 77.8511 111.199 78.5691 110.643 79.2507C109.949 80.1016 109.301 80.8958 111.208 83.3997C111.588 84.5937 111.264 85.214 110.916 85.8807C110.424 86.822 109.884 87.8555 111.209 90.726C111.209 92.4176 110.733 92.7223 110.317 92.9887C109.739 93.3582 109.277 93.6539 110.362 97.4755C110.673 99.8018 110.01 102.32 109.001 103.999V52Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M108.477 0C109.114 0.758582 109.806 2.11747 110.587 4.41857C111.153 6.08556 110.757 6.46169 110.345 6.85374C109.881 7.29485 109.396 7.75612 110.235 10.0988C111.33 12.089 111.03 12.496 110.675 12.9802C110.332 13.447 109.936 13.9857 110.685 16.0845C110.878 17.2636 110.541 17.7048 110.238 18.1012C109.829 18.6365 109.482 19.0902 110.587 21.1698C110.587 22.1257 110.234 22.7999 109.945 23.3518C109.5 24.2014 109.206 24.7611 110.587 25.6127C111.261 27.3727 110.675 28.1329 110.119 28.8546C109.425 29.7555 108.778 30.5964 110.685 33.2476C111.065 34.5117 110.741 35.1685 110.392 35.8744C109.901 36.871 109.361 37.9653 110.685 41.0045C110.685 42.7956 110.209 43.1183 109.793 43.4003C109.216 43.7916 108.754 44.1046 109.839 48.1509C110.149 50.6152 109.486 53.2826 108.477 55.0607V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26359" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26359)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0668 0.24287 59.4118 0.655007 59.7716C1.11871 60.1763 1.60361 60.5995 0.764893 62.7488C-0.329534 64.5748 -0.030444 64.9482 0.325393 65.3924C0.668488 65.8208 1.06434 66.315 0.314701 68.2405C0.121937 69.3224 0.459179 69.7271 0.762211 70.0908C1.17144 70.582 1.51829 70.9983 0.413117 72.9062C0.413067 73.7832 0.766251 74.4018 1.05537 74.9082C1.50041 75.6876 1.79366 76.2012 0.413183 76.9825C-0.261059 78.5972 0.324575 79.2947 0.880538 79.9568C1.5746 80.7834 2.22242 81.5549 0.315041 83.9873C-0.0646114 85.1471 0.259459 85.7497 0.6077 86.3973C1.0994 87.3117 1.63929 88.3157 0.314519 91.1041C0.314519 92.7474 0.790691 93.0434 1.20687 93.3022C1.78422 93.6611 2.24611 93.9483 1.16116 97.6608C0.850626 99.9217 1.51406 102.369 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736901 1.19373 2.05695 0.41304 4.29228C-0.152521 5.91163 0.24287 6.277 0.655007 6.65785C1.11871 7.08635 1.60361 7.53444 0.764893 9.81017C-0.329534 11.7434 -0.030444 12.1388 0.325393 12.6092C0.668488 13.0627 1.06434 13.586 0.314701 15.6248C0.121937 16.7702 0.459179 17.1988 0.762211 17.5838C1.17144 18.1039 1.51829 18.5446 0.413117 20.5647C0.413067 21.4933 0.766251 22.1483 1.05537 22.6844C1.50041 23.5096 1.79366 24.0534 0.413183 24.8806C-0.261059 26.5903 0.324575 27.3288 0.880538 28.0298C1.5746 28.905 2.22242 29.7219 0.315041 32.2973C-0.0646115 33.5253 0.259459 34.1634 0.6077 34.849C1.0994 35.8172 1.63929 36.8802 0.314519 39.8325C0.314519 41.5724 0.790691 41.8859 1.20687 42.1598C1.78422 42.5399 2.24611 42.844 1.16116 46.7747C0.850626 49.1685 1.51406 51.7597 2.52344 53.487V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4813_26359" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4813_26359)"/>
                                    <mask id="path-11-outside-4_4813_26359" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26359)"/>
                                    <mask id="path-13-outside-5_4813_26359" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4813_26359)"/>
                                    <mask id="path-15-outside-6_4813_26359" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4813_26359)"/>
                                    <mask id="path-17-outside-7_4813_26359" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-17-outside-7_4813_26359)"/>
                                    <mask id="path-19-outside-8_4813_26359" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-19-outside-8_4813_26359)"/>
                                    <rect x="40" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="93" height="104" viewBox="0 0 91 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_4813_26335" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_4813_26335)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4922C1.88571 54.188 1.19373 55.4346 0.41304 57.5454C-0.152521 59.0746 0.24287 59.4196 0.655007 59.7792C1.11871 60.1839 1.60361 60.607 0.764893 62.756C-0.329534 64.5816 -0.030444 64.9549 0.325393 65.3991C0.668488 65.8273 1.06434 66.3215 0.314701 68.2467C0.121937 69.3284 0.459179 69.733 0.762211 70.0967C1.17144 70.5877 1.51829 71.0039 0.413117 72.9115C0.413067 73.7884 0.766251 74.4068 1.05537 74.9131C1.50041 75.6924 1.79366 76.2059 0.413183 76.9871C-0.261059 78.6015 0.324575 79.2989 0.880538 79.9609C1.5746 80.7873 2.22242 81.5587 0.315041 83.9906C-0.0646114 85.1502 0.259459 85.7528 0.6077 86.4002C1.0994 87.3145 1.63929 88.3183 0.314519 91.1062C0.314519 92.7491 0.790691 93.0451 1.20687 93.3039C1.78422 93.6628 2.24611 93.9499 1.16116 97.6617C0.850626 99.9222 1.51406 102.369 2.52344 104V53.4922Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.0195312C1.88571 0.7563 1.19373 2.07612 0.41304 4.31104C-0.152521 5.9301 0.24287 6.29541 0.655007 6.67619C1.11871 7.10462 1.60361 7.55263 0.764893 9.82795C-0.329534 11.7609 -0.030444 12.1562 0.325393 12.6265C0.668488 13.0799 1.06434 13.6031 0.314701 15.6415C0.121937 16.7868 0.459179 17.2152 0.762211 17.6002C1.17144 18.1202 1.51829 18.5608 0.413117 20.5806C0.413067 21.509 0.766251 22.1638 1.05537 22.6999C1.50041 23.525 1.79366 24.0687 0.413183 24.8957C-0.261059 26.6051 0.324575 27.3435 0.880538 28.0444C1.5746 28.9194 2.22242 29.7361 0.315041 32.3111C-0.0646113 33.5388 0.259458 34.1768 0.6077 34.8624C1.0994 35.8303 1.63929 36.8931 0.314519 39.845C0.314519 41.5845 0.790691 41.8979 1.20687 42.1718C1.78422 42.5519 2.24611 42.8559 1.16116 46.7859C0.850626 49.1793 1.51406 51.77 2.52344 53.497V0.0195312Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_4813_26335" maskUnits="userSpaceOnUse" x="79" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="79" width="12" height="104"/>
                                    <path d="M88 0H80V101H88V0Z"/>
                                    </mask>
                                    <path d="M88 0H80V101H88V0Z" fill="white"/>
                                    <path d="M80 101V104H79V101H80ZM88 101H91V104H88V101ZM81 0V101H79V0H81ZM80 98H88V104H80V98ZM85 101V0H91V101H85Z" fill="#B6C2D0" mask="url(#path-5-outside-2_4813_26335)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 53.4824C91.1143 54.1784 91.8063 55.4252 92.587 57.5364C93.1525 59.0658 92.7571 59.4109 92.345 59.7706C91.8813 60.1753 91.3964 60.5985 92.2351 62.7479C93.3295 64.5738 93.0304 64.9472 92.6746 65.3915C92.3315 65.8198 91.9357 66.314 92.6853 68.2396C92.8781 69.3215 92.5408 69.7262 92.2378 70.0899C91.8286 70.581 91.4817 70.9973 92.5869 72.9053C92.5869 73.7823 92.2337 74.4009 91.9446 74.9072C91.4996 75.6867 91.2063 76.2002 92.5868 76.9815C93.2611 78.5963 92.6754 79.2937 92.1195 79.9559C91.4254 80.7824 90.7776 81.554 92.685 83.9864C93.0646 85.1462 92.7405 85.7488 92.3923 86.3964C91.9006 87.3108 91.3607 88.3148 92.6855 91.1032C92.6855 92.7464 92.2093 93.0425 91.7931 93.3013C91.2158 93.6602 90.7539 93.9474 91.8388 97.6599C92.1494 99.9208 91.4859 102.368 90.4766 103.999V53.4824Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 0C91.1143 0.736902 91.8063 2.05696 92.587 4.29228C93.1525 5.91164 92.7571 6.27701 92.345 6.65786C91.8813 7.08636 91.3964 7.53445 92.2351 9.81019C93.3295 11.7435 93.0304 12.1388 92.6746 12.6092C92.3315 13.0627 91.9357 13.586 92.6853 15.6248C92.8781 16.7703 92.5408 17.1988 92.2378 17.5839C91.8286 18.1039 91.4817 18.5446 92.5869 20.5648C92.5869 21.4934 92.2337 22.1483 91.9446 22.6844C91.4996 23.5097 91.2063 24.0535 92.5868 24.8807C93.2611 26.5904 92.6754 27.3288 92.1195 28.0299C91.4254 28.9051 90.7776 29.7219 92.685 32.2974C93.0646 33.5253 92.7405 34.1634 92.3923 34.8491C91.9006 35.8172 91.3607 36.8803 92.6855 39.8326C92.6855 41.5725 92.2093 41.8859 91.7931 42.1599C91.2158 42.54 90.7539 42.8441 91.8388 46.7748C92.1494 49.1686 91.4859 51.7598 90.4766 53.4871V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_4813_26335" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_4813_26335)"/>
                                    <mask id="path-11-outside-4_4813_26335" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_4813_26335)"/>
                                    <mask id="path-13-outside-5_4813_26335" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-13-outside-5_4813_26335)"/>
                                    <mask id="path-15-outside-6_4813_26335" maskUnits="userSpaceOnUse" x="61" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="61" width="10" height="104"/>
                                    <path d="M62 0H70V104H62V0Z"/>
                                    </mask>
                                    <path d="M62 0H70V104H62V0Z" fill="white"/>
                                    <path d="M69 0V104H71V0H69ZM63 104V0H61V104H63Z" fill="#B6C2D0" mask="url(#path-15-outside-6_4813_26335)"/>
                                    <rect x="31" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="75" height="104" viewBox="0 0 73 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="14" y="101" width="47" height="3" fill="#B6C2D0"/>
                                    <mask id="path-2-outside-1_4813_26310" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-2-outside-1_4813_26310)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4844C1.88571 54.1803 1.19373 55.4271 0.41304 57.5383C-0.152521 59.0677 0.24287 59.4128 0.655007 59.7725C1.11871 60.1772 1.60361 60.6004 0.764893 62.7497C-0.329534 64.5756 -0.030444 64.949 0.325393 65.3933C0.668488 65.8216 1.06434 66.3158 0.314701 68.2414C0.121937 69.3232 0.459179 69.7279 0.762211 70.0916C1.17144 70.5828 1.51829 70.999 0.413117 72.907C0.413067 73.784 0.766251 74.4025 1.05537 74.9089C1.50041 75.6883 1.79366 76.2019 0.413183 76.9832C-0.261059 78.5979 0.324575 79.2954 0.880538 79.9575C1.5746 80.784 2.22242 81.5555 0.315041 83.9879C-0.0646114 85.1477 0.259459 85.7503 0.6077 86.3979C1.0994 87.3123 1.63929 88.3163 0.314519 91.1047C0.314519 92.7479 0.790691 93.044 1.20687 93.3027C1.78422 93.6617 2.24611 93.9489 1.16116 97.6613C0.850626 99.9221 1.51406 102.369 2.52344 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.030444 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.297C-0.0646113 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-6-outside-2_4813_26310" maskUnits="userSpaceOnUse" x="61" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="61" width="12" height="104"/>
                                    <path d="M70 0H62V101H70V0Z"/>
                                    </mask>
                                    <path d="M70 0H62V101H70V0Z" fill="white"/>
                                    <path d="M62 101V104H61V101H62ZM70 101H73V104H70V101ZM63 0V101H61V0H63ZM62 98H70V104H62V98ZM67 101V0H73V101H67Z" fill="#B6C2D0" mask="url(#path-6-outside-2_4813_26310)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 53.4844C73.1143 54.1803 73.8063 55.4271 74.587 57.5383C75.1525 59.0677 74.7571 59.4128 74.345 59.7725C73.8813 60.1772 73.3964 60.6004 74.2351 62.7497C75.3295 64.5756 75.0304 64.949 74.6746 65.3933C74.3315 65.8216 73.9357 66.3158 74.6853 68.2414C74.8781 69.3232 74.5408 69.7279 74.2378 70.0916C73.8286 70.5828 73.4817 70.999 74.5869 72.907C74.5869 73.784 74.2337 74.4025 73.9446 74.9089C73.4996 75.6883 73.2063 76.2019 74.5868 76.9832C75.2611 78.5979 74.6754 79.2954 74.1195 79.9575C73.4254 80.784 72.7776 81.5555 74.685 83.9879C75.0646 85.1477 74.7405 85.7503 74.3923 86.3979C73.9006 87.3123 73.3607 88.3163 74.6855 91.1047C74.6855 92.7479 74.2093 93.044 73.7931 93.3027C73.2158 93.6617 72.7539 93.9489 73.8388 97.6613C74.1494 99.9221 73.4859 102.369 72.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 0C73.1143 0.736893 73.8063 2.05693 74.587 4.29223C75.1525 5.91156 74.7571 6.27693 74.345 6.65778C73.8813 7.08627 73.3964 7.53436 74.2351 9.81006C75.3295 11.7433 75.0304 12.1387 74.6746 12.609C74.3315 13.0626 73.9357 13.5858 74.6853 15.6246C74.8781 16.77 74.5408 17.1986 74.2378 17.5836C73.8286 18.1037 73.4817 18.5444 74.5869 20.5645C74.5869 21.4931 74.2337 22.148 73.9446 22.6841C73.4996 23.5094 73.2063 24.0532 74.5868 24.8804C75.2611 26.59 74.6754 27.3285 74.1195 28.0295C73.4254 28.9047 72.7776 29.7216 74.685 32.297C75.0646 33.5249 74.7405 34.163 74.3923 34.8487C73.9006 35.8168 73.3607 36.8798 74.6855 39.8321C74.6855 41.5719 74.2093 41.8854 73.7931 42.1594C73.2158 42.5394 72.7539 42.8435 73.8388 46.7742C74.1494 49.168 73.4859 51.7591 72.4766 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-10-outside-3_4813_26310" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-10-outside-3_4813_26310)"/>
                                    <mask id="path-12-outside-4_4813_26310" maskUnits="userSpaceOnUse" x="52" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="52" width="10" height="104"/>
                                    <path d="M53 0H61V104H53V0Z"/>
                                    </mask>
                                    <path d="M53 0H61V104H53V0Z" fill="white"/>
                                    <path d="M60 0V104H62V0H60ZM54 104V0H52V104H54Z" fill="#B6C2D0" mask="url(#path-12-outside-4_4813_26310)"/>
                                    <rect x="22" width="31" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="57" height="104" viewBox="0 0 55 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="5" y="101" width="47" height="3" fill="#B6C2D0"/>
                                    <mask id="path-2-outside-1_4813_26287" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-2-outside-1_4813_26287)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4844C1.88571 54.1803 1.19373 55.4271 0.41304 57.5383C-0.152521 59.0677 0.24287 59.4128 0.655007 59.7725C1.11871 60.1772 1.60361 60.6004 0.764893 62.7497C-0.329534 64.5756 -0.030444 64.949 0.325393 65.3933C0.668488 65.8216 1.06434 66.3158 0.314701 68.2414C0.121937 69.3232 0.459179 69.7279 0.762211 70.0916C1.17144 70.5828 1.51829 70.999 0.413117 72.907C0.413067 73.784 0.766251 74.4025 1.05537 74.9089C1.50041 75.6883 1.79366 76.2019 0.413183 76.9832C-0.261059 78.5979 0.324575 79.2954 0.880538 79.9575C1.5746 80.784 2.22242 81.5555 0.315041 83.9879C-0.0646114 85.1477 0.259459 85.7503 0.6077 86.3979C1.0994 87.3123 1.63929 88.3163 0.314519 91.1047C0.314519 92.7479 0.790691 93.044 1.20687 93.3027C1.78422 93.6617 2.24611 93.9489 1.16116 97.6613C0.850626 99.9221 1.51406 102.369 2.52344 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.030444 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.297C-0.0646113 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-6-outside-2_4813_26287" maskUnits="userSpaceOnUse" x="43" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="43" width="12" height="104"/>
                                    <path d="M52 0H44V101H52V0Z"/>
                                    </mask>
                                    <path d="M52 0H44V101H52V0Z" fill="white"/>
                                    <path d="M44 101V104H43V101H44ZM52 101H55V104H52V101ZM45 0V101H43V0H45ZM44 98H52V104H44V98ZM49 101V0H55V101H49Z" fill="#B6C2D0" mask="url(#path-6-outside-2_4813_26287)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.4766 53.4844C55.1143 54.1803 55.8063 55.4271 56.587 57.5383C57.1525 59.0677 56.7571 59.4128 56.345 59.7725C55.8813 60.1772 55.3964 60.6004 56.2351 62.7497C57.3295 64.5756 57.0304 64.949 56.6746 65.3933C56.3315 65.8216 55.9357 66.3158 56.6853 68.2414C56.8781 69.3232 56.5408 69.7279 56.2378 70.0916C55.8286 70.5828 55.4817 70.999 56.5869 72.907C56.5869 73.784 56.2337 74.4025 55.9446 74.9089C55.4996 75.6883 55.2063 76.2019 56.5868 76.9832C57.2611 78.5979 56.6754 79.2954 56.1195 79.9575C55.4254 80.784 54.7776 81.5555 56.685 83.9879C57.0646 85.1477 56.7405 85.7503 56.3923 86.3979C55.9006 87.3123 55.3607 88.3163 56.6855 91.1047C56.6855 92.7479 56.2093 93.044 55.7931 93.3027C55.2158 93.6617 54.7539 93.9489 55.8388 97.6613C56.1494 99.9221 55.4859 102.369 54.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.4766 0C55.1143 0.736893 55.8063 2.05693 56.587 4.29223C57.1525 5.91156 56.7571 6.27693 56.345 6.65778C55.8813 7.08627 55.3964 7.53436 56.2351 9.81006C57.3295 11.7433 57.0304 12.1387 56.6746 12.609C56.3315 13.0626 55.9357 13.5858 56.6853 15.6246C56.8781 16.77 56.5408 17.1986 56.2378 17.5836C55.8286 18.1037 55.4817 18.5444 56.5869 20.5645C56.5869 21.4931 56.2337 22.148 55.9446 22.6841C55.4996 23.5094 55.2063 24.0532 56.5868 24.8804C57.2611 26.59 56.6754 27.3285 56.1195 28.0295C55.4254 28.9047 54.7776 29.7216 56.685 32.297C57.0646 33.5249 56.7405 34.163 56.3923 34.8487C55.9006 35.8168 55.3607 36.8798 56.6855 39.8321C56.6855 41.5719 56.2093 41.8854 55.7931 42.1594C55.2158 42.5394 54.7539 42.8435 55.8388 46.7742C56.1494 49.168 55.4859 51.7591 54.4766 53.4864V0Z" fill="#B6C2D0"/>
                                    <rect x="13" width="31" height="101" fill="#EDF2F8"/>
                                </svg>
                            </template>
                            <template v-if="size === 6 && mappingBorehole() === 3">
                                <svg width="128" height="105" viewBox="0 0 128 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_6021_16404" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V100.971H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V100.971H5V0Z" fill="white"/>
                                    <path d="M13 100.971V103.971H14V100.971H13ZM5 100.971H2V103.971H5V100.971ZM12 0V100.971H14V0H12ZM13 97.9709H5V103.971H13V97.9709ZM8 100.971V0H2V100.971H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_6021_16404)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0667 0.24287 59.4118 0.655007 59.7715C1.11871 60.1762 1.60361 60.5994 0.764893 62.7487C-0.329534 64.5746 -0.030444 64.948 0.325393 65.3923C0.668488 65.8206 1.06434 66.3148 0.314701 68.2404C0.121937 69.3222 0.459179 69.727 0.762211 70.0907C1.17144 70.5818 1.51829 70.9981 0.413117 72.906C0.413067 73.783 0.766251 74.4016 1.05537 74.9079C1.50041 75.6873 1.79366 76.2009 0.413183 76.9822C-0.261059 78.5969 0.324575 79.2944 0.880538 79.9565C1.5746 80.7831 2.22242 81.5546 0.315041 83.987C-0.0646113 85.1467 0.259458 85.7494 0.6077 86.397C1.0994 87.3113 1.63929 88.3153 0.314519 91.1037C0.314519 92.7469 0.790691 93.043 1.20687 93.3017C1.78422 93.6607 2.24611 93.9479 1.16116 97.6603C0.850626 99.9212 1.51406 102.368 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.0304441 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.2969C-0.0646112 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_6021_16404" maskUnits="userSpaceOnUse" x="114" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="114" width="12" height="104"/>
                                    <path d="M123 0H115V101H123V0Z"/>
                                    </mask>
                                    <path d="M123 0H115V101H123V0Z" fill="white"/>
                                    <path d="M115 101V104H114V101H115ZM123 101H126V104H123V101ZM116 0V101H114V0H116ZM115 98H123V104H115V98ZM120 101V0H126V101H120Z" fill="#B6C2D0" mask="url(#path-5-outside-2_6021_16404)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 53.4844C126.114 54.1804 126.806 55.4271 127.587 57.5383C128.153 59.0678 127.757 59.4129 127.345 59.7726C126.881 60.1773 126.396 60.6005 127.235 62.7499C128.33 64.5758 128.03 64.9493 127.675 65.3935C127.332 65.8219 126.936 66.3161 127.685 68.2417C127.878 69.3235 127.541 69.7283 127.238 70.092C126.829 70.5831 126.482 70.9994 127.587 72.9074C127.587 73.7844 127.234 74.403 126.945 74.9094C126.5 75.6888 126.206 76.2024 127.587 76.9837C128.261 78.5985 127.675 79.2959 127.119 79.958C126.425 80.7846 125.778 81.5561 127.685 83.9886C128.065 85.1484 127.741 85.751 127.392 86.3986C126.901 87.313 126.361 88.317 127.685 91.1055C127.685 92.7487 127.209 93.0448 126.793 93.3036C126.216 93.6625 125.754 93.9497 126.839 97.6622C127.149 99.9231 126.486 102.37 125.477 104.002V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 0C126.114 0.736908 126.806 2.05697 127.587 4.29232C128.153 5.91169 127.757 6.27707 127.345 6.65792C126.881 7.08642 126.396 7.53452 127.235 9.81027C128.33 11.7436 128.03 12.1389 127.675 12.6093C127.332 13.0628 126.936 13.5861 127.685 15.6249C127.878 16.7704 127.541 17.1989 127.238 17.584C126.829 18.104 126.482 18.5448 127.587 20.5649C127.587 21.4935 127.234 22.1485 126.945 22.6846C126.5 23.5099 126.206 24.0537 127.587 24.8809C128.261 26.5906 127.675 27.3291 127.119 28.0301C126.425 28.9053 125.778 29.7222 127.685 32.2976C128.065 33.5256 127.741 34.1637 127.392 34.8494C126.901 35.8175 126.361 36.8806 127.685 39.8329C127.685 41.5728 127.209 41.8863 126.793 42.1603C126.216 42.5403 125.754 42.8444 126.839 46.7752C127.149 49.169 126.486 51.7602 125.477 53.4876V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_6021_16404" maskUnits="userSpaceOnUse" x="106" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="106" width="10" height="104"/>
                                    <path d="M107 0H115V104H107V0Z"/>
                                    </mask>
                                    <path d="M107 0H115V104H107V0Z" fill="white"/>
                                    <path d="M114 0V104H116V0H114ZM108 104V0H106V104H108Z" fill="#B6C2D0" mask="url(#path-9-outside-3_6021_16404)"/>
                                    <mask id="path-11-outside-4_6021_16404" maskUnits="userSpaceOnUse" x="97" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="97" width="10" height="104"/>
                                    <path d="M98 0H106V104H98V0Z"/>
                                    </mask>
                                    <path d="M98 0H106V104H98V0Z" fill="white"/>
                                    <path d="M105 0V104H107V0H105ZM99 104V0H97V104H99Z" fill="#B6C2D0" mask="url(#path-11-outside-4_6021_16404)"/>
                                    <mask id="path-13-outside-5_6021_16404" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-13-outside-5_6021_16404)"/>
                                    <mask id="path-15-outside-6_6021_16404" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-15-outside-6_6021_16404)"/>
                                    <mask id="path-17-outside-7_6021_16404" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-17-outside-7_6021_16404)"/>
                                    <mask id="path-19-outside-8_6021_16404" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-19-outside-8_6021_16404)"/>
                                    <mask id="path-21-outside-9_6021_16404" maskUnits="userSpaceOnUse" x="40" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="40" width="10" height="104"/>
                                    <path d="M41 0H49V104H41V0Z"/>
                                    </mask>
                                    <path d="M41 0H49V104H41V0Z" fill="white"/>
                                    <path d="M48 0V104H50V0H48ZM42 104V0H40V104H42Z" fill="#B6C2D0" mask="url(#path-21-outside-9_6021_16404)"/>
                                    <mask id="path-23-outside-10_6021_16404" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-23-outside-10_6021_16404)"/>
                                    <mask id="path-25-outside-11_6021_16404" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-25-outside-11_6021_16404)"/>
                                    <mask id="path-27-outside-12_6021_16404" maskUnits="userSpaceOnUse" x="49" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="49" width="10" height="104"/>
                                    <path d="M50 0H58V104H50V0Z"/>
                                    </mask>
                                    <path d="M50 0H58V104H50V0Z" fill="white"/>
                                    <path d="M57 0V104H59V0H57ZM51 104V0H49V104H51Z" fill="#B6C2D0" mask="url(#path-27-outside-12_6021_16404)"/>
                                    <rect x="58" width="14" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="112" height="104" viewBox="0 0 110 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_6021_16376" maskUnits="userSpaceOnUse" x="97" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="97" width="12" height="104"/>
                                    <path d="M106 0H98V101H106V0Z"/>
                                    </mask>
                                    <path d="M106 0H98V101H106V0Z" fill="white"/>
                                    <path d="M98 101V104H97V101H98ZM106 101H109V104H106V101ZM99 0V101H97V0H99ZM98 98H106V104H98V98ZM103 101V0H109V101H103Z" fill="#B6C2D0" mask="url(#path-1-outside-1_6021_16376)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.001 52C109.639 52.7168 110.33 53.9999 111.11 56.1716C111.676 57.746 111.281 58.1012 110.868 58.4715C110.405 58.8881 109.92 59.3238 110.759 61.5364C111.853 63.416 111.554 63.8004 111.198 64.2578C110.855 64.6987 110.459 65.2075 111.209 67.1897C111.402 68.3034 111.064 68.72 110.761 69.0944C110.352 69.6 110.005 70.0285 111.11 71.9926C111.11 72.8955 110.757 73.5322 110.468 74.0535C110.023 74.8558 109.73 75.3846 111.11 76.1888C111.784 77.8511 111.199 78.5691 110.643 79.2507C109.949 80.1016 109.301 80.8958 111.208 83.3997C111.588 84.5937 111.264 85.214 110.916 85.8807C110.424 86.822 109.884 87.8555 111.209 90.726C111.209 92.4176 110.733 92.7223 110.317 92.9887C109.739 93.3582 109.277 93.6539 110.362 97.4755C110.673 99.8018 110.01 102.32 109.001 103.999V52Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M108.477 0C109.114 0.758582 109.806 2.11747 110.587 4.41857C111.153 6.08556 110.757 6.46169 110.345 6.85374C109.881 7.29485 109.396 7.75612 110.235 10.0988C111.33 12.089 111.03 12.496 110.675 12.9802C110.332 13.447 109.936 13.9857 110.685 16.0845C110.878 17.2636 110.541 17.7048 110.238 18.1012C109.829 18.6365 109.482 19.0902 110.587 21.1698C110.587 22.1257 110.234 22.7999 109.945 23.3518C109.5 24.2014 109.206 24.7611 110.587 25.6127C111.261 27.3727 110.675 28.1329 110.119 28.8546C109.425 29.7555 108.778 30.5964 110.685 33.2476C111.065 34.5117 110.741 35.1685 110.392 35.8744C109.901 36.871 109.361 37.9653 110.685 41.0045C110.685 42.7956 110.209 43.1183 109.793 43.4003C109.216 43.7916 108.754 44.1046 109.839 48.1509C110.149 50.6152 109.486 53.2826 108.477 55.0607V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_6021_16376" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-5-outside-2_6021_16376)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0668 0.24287 59.4118 0.655007 59.7716C1.11871 60.1763 1.60361 60.5995 0.764893 62.7488C-0.329534 64.5748 -0.030444 64.9482 0.325393 65.3924C0.668488 65.8208 1.06434 66.315 0.314701 68.2405C0.121937 69.3224 0.459179 69.7271 0.762211 70.0908C1.17144 70.582 1.51829 70.9983 0.413117 72.9062C0.413067 73.7832 0.766251 74.4018 1.05537 74.9082C1.50041 75.6876 1.79366 76.2012 0.413183 76.9825C-0.261059 78.5972 0.324575 79.2947 0.880538 79.9568C1.5746 80.7834 2.22242 81.5549 0.315041 83.9873C-0.0646114 85.1471 0.259459 85.7497 0.6077 86.3973C1.0994 87.3117 1.63929 88.3157 0.314519 91.1041C0.314519 92.7474 0.790691 93.0434 1.20687 93.3022C1.78422 93.6611 2.24611 93.9483 1.16116 97.6608C0.850626 99.9217 1.51406 102.369 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736901 1.19373 2.05695 0.41304 4.29228C-0.152521 5.91163 0.24287 6.277 0.655007 6.65785C1.11871 7.08635 1.60361 7.53444 0.764893 9.81017C-0.329534 11.7434 -0.030444 12.1388 0.325393 12.6092C0.668488 13.0627 1.06434 13.586 0.314701 15.6248C0.121937 16.7702 0.459179 17.1988 0.762211 17.5838C1.17144 18.1039 1.51829 18.5446 0.413117 20.5647C0.413067 21.4933 0.766251 22.1483 1.05537 22.6844C1.50041 23.5096 1.79366 24.0534 0.413183 24.8806C-0.261059 26.5903 0.324575 27.3288 0.880538 28.0298C1.5746 28.905 2.22242 29.7219 0.315041 32.2973C-0.0646115 33.5253 0.259459 34.1634 0.6077 34.849C1.0994 35.8172 1.63929 36.8802 0.314519 39.8325C0.314519 41.5724 0.790691 41.8859 1.20687 42.1598C1.78422 42.5399 2.24611 42.844 1.16116 46.7747C0.850626 49.1685 1.51406 51.7597 2.52344 53.487V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_6021_16376" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_6021_16376)"/>
                                    <mask id="path-11-outside-4_6021_16376" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_6021_16376)"/>
                                    <mask id="path-13-outside-5_6021_16376" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-13-outside-5_6021_16376)"/>
                                    <mask id="path-15-outside-6_6021_16376" maskUnits="userSpaceOnUse" x="40" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="40" width="10" height="104"/>
                                    <path d="M41 0H49V104H41V0Z"/>
                                    </mask>
                                    <path d="M41 0H49V104H41V0Z" fill="white"/>
                                    <path d="M48 0V104H50V0H48ZM42 104V0H40V104H42Z" fill="#B6C2D0" mask="url(#path-15-outside-6_6021_16376)"/>
                                    <mask id="path-17-outside-7_6021_16376" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-17-outside-7_6021_16376)"/>
                                    <mask id="path-19-outside-8_6021_16376" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-19-outside-8_6021_16376)"/>
                                    <mask id="path-21-outside-9_6021_16376" maskUnits="userSpaceOnUse" x="61" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="61" width="10" height="104"/>
                                    <path d="M62 0H70V104H62V0Z"/>
                                    </mask>
                                    <path d="M62 0H70V104H62V0Z" fill="white"/>
                                    <path d="M69 0V104H71V0H69ZM63 104V0H61V104H63Z" fill="#B6C2D0" mask="url(#path-21-outside-9_6021_16376)"/>
                                    <mask id="path-23-outside-10_6021_16376" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-23-outside-10_6021_16376)"/>
                                    <rect x="49" width="14" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="93" height="104" viewBox="0 0 91 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_6021_16350" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_6021_16350)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4922C1.88571 54.188 1.19373 55.4346 0.41304 57.5454C-0.152521 59.0746 0.24287 59.4196 0.655007 59.7792C1.11871 60.1839 1.60361 60.607 0.764893 62.756C-0.329534 64.5816 -0.030444 64.9549 0.325393 65.3991C0.668488 65.8273 1.06434 66.3215 0.314701 68.2467C0.121937 69.3284 0.459179 69.733 0.762211 70.0967C1.17144 70.5877 1.51829 71.0039 0.413117 72.9115C0.413067 73.7884 0.766251 74.4068 1.05537 74.9131C1.50041 75.6924 1.79366 76.2059 0.413183 76.9871C-0.261059 78.6015 0.324575 79.2989 0.880538 79.9609C1.5746 80.7873 2.22242 81.5587 0.315041 83.9906C-0.0646114 85.1502 0.259459 85.7528 0.6077 86.4002C1.0994 87.3145 1.63929 88.3183 0.314519 91.1062C0.314519 92.7491 0.790691 93.0451 1.20687 93.3039C1.78422 93.6628 2.24611 93.9499 1.16116 97.6617C0.850626 99.9222 1.51406 102.369 2.52344 104V53.4922Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.0195312C1.88571 0.7563 1.19373 2.07612 0.41304 4.31104C-0.152521 5.9301 0.24287 6.29541 0.655007 6.67619C1.11871 7.10462 1.60361 7.55263 0.764893 9.82795C-0.329534 11.7609 -0.030444 12.1562 0.325393 12.6265C0.668488 13.0799 1.06434 13.6031 0.314701 15.6415C0.121937 16.7868 0.459179 17.2152 0.762211 17.6002C1.17144 18.1202 1.51829 18.5608 0.413117 20.5806C0.413067 21.509 0.766251 22.1638 1.05537 22.6999C1.50041 23.525 1.79366 24.0687 0.413183 24.8957C-0.261059 26.6051 0.324575 27.3435 0.880538 28.0444C1.5746 28.9194 2.22242 29.7361 0.315041 32.3111C-0.0646113 33.5388 0.259458 34.1768 0.6077 34.8624C1.0994 35.8303 1.63929 36.8931 0.314519 39.845C0.314519 41.5845 0.790691 41.8979 1.20687 42.1718C1.78422 42.5519 2.24611 42.8559 1.16116 46.7859C0.850626 49.1793 1.51406 51.77 2.52344 53.497V0.0195312Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_6021_16350" maskUnits="userSpaceOnUse" x="79" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="79" width="12" height="104"/>
                                    <path d="M88 0H80V101H88V0Z"/>
                                    </mask>
                                    <path d="M88 0H80V101H88V0Z" fill="white"/>
                                    <path d="M80 101V104H79V101H80ZM88 101H91V104H88V101ZM81 0V101H79V0H81ZM80 98H88V104H80V98ZM85 101V0H91V101H85Z" fill="#B6C2D0" mask="url(#path-5-outside-2_6021_16350)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 53.4824C91.1143 54.1784 91.8063 55.4252 92.587 57.5364C93.1525 59.0658 92.7571 59.4109 92.345 59.7706C91.8813 60.1753 91.3964 60.5985 92.2351 62.7479C93.3295 64.5738 93.0304 64.9472 92.6746 65.3915C92.3315 65.8198 91.9357 66.314 92.6853 68.2396C92.8781 69.3215 92.5408 69.7262 92.2378 70.0899C91.8286 70.581 91.4817 70.9973 92.5869 72.9053C92.5869 73.7823 92.2337 74.4009 91.9446 74.9072C91.4996 75.6867 91.2063 76.2002 92.5868 76.9815C93.2611 78.5963 92.6754 79.2937 92.1195 79.9559C91.4254 80.7824 90.7776 81.554 92.685 83.9864C93.0646 85.1462 92.7405 85.7488 92.3923 86.3964C91.9006 87.3108 91.3607 88.3148 92.6855 91.1032C92.6855 92.7464 92.2093 93.0425 91.7931 93.3013C91.2158 93.6602 90.7539 93.9474 91.8388 97.6599C92.1494 99.9208 91.4859 102.368 90.4766 103.999V53.4824Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 0C91.1143 0.736902 91.8063 2.05696 92.587 4.29228C93.1525 5.91164 92.7571 6.27701 92.345 6.65786C91.8813 7.08636 91.3964 7.53445 92.2351 9.81019C93.3295 11.7435 93.0304 12.1388 92.6746 12.6092C92.3315 13.0627 91.9357 13.586 92.6853 15.6248C92.8781 16.7703 92.5408 17.1988 92.2378 17.5839C91.8286 18.1039 91.4817 18.5446 92.5869 20.5648C92.5869 21.4934 92.2337 22.1483 91.9446 22.6844C91.4996 23.5097 91.2063 24.0535 92.5868 24.8807C93.2611 26.5904 92.6754 27.3288 92.1195 28.0299C91.4254 28.9051 90.7776 29.7219 92.685 32.2974C93.0646 33.5253 92.7405 34.1634 92.3923 34.8491C91.9006 35.8172 91.3607 36.8803 92.6855 39.8326C92.6855 41.5725 92.2093 41.8859 91.7931 42.1599C91.2158 42.54 90.7539 42.8441 91.8388 46.7748C92.1494 49.1686 91.4859 51.7598 90.4766 53.4871V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_6021_16350" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_6021_16350)"/>
                                    <mask id="path-11-outside-4_6021_16350" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_6021_16350)"/>
                                    <mask id="path-13-outside-5_6021_16350" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-13-outside-5_6021_16350)"/>
                                    <mask id="path-15-outside-6_6021_16350" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-15-outside-6_6021_16350)"/>
                                    <mask id="path-17-outside-7_6021_16350" maskUnits="userSpaceOnUse" x="61" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="61" width="10" height="104"/>
                                    <path d="M62 0H70V104H62V0Z"/>
                                    </mask>
                                    <path d="M62 0H70V104H62V0Z" fill="white"/>
                                    <path d="M69 0V104H71V0H69ZM63 104V0H61V104H63Z" fill="#B6C2D0" mask="url(#path-17-outside-7_6021_16350)"/>
                                    <mask id="path-19-outside-8_6021_16350" maskUnits="userSpaceOnUse" x="52" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="52" width="10" height="104"/>
                                    <path d="M53 0H61V104H53V0Z"/>
                                    </mask>
                                    <path d="M53 0H61V104H53V0Z" fill="white"/>
                                    <path d="M60 0V104H62V0H60ZM54 104V0H52V104H54Z" fill="#B6C2D0" mask="url(#path-19-outside-8_6021_16350)"/>
                                    <rect x="40" width="14" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="75" height="104" viewBox="0 0 73 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="14" y="101" width="47" height="3" fill="#B6C2D0"/>
                                    <mask id="path-2-outside-1_6021_16323" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-2-outside-1_6021_16323)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4844C1.88571 54.1803 1.19373 55.4271 0.41304 57.5383C-0.152521 59.0677 0.24287 59.4128 0.655007 59.7725C1.11871 60.1772 1.60361 60.6004 0.764893 62.7497C-0.329534 64.5756 -0.030444 64.949 0.325393 65.3933C0.668488 65.8216 1.06434 66.3158 0.314701 68.2414C0.121937 69.3232 0.459179 69.7279 0.762211 70.0916C1.17144 70.5828 1.51829 70.999 0.413117 72.907C0.413067 73.784 0.766251 74.4025 1.05537 74.9089C1.50041 75.6883 1.79366 76.2019 0.413183 76.9832C-0.261059 78.5979 0.324575 79.2954 0.880538 79.9575C1.5746 80.784 2.22242 81.5555 0.315041 83.9879C-0.0646114 85.1477 0.259459 85.7503 0.6077 86.3979C1.0994 87.3123 1.63929 88.3163 0.314519 91.1047C0.314519 92.7479 0.790691 93.044 1.20687 93.3027C1.78422 93.6617 2.24611 93.9489 1.16116 97.6613C0.850626 99.9221 1.51406 102.369 2.52344 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.030444 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.297C-0.0646113 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-6-outside-2_6021_16323" maskUnits="userSpaceOnUse" x="61" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="61" width="12" height="104"/>
                                    <path d="M70 0H62V101H70V0Z"/>
                                    </mask>
                                    <path d="M70 0H62V101H70V0Z" fill="white"/>
                                    <path d="M62 101V104H61V101H62ZM70 101H73V104H70V101ZM63 0V101H61V0H63ZM62 98H70V104H62V98ZM67 101V0H73V101H67Z" fill="#B6C2D0" mask="url(#path-6-outside-2_6021_16323)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 53.4844C73.1143 54.1803 73.8063 55.4271 74.587 57.5383C75.1525 59.0677 74.7571 59.4128 74.345 59.7725C73.8813 60.1772 73.3964 60.6004 74.2351 62.7497C75.3295 64.5756 75.0304 64.949 74.6746 65.3933C74.3315 65.8216 73.9357 66.3158 74.6853 68.2414C74.8781 69.3232 74.5408 69.7279 74.2378 70.0916C73.8286 70.5828 73.4817 70.999 74.5869 72.907C74.5869 73.784 74.2337 74.4025 73.9446 74.9089C73.4996 75.6883 73.2063 76.2019 74.5868 76.9832C75.2611 78.5979 74.6754 79.2954 74.1195 79.9575C73.4254 80.784 72.7776 81.5555 74.685 83.9879C75.0646 85.1477 74.7405 85.7503 74.3923 86.3979C73.9006 87.3123 73.3607 88.3163 74.6855 91.1047C74.6855 92.7479 74.2093 93.044 73.7931 93.3027C73.2158 93.6617 72.7539 93.9489 73.8388 97.6613C74.1494 99.9221 73.4859 102.369 72.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 0C73.1143 0.736893 73.8063 2.05693 74.587 4.29223C75.1525 5.91156 74.7571 6.27693 74.345 6.65778C73.8813 7.08627 73.3964 7.53436 74.2351 9.81006C75.3295 11.7433 75.0304 12.1387 74.6746 12.609C74.3315 13.0626 73.9357 13.5858 74.6853 15.6246C74.8781 16.77 74.5408 17.1986 74.2378 17.5836C73.8286 18.1037 73.4817 18.5444 74.5869 20.5645C74.5869 21.4931 74.2337 22.148 73.9446 22.6841C73.4996 23.5094 73.2063 24.0532 74.5868 24.8804C75.2611 26.59 74.6754 27.3285 74.1195 28.0295C73.4254 28.9047 72.7776 29.7216 74.685 32.297C75.0646 33.5249 74.7405 34.163 74.3923 34.8487C73.9006 35.8168 73.3607 36.8798 74.6855 39.8321C74.6855 41.5719 74.2093 41.8854 73.7931 42.1594C73.2158 42.5394 72.7539 42.8435 73.8388 46.7742C74.1494 49.168 73.4859 51.7591 72.4766 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-10-outside-3_6021_16323" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-10-outside-3_6021_16323)"/>
                                    <mask id="path-12-outside-4_6021_16323" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-12-outside-4_6021_16323)"/>
                                    <mask id="path-14-outside-5_6021_16323" maskUnits="userSpaceOnUse" x="52" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="52" width="10" height="104"/>
                                    <path d="M53 0H61V104H53V0Z"/>
                                    </mask>
                                    <path d="M53 0H61V104H53V0Z" fill="white"/>
                                    <path d="M60 0V104H62V0H60ZM54 104V0H52V104H54Z" fill="#B6C2D0" mask="url(#path-14-outside-5_6021_16323)"/>
                                    <mask id="path-16-outside-6_6021_16323" maskUnits="userSpaceOnUse" x="43" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="43" width="10" height="104"/>
                                    <path d="M44 0H52V104H44V0Z"/>
                                    </mask>
                                    <path d="M44 0H52V104H44V0Z" fill="white"/>
                                    <path d="M51 0V104H53V0H51ZM45 104V0H43V104H45Z" fill="#B6C2D0" mask="url(#path-16-outside-6_6021_16323)"/>
                                    <rect x="31" width="14" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="57" height="107" viewBox="0 0 57 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_6021_16276" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="107" fill="black">
                                    <rect fill="white" x="2" width="12" height="107"/>
                                    <path d="M5 0H13V104H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V104H5V0Z" fill="white"/>
                                    <path d="M13 104V107H14V104H13ZM5 104H2V107H5V104ZM12 0V104H14V0H12ZM13 101H5V107H13V101ZM8 104V0H2V104H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_6021_16276)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4844C1.88571 54.1803 1.19373 55.4271 0.41304 57.5383C-0.152521 59.0677 0.24287 59.4128 0.655007 59.7725C1.11871 60.1772 1.60361 60.6004 0.764893 62.7497C-0.329534 64.5756 -0.030444 64.949 0.325393 65.3933C0.668488 65.8216 1.06434 66.3158 0.314701 68.2414C0.121937 69.3232 0.459179 69.7279 0.762211 70.0916C1.17144 70.5828 1.51829 70.999 0.413117 72.907C0.413067 73.784 0.766251 74.4025 1.05537 74.9089C1.50041 75.6883 1.79366 76.2019 0.413183 76.9832C-0.261059 78.5979 0.324575 79.2954 0.880538 79.9575C1.5746 80.784 2.22242 81.5555 0.315041 83.9879C-0.0646114 85.1477 0.259459 85.7503 0.6077 86.3979C1.0994 87.3123 1.63929 88.3163 0.314519 91.1047C0.314519 92.7479 0.790691 93.044 1.20687 93.3027C1.78422 93.6617 2.24611 93.9489 1.16116 97.6613C0.850626 99.9221 1.51406 102.369 2.52344 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.030444 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.297C-0.0646113 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_6021_16276" maskUnits="userSpaceOnUse" x="43" y="0" width="12" height="107" fill="black">
                                    <rect fill="white" x="43" width="12" height="107"/>
                                    <path d="M52 0H44V104H52V0Z"/>
                                    </mask>
                                    <path d="M52 0H44V104H52V0Z" fill="white"/>
                                    <path d="M44 104V107H43V104H44ZM52 104H55V107H52V104ZM45 0V104H43V0H45ZM44 101H52V107H44V101ZM49 104V0H55V104H49Z" fill="#B6C2D0" mask="url(#path-5-outside-2_6021_16276)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.4766 53.4844C55.1143 54.1803 55.8063 55.4271 56.587 57.5383C57.1525 59.0677 56.7571 59.4128 56.345 59.7725C55.8813 60.1772 55.3964 60.6004 56.2351 62.7497C57.3295 64.5756 57.0304 64.949 56.6746 65.3933C56.3315 65.8216 55.9357 66.3158 56.6853 68.2414C56.8781 69.3232 56.5408 69.7279 56.2378 70.0916C55.8286 70.5828 55.4817 70.999 56.5869 72.907C56.5869 73.784 56.2337 74.4025 55.9446 74.9089C55.4996 75.6883 55.2063 76.2019 56.5868 76.9832C57.2611 78.5979 56.6754 79.2954 56.1195 79.9575C55.4254 80.784 54.7776 81.5555 56.685 83.9879C57.0646 85.1477 56.7405 85.7503 56.3923 86.3979C55.9006 87.3123 55.3607 88.3163 56.6855 91.1047C56.6855 92.7479 56.2093 93.044 55.7931 93.3027C55.2158 93.6617 54.7539 93.9489 55.8388 97.6613C56.1494 99.9221 55.4859 102.369 54.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.4766 0C55.1143 0.736893 55.8063 2.05693 56.587 4.29223C57.1525 5.91156 56.7571 6.27693 56.345 6.65778C55.8813 7.08627 55.3964 7.53436 56.2351 9.81006C57.3295 11.7433 57.0304 12.1387 56.6746 12.609C56.3315 13.0626 55.9357 13.5858 56.6853 15.6246C56.8781 16.77 56.5408 17.1986 56.2378 17.5836C55.8286 18.1037 55.4817 18.5444 56.5869 20.5645C56.5869 21.4931 56.2337 22.148 55.9446 22.6841C55.4996 23.5094 55.2063 24.0532 56.5868 24.8804C57.2611 26.59 56.6754 27.3285 56.1195 28.0295C55.4254 28.9047 54.7776 29.7216 56.685 32.297C57.0646 33.5249 56.7405 34.163 56.3923 34.8487C55.9006 35.8168 55.3607 36.8798 56.6855 39.8321C56.6855 41.5719 56.2093 41.8854 55.7931 42.1594C55.2158 42.5394 54.7539 42.8435 55.8388 46.7742C56.1494 49.168 55.4859 51.7591 54.4766 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_6021_16276" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_6021_16276)"/>
                                    <mask id="path-11-outside-4_6021_16276" maskUnits="userSpaceOnUse" x="34" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="34" width="10" height="104"/>
                                    <path d="M35 0H43V104H35V0Z"/>
                                    </mask>
                                    <path d="M35 0H43V104H35V0Z" fill="white"/>
                                    <path d="M42 0V104H44V0H42ZM36 104V0H34V104H36Z" fill="#B6C2D0" mask="url(#path-11-outside-4_6021_16276)"/>
                                    <rect x="22" width="14" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="62" height="104" viewBox="0 0 62 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="13" y="101" width="35" height="3" fill="#B6C2D0"/>
                                    <mask id="path-2-outside-1_6021_16300" maskUnits="userSpaceOnUse" x="13" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="13" width="12" height="104"/>
                                    <path d="M16 0H24V101H16V0Z"/>
                                    </mask>
                                    <path d="M16 0H24V101H16V0Z" fill="white"/>
                                    <path d="M24 101V104H25V101H24ZM16 101H13V104H16V101ZM23 0V101H25V0H23ZM24 98H16V104H24V98ZM19 101V0H13V101H19Z" fill="#B6C2D0" mask="url(#path-2-outside-1_6021_16300)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5234 53.4844C12.8857 54.1803 12.1937 55.4271 11.413 57.5383C10.8475 59.0677 11.2429 59.4128 11.655 59.7725C12.1187 60.1772 12.6036 60.6004 11.7649 62.7497C10.6705 64.5756 10.9696 64.949 11.3254 65.3933C11.6685 65.8216 12.0643 66.3158 11.3147 68.2414C11.1219 69.3232 11.4592 69.7279 11.7622 70.0916C12.1714 70.5828 12.5183 70.999 11.4131 72.907C11.4131 73.784 11.7663 74.4025 12.0554 74.9089C12.5004 75.6883 12.7937 76.2019 11.4132 76.9832C10.7389 78.5979 11.3246 79.2954 11.8805 79.9575C12.5746 80.784 13.2224 81.5555 11.315 83.9879C10.9354 85.1477 11.2595 85.7503 11.6077 86.3979C12.0994 87.3123 12.6393 88.3163 11.3145 91.1047C11.3145 92.7479 11.7907 93.044 12.2069 93.3027C12.7842 93.6617 13.2461 93.9489 12.1612 97.6612C11.8506 99.9221 12.5141 102.369 13.5234 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5234 0.000203383C12.8857 0.737096 12.1937 2.05713 11.413 4.29243C10.8475 5.91176 11.2429 6.27713 11.655 6.65798C12.1187 7.08648 12.6036 7.53456 11.7649 9.81027C10.6705 11.7435 10.9696 12.1389 11.3254 12.6092C11.6685 13.0628 12.0643 13.586 11.3147 15.6248C11.1219 16.7702 11.4592 17.1988 11.7622 17.5838C12.1714 18.1039 12.5183 18.5446 11.4131 20.5647C11.4131 21.4933 11.7663 22.1482 12.0554 22.6843C12.5004 23.5096 12.7937 24.0534 11.4132 24.8806C10.7389 26.5902 11.3246 27.3287 11.8805 28.0297C12.5746 28.9049 13.2224 29.7218 11.315 32.2971C10.9354 33.5251 11.2595 34.1632 11.6077 34.8489C12.0994 35.817 12.6393 36.88 11.3145 39.8323C11.3145 41.5721 11.7907 41.8856 12.2069 42.1596C12.7842 42.5396 13.2461 42.8437 12.1612 46.7744C11.8506 49.1682 12.5141 51.7593 13.5234 53.4866V0.000203383Z" fill="#B6C2D0"/>
                                    <mask id="path-6-outside-2_6021_16300" maskUnits="userSpaceOnUse" x="36" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="36" width="12" height="104"/>
                                    <path d="M45 0H37V101H45V0Z"/>
                                    </mask>
                                    <path d="M45 0H37V101H45V0Z" fill="white"/>
                                    <path d="M37 101V104H36V101H37ZM45 101H48V104H45V101ZM38 0V101H36V0H38ZM37 98H45V104H37V98ZM42 101V0H48V101H42Z" fill="#B6C2D0" mask="url(#path-6-outside-2_6021_16300)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M47.4766 53.4844C48.1143 54.1803 48.8063 55.4271 49.587 57.5383C50.1525 59.0677 49.7571 59.4128 49.345 59.7725C48.8813 60.1772 48.3964 60.6004 49.2351 62.7497C50.3295 64.5756 50.0304 64.949 49.6746 65.3933C49.3315 65.8216 48.9357 66.3158 49.6853 68.2414C49.8781 69.3232 49.5408 69.7279 49.2378 70.0916C48.8286 70.5828 48.4817 70.999 49.5869 72.907C49.5869 73.784 49.2337 74.4025 48.9446 74.9089C48.4996 75.6883 48.2063 76.2019 49.5868 76.9832C50.2611 78.5979 49.6754 79.2954 49.1195 79.9575C48.4254 80.784 47.7776 81.5555 49.685 83.9879C50.0646 85.1477 49.7405 85.7503 49.3923 86.3979C48.9006 87.3123 48.3607 88.3163 49.6855 91.1047C49.6855 92.7479 49.2093 93.044 48.7931 93.3027C48.2158 93.6617 47.7539 93.9489 48.8388 97.6612C49.1494 99.9221 48.4859 102.369 47.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M47.4766 0.000203383C48.1143 0.737096 48.8063 2.05713 49.587 4.29243C50.1525 5.91176 49.7571 6.27713 49.345 6.65798C48.8813 7.08648 48.3964 7.53456 49.2351 9.81027C50.3295 11.7435 50.0304 12.1389 49.6746 12.6092C49.3315 13.0628 48.9357 13.586 49.6853 15.6248C49.8781 16.7702 49.5408 17.1988 49.2378 17.5838C48.8286 18.1039 48.4817 18.5446 49.5869 20.5647C49.5869 21.4933 49.2337 22.1482 48.9446 22.6843C48.4996 23.5096 48.2063 24.0534 49.5868 24.8806C50.2611 26.5902 49.6754 27.3287 49.1195 28.0297C48.4254 28.9049 47.7776 29.7218 49.685 32.2971C50.0646 33.5251 49.7405 34.1632 49.3923 34.8489C48.9006 35.817 48.3607 36.88 49.6855 39.8323C49.6855 41.5721 49.2093 41.8856 48.7931 42.1596C48.2158 42.5396 47.7539 42.8437 48.8388 46.7744C49.1494 49.1682 48.4859 51.7593 47.4766 53.4866V0.000203383Z" fill="#B6C2D0"/>
                                    <rect x="24" width="14" height="101" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 22L24 17.6699L24 26.3301L-2.18557e-07 22Z" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 36L24 31.6699L24 40.3301L-2.18557e-07 36Z" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 50L24 45.6699L24 54.3301L-2.18557e-07 50Z" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 64L24 59.6699L24 68.3301L-2.18557e-07 64Z" fill="#EDF2F8"/>
                                    <path d="M-2.18557e-07 78L24 73.6699L24 82.3301L-2.18557e-07 78Z" fill="#EDF2F8"/>
                                    <path d="M62 22L38 17.6699L38 26.3301L62 22Z" fill="#EDF2F8"/>
                                    <path d="M62 36L38 31.6699L38 40.3301L62 36Z" fill="#EDF2F8"/>
                                    <path d="M62 50L38 45.6699L38 54.3301L62 50Z" fill="#EDF2F8"/>
                                    <path d="M62 64L38 59.6699L38 68.3301L62 64Z" fill="#EDF2F8"/>
                                    <path d="M62 78L38 73.6699L38 82.3301L62 78Z" fill="#EDF2F8"/>
                                </svg>
                            </template>
                            <template v-if="size === 6 && mappingBorehole() === 14">
                                <svg width="128" height="105" viewBox="0 0 128 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_6021_16590" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V100.971H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V100.971H5V0Z" fill="white"/>
                                    <path d="M13 100.971V103.971H14V100.971H13ZM5 100.971H2V103.971H5V100.971ZM12 0V100.971H14V0H12ZM13 97.9709H5V103.971H13V97.9709ZM8 100.971V0H2V100.971H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_6021_16590)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0667 0.24287 59.4118 0.655007 59.7715C1.11871 60.1762 1.60361 60.5994 0.764893 62.7487C-0.329534 64.5746 -0.030444 64.948 0.325393 65.3923C0.668488 65.8206 1.06434 66.3148 0.314701 68.2404C0.121937 69.3222 0.459179 69.727 0.762211 70.0907C1.17144 70.5818 1.51829 70.9981 0.413117 72.906C0.413067 73.783 0.766251 74.4016 1.05537 74.9079C1.50041 75.6873 1.79366 76.2009 0.413183 76.9822C-0.261059 78.5969 0.324575 79.2944 0.880538 79.9565C1.5746 80.7831 2.22242 81.5546 0.315041 83.987C-0.0646113 85.1467 0.259458 85.7494 0.6077 86.397C1.0994 87.3113 1.63929 88.3153 0.314519 91.1037C0.314519 92.7469 0.790691 93.043 1.20687 93.3017C1.78422 93.6607 2.24611 93.9479 1.16116 97.6603C0.850626 99.9212 1.51406 102.368 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.0304441 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.2969C-0.0646112 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_6021_16590" maskUnits="userSpaceOnUse" x="114" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="114" width="12" height="104"/>
                                    <path d="M123 0H115V101H123V0Z"/>
                                    </mask>
                                    <path d="M123 0H115V101H123V0Z" fill="white"/>
                                    <path d="M115 101V104H114V101H115ZM123 101H126V104H123V101ZM116 0V101H114V0H116ZM115 98H123V104H115V98ZM120 101V0H126V101H120Z" fill="#B6C2D0" mask="url(#path-5-outside-2_6021_16590)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 53.4844C126.114 54.1804 126.806 55.4271 127.587 57.5383C128.153 59.0678 127.757 59.4129 127.345 59.7726C126.881 60.1773 126.396 60.6005 127.235 62.7499C128.33 64.5758 128.03 64.9493 127.675 65.3935C127.332 65.8219 126.936 66.3161 127.685 68.2417C127.878 69.3235 127.541 69.7283 127.238 70.092C126.829 70.5831 126.482 70.9994 127.587 72.9074C127.587 73.7844 127.234 74.403 126.945 74.9094C126.5 75.6888 126.206 76.2024 127.587 76.9837C128.261 78.5985 127.675 79.2959 127.119 79.958C126.425 80.7846 125.778 81.5561 127.685 83.9886C128.065 85.1484 127.741 85.751 127.392 86.3986C126.901 87.313 126.361 88.317 127.685 91.1055C127.685 92.7487 127.209 93.0448 126.793 93.3036C126.216 93.6625 125.754 93.9497 126.839 97.6622C127.149 99.9231 126.486 102.37 125.477 104.002V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.477 0C126.114 0.736908 126.806 2.05697 127.587 4.29232C128.153 5.91169 127.757 6.27707 127.345 6.65792C126.881 7.08642 126.396 7.53452 127.235 9.81027C128.33 11.7436 128.03 12.1389 127.675 12.6093C127.332 13.0628 126.936 13.5861 127.685 15.6249C127.878 16.7704 127.541 17.1989 127.238 17.584C126.829 18.104 126.482 18.5448 127.587 20.5649C127.587 21.4935 127.234 22.1485 126.945 22.6846C126.5 23.5099 126.206 24.0537 127.587 24.8809C128.261 26.5906 127.675 27.3291 127.119 28.0301C126.425 28.9053 125.778 29.7222 127.685 32.2976C128.065 33.5256 127.741 34.1637 127.392 34.8494C126.901 35.8175 126.361 36.8806 127.685 39.8329C127.685 41.5728 127.209 41.8863 126.793 42.1603C126.216 42.5403 125.754 42.8444 126.839 46.7752C127.149 49.169 126.486 51.7602 125.477 53.4876V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_6021_16590" maskUnits="userSpaceOnUse" x="106" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="106" width="10" height="104"/>
                                    <path d="M107 0H115V104H107V0Z"/>
                                    </mask>
                                    <path d="M107 0H115V104H107V0Z" fill="white"/>
                                    <path d="M114 0V104H116V0H114ZM108 104V0H106V104H108Z" fill="#B6C2D0" mask="url(#path-9-outside-3_6021_16590)"/>
                                    <mask id="path-11-outside-4_6021_16590" maskUnits="userSpaceOnUse" x="97" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="97" width="10" height="104"/>
                                    <path d="M98 0H106V104H98V0Z"/>
                                    </mask>
                                    <path d="M98 0H106V104H98V0Z" fill="white"/>
                                    <path d="M105 0V104H107V0H105ZM99 104V0H97V104H99Z" fill="#B6C2D0" mask="url(#path-11-outside-4_6021_16590)"/>
                                    <mask id="path-13-outside-5_6021_16590" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-13-outside-5_6021_16590)"/>
                                    <mask id="path-15-outside-6_6021_16590" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-15-outside-6_6021_16590)"/>
                                    <mask id="path-17-outside-7_6021_16590" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-17-outside-7_6021_16590)"/>
                                    <mask id="path-19-outside-8_6021_16590" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-19-outside-8_6021_16590)"/>
                                    <mask id="path-21-outside-9_6021_16590" maskUnits="userSpaceOnUse" x="40" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="40" width="10" height="104"/>
                                    <path d="M41 0H49V104H41V0Z"/>
                                    </mask>
                                    <path d="M41 0H49V104H41V0Z" fill="white"/>
                                    <path d="M48 0V104H50V0H48ZM42 104V0H40V104H42Z" fill="#B6C2D0" mask="url(#path-21-outside-9_6021_16590)"/>
                                    <mask id="path-23-outside-10_6021_16590" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-23-outside-10_6021_16590)"/>
                                    <mask id="path-25-outside-11_6021_16590" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-25-outside-11_6021_16590)"/>
                                    <mask id="path-27-outside-12_6021_16590" maskUnits="userSpaceOnUse" x="49" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="49" width="10" height="104"/>
                                    <path d="M50 0H58V104H50V0Z"/>
                                    </mask>
                                    <path d="M50 0H58V104H50V0Z" fill="white"/>
                                    <path d="M57 0V104H59V0H57ZM51 104V0H49V104H51Z" fill="#B6C2D0" mask="url(#path-27-outside-12_6021_16590)"/>
                                    <rect x="58" width="14" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="112" height="104" viewBox="0 0 110 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_6021_16562" maskUnits="userSpaceOnUse" x="97" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="97" width="12" height="104"/>
                                    <path d="M106 0H98V101H106V0Z"/>
                                    </mask>
                                    <path d="M106 0H98V101H106V0Z" fill="white"/>
                                    <path d="M98 101V104H97V101H98ZM106 101H109V104H106V101ZM99 0V101H97V0H99ZM98 98H106V104H98V98ZM103 101V0H109V101H103Z" fill="#B6C2D0" mask="url(#path-1-outside-1_6021_16562)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.001 52C109.639 52.7168 110.33 53.9999 111.11 56.1716C111.676 57.746 111.281 58.1012 110.868 58.4715C110.405 58.8881 109.92 59.3238 110.759 61.5364C111.853 63.416 111.554 63.8004 111.198 64.2578C110.855 64.6987 110.459 65.2075 111.209 67.1897C111.402 68.3034 111.064 68.72 110.761 69.0944C110.352 69.6 110.005 70.0285 111.11 71.9926C111.11 72.8955 110.757 73.5322 110.468 74.0535C110.023 74.8558 109.73 75.3846 111.11 76.1888C111.784 77.8511 111.199 78.5691 110.643 79.2507C109.949 80.1016 109.301 80.8958 111.208 83.3997C111.588 84.5937 111.264 85.214 110.916 85.8807C110.424 86.822 109.884 87.8555 111.209 90.726C111.209 92.4176 110.733 92.7223 110.317 92.9887C109.739 93.3582 109.277 93.6539 110.362 97.4755C110.673 99.8018 110.01 102.32 109.001 103.999V52Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M108.477 0C109.114 0.758582 109.806 2.11747 110.587 4.41857C111.153 6.08556 110.757 6.46169 110.345 6.85374C109.881 7.29485 109.396 7.75612 110.235 10.0988C111.33 12.089 111.03 12.496 110.675 12.9802C110.332 13.447 109.936 13.9857 110.685 16.0845C110.878 17.2636 110.541 17.7048 110.238 18.1012C109.829 18.6365 109.482 19.0902 110.587 21.1698C110.587 22.1257 110.234 22.7999 109.945 23.3518C109.5 24.2014 109.206 24.7611 110.587 25.6127C111.261 27.3727 110.675 28.1329 110.119 28.8546C109.425 29.7555 108.778 30.5964 110.685 33.2476C111.065 34.5117 110.741 35.1685 110.392 35.8744C109.901 36.871 109.361 37.9653 110.685 41.0045C110.685 42.7956 110.209 43.1183 109.793 43.4003C109.216 43.7916 108.754 44.1046 109.839 48.1509C110.149 50.6152 109.486 53.2826 108.477 55.0607V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_6021_16562" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-5-outside-2_6021_16562)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4834C1.88571 54.1794 1.19373 55.4261 0.41304 57.5373C-0.152521 59.0668 0.24287 59.4118 0.655007 59.7716C1.11871 60.1763 1.60361 60.5995 0.764893 62.7488C-0.329534 64.5748 -0.030444 64.9482 0.325393 65.3924C0.668488 65.8208 1.06434 66.315 0.314701 68.2405C0.121937 69.3224 0.459179 69.7271 0.762211 70.0908C1.17144 70.582 1.51829 70.9983 0.413117 72.9062C0.413067 73.7832 0.766251 74.4018 1.05537 74.9082C1.50041 75.6876 1.79366 76.2012 0.413183 76.9825C-0.261059 78.5972 0.324575 79.2947 0.880538 79.9568C1.5746 80.7834 2.22242 81.5549 0.315041 83.9873C-0.0646114 85.1471 0.259459 85.7497 0.6077 86.3973C1.0994 87.3117 1.63929 88.3157 0.314519 91.1041C0.314519 92.7474 0.790691 93.0434 1.20687 93.3022C1.78422 93.6611 2.24611 93.9483 1.16116 97.6608C0.850626 99.9217 1.51406 102.369 2.52344 104V53.4834Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736901 1.19373 2.05695 0.41304 4.29228C-0.152521 5.91163 0.24287 6.277 0.655007 6.65785C1.11871 7.08635 1.60361 7.53444 0.764893 9.81017C-0.329534 11.7434 -0.030444 12.1388 0.325393 12.6092C0.668488 13.0627 1.06434 13.586 0.314701 15.6248C0.121937 16.7702 0.459179 17.1988 0.762211 17.5838C1.17144 18.1039 1.51829 18.5446 0.413117 20.5647C0.413067 21.4933 0.766251 22.1483 1.05537 22.6844C1.50041 23.5096 1.79366 24.0534 0.413183 24.8806C-0.261059 26.5903 0.324575 27.3288 0.880538 28.0298C1.5746 28.905 2.22242 29.7219 0.315041 32.2973C-0.0646115 33.5253 0.259459 34.1634 0.6077 34.849C1.0994 35.8172 1.63929 36.8802 0.314519 39.8325C0.314519 41.5724 0.790691 41.8859 1.20687 42.1598C1.78422 42.5399 2.24611 42.844 1.16116 46.7747C0.850626 49.1685 1.51406 51.7597 2.52344 53.487V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_6021_16562" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_6021_16562)"/>
                                    <mask id="path-11-outside-4_6021_16562" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_6021_16562)"/>
                                    <mask id="path-13-outside-5_6021_16562" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-13-outside-5_6021_16562)"/>
                                    <mask id="path-15-outside-6_6021_16562" maskUnits="userSpaceOnUse" x="40" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="40" width="10" height="104"/>
                                    <path d="M41 0H49V104H41V0Z"/>
                                    </mask>
                                    <path d="M41 0H49V104H41V0Z" fill="white"/>
                                    <path d="M48 0V104H50V0H48ZM42 104V0H40V104H42Z" fill="#B6C2D0" mask="url(#path-15-outside-6_6021_16562)"/>
                                    <mask id="path-17-outside-7_6021_16562" maskUnits="userSpaceOnUse" x="79" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="79" width="10" height="104"/>
                                    <path d="M80 0H88V104H80V0Z"/>
                                    </mask>
                                    <path d="M80 0H88V104H80V0Z" fill="white"/>
                                    <path d="M87 0V104H89V0H87ZM81 104V0H79V104H81Z" fill="#B6C2D0" mask="url(#path-17-outside-7_6021_16562)"/>
                                    <mask id="path-19-outside-8_6021_16562" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-19-outside-8_6021_16562)"/>
                                    <mask id="path-21-outside-9_6021_16562" maskUnits="userSpaceOnUse" x="61" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="61" width="10" height="104"/>
                                    <path d="M62 0H70V104H62V0Z"/>
                                    </mask>
                                    <path d="M62 0H70V104H62V0Z" fill="white"/>
                                    <path d="M69 0V104H71V0H69ZM63 104V0H61V104H63Z" fill="#B6C2D0" mask="url(#path-21-outside-9_6021_16562)"/>
                                    <mask id="path-23-outside-10_6021_16562" maskUnits="userSpaceOnUse" x="88" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="88" width="10" height="104"/>
                                    <path d="M89 0H97V104H89V0Z"/>
                                    </mask>
                                    <path d="M89 0H97V104H89V0Z" fill="white"/>
                                    <path d="M96 0V104H98V0H96ZM90 104V0H88V104H90Z" fill="#B6C2D0" mask="url(#path-23-outside-10_6021_16562)"/>
                                    <rect x="49" width="14" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="93" height="104" viewBox="0 0 91 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_6021_16536" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_6021_16536)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4922C1.88571 54.188 1.19373 55.4346 0.41304 57.5454C-0.152521 59.0746 0.24287 59.4196 0.655007 59.7792C1.11871 60.1839 1.60361 60.607 0.764893 62.756C-0.329534 64.5816 -0.030444 64.9549 0.325393 65.3991C0.668488 65.8273 1.06434 66.3215 0.314701 68.2467C0.121937 69.3284 0.459179 69.733 0.762211 70.0967C1.17144 70.5877 1.51829 71.0039 0.413117 72.9115C0.413067 73.7884 0.766251 74.4068 1.05537 74.9131C1.50041 75.6924 1.79366 76.2059 0.413183 76.9871C-0.261059 78.6015 0.324575 79.2989 0.880538 79.9609C1.5746 80.7873 2.22242 81.5587 0.315041 83.9906C-0.0646114 85.1502 0.259459 85.7528 0.6077 86.4002C1.0994 87.3145 1.63929 88.3183 0.314519 91.1062C0.314519 92.7491 0.790691 93.0451 1.20687 93.3039C1.78422 93.6628 2.24611 93.9499 1.16116 97.6617C0.850626 99.9222 1.51406 102.369 2.52344 104V53.4922Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0.0195312C1.88571 0.7563 1.19373 2.07612 0.41304 4.31104C-0.152521 5.9301 0.24287 6.29541 0.655007 6.67619C1.11871 7.10462 1.60361 7.55263 0.764893 9.82795C-0.329534 11.7609 -0.030444 12.1562 0.325393 12.6265C0.668488 13.0799 1.06434 13.6031 0.314701 15.6415C0.121937 16.7868 0.459179 17.2152 0.762211 17.6002C1.17144 18.1202 1.51829 18.5608 0.413117 20.5806C0.413067 21.509 0.766251 22.1638 1.05537 22.6999C1.50041 23.525 1.79366 24.0687 0.413183 24.8957C-0.261059 26.6051 0.324575 27.3435 0.880538 28.0444C1.5746 28.9194 2.22242 29.7361 0.315041 32.3111C-0.0646113 33.5388 0.259458 34.1768 0.6077 34.8624C1.0994 35.8303 1.63929 36.8931 0.314519 39.845C0.314519 41.5845 0.790691 41.8979 1.20687 42.1718C1.78422 42.5519 2.24611 42.8559 1.16116 46.7859C0.850626 49.1793 1.51406 51.77 2.52344 53.497V0.0195312Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_6021_16536" maskUnits="userSpaceOnUse" x="79" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="79" width="12" height="104"/>
                                    <path d="M88 0H80V101H88V0Z"/>
                                    </mask>
                                    <path d="M88 0H80V101H88V0Z" fill="white"/>
                                    <path d="M80 101V104H79V101H80ZM88 101H91V104H88V101ZM81 0V101H79V0H81ZM80 98H88V104H80V98ZM85 101V0H91V101H85Z" fill="#B6C2D0" mask="url(#path-5-outside-2_6021_16536)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 53.4824C91.1143 54.1784 91.8063 55.4252 92.587 57.5364C93.1525 59.0658 92.7571 59.4109 92.345 59.7706C91.8813 60.1753 91.3964 60.5985 92.2351 62.7479C93.3295 64.5738 93.0304 64.9472 92.6746 65.3915C92.3315 65.8198 91.9357 66.314 92.6853 68.2396C92.8781 69.3215 92.5408 69.7262 92.2378 70.0899C91.8286 70.581 91.4817 70.9973 92.5869 72.9053C92.5869 73.7823 92.2337 74.4009 91.9446 74.9072C91.4996 75.6867 91.2063 76.2002 92.5868 76.9815C93.2611 78.5963 92.6754 79.2937 92.1195 79.9559C91.4254 80.7824 90.7776 81.554 92.685 83.9864C93.0646 85.1462 92.7405 85.7488 92.3923 86.3964C91.9006 87.3108 91.3607 88.3148 92.6855 91.1032C92.6855 92.7464 92.2093 93.0425 91.7931 93.3013C91.2158 93.6602 90.7539 93.9474 91.8388 97.6599C92.1494 99.9208 91.4859 102.368 90.4766 103.999V53.4824Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.4766 0C91.1143 0.736902 91.8063 2.05696 92.587 4.29228C93.1525 5.91164 92.7571 6.27701 92.345 6.65786C91.8813 7.08636 91.3964 7.53445 92.2351 9.81019C93.3295 11.7435 93.0304 12.1388 92.6746 12.6092C92.3315 13.0627 91.9357 13.586 92.6853 15.6248C92.8781 16.7703 92.5408 17.1988 92.2378 17.5839C91.8286 18.1039 91.4817 18.5446 92.5869 20.5648C92.5869 21.4934 92.2337 22.1483 91.9446 22.6844C91.4996 23.5097 91.2063 24.0535 92.5868 24.8807C93.2611 26.5904 92.6754 27.3288 92.1195 28.0299C91.4254 28.9051 90.7776 29.7219 92.685 32.2974C93.0646 33.5253 92.7405 34.1634 92.3923 34.8491C91.9006 35.8172 91.3607 36.8803 92.6855 39.8326C92.6855 41.5725 92.2093 41.8859 91.7931 42.1599C91.2158 42.54 90.7539 42.8441 91.8388 46.7748C92.1494 49.1686 91.4859 51.7598 90.4766 53.4871V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_6021_16536" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_6021_16536)"/>
                                    <mask id="path-11-outside-4_6021_16536" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-11-outside-4_6021_16536)"/>
                                    <mask id="path-13-outside-5_6021_16536" maskUnits="userSpaceOnUse" x="31" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="31" width="10" height="104"/>
                                    <path d="M32 0H40V104H32V0Z"/>
                                    </mask>
                                    <path d="M32 0H40V104H32V0Z" fill="white"/>
                                    <path d="M39 0V104H41V0H39ZM33 104V0H31V104H33Z" fill="#B6C2D0" mask="url(#path-13-outside-5_6021_16536)"/>
                                    <mask id="path-15-outside-6_6021_16536" maskUnits="userSpaceOnUse" x="70" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="70" width="10" height="104"/>
                                    <path d="M71 0H79V104H71V0Z"/>
                                    </mask>
                                    <path d="M71 0H79V104H71V0Z" fill="white"/>
                                    <path d="M78 0V104H80V0H78ZM72 104V0H70V104H72Z" fill="#B6C2D0" mask="url(#path-15-outside-6_6021_16536)"/>
                                    <mask id="path-17-outside-7_6021_16536" maskUnits="userSpaceOnUse" x="61" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="61" width="10" height="104"/>
                                    <path d="M62 0H70V104H62V0Z"/>
                                    </mask>
                                    <path d="M62 0H70V104H62V0Z" fill="white"/>
                                    <path d="M69 0V104H71V0H69ZM63 104V0H61V104H63Z" fill="#B6C2D0" mask="url(#path-17-outside-7_6021_16536)"/>
                                    <mask id="path-19-outside-8_6021_16536" maskUnits="userSpaceOnUse" x="52" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="52" width="10" height="104"/>
                                    <path d="M53 0H61V104H53V0Z"/>
                                    </mask>
                                    <path d="M53 0H61V104H53V0Z" fill="white"/>
                                    <path d="M60 0V104H62V0H60ZM54 104V0H52V104H54Z" fill="#B6C2D0" mask="url(#path-19-outside-8_6021_16536)"/>
                                    <rect x="40" width="14" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="75" height="104" viewBox="0 0 73 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="14" y="101" width="47" height="3" fill="#B6C2D0"/>
                                    <mask id="path-2-outside-1_6021_16509" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-2-outside-1_6021_16509)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4844C1.88571 54.1803 1.19373 55.4271 0.41304 57.5383C-0.152521 59.0677 0.24287 59.4128 0.655007 59.7725C1.11871 60.1772 1.60361 60.6004 0.764893 62.7497C-0.329534 64.5756 -0.030444 64.949 0.325393 65.3933C0.668488 65.8216 1.06434 66.3158 0.314701 68.2414C0.121937 69.3232 0.459179 69.7279 0.762211 70.0916C1.17144 70.5828 1.51829 70.999 0.413117 72.907C0.413067 73.784 0.766251 74.4025 1.05537 74.9089C1.50041 75.6883 1.79366 76.2019 0.413183 76.9832C-0.261059 78.5979 0.324575 79.2954 0.880538 79.9575C1.5746 80.784 2.22242 81.5555 0.315041 83.9879C-0.0646114 85.1477 0.259459 85.7503 0.6077 86.3979C1.0994 87.3123 1.63929 88.3163 0.314519 91.1047C0.314519 92.7479 0.790691 93.044 1.20687 93.3027C1.78422 93.6617 2.24611 93.9489 1.16116 97.6613C0.850626 99.9221 1.51406 102.369 2.52344 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.030444 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.297C-0.0646113 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-6-outside-2_6021_16509" maskUnits="userSpaceOnUse" x="61" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="61" width="12" height="104"/>
                                    <path d="M70 0H62V101H70V0Z"/>
                                    </mask>
                                    <path d="M70 0H62V101H70V0Z" fill="white"/>
                                    <path d="M62 101V104H61V101H62ZM70 101H73V104H70V101ZM63 0V101H61V0H63ZM62 98H70V104H62V98ZM67 101V0H73V101H67Z" fill="#B6C2D0" mask="url(#path-6-outside-2_6021_16509)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 53.4844C73.1143 54.1803 73.8063 55.4271 74.587 57.5383C75.1525 59.0677 74.7571 59.4128 74.345 59.7725C73.8813 60.1772 73.3964 60.6004 74.2351 62.7497C75.3295 64.5756 75.0304 64.949 74.6746 65.3933C74.3315 65.8216 73.9357 66.3158 74.6853 68.2414C74.8781 69.3232 74.5408 69.7279 74.2378 70.0916C73.8286 70.5828 73.4817 70.999 74.5869 72.907C74.5869 73.784 74.2337 74.4025 73.9446 74.9089C73.4996 75.6883 73.2063 76.2019 74.5868 76.9832C75.2611 78.5979 74.6754 79.2954 74.1195 79.9575C73.4254 80.784 72.7776 81.5555 74.685 83.9879C75.0646 85.1477 74.7405 85.7503 74.3923 86.3979C73.9006 87.3123 73.3607 88.3163 74.6855 91.1047C74.6855 92.7479 74.2093 93.044 73.7931 93.3027C73.2158 93.6617 72.7539 93.9489 73.8388 97.6613C74.1494 99.9221 73.4859 102.369 72.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.4766 0C73.1143 0.736893 73.8063 2.05693 74.587 4.29223C75.1525 5.91156 74.7571 6.27693 74.345 6.65778C73.8813 7.08627 73.3964 7.53436 74.2351 9.81006C75.3295 11.7433 75.0304 12.1387 74.6746 12.609C74.3315 13.0626 73.9357 13.5858 74.6853 15.6246C74.8781 16.77 74.5408 17.1986 74.2378 17.5836C73.8286 18.1037 73.4817 18.5444 74.5869 20.5645C74.5869 21.4931 74.2337 22.148 73.9446 22.6841C73.4996 23.5094 73.2063 24.0532 74.5868 24.8804C75.2611 26.59 74.6754 27.3285 74.1195 28.0295C73.4254 28.9047 72.7776 29.7216 74.685 32.297C75.0646 33.5249 74.7405 34.163 74.3923 34.8487C73.9006 35.8168 73.3607 36.8798 74.6855 39.8321C74.6855 41.5719 74.2093 41.8854 73.7931 42.1594C73.2158 42.5394 72.7539 42.8435 73.8388 46.7742C74.1494 49.168 73.4859 51.7591 72.4766 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-10-outside-3_6021_16509" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-10-outside-3_6021_16509)"/>
                                    <mask id="path-12-outside-4_6021_16509" maskUnits="userSpaceOnUse" x="22" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="22" width="10" height="104"/>
                                    <path d="M23 0H31V104H23V0Z"/>
                                    </mask>
                                    <path d="M23 0H31V104H23V0Z" fill="white"/>
                                    <path d="M30 0V104H32V0H30ZM24 104V0H22V104H24Z" fill="#B6C2D0" mask="url(#path-12-outside-4_6021_16509)"/>
                                    <mask id="path-14-outside-5_6021_16509" maskUnits="userSpaceOnUse" x="52" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="52" width="10" height="104"/>
                                    <path d="M53 0H61V104H53V0Z"/>
                                    </mask>
                                    <path d="M53 0H61V104H53V0Z" fill="white"/>
                                    <path d="M60 0V104H62V0H60ZM54 104V0H52V104H54Z" fill="#B6C2D0" mask="url(#path-14-outside-5_6021_16509)"/>
                                    <mask id="path-16-outside-6_6021_16509" maskUnits="userSpaceOnUse" x="43" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="43" width="10" height="104"/>
                                    <path d="M44 0H52V104H44V0Z"/>
                                    </mask>
                                    <path d="M44 0H52V104H44V0Z" fill="white"/>
                                    <path d="M51 0V104H53V0H51ZM45 104V0H43V104H45Z" fill="#B6C2D0" mask="url(#path-16-outside-6_6021_16509)"/>
                                    <rect x="31" width="14" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="57" height="107" viewBox="0 0 57 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-outside-1_6021_16462" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="107" fill="black">
                                    <rect fill="white" x="2" width="12" height="107"/>
                                    <path d="M5 0H13V104H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V104H5V0Z" fill="white"/>
                                    <path d="M13 104V107H14V104H13ZM5 104H2V107H5V104ZM12 0V104H14V0H12ZM13 101H5V107H13V101ZM8 104V0H2V104H8Z" fill="#B6C2D0" mask="url(#path-1-outside-1_6021_16462)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4844C1.88571 54.1803 1.19373 55.4271 0.41304 57.5383C-0.152521 59.0677 0.24287 59.4128 0.655007 59.7725C1.11871 60.1772 1.60361 60.6004 0.764893 62.7497C-0.329534 64.5756 -0.030444 64.949 0.325393 65.3933C0.668488 65.8216 1.06434 66.3158 0.314701 68.2414C0.121937 69.3232 0.459179 69.7279 0.762211 70.0916C1.17144 70.5828 1.51829 70.999 0.413117 72.907C0.413067 73.784 0.766251 74.4025 1.05537 74.9089C1.50041 75.6883 1.79366 76.2019 0.413183 76.9832C-0.261059 78.5979 0.324575 79.2954 0.880538 79.9575C1.5746 80.784 2.22242 81.5555 0.315041 83.9879C-0.0646114 85.1477 0.259459 85.7503 0.6077 86.3979C1.0994 87.3123 1.63929 88.3163 0.314519 91.1047C0.314519 92.7479 0.790691 93.044 1.20687 93.3027C1.78422 93.6617 2.24611 93.9489 1.16116 97.6613C0.850626 99.9221 1.51406 102.369 2.52344 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.030444 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.297C-0.0646113 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-5-outside-2_6021_16462" maskUnits="userSpaceOnUse" x="43" y="0" width="12" height="107" fill="black">
                                    <rect fill="white" x="43" width="12" height="107"/>
                                    <path d="M52 0H44V104H52V0Z"/>
                                    </mask>
                                    <path d="M52 0H44V104H52V0Z" fill="white"/>
                                    <path d="M44 104V107H43V104H44ZM52 104H55V107H52V104ZM45 0V104H43V0H45ZM44 101H52V107H44V101ZM49 104V0H55V104H49Z" fill="#B6C2D0" mask="url(#path-5-outside-2_6021_16462)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.4766 53.4844C55.1143 54.1803 55.8063 55.4271 56.587 57.5383C57.1525 59.0677 56.7571 59.4128 56.345 59.7725C55.8813 60.1772 55.3964 60.6004 56.2351 62.7497C57.3295 64.5756 57.0304 64.949 56.6746 65.3933C56.3315 65.8216 55.9357 66.3158 56.6853 68.2414C56.8781 69.3232 56.5408 69.7279 56.2378 70.0916C55.8286 70.5828 55.4817 70.999 56.5869 72.907C56.5869 73.784 56.2337 74.4025 55.9446 74.9089C55.4996 75.6883 55.2063 76.2019 56.5868 76.9832C57.2611 78.5979 56.6754 79.2954 56.1195 79.9575C55.4254 80.784 54.7776 81.5555 56.685 83.9879C57.0646 85.1477 56.7405 85.7503 56.3923 86.3979C55.9006 87.3123 55.3607 88.3163 56.6855 91.1047C56.6855 92.7479 56.2093 93.044 55.7931 93.3027C55.2158 93.6617 54.7539 93.9489 55.8388 97.6613C56.1494 99.9221 55.4859 102.369 54.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.4766 0C55.1143 0.736893 55.8063 2.05693 56.587 4.29223C57.1525 5.91156 56.7571 6.27693 56.345 6.65778C55.8813 7.08627 55.3964 7.53436 56.2351 9.81006C57.3295 11.7433 57.0304 12.1387 56.6746 12.609C56.3315 13.0626 55.9357 13.5858 56.6853 15.6246C56.8781 16.77 56.5408 17.1986 56.2378 17.5836C55.8286 18.1037 55.4817 18.5444 56.5869 20.5645C56.5869 21.4931 56.2337 22.148 55.9446 22.6841C55.4996 23.5094 55.2063 24.0532 56.5868 24.8804C57.2611 26.59 56.6754 27.3285 56.1195 28.0295C55.4254 28.9047 54.7776 29.7216 56.685 32.297C57.0646 33.5249 56.7405 34.163 56.3923 34.8487C55.9006 35.8168 55.3607 36.8798 56.6855 39.8321C56.6855 41.5719 56.2093 41.8854 55.7931 42.1594C55.2158 42.5394 54.7539 42.8435 55.8388 46.7742C56.1494 49.168 55.4859 51.7591 54.4766 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-9-outside-3_6021_16462" maskUnits="userSpaceOnUse" x="13" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="13" width="10" height="104"/>
                                    <path d="M14 0H22V104H14V0Z"/>
                                    </mask>
                                    <path d="M14 0H22V104H14V0Z" fill="white"/>
                                    <path d="M21 0V104H23V0H21ZM15 104V0H13V104H15Z" fill="#B6C2D0" mask="url(#path-9-outside-3_6021_16462)"/>
                                    <mask id="path-11-outside-4_6021_16462" maskUnits="userSpaceOnUse" x="34" y="0" width="10" height="104" fill="black">
                                    <rect fill="white" x="34" width="10" height="104"/>
                                    <path d="M35 0H43V104H35V0Z"/>
                                    </mask>
                                    <path d="M35 0H43V104H35V0Z" fill="white"/>
                                    <path d="M42 0V104H44V0H42ZM36 104V0H34V104H36Z" fill="#B6C2D0" mask="url(#path-11-outside-4_6021_16462)"/>
                                    <rect x="22" width="14" height="104" fill="#EDF2F8"/>
                                </svg>
                                <svg width="39" height="145" viewBox="0 0 37 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="2" y="101" width="35" height="3" fill="#B6C2D0"/>
                                    <mask id="path-2-outside-1_6021_16486" maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="2" width="12" height="104"/>
                                    <path d="M5 0H13V101H5V0Z"/>
                                    </mask>
                                    <path d="M5 0H13V101H5V0Z" fill="white"/>
                                    <path d="M13 101V104H14V101H13ZM5 101H2V104H5V101ZM12 0V101H14V0H12ZM13 98H5V104H13V98ZM8 101V0H2V101H8Z" fill="#B6C2D0" mask="url(#path-2-outside-1_6021_16486)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 53.4844C1.88571 54.1803 1.19373 55.4271 0.41304 57.5383C-0.152521 59.0677 0.24287 59.4128 0.655007 59.7725C1.11871 60.1772 1.60361 60.6004 0.764893 62.7497C-0.329534 64.5756 -0.030444 64.949 0.325393 65.3933C0.668488 65.8216 1.06434 66.3158 0.314701 68.2414C0.121937 69.3232 0.459179 69.7279 0.762211 70.0916C1.17144 70.5828 1.51829 70.999 0.413117 72.907C0.413067 73.784 0.766251 74.4025 1.05537 74.9089C1.50041 75.6883 1.79366 76.2019 0.413183 76.9832C-0.261059 78.5979 0.324575 79.2954 0.880538 79.9575C1.5746 80.784 2.22242 81.5555 0.315041 83.9879C-0.0646114 85.1477 0.259459 85.7503 0.6077 86.3979C1.0994 87.3123 1.63929 88.3163 0.314519 91.1047C0.314519 92.7479 0.790691 93.044 1.20687 93.3027C1.78422 93.6617 2.24611 93.9489 1.16116 97.6613C0.850626 99.9221 1.51406 102.369 2.52344 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52344 0C1.88571 0.736893 1.19373 2.05693 0.41304 4.29223C-0.152521 5.91156 0.24287 6.27693 0.655007 6.65778C1.11871 7.08627 1.60361 7.53436 0.764893 9.81006C-0.329534 11.7433 -0.030444 12.1387 0.325393 12.609C0.668488 13.0626 1.06434 13.5858 0.314701 15.6246C0.121937 16.77 0.459179 17.1986 0.762211 17.5836C1.17144 18.1037 1.51829 18.5444 0.413117 20.5645C0.413067 21.4931 0.766251 22.148 1.05537 22.6841C1.50041 23.5094 1.79366 24.0532 0.413183 24.8804C-0.261059 26.59 0.324575 27.3285 0.880538 28.0295C1.5746 28.9047 2.22242 29.7216 0.315041 32.297C-0.0646113 33.5249 0.259458 34.163 0.6077 34.8487C1.0994 35.8168 1.63929 36.8798 0.314519 39.8321C0.314519 41.5719 0.790691 41.8854 1.20687 42.1594C1.78422 42.5394 2.24611 42.8435 1.16116 46.7742C0.850626 49.168 1.51406 51.7591 2.52344 53.4864V0Z" fill="#B6C2D0"/>
                                    <mask id="path-6-outside-2_6021_16486" maskUnits="userSpaceOnUse" x="25" y="0" width="12" height="104" fill="black">
                                    <rect fill="white" x="25" width="12" height="104"/>
                                    <path d="M34 0H26V101H34V0Z"/>
                                    </mask>
                                    <path d="M34 0H26V101H34V0Z" fill="white"/>
                                    <path d="M26 101V104H25V101H26ZM34 101H37V104H34V101ZM27 0V101H25V0H27ZM26 98H34V104H26V98ZM31 101V0H37V101H31Z" fill="#B6C2D0" mask="url(#path-6-outside-2_6021_16486)"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M36.4766 53.4844C37.1143 54.1803 37.8063 55.4271 38.587 57.5383C39.1525 59.0677 38.7571 59.4128 38.345 59.7725C37.8813 60.1772 37.3964 60.6004 38.2351 62.7497C39.3295 64.5756 39.0304 64.949 38.6746 65.3933C38.3315 65.8216 37.9357 66.3158 38.6853 68.2414C38.8781 69.3232 38.5408 69.7279 38.2378 70.0916C37.8286 70.5828 37.4817 70.999 38.5869 72.907C38.5869 73.784 38.2337 74.4025 37.9446 74.9089C37.4996 75.6883 37.2063 76.2019 38.5868 76.9832C39.2611 78.5979 38.6754 79.2954 38.1195 79.9575C37.4254 80.784 36.7776 81.5555 38.685 83.9879C39.0646 85.1477 38.7405 85.7503 38.3923 86.3979C37.9006 87.3123 37.3607 88.3163 38.6855 91.1047C38.6855 92.7479 38.2093 93.044 37.7931 93.3027C37.2158 93.6617 36.7539 93.9489 37.8388 97.6613C38.1494 99.9221 37.4859 102.369 36.4766 104.001V53.4844Z" fill="#B6C2D0"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M36.4766 0C37.1143 0.736893 37.8063 2.05693 38.587 4.29223C39.1525 5.91156 38.7571 6.27693 38.345 6.65778C37.8813 7.08627 37.3964 7.53436 38.2351 9.81006C39.3295 11.7433 39.0304 12.1387 38.6746 12.609C38.3315 13.0626 37.9357 13.5858 38.6853 15.6246C38.8781 16.77 38.5408 17.1986 38.2378 17.5836C37.8286 18.1037 37.4817 18.5444 38.5869 20.5645C38.5869 21.4931 38.2337 22.148 37.9446 22.6841C37.4996 23.5094 37.2063 24.0532 38.5868 24.8804C39.2611 26.59 38.6754 27.3285 38.1195 28.0295C37.4254 28.9047 36.7776 29.7216 38.685 32.297C39.0646 33.5249 38.7405 34.163 38.3923 34.8487C37.9006 35.8168 37.3607 36.8798 38.6855 39.8321C38.6855 41.5719 38.2093 41.8854 37.7931 42.1594C37.2158 42.5394 36.7539 42.8435 37.8388 46.7742C38.1494 49.168 37.4859 51.7591 36.4766 53.4864V0Z" fill="#B6C2D0"/>
                                    <rect x="13" width="14" height="101" fill="#EDF2F8"/>
                                    <rect x="13" y="101" width="14" height="44" fill="#EDF2F8"/>
                                </svg>
                            </template>
                        </div>
                    </div>
                    </transition>
                </div>
            </div>
        </div>
        <div v-else>
            <h1>Скважины еще не добавлены</h1>
        </div>
    </div>
</template>
<script>
import Menubar from "../../components/ui-components/oil-menubar/menubar-cmp.vue"
import axios from "axios"
import constructTable from "../../components/ui-components/oil-table-drilling/construct-table/construct-table.vue"
import profileTable from "../../components/ui-components/oil-table-drilling/profile-table/profile-table.vue"
import selectInput from "../../components/ui-components/oil-select-input/oil-select-input.vue"
import { mapState } from 'vuex'

export default {
    data() {
        return {
            active: "Profile",
            perforation: false,
            open_face: true,
            menubar: [
                {
                    text: "Профиль",
                    id: "Profile"
                },
                {
                    text: "Конструкция",
                    id: "Construction"
                },
            ],
            level: 1,
            boreholeId: 0,
            table: [],
            construct: [],
            profile: [],
            size: 0,
            list: {
                items: []
            },
            selectInputVisible: false,
            visible: false
        }
    },
    computed: {
        // ...mapGetters('request', ['update']),
        ...mapState('request', ['update', 'host', 'updateInterval']),
    },
    components: {
        'menubar-cmp': Menubar,
        'construct-table': constructTable,
        'profile-table': profileTable,
        'select-input': selectInput
    },
    watch: {
        updateInterval() {
            if(this.updateInterval) {
                const urls = [
                    `${this.host}Views/Browser/Drilling/${this.active}?id=${this.boreholeId}`,
                ]
                axios.all(urls.map((endpoint) => axios.get(endpoint)))
                    .then(
                        axios.spread((resp) => {
                            this.size = resp.data.content.content.length
                            if(this.active === "Profile") {
                                this.profile = resp.data
                            } else {
                                this.construct = resp.data
                            }
                        })
                );  
            }
        },
        list() {
            this.visible = false
            const urls = [
                `${this.host}Views/Browser/Drilling/${this.active}?id=${this.boreholeId}`,
            ]
            axios.all(urls.map((endpoint) => axios.get(endpoint)))
                .then(
                    axios.spread((resp) => {
                        // this.size = resp.data.content.content.length
                        this.table = [resp.data.header, resp.data.content]
                    })
                // .catch(() => this.$root.visibleNotification({
                //             props: {
                //                 error: true,
                //                 title: "Ошибка",
                //                 desc: 'Что-то пошло не так, попробуйте позже'
                //             }
                //         }))    
            );  
            this.selectInputVisible = true
            if(Number(this.$route.params.id) === 0 && this.list.items.length) {
                this.visible = true
                this.boreholeId = this.list.items[0].id
            } else if(this.list.items.length) {
                this.visible = true
                this.boreholeId = Number(this.$route.params.id)
            }
        },
        update() {
            const urls = [
                `${this.host}Views/Browser/Drilling/${this.active}?id=${this.boreholeId}`,
            ]
            axios.all(urls.map((endpoint) => axios.get(endpoint)))
                .then(
                    axios.spread((resp) => {
                        this.size = resp.data.content.content.length
                        this.table = [resp.data.header, resp.data.content]
                    })
                // .catch(() => this.$root.visibleNotification({
                //             props: {
                //                 error: true,
                //                 title: "Ошибка",
                //                 desc: 'Что-то пошло не так, попробуйте позже'
                //             }
                //         }))    
            );  
        },
        boreholeId() {
            const urls = [
                `${this.host}Views/Browser/Drilling/Construction?id=${this.boreholeId}`,
                `${this.host}Views/Browser/Drilling/Profile?id=${this.boreholeId}`
            ]
            axios.all(urls.map((endpoint) => axios.get(endpoint)))
                .then(
                    axios.spread((firstResp, secondResp) => {
                        this.size = firstResp.data.content.content.length
                        this.construct = firstResp.data 
                        this.profile = secondResp.data 
                    })
            )
            // .catch(() => this.$root.visibleNotification({
            //                 props: {
            //                     error: true,
            //                     title: "Ошибка",
            //                     desc: 'Что-то пошло не так, попробуйте позже'
            //                 }
            //             }))
        }
    },
    methods: {
        mappingBorehole() {
            const pillar = this.construct.content.content.filter(item => item.interval === 'Обсадная колонна').length
            
            if(this.construct.content.content.find(item => item.interval === 'Эксплуатационная колонна')) {
                const open_face = this.construct.content.content.find(item => item.interval === 'Эксплуатационная колонна').subrow.find(name => name.name === 'Цементирование').value

                if(open_face === 'True') {
                    if(pillar === 1) {
                        return 1
                    } else if(pillar === 2) {
                        return 2
                    } else if(pillar === 3) {
                        return 3 
                    } else {
                        return 4
                    }
                } else {
                    if(!pillar) {
                        return 11
                    } else if(pillar === 1) {
                        return 12
                    } else if(pillar === 2) {
                        return 13
                    } else if(pillar === 3) {
                        return 14
                    }
                }
            } else {
                if(pillar === 1) {
                    return 21
                } else if(pillar === 2) {
                    return 22
                } else if(pillar === 3) {
                    return 23
                }   
            }
           
            // if(pillar === 1) {
            //     return 1
            // } else if(pillar === 2) {
            //     return 2
            // } else if(!pillar && open_face === 'False') {
            //     return 4
            // } else if(pillar === 2 && open_face === 'False') {
            //     return 14
            // }

        },
        selectBorehole(val) {
            this.boreholeId = val
        },
        selectMenu(val) {
            this.active = val;
            const url = `Views/Browser/Drilling/${this.active}?id=${this.boreholeId}`
            this.$root.getInfo(url)
                .then((resp) => {this.table = [resp.header, resp.content]})
                .catch(() => this.$root.visibleNotification({
                    props: {
                        error: true,
                        title: "Ошибка",
                        desc: 'Что-то пошло не так, попробуйте позже'
                    }
                })
            )
        },
    },
    created() {
        if(this.$route.params.id === 0 && this.list.items.length) {
            this.boreholeId = this.list.items[0].id
            this.visible = true
        } else if(this.list.items.length) {
            this.visible = true
        }


        const url = 'Views/Browser/Map/Borehole/Info'
        this.$root.getInfo(url)
            .then((resp) => {this.list = resp})
            .catch(() => this.$root.visibleNotification({
                props: {
                    error: true,
                    title: "Ошибка",
                    desc: 'Что-то пошло не так, попробуйте позже'
                }
            }))     
        

        const urls = [
            `${this.host}Views/Browser/Drilling/Profile?id=${this.boreholeId}`,
            `${this.host}Views/Browser/Drilling/Construction?id=${this.boreholeId}`,
        ]
        axios.all(urls.map((endpoint) => axios.get(endpoint)))
            .then(
                axios.spread((firstResp, secondResp) => {
                    this.profile = firstResp.data 
                    this.construct = secondResp.data 
                })
        )

        
        // .catch(() => this.$root.visibleNotification({
        //                     props: {
        //                         error: true,
        //                         title: "Ошибка",
        //                         desc: 'Что-то пошло не так, попробуйте позже'
        //                     }
        //                 }))
    }
}
</script>
