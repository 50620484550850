<template>
    <div class="oil-scene oil-container">
        <div class="oil-scene__model" id="scene" v-if="active_page">

        </div>
        <div class="oil-scene__view">
            <view-cmp 
                :scene="scene"
            />
        </div>
    </div>
</template>
<script>
import { CanvasScene } from '../../components/canvas-scene/canvas'
import axios from 'axios'
import { mapState } from 'vuex'
import View from "../../components/ui-components/oil-view/view-cmp.vue"

    export default {
        data() {
            return {
                scene: [],
                active_page: true
            }
        },
        components: {
            'view-cmp': View
        },
        computed: {
            ...mapState('request', ['host'])
        },
        mixins: [CanvasScene],
        mounted() {
            const url = `${this.host}Views/Browser/map/map/MapObject/object`
            axios.get(url).then((resp) => this.scene = resp.data.items).catch(() => this.$root.visibleNotification({
                props: {
                    error: true,
                    title: "Ошибка",
                    desc: 'Что-то пошло не так, попробуйте позже'
                }
            }))
            // this.$root.getInfo(url)
            //     .then((resp) => {this.scene = resp.items})
            //     .catch(() => this.$root.visibleNotification({
            //         props: {
            //             error: true,
            //             title: "Ошибка",
            //             desc: 'Что-то пошло не так, попробуйте позже'
            //         }
            //     }))      
        },
        unmounted() {
            this.active_page = false
        }
    }
</script>
