import { createStore } from 'vuex'
import request from './modules/request'
import modal from './modules/modal'
import curtain from './modules/curtain'
import notification from './modules/notification'
import building from './modules/building'


const store = createStore({
    state: {},
    mutations: {},
    modules: {
        request,
        modal,
        curtain,
        notification,
        building
    },
    actions: {}
})

export default store