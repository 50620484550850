<template>
    <div class="oil-borehole-table">
        <template v-if="view === 'header'">
            <div class="oil-borehole-table__row"  v-for="item in table" :key="item">
                <span class="oil-borehole-table__row-cell _header">{{ item.boreholes.text }}
                </span>
                <span class="oil-borehole-table__row-cell _header"  @mouseenter="visibleTooltip = item.status.text" @mouseleave="visibleTooltip = false">{{ item.status.text }}
                    <transition name="fade">
                        <div class="oil-borehole-table__row-cell-tooltip" v-if="item.status.tooltip && visibleTooltip === item.status.text">{{ item.status.tooltip }}</div>
                    </transition>
                </span>
                <span class="oil-borehole-table__row-cell _header"  @mouseenter="visibleTooltip = item.tu.text" @mouseleave="visibleTooltip = false">{{ item.tu.text }}
                    <transition name="fade">
                        <div class="oil-borehole-table__row-cell-tooltip" v-if="item.tu.tooltip && visibleTooltip === item.tu.text">{{ item.tu.tooltip }}</div>
                    </transition>
                </span>
                <span class="oil-borehole-table__row-cell _header"  @mouseenter="visibleTooltip = item.capex.text" @mouseleave="visibleTooltip = false">{{ item.capex.text }}
                    <transition name="fade">
                        <div class="oil-borehole-table__row-cell-tooltip" v-if="item.capex.tooltip && visibleTooltip === item.capex.text">{{ item.capex.tooltip }}</div>
                    </transition>
                </span>
                <span class="oil-borehole-table__row-cell _header"  @mouseenter="visibleTooltip = item.currentOpex.text" @mouseleave="visibleTooltip = false">{{ item.currentOpex.text }}
                    <transition name="fade">
                        <div class="oil-borehole-table__row-cell-tooltip" v-if="item.currentOpex.tooltip && visibleTooltip === item.currentOpex.text">{{ item.currentOpex.tooltip }}</div>
                    </transition>
                </span>
                <span class="oil-borehole-table__row-cell _header"  @mouseenter="visibleTooltip = item.wfu.text" @mouseleave="visibleTooltip = false">{{ item.wfu.text }}
                    <transition name="fade">
                        <div class="oil-borehole-table__row-cell-tooltip" v-if="item.wfu.tooltip && visibleTooltip === item.wfu.text">{{ item.wfu.tooltip }}</div>
                    </transition>
                </span>
                <span class="oil-borehole-table__row-cell _header"  @mouseenter="visibleTooltip = item.eu.text" @mouseleave="visibleTooltip = false">{{ item.eu.text }}
                    <transition name="fade">
                        <div class="oil-borehole-table__row-cell-tooltip" v-if="item.eu.tooltip && visibleTooltip === item.eu.text">{{ item.eu.tooltip }}</div>
                    </transition>
                </span>
                <span class="oil-borehole-table__row-cell _header">Дорога/трубы</span>
            </div>
        </template>
       
        <template v-if="view === 'content'">
            <div v-for="item in table" :key="item" class="oil-borehole-table__row _table">
                <div class="oil-borehole-table__row-cell _name">
                    <span>{{ item.boreholes }}</span>
                    <span class="_coords">
                        Ячейка 
                            x {{ item.coords.cell.x }}
                            y {{ item.coords.cell.y }},
                        участок   
                            x {{ item.coords.region.x }}
                            y {{ item.coords.region.y }} 
                    </span>
                </div>
                <div class="oil-borehole-table__row-cell">
                    <span class="_status" :style="{background: item.statusColor}" @click="openModal({
                        newState: 'statusModal',
                        props: {
                            text: 'скважины',
                            type: 'Borehole',
                            url: `${host}Views/Browser/Map/ObjectsAndResearches/BoreholeChangeStatus?boreholeId=${item.id}`,
                            id: item.id
                        }
                    })">{{ item.status }}</span>
                </div>
                <span class="oil-borehole-table__row-cell">{{ item.tu }}</span>
                <span class="oil-borehole-table__row-cell">{{ item.capex }}</span>
                <span class="oil-borehole-table__row-cell">{{ item.currentOpex }}</span>
                <span class="oil-borehole-table__row-cell">{{ item.wfu }}</span>
                <span class="oil-borehole-table__row-cell">{{ item.eu }}</span>
                <switch-cmp 
                    :activeToggle="item.typeOfDelivery"
                    @toggle="changeSellOil($event, item.id)"
                />
                <svg @click="openDetailedPage(item.id)" class="oil-borehole-table__row-table" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 9.99999L8 9.99999M18 4.99999L8 4.99999M18 15L8 15M4.66667 9.99999C4.66667 10.4602 4.29357 10.8333 3.83333 10.8333C3.3731 10.8333 3 10.4602 3 9.99999C3 9.53975 3.3731 9.16666 3.83333 9.16666C4.29357 9.16666 4.66667 9.53975 4.66667 9.99999ZM4.66667 4.99999C4.66667 5.46023 4.29357 5.83332 3.83333 5.83332C3.3731 5.83332 3 5.46023 3 4.99999C3 4.53975 3.3731 4.16666 3.83333 4.16666C4.29357 4.16666 4.66667 4.53975 4.66667 4.99999ZM4.66667 15C4.66667 15.4602 4.29357 15.8333 3.83333 15.8333C3.3731 15.8333 3 15.4602 3 15C3 14.5398 3.3731 14.1667 3.83333 14.1667C4.29357 14.1667 4.66667 14.5398 4.66667 15Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg class="oil-borehole-table__row-basket" @click="deleteBorehole(item.id)" v-if="item.status === 'Планирование'" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.8333 4.99999V4.33332C13.8333 3.3999 13.8333 2.93319 13.6517 2.57667C13.4919 2.26307 13.2369 2.0081 12.9233 1.84831C12.5668 1.66666 12.1001 1.66666 11.1667 1.66666H9.83333C8.89991 1.66666 8.4332 1.66666 8.07668 1.84831C7.76308 2.0081 7.50811 2.26307 7.34832 2.57667C7.16667 2.93319 7.16667 3.3999 7.16667 4.33332V4.99999M3 4.99999H18M16.3333 4.99999V14.3333C16.3333 15.7335 16.3333 16.4335 16.0608 16.9683C15.8212 17.4387 15.4387 17.8212 14.9683 18.0608C14.4335 18.3333 13.7335 18.3333 12.3333 18.3333H8.66667C7.26654 18.3333 6.56647 18.3333 6.03169 18.0608C5.56129 17.8212 5.17883 17.4387 4.93915 16.9683C4.66667 16.4335 4.66667 15.7335 4.66667 14.3333V4.99999" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg v-if="!item.id && item.tu !== '0'" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.4998 4.99999V9.99999L13.8332 11.6667M18.8332 9.99999C18.8332 14.6024 15.1022 18.3333 10.4998 18.3333C5.89746 18.3333 2.1665 14.6024 2.1665 9.99999C2.1665 5.39762 5.89746 1.66666 10.4998 1.66666C15.1022 1.66666 18.8332 5.39762 18.8332 9.99999Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="oil-borehole-table__row _final">
                <span class="oil-borehole-table__row-cell">Итого</span>
                <span class="oil-borehole-table__row-cell"></span>
                <span class="oil-borehole-table__row-cell"></span>
                <span class="oil-borehole-table__row-cell">{{ sumCapex(table).toFixed(2) }}</span>
                <span class="oil-borehole-table__row-cell">{{ sumCurrentOpex(table).toFixed(2) }}</span>
                <span class="oil-borehole-table__row-cell">{{ sumWfu(table) }}</span>
                <span class="oil-borehole-table__row-cell">{{ sumEu(table) }}</span>
            </div>
        </template>
    </div>
</template>
<script> 
    import axios from 'axios'
    import { mapMutations, mapState } from 'vuex'
    import switchCmp from '../../oil-switch/switch-cmp.vue'

    export default {
        props: {
            view: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: ''
            },
            table: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                visibleTooltip: false
            }
        },
        computed: {
            ...mapState('request', ['host'])
        },
        components: {
            'switch-cmp': switchCmp
        },
        methods: {
            ...mapMutations('request', ['updateMap']),
            ...mapMutations('modal', ['openModal']),

            // setTypeDelivery(id) {
            //     axios
            //         .patch(`${this.host}api/v1/Purchased/Borehole/DeliveryType`, {
            //             boreholeId: id,
            //             typeOfDelivery: this.changeSellOil() ? "ByTube" : "ByRoad"
            //         })
            //         .then(resp => {
            //             console.log(resp);
            //         })
            //         .catch(err => {
            //             this.$root.visibleNotification({
            //                 props: {
            //                     error: true,
            //                     title: "Ошибка",
            //                     desc: err.data
            //                 }
            //             })
            //         })
            // },
            changeSellOil(val, id) {
                this.$emit('curtain_active', true)
                axios
                    .patch(`${this.host}api/v1/Purchased/Borehole/DeliveryType`, {
                        Id: id,
                        typeOfDelivery: val ? "ByTube" : "ByRoad"
                    })
                    .then(resp => {
                        this.$root.visibleNotification({
                            props: {
                                success: true,
                                title: "Ошибка",
                                desc: resp.data
                            }
                        })
                    })
                    .catch(err => {
                        console.log(err);
                        this.$root.visibleNotification({
                            props: {
                                error: true,
                                title: "Ошибка",
                                desc: err.response.data
                            }
                        })
                    })
                    .finally(() => {
                        this.$emit('curtain_active', false)
                        this.updateMap()
                    })
            },
            deleteBorehole(id) {
                axios
                    .delete(`${this.host}api/v1/Purchased/borehole/${id}`)
                    .then(reps => {
                        console.log(reps);
                    })
                    .catch(err => {
                        console.log(err);
                    })
                this.updateMap()   
            },
            openDetailedPage(id) {      
                this.$router.push(`card/${id}`)
            },
            sumCapex(val) {
                return Number(val.reduce((accumulator, currentValue) => {return  Number(accumulator) +  Number(currentValue.capex)}, 0))
            },
            sumCurrentOpex(val) {
                return Number(val.reduce((accumulator, currentValue) => {return  Number(accumulator) +  Number(currentValue.currentOpex)}, 0))
            },
            sumWfu(val) {
                return Number(val.reduce((accumulator, currentValue) => {return  Number(accumulator) +  Number(currentValue.wfu)}, 0))
            },
            sumAccumulatedOpex(val) {
                return Number(val.reduce((accumulator, currentValue) => {return Number(accumulator) +  Number(currentValue.accumulatedOpex)}, 0))
            },
            sumEu(val) {
                return val.reduce((accumulator, currentValue) => {return Number(accumulator) + Number(currentValue.eu)}, 0)
            }
        }
    }
</script>