<template>
    <div class="oil-icons" >
        <div class="oil-icons__container" ref="container" ></div>
        <span class="oil-icons__count" v-if="!selector">{{ Number(count).toLocaleString() }}</span>
        <span class="oil-icons__units">{{ units }}</span>
    </div>
</template>
<script>
    export default {
        props: {
            count: {
                type: Number,
                default: 0
            },
            units: {
                type: String,
                default: ''
            },
            selector: {
                type: Boolean,
                default: false
            },
            img: {}
        },
        mounted() {
            this.$refs.container.innerHTML = this.$props.img
        }
    }
</script>
