<template>
    <div class="oil-accordion-table">
        <template v-if="view === 'header'">
            <div class="oil-accordion-table__row _header" v-for="item in table" :key="item">
                <span class="oil-accordion-table__row-cell-text">{{ item.parametr }}</span>
                <span class="oil-accordion-table__row-cell-text">{{ item.value }}</span>
            </div>
        </template>
        <template v-if="view ==='content'">
            <div class="oil-accordion-table__row _table"  v-for="item in table" :key="item">
                <div @click="openSubtable(item.parametr)" class="oil-accordion-table__row__inner" :class="{_open: active === item.parametr}">
                    <icon-cmp 
                        :img="item.icon"
                        :selector="'true'"
                    />
                    <div class="oil-accordion-table__row-cell">
                        <span class="oil-accordion-table__row-cell-text">{{ item.parametr }}</span>
                        <span class="oil-accordion-table__row-cell-text">{{ item.value }}</span>
                    </div>
                    <svg :class="{_active: active === item.parametr}" class="oil-accordion-table__row-chevron" v-if="item.detailed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 9L12 15L18 9" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <transition-group name="fadeHeight">
                    <template v-if="active === item.parametr">
                        <div class="oil-accordion-table__row-subtable" v-for="elem in item.detailed" :key="elem" @click="detailedValues(elem.parametr)">
                            <div class="oil-accordion-table__row-subtable__inner" :class="{'_open-subtable': detailed === elem.parametr}">
                                <span class="oil-accordion-table__row-subtable__inner-cell">{{ elem.parametr }}</span>
                                <span class="oil-accordion-table__row-subtable-cell">{{ elem.value }}</span>
                                <svg :class="{_active: detailed === elem.parametr}" class="oil-accordion-table__row-subtable__inner-chevron" v-if="elem.detailed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 9L12 15L18 9" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <transition-group name="fadeHeight">
                                <template v-if="detailed === elem.parametr">
                                    <div class="oil-accordion-table__row-subtable__detailed" v-for="detailed in elem.detailed" :key="detailed">
                                    <span class="oil-accordion-table__row-subtable__detailed-cell">{{ detailed.parametr }}</span>
                                        <span class="oil-accordion-table__row-subtable__detailed-cell">{{ detailed.value }}</span>
                                    </div>
                                </template>
                            </transition-group>
                        </div>
                    </template>
                </transition-group>
            </div>
        </template>
    </div>
</template>
<script> 
import iconCmp from "../oil-icons/icons-cmp.vue"

    export default {
        props: {
            view: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: ''
            },
            table: {
                type: Array,
                default: () => []
            },
            radio: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                active: "",
                detailed: "",
                icons: [
                    {
                        icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6663 4.16663C16.6663 5.54734 13.6816 6.66663 9.99967 6.66663C6.31778 6.66663 3.33301 5.54734 3.33301 4.16663M16.6663 4.16663C16.6663 2.78591 13.6816 1.66663 9.99967 1.66663C6.31778 1.66663 3.33301 2.78591 3.33301 4.16663M16.6663 4.16663V15.8333C16.6663 17.214 13.6816 18.3333 9.99967 18.3333C6.31778 18.3333 3.33301 17.214 3.33301 15.8333V4.16663M16.6663 8.05546C16.6663 9.43617 13.6816 10.5555 9.99967 10.5555C6.31778 10.5555 3.33301 9.43617 3.33301 8.05546M16.6663 11.9416C16.6663 13.3223 13.6816 14.4416 9.99967 14.4416C6.31778 14.4416 3.33301 13.3223 3.33301 11.9416" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                    },
                    {
                        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                    },
                    {
                        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                    },
                    {
                        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9999 2L4.09332 12.6879C3.74451 13.1064 3.57011 13.3157 3.56744 13.4925C3.56512 13.6461 3.63359 13.7923 3.75312 13.8889C3.89061 14 4.16304 14 4.7079 14H11.9999L10.9999 22L19.9064 11.3121C20.2552 10.8936 20.4296 10.6843 20.4323 10.5075C20.4346 10.3539 20.3661 10.2077 20.2466 10.1111C20.1091 10 19.8367 10 19.2918 10H11.9999L12.9999 2Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
                    }
                ],
            }
        },
        watch: {
            table() {
                if(this.view !== 'header') {
                    this.table.map((item, idx) => Object.assign(item, this.icons.find((img, index) => idx === index)))
                }
            }
        },
        components: {
            'icon-cmp': iconCmp
        },
        methods: {
            openSubtable(val) {
                if(this.active === val) {
                    this.active = ""
                    this.detailed = ""
                } else {
                    this.active = val

                }
            },
            detailedValues(val) {
                if(this.detailed === val) {
                    this.detailed = ""
                } else {
                    this.detailed = val
                }
            }
        },  
        created() {
            if(this.table) {
                if(this.view !== 'header') {
                    this.table.map((item, idx) => Object.assign(item, this.icons.find((img, index) => idx === index)))
                }
            }
        }
    }
</script>