<template>
    <div class="oil-radio" @click="setValue(text, id, boreholeId)" :class="{_disable: status === 'Planned'}">
        <div class="oil-radio__button">
            <svg :class="{_active: active === id || active === 'Current'}" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="5" fill="#176DC1"/>
            </svg> 
        </div>
        <span class="oil-radio__text" v-if="text">{{ text }}</span>
        <svg class="oil-radio__clock" v-if="status === 'Planned'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
</template>
<script> 
    export default {
        props: {
            text: {
                type: String,
                default: ""
            },
            active: {
                type: String,
                default: ""
            },
            id: {
                type: Number,
                default: 1
            },
            status: {
                type: String,
                default: ''
            },
            boreholeId: {
                type: Number,
                default: null
            }
        },  
        data() {
        },
        methods: {
            setValue(text, id, boreholeId) {
                this.$emit('setRadio', text)
                this.$emit('setId', id)
                this.$emit('setBoreholeId', boreholeId)
            }
        }
    }
</script>