<template>
  <div class="oil-legend oil-container">
    <div class="oil-legend__menubar">
      <menubar-cmp
        v-for="item in legend.header"
        :key="item.id"
        :active="active"
        :id="item.id"
        class="oil-legend__menubar-item"
        :text="item.text"
        @click="selectMenu(item.id)"
      />
    </div>
    <div class="oil-legend__content">
      <rules-cmp
        v-for="item in legend.content"
        :key="item.id"
        :active="active"
        :id="item.id"
        class="oil-legend__content-item"
        :text="item.text"
      ></rules-cmp>
    </div>
  </div>
</template>

<script>
import Menubar from "../../components/ui-components/oil-menubar/menubar-cmp.vue"
import Rules from "../../components/ui-components/oil-rules/rules-cmp.vue"

export default {
  data() {
    return {
      active: 1,
      legend: {},
    };
  },
  components: {
    "menubar-cmp": Menubar,
    "rules-cmp": Rules,
  },
  methods: {
    selectMenu(val) {
      this.active = val;
    },
  },
  created() {
    const url = 'api/v1/static/text?key=mainLegend'

    this.$root.getInfo(url)
      .then((resp) => {this.legend = resp})
      .catch(() => this.$root.visibleNotification({
          props: {
              error: true,
              title: "Ошибка",
              desc: 'Что-то пошло не так, попробуйте позже'
          }
      })
    )
}
};
</script>
