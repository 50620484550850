<template>
    <div class="oil-card oil-container">
        <div class="oil-card__header">
            <div class="oil-card__header-button" @click="$router.go(-1)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 12H4M4 12L10 18M4 12L10 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Назад
            </div>
            <div class="oil-card__header-selector" @click="openSelector">
                <span class="oil-card__header-selector-text" v-text="borehole"></span>
                <svg :class="{_active: selector}" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="#9AA7BB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div class="oil-card__header-selector-list" v-if="selector">
                    <span v-for="item in wellsName.items" :key="item.id" @click="setBorehole(item.name, item.id)">{{ item.name }}</span>
                </div>
            </div>
        </div>
        <div class="oil-card__container" :class="{_edit: edit}">
            <template v-if="!edit">
                <span class="oil-card__title">{{ borehole }}</span>
            </template>
            <template v-else-if="edit">
                <input-cmp 
                    class="oil-card__edit-input"
                    v-if="edit"
                    :label="'Название'"
                    :initValue="title"
                    @set="setTitle"
                />
                <div class="oil-card__edit-btn" @click="saveTitle">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 6L9 17L4 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </template>
            
        </div>
        <div class="oil-card__menubar">
            <menubar-cmp 
                v-for="item in header"
                :key="item.id"
                :active="active"
                :id="item.id"
                class="oil-card__menubar-item"
                :text="item.text"
                @click="selectMenu(item.id)"
            />
        </div>   
        <transition-group name="fadeRight">
                <div v-if="active === 'generalInformation'">
                    <params-cmp
                        v-for="item in table.generalInformation"
                        :key="item"
                        :view="item.type"
                        :table="item.content"
                        :id="$route.params.id"
                        @update_info="upd"
                    >
                    </params-cmp>
                </div>
                <div v-if="active === 'Expenses'">
                    <cost-cmp 
                        v-for="item in table.expenses"
                        :key="item"
                        :view="item.type"
                        :table="item.content"
                    />
                </div>
                <div v-if="active === 'Extraction'">
                    <mining-cmp 
                        v-for="item in table.extraction"
                        :key="item"
                        :view="item.type"
                        :table="item.content"
                    />
                </div>
                <div class="oil-card__research-table" v-if="active === 'Research'">
                    <research-cmp 
                        v-for="item in table.research"
                        :key="item"
                        :view="item.type"
                        :table="item.content"
                        :id="$route.params.id"
                        @add-data="pushResearch"
                    />
                    <!-- <button-cmp 
                        :text="'Сохранить'"
                        @click="sendResearch"
                    /> -->
                </div>
        </transition-group>
    </div>
</template>
<script> 
import Menubar from "../../components/ui-components/oil-menubar/menubar-cmp.vue"
import Params from "../../components/ui-components/oil-params/params-cmp.vue"
import Cost from "../../components/ui-components/oil-accordion/accordion-cmp.vue"
import Mining from "../../components/ui-components/oil-mining/mining-cmp.vue"
import Research from "../../components/ui-components/oil-research/research-cmp.vue"
import inputCmp from "../../components/ui-components/oil-input/input-cmp.vue"
// import buttonCmp from "../../components/ui-components/oil-button/button-cmp.vue"

import axios from "axios"
import { mapState } from 'vuex'

    export default {
        data() {
            return {   
                title: 'Добываюшая скважина P1',
                active: 'generalInformation',
                edit: false,
                table: [],
                mining: [],
                consumption: [],
                research: [],
                wells: [],
                selector: false,
                list: [],
                borehole: '',
                wellsName: [],
                choosesResearch: [],
                update: false,
                header: [
                    {
                        id: 'generalInformation',
                        text: "Общая информация",
                    },
                    {
                        id: 'Expenses',
                        text: "Затраты",
                    },
                    {
                        id: 'Extraction',
                        text: "Добыча",
                    },
                    {
                        id: 'Research',
                        text: "Исследования",
                    },
                ],
            }
        },
        watch: {
            update() {
                if(this.update) {
                    const urls = [
                    `${this.host}Views/Browser/Map/Borehole/generalInformation/${this.$route.params.id}`,
                    `${this.host}Views/Browser/Map/Borehole/Expenses/${this.$route.params.id}`,
                    `${this.host}Views/Browser/Map/Borehole/Extraction/${this.$route.params.id}`,
                    `${this.host}Views/Browser/Map/Borehole/Research/${this.$route.params.id}`
                    ]
                    axios.all(urls.map((endpoint) => axios.get(endpoint)))
                        .then(
                            axios.spread((firstResp, secondResp, thirdResp, fourthResp) => {
                                this.table.generalInformation = firstResp.data 
                                this.table.expenses = secondResp.data 
                                this.table.extraction = thirdResp.data 
                                this.table.research = fourthResp.data
                                this.update = false
                            })
                        // .catch(() => this.$root.visibleNotification({
                        //             props: {
                        //                 error: true,
                        //                 title: "Ошибка",
                        //                 desc: 'Что-то пошло не так, попробуйте позже'
                        //             }
                        //         }))    
                    );   
                }
            },
            wellsName() {
                if(this.borehole === '') {
                    this.borehole = this.wellsName.items.find(item => item.id === this.$route.params.id ? item.name : "Выберите скважину").name
                }
            },
            borehole() {
                const urls = [
                `${this.host}Views/Browser/Map/Borehole/generalInformation/${this.$route.params.id}`,
                `${this.host}Views/Browser/Map/Borehole/Expenses/${this.$route.params.id}`,
                `${this.host}Views/Browser/Map/Borehole/Extraction/${this.$route.params.id}`,
                `${this.host}Views/Browser/Map/Borehole/Research/${this.$route.params.id}`
                ]
                axios.all(urls.map((endpoint) => axios.get(endpoint)))
                    .then(
                        axios.spread((firstResp, secondResp, thirdResp, fourthResp) => {
                            this.table.generalInformation = firstResp.data 
                            this.table.expenses = secondResp.data 
                            this.table.extraction = thirdResp.data 
                            this.table.research = fourthResp.data
                        })
                    // .catch(() => this.$root.visibleNotification({
                    //             props: {
                    //                 error: true,
                    //                 title: "Ошибка",
                    //                 desc: 'Что-то пошло не так, попробуйте позже'
                    //             }
                    //         }))    
                );   
                const url = 'Views/Browser/Map/Borehole/Info'
                this.$root.getInfo(url)
                    .then((resp) => {this.wellsName = resp})
                    .catch(() => this.$root.visibleNotification({
                        props: {
                            error: true,
                            title: "Ошибка",
                            desc: 'Что-то пошло не так, попробуйте позже'
                        }
                    })
                )
            }
        },
        computed: {
            ...mapState('request', ['host'])
        },
        components: {
            "menubar-cmp": Menubar,
            "research-cmp": Research,
            "mining-cmp": Mining,
            "cost-cmp": Cost,
            "params-cmp": Params,
            'input-cmp': inputCmp,
            // 'button-cmp': buttonCmp
        },
        methods: {
            upd(val) {
                this.update = val
            },
            setTitle(val) {
                this.title = val.val
            },
            openEditTitle() {
                this.edit = true
            },
            selectMenu(val) {
                this.active = val;
                
            },
            saveTitle() {
                this.edit = false
            },
            openSelector() {
                this.selector = !this.selector;
            },
            setBorehole(val, id) {
                this.borehole = val
                this.$router.push(`${id}`)
            },
            pushResearch(val) {
                if(!this.choosesResearch.find(item => item.LogNameId === val.LogNameId)) {
                    this.choosesResearch.push(val)
                }
            },
            sendResearch() {
                const url = 'api/v1/Purchased/BoreholeLog'

                this.$root.setInfo({url: url, body: this.choosesResearch})
                .then((resp) => {
                    if(typeof(resp) !== "number") {
                        this.$root.visibleNotification({
                            props: {
                                error: true,
                                title: "Ошибка",
                                desc: resp.response.data
                            }
                        })
                    }
                })
                // axios 
                //     .post(url, this.choosesResearch)
                //     .then((resp) => console.log(resp))
                //     .catch((err) => {
                //         this.$root.visibleNotification({
                //             props: {
                //                 error: true,
                //                 title: "Ошибка",
                //                 desc:err.response.data
                //             }
                //         })
                //     })
            }
        },
        created() {
            const urls = [
                `${this.host}Views/Browser/Map/Borehole/generalInformation/${this.$route.params.id}`,
                `${this.host}Views/Browser/Map/Borehole/Expenses/${this.$route.params.id}`,
                `${this.host}Views/Browser/Map/Borehole/Extraction/${this.$route.params.id}`,
                `${this.host}Views/Browser/Map/Borehole/Research/${this.$route.params.id}`
            ]
            axios.all(urls.map((endpoint) => axios.get(endpoint)))
                .then(
                    axios.spread((firstResp, secondResp, thirdResp, fourthResp) => {
                        this.table.generalInformation = firstResp.data 
                        this.table.expenses = secondResp.data 
                        this.table.extraction = thirdResp.data 
                        this.table.research = fourthResp.data
                    })
                // .catch(() => this.$root.visibleNotification({
                //             props: {
                //                 error: true,
                //                 title: "Ошибка",
                //                 desc: 'Что-то пошло не так, попробуйте позже'
                //             }
                //         }))    
            );   
            const url = 'Views/Browser/Map/Borehole/Info'
            this.$root.getInfo(url)
                .then((resp) => {this.wellsName = resp})
                .catch(() => this.$root.visibleNotification({
                    props: {
                        error: true,
                        title: "Ошибка",
                        desc: 'Что-то пошло не так, попробуйте позже'
                    }
                })
            )
        }
    }
</script>