<template>
    <transition-group name="fadeRight">
      <div v-if="id === active">
        <div class="oil-rules" v-html="text"></div> 
        <div v-if="id === 4 && active === 4" class="oil-rules__material">
          <button @click="dowloadFile('files/PVT_Moon.xlsx', 'PVT_Moon.xlsx')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            PVT_Moon</button>
          <button @click="dowloadFile('files/ОФП_Moon.xlsx', 'ОФП_Moon.xlsx')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            ОФП_Moon</button>
        </div>
      </div>
    </transition-group>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: 1,
    },
    active: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  methods: {
    dowloadFile(url, name) {
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // const link = document.createElement('a')
      
      // link.href = href 
      // link.setAttribute('download', 'plain.zip')
      // document.body.appendChild(link)
      // link.click()

      // document.body.removeChild(link);
      // URL.revokeObjectURL(href);
    }
  }
};
</script>
