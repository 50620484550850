<template>
    <div class="oil-map oil-container">
        <grid-cmp class="oil-map__block" />
        <object-cmp class="oil-map__block" />
    </div>
</template>
<script>
import Grid from "../../components/ui-components/oil-grid/grid-cmp.vue"
import Object from "../../components/ui-components/oil-object/object-cmp.vue"


export default {
    data() {
        return {

        }
    },
    components: {
        'grid-cmp': Grid,
        'object-cmp': Object
    },
}
</script>