<template>
    <div class="oil-maps-table">
        <template v-if="view === 'header'">
            <div class="oil-maps-table__row" v-for="item in table" :key="item">
                <span class="oil-maps-table__row-cell _header">{{ item.maps.text }}
                </span>
                <span class="oil-maps-table__row-cell _header"  @mouseenter="visibleTooltip = item.tu.text" @mouseleave="visibleTooltip = false">{{ item.tu.text }}
                    <transition name="fade">
                        <div class="oil-maps-table__row-cell-tooltip" v-if="item.tu.tooltip && visibleTooltip === item.tu.text">{{ item.tu.tooltip }}</div>
                    </transition>
                </span>
                <span class="oil-maps-table__row-cell _header"  @mouseenter="visibleTooltip = item.capex.text" @mouseleave="visibleTooltip = false">{{ item.capex.text }}
                    <transition name="fade">
                        <div class="oil-maps-table__row-cell-tooltip" v-if="item.capex.tooltip && visibleTooltip === item.capex.text">{{ item.capex.tooltip }}</div>
                    </transition>
                </span>
                <span class="oil-maps-table__row-cell _header"  @mouseenter="visibleTooltip = item.wfu.text" @mouseleave="visibleTooltip = false">{{ item.wfu.text }}
                    <transition name="fade">
                        <div class="oil-maps-table__row-cell-tooltip" v-if="item.wfu.tooltip && visibleTooltip === item.wfu.text">{{ item.wfu.tooltip }}</div>
                    </transition>
                </span>
            </div>
        </template>
        <template v-if="view === 'content'">
            <div v-for="item in table" :key="item" class="oil-maps-table__row _table">
                <span class="oil-maps-table__row-cell">{{ item.maps }}</span>
                <span class="oil-maps-table__row-cell">{{ item.tu }}</span>
                <span class="oil-maps-table__row-cell">{{ item.capex }}</span>
                <span class="oil-maps-table__row-cell">{{ item.wfu }}</span>
                <svg class="oil-maps-table__row-cell-basket"  @click="deleteMaps(item.id)" v-if="item.status === 'Planned'" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.8333 4.99999V4.33332C13.8333 3.3999 13.8333 2.93319 13.6517 2.57667C13.4919 2.26307 13.2369 2.0081 12.9233 1.84831C12.5668 1.66666 12.1001 1.66666 11.1667 1.66666H9.83333C8.89991 1.66666 8.4332 1.66666 8.07668 1.84831C7.76308 2.0081 7.50811 2.26307 7.34832 2.57667C7.16667 2.93319 7.16667 3.3999 7.16667 4.33332V4.99999M3 4.99999H18M16.3333 4.99999V14.3333C16.3333 15.7335 16.3333 16.4335 16.0608 16.9683C15.8212 17.4387 15.4387 17.8212 14.9683 18.0608C14.4335 18.3333 13.7335 18.3333 12.3333 18.3333H8.66667C7.26654 18.3333 6.56647 18.3333 6.03169 18.0608C5.56129 17.8212 5.17883 17.4387 4.93915 16.9683C4.66667 16.4335 4.66667 15.7335 4.66667 14.3333V4.99999" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="oil-maps-table__row">
                <span class="oil-maps-table__row-cell">Итого</span>
                <span class="oil-maps-table__row-cell"></span>
                <span class="oil-maps-table__row-cell">{{ sumCapex(table).toFixed(2) }}</span>
                <span class="oil-maps-table__row-cell">{{ sumWfu(table) }}</span>
            </div>
            <button v-if="table.length <= 3" class="oil-maps-table__row-add" @click="openModal({
                    newState: 'addMapModal'
                })">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 5V19M5 12H19" stroke="#176DC1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>Добавить карту</span>
            </button>
        </template>
    </div>
</template>
<script> 
import { mapMutations, mapState } from 'vuex'
import axios from 'axios'

    export default {
        props: {
            view: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: ''
            },
            table: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                visibleTooltip: false
            }
        },
        computed: {
            ...mapState('request', ['host'])
        },
        methods: {
            ...mapMutations('modal', ['openModal', 'closeModal']),
            ...mapMutations('request', ['updateMap']),

            deleteMaps(id) {
                axios
                    .delete(`${this.host}api/v1/Purchased/SurfaceImage/${id}`)
                
                this.updateMap()   
            },
            sumWfu(val) {
                return val.reduce((accumulator, currentValue) => {return  Number(accumulator) +  Number(currentValue.wfu)}, 0)
            },
            sumCapex(val) {
                return val.reduce((accumulator, currentValue) => {return  Number(accumulator) +  Number(currentValue.capex)}, 0)
            }
        },
    }
</script>