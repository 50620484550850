<template>
    <div class="oil-restore-modal">
        <div class="oil-login-modal__logo-container">
            <img :src="logo" class="oil-login-modal__logo" />
        </div>
        <div class="oil-restore-modal__container">
            <span class="oil-restore-modal__title">Восстановление доступа</span>
            <span class="oil-restore-modal__text">Обратитесь в нашу <a class="oil-restore-modal__tg" href="https://t.me/oilcase_support" target="_blank">службу поддержки в Telegram</a> или на <a href="mailto:support@oilcase.com" class="oil-restore-modal__email">support@oilcase.com</a> вам помогут восстановить доступ. </span>
            <button-cmp 
                :text="'Назад'"
                class="oil-restore-modal__button"
                @click="openModal({
                    newState: 'authModal'
                })"
            />
            <button></button>
            <!-- <button class="oil-restore-modal__switch">Switch to English</button> -->
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import Button from "../../../ui-components/oil-button/button-cmp.vue" 
import OilLogo from "../../../../assets/img/logoOil.png"

    export default {
        data() {
            return {
                modal: true,
                logo: OilLogo
            }
        },
        components: {
            'button-cmp': Button
        },
        methods: {
            ...mapMutations('modal', ['openModal'])
        }
    }
</script>